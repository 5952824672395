<template>
  <div>
    <p>ERRR 404</p>
  </div>
</template>

<script>

export default {
  name: "Spark-Error-404-Not-Found",
  path: "not-found",
  mounted() {
   this.$spark.$breadcrumbs(this.$options);
  }
};
</script>

<template>
  <component :is="template">
    <div
      id="kt_header_mobile"
      class="header-mobile align-items-center spark-mobile-header-banner"
      v-bind:class="headerClasses"
    >
      <!--begin::Logo-->
      <a href="/">
        <img
          class="spark-portal-mobile-header-logo"
          alt="Logo"
          :src="'/media/logos/clients/' + $spark.$config.resources.logo_small_url"
        />
        <div
          class="spark-variant-text-light h3 spark-portal-mobile-header-title"
        >
          {{ $spark.$config.main_brand }}
        </div>
      </a>
      <!--end::Logo-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <!--begin::Topbar Mobile Toggle-->
        <button
          class="btn btn-hover-text-primary p-0 ml-2"
          id="kt_header_mobile_topbar_toggle"
          ref="kt_header_mobile_topbar_toggle"
          @click="toggleMenu"
        >
          <span class="svg-icon svg-icon-xl">
            <!--begin::Svg Icon | path:svg/icons/General/User.svg-->
            <inline-svg
              class="svg-icon"
              src="media/svg/icons/General/User.svg"
            />
            <!--end::Svg Icon-->
          </span>
        </button>
        <!--end::Topbar Mobile Toggle-->
      </div>
      <!--end::Toolbar-->
    </div>
    <KTMenuMobile></KTMenuMobile>
  </component>
</template>

<script>
import KTLayoutHeaderTopbar from "@/assets/js/layout/base/header-topbar.js";
import KTMenuMobile from "@/view/spark_layout/portal/wireframe/MenuMobile";
import {
  CLIENT_REMOTE_STYLES,
  SPARK_TEMPLATE_HEADER,
} from "@/core/services/vars.service";

export default {
  name: "KTHeaderMobile",
  components: {
    KTMenuMobile
  },
  data() {
    return {
      menu_open: false,
      template: null
    };
  },
  mounted() {
    // Init Header Topbar For Mobile Mode
    KTLayoutHeaderTopbar.init(this.$refs["kt_header_mobile_topbar_toggle"]);
  },
  beforeMount() {
    this.template = this.$store.getters[CLIENT_REMOTE_STYLES](SPARK_TEMPLATE_HEADER);
  },
  methods: {
    toggleMenu() {
      this.menu_open = !this.menu_open;
      return true;
    },
  },
  computed: {

    menuOpen() {
      return this.menu_open;
    },

    /**
     * Get header logo
     * @returns {string}
     */
    headerLogo() {
      return process.env.BASE_URL + this.$store.getters["layoutConfig"]("self.logo.dark");
    },

    /**
     * Get classes for mobile header
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.$store.getters["getClasses"]("header_mobile");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.$store.getters["layoutConfig"]("aside.self.display");
    }
  }
};
</script>

<style>
.header-mobile {
  background-color: var(--spark-variant-primary) !important;
}
.spark-mobile-header-banner {
  border-bottom: 1px solid var(--spark-variant-primary-light);
}
.spark-portal-mobile-header-logo {
  max-height: 45px;
  margin-right: 1em;
  vertical-align: top;
}
.spark-portal-mobile-header-title {
  display: inline-block;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  margin-top: 0.7em;
}
@media (max-width: 399.98px) {
  .spark-portal-mobile-header-title {
    max-width: calc(100% - 100px);
  }
}
</style>

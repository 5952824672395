import axios from "axios";
import $spark from "@/core/services/spark.service";
import store from "@/core/services/store/index";
import { 
  REFRESH_AUTH,
  AUTH_TOKEN,
  CURRENT_USER,
  SET_SPARK_LOADING,
  SET, LOAD,
  ERROR_401, ERROR_403, ERROR_404, ERROR_427, INTERNAL_ERROR
} from "@/core/services/vars.service";

/*
example config, mainly for MY memory while im writing it
*/

/*
var EXAMPLE = {
  name: "GETTER_NAME", // name of the getter for this data, will be prepended with LOAD_ (action) and SET_ (mutation)
  default: Array(), // default for the state object - if not inferred from pk/paginated, {}, null, false etc, if unspecified and not inferred, will set to null
  pk: true, // if set action needs pk in payload AND getter needs pk - OPTIONAL [CAN BE TRUE: we default to pk=id, or string of pk key name]
  paginated: true, // if set and true, action needs page in payload AND getter needs page - OPTIONAL
  method: "post", // method, defaults to get - OPTIONAL
  commit: false, // if set to false, will not commit the data from the response to the default mutation (for cases where its not a standard list/detail)
  url: {
    path: "test path", // this is the default, specify a path, all is good, or you can omit this,
    customer: PORTAL_URLS.account.customer.detail, // and specify a customer AND vet url instead
    vet: PORTAL_URLS.account.vet.detail,
    replace: {
      "CLAIM_PK": 'CLAIM_DETAIL_PK', // this is the getter name CONSTANT for replacing a url value with a pk  value
      "DOCUMENT_PK": 'CLAIM_DOCUMENT_PK' // we have an object because of multiples
    }
  },
  on_success: [SET_CLIENT_REMOTE_STYLES, SET_CLIENT_REMOTE_STYLES] // extra custom mutations
}
*/

/* create function that can be called to populate the store with the state value for this poke */
/* synchronous so no need to reassign options to internal variable */
const State = (_options) => {

  var options = _options;

  /* default state value for this state object upon initialisation */
  let state = null;

  /* possibly override the default state value based on response object type or a manual override from options */
  if (options.pk) {

    /* if this is a pk poke (eg multiple claim objects) set the state as an empty object */
    /* this object is the home for ALL pokes to this url, can retrieve by passsing the getter the pk of the object */
    state = null;
  } else if (options.paginated && options.paginated === true) {

    /* if this is a pagination poke, (eg claim list, 6 per page) set the state as an object */
    /* this object stores pagination data and response data */
    state = {
      data: {},
      pagination: null
    }
  } else if (options.default) {

    /* override the default state value from options */
    state = options.default;
  }
  return state;
}

/* create function that can be called to create a wrapper functionn for executing extra actions/mutations */
const After = (_options, _payload) => {

  /* set options as internal variable so it cant be fucked with by anything else */
  /* the function we are about to create is executed asynchronously, so we cover our ass */
  var options = _options;
  /* save the get/post/put/patch params, to use in the serializer */
  var payload = _payload;

  /* return a function thats waiting execution, after a successful poke, and the default mutation */
  /* accepts store dispatch/commit context and api response as args */
  return (dispatch, commit, response) => {

    return new Promise((resolve, reject) => {

      /* if extra mutations, perform them sequentially */
      if (options.commit_on_success) {
        options.commit_on_success.forEach(mutation => {
          console.log(mutation, typeof mutation);
          if (typeof mutation === "string") {
            commit(mutation, response);
          } else {
            commit(mutation.name, mutation.serializer(payload, response));
          }
        });
      }

      /* if extra actions, they are async, so we need to resolve this promise (what promise??) once they are all executed */
      /* the code (and said promise!) to make this async capable will be added when i find a poke that requires it to happen */
      if (options.dispatch_on_success) {
        options.dispatch_on_success.forEach(action => {
          if (typeof action === "string") {
            dispatch(action, response).catch((e) => { 
              reject(e); 
            });
          } else {
            dispatch(action.name, action.serializer(payload, response)).catch((e) => { 
              reject(e);
            });
          }
        });
      }
      resolve(response);
    });
  }
}

/* create function that can be called to populate the store with the action for this poke */
const Action = (_options) => {

  /* set options as internal variable so it cant be fucked with by anything else */
  /* the function we are about to create is executed asynchronously, so we cover our ass */
  var options = _options;

  /* [[PLACEHOLDER]] */

  /* return a function thats waiting execution within $spark.$api() */
  /* accepts store execution functions / payload as args */
  /* store functions within object as $store.dispatch('action_name', payload) passes an object */
  /* the function initialises a new Promise each time it is invoked by $store.dispatch() */
  /* I <3 ASYNC JAVASCRIPT */
  return ({ dispatch, commit }, payload=null) => new Promise((resolve, reject) => {

    //console.log(payload, options);

    /* execute After() to generate a function to run after a successful API poke (for other actions/mutations) */
    /* After() returns the function that gets saved in the 'after' variable, ready for use later */
    /* as this is async, i execute After() when the promise gets invoked for each poke */
    /* NOTE: could possibly be moved to the [[PLACEHOLDER]] above, and only executed once */
    /* i would prefer that as it matches the theme of generating everything once at init(), ready to be called when necessary by each poke */
    let after = After(options, payload);

    /* set defaults */
    let loader = true;
    let domain = process.env.VUE_APP_ROOT_API_URL;
    let auth = true;
    let method = "get";
    let default_commit = true;
    let error_override = null;

    /* set vars for data within the promise */
    let error_message = null;
    let url = null;
    let url_value = null;
    let token = null;
    let headers = {};
    let params = {};
    let data = {};
    let file = false;

    /* possibly override defaults from options */
    if (options.hasOwnProperty("loader") && options.loader === false) {
      loader = false;
    }
    if (options.hasOwnProperty("auth") && options.auth === false) {
      auth = false;
    }
    if (options.hasOwnProperty("commit") && options.commit === false) {
      default_commit = false;
    }
    if (options.method) {
      method = options.method.toLowerCase();
    }
    if (options.file) {
      file = options.file;
    }
    if (options.error) {
      error_override = options.error;
    }
    if ($spark.$config.api_root) {
      domain = $spark.$config.api_root;
    }

    //console.log("AUTH REQUIREED", auth);

    /* if auth required, get token from store and set user type as variable for url */
    if (auth) {
      token = store.getters[AUTH_TOKEN];

      /* if token expected but not in store, reject the promise */
      if (!token) {
        
        $spark.$portalLogin({error: ERROR_401});
        return reject();
      }
      headers["Authorization"] = token;
    }

    /* if uploading file, add content type header */
    if (file) {
      headers["Content-Type"] = "multipart/form-data";
    }

    /* if payload contains headers, add them to the headers object */
    if (payload && payload.headers) {
      Object.assign(headers, payload.headers);
    }

    /* if payload contains any params, add to axios options (get/post/put/patch key/value pairs) */
    if (payload && payload.params) {
      if (method === "get") {
        params = payload.params;
      } else {
        data = payload.params;
      }
    }

    /* get url from options */
    if (options.url.path) {
      url = options.url.path;
    } else {
      url = options.url[store.getters[CURRENT_USER].$type];
    }

    /* if any parameters in url string, replace with values fetched from the store */
    if (options.url.replace) {

      /* loop each parameter */
      Object.keys(options.url.replace).forEach(key => {

        /* if no getter, reject the promise (this is technically impossible unless you fuck up the options object) */
        if (!(options.url.replace[key] in store.getters)) {
          $spark.$dashboard({error: "Action 1" + INTERNAL_ERROR});
          return reject();
        }

        /* because in loop, reset to null before trying to get the value from the store */
        url_value = null;
        url_value = store.getters[options.url.replace[key]];

        /* if getter value not set, reject the promise */
        if (!url_value) {
          $spark.$dashboard({error: "Action 2" + INTERNAL_ERROR});
          return reject();
        }

        /* do the needful to replace the placeholder in the url string with the value from the store */
        url = url.replace(key, url_value);
      });
    }
    
    /* if loader needed for this poke, turn it on */
    if (loader) {
      commit(SET_SPARK_LOADING, { fn: LOAD + options.name, status: true });
    }

    /* do we need to use serializers on the input params / data */
    if (options.input_param_serializer) {
      params = options.input_param_serializer(params);
    }
    if (options.input_data_serializer) {
      data = options.input_data_serializer(data);
    }

    console.log(domain + url, method, params, data, headers )
    
    /* do the pokey pokey, in out, in out, shake it all about */
    axios({
      url: domain + url,
      method: method,
      params: params,
      data: data,
      headers: headers,
      validateStatus: function (status) {
        return status >= 200 && status <= 299; // Resolve only if the status code is 2xx
      }
    }).then(response => {

      /* API SUCCESS */


      /* perform default mutation to commit the response to the state object */
      if (default_commit) {
        if (options.default_serializer) {
          commit(SET + options.name, options.default_serializer(payload, response.data));
        } else {
          commit(SET + options.name, response.data);
        }
      }

      /* execute after() function to dispatch/commit the response to any other necessary places */
      after(dispatch, commit, response.data).catch((e) => {
        console.error("Spark Error: After", options.name, response.data, e);
        $spark.$error("Action 3" + INTERNAL_ERROR);
        return reject();
      });

      /* disable loader */
      if (loader) {
        commit(SET_SPARK_LOADING, { fn: LOAD + options.name, status: false });
      }

      /* if auth required, refresh auth (re-saves the token to "start the auto logout timer" again) */
      if (auth === true) {
        dispatch(REFRESH_AUTH);
      }

      /* resolve the promise, for $spark.$api().then((response) => { // dostuff }); */
      if (options.resolve_serializer) {
        resolve(options.resolve_serializer(payload, response.data));
      } else {
        resolve(response.data);
      }

    }).catch(error => {

      /* API ERROR */

      /* disable loader */
      if (loader) {
        commit(SET_SPARK_LOADING, { fn: LOAD + options.name, status: false });
      }
      
      /* necessary because of the bullshit below */
      console.log("PARSE ERROR", error, error.response);

      if (error.response) {
        if (error.response.status === 401) {
          $spark.$portalLogin({error: ERROR_401});
          return reject();
        } else if(error.response.status === 403) {
          $spark.$dashboard({error: ERROR_403});
          return reject();
        } else if(error.response.status === 404) {
          if ($spark.$secure()) {
            $spark.$dashboard({error: ERROR_404});
          } else {
            $spark.$portalLogin({error: ERROR_404});
          }
          return reject();
        } else if(error.response.status === 427) {
          $spark.$error(ERROR_427);
          return reject();
        }
      }

      /* this whole fucking block is absolutely disgusting and we must standardise the API error response !!@!!!!!!!! */
      if (!error.response.data) return [];
      if (error.response.data.non_field_errors) {
        error_message = error.response.data.non_field_errors;
      } else if (error.response.data.errors) {
        if (error.response.data.errors.non_field_errors) {
          error_message = error.response.data.errors.non_field_errors;
        }
      } else if (error.response.data.detail) {
        error_message = [error.response.data.detail];
      }
      /* end bullshit block */

      /* if options set override message, don't bother with API error */
      if (error_override) {

        /* make alert show with error messages */
        $spark.$error(error_override);

        /* add override message to reject error response, incase the component needs it */
        error_message = error.response;
        error_message.override = error_override;
      
      /* if the bullshit above extracted parse-able errors, throw them to the alert component */
      /* if no message extracted, throw the entire response object (including status code etc) as the promise rejection data */
      } else if (error_message) {

        /* make alert show with error messages */
        $spark.$error(Array(error_message).join("<br/>"));
      } else {

        /* set response object (including status code etc) to pass to $spark.$api().catch((error) => { // dostuff }); */
        error_message = error.response;
      }

      /* reject the promise for $spark.$api().catch((error) => { // dostuff }); */
      if (options.reject_serializer) {
        reject(options.reject_serializer(payload, error.response));
      } else {
        reject(error_message);
      }
    });
  });
}


/* create function that can be called to populate the store with the default mutation for this poke */
/* synchronous so no need to reassign options to internal variable */
const Mutation = (_options) => {

  var options = _options;

  /* use expected response object type from options to determine the type of mutation function required to be created */
  /* mutation functions we add to the store config must return a function that an be executed by vuex with a payload to save */

  /* if this is a pk poke (eg multiple claim objects) save the response to the state object */
  /* if options.pk is a string, will use that as the pk key name to grab the value from the response */
  if (options.pk) {

    /* default pk key name */
    //let field = "id";

    /* possibly override the pk key name */
    //if (typeof options.pk === "string") {
    //  field = options.pk;
    //}

    /* return mutation function for saving the pk object */
    return (state, pk_payload) => {
      console.log(options.name, options, state, pk_payload)
      /* extract the object pk from the response object */
      //let pk = pk_payload[field];

      /* save the data, using pk as object key */
      //state[options.name][pk] = pk_payload;
      state[options.name] = pk_payload;
    }

  /* if this is a list poke, with pagination, (eg claim list, 6 per page) save the response and pagination data to the state object */
  } else if (options.paginated && options.paginated === true) {

    /* return mutation function for saving the pagination object */
    return (state, pagination_payload) => {
      console.log(options.name, options, state, pagination_payload)

      /* extract the current page number from the response object */
      let page = pagination_payload.pagination.current_page;

      /* save the data using current page number as the object key */
      state[options.name].data[page] = pagination_payload.data;

      /* save the pagination data so it can be used to display nav buttons */
      state[options.name].pagination = pagination_payload.pagination;
    }

  /* default mutation function to save the response to the state object */
  } else {

    /* return mutation function for saving the response */
    return (state, payload) => {
      console.log(options.name, options, state, payload)
      /* save the data */
      state[options.name] = payload;
    }
  }
}


/* create function that can be called to populate the store with the default getter for this poke's data */
const Getter = (_options) => {

  /* set options as internal variable so it cant be fucked with by anything else */
  /* synchronous function, BUT, because getters are watched in computed properies, we err on the side of caution */
  const options = _options;

  console.log(options);

  /* pk items are stored in an object with pk's as the key (multiple claims etc) */
  if (options.pk) { 

    /*if (!(options.name in state)) {
      return () => {
        return null;
      }
    }*/

    /* return getter function for retrieving the pk object */
    /* component computed properties and methods can execute this anytime, with the desired pk value to retrieve the object */
    return (state) => {

      /* if pk not found, return null (i hate random KeyNotFound errors :D) */
      //if (!state[options.name].hasOwnProperty(state.detail_pk)) return null;

      /* return the pk object */
      //return state[options.name][state.detail_pk];
     return state[options.name];
    }

  /* pagination objects are stored in an object with page numbers as the key */
  } else if (options.paginated && options.paginated === true) {

    /* return getter function for retrieving the desired page of data from the stored pagination object */
    /* component computed properties and methods can execute this anytime, with the desired pk value to retrieve the object */
    return (state) => {

      /* if page not given, get current page */
      if (!state[options.name].pagination) return null;

      /* extract the current page number from the response object */
      let page = state[options.name].pagination.current_page;

      /* return the pagination object page data */
      return state[options.name].data[page];

      /* if pk not found, return null (i hate random KeyNotFound errors :D) */
      
    }

  /* basic objects (default) are just *stored* - the getter accepts no parameter */
  } else {

    /* return getter function for retrieving the object */
    return (state) => {
      /* return the data */
      return state[options.name];
    }
  }
}

/* create function that can be called to populate the store with the pagination getter for this poke's pagination data */
/* this is only executed and injected into the store if its a pagination object */
const PaginationGetter = (_options) => {

  /* set options as internal variable so it cant be fucked with by anything else */
  /* synchronous function, BUT, because getters are watched in computed properies, we err on the side of caution */
  var options = _options;

  /* return getter function for retrieving the pagination object */
  return (state) => {

    /* return the pagination object */
    return state[options.name].pagination;
  }
}

export { State, Action, Mutation, Getter, PaginationGetter }

<template>
  <div class="mb-10 g-0">
    <!-- ONLY SHOW SECTIONS IF CONDITIONS PASSED -->
    <div v-show="conditionsMet === true">
      <!-- KNOWN PROPERTY DISPLAY -->
      <template v-if="question.hasOwnProperty('property_value')">
        <spark-display-property
          :label="question.label"
          :sub_label="question.sub_label"
          :question_name="question.name"
          :value="question.property_value"
          :field_type="question.field_type"
          :choices="question.choices"
        ></spark-display-property>
      </template>
      <!-- END KNOWN PROPERTY DISPLAY -->

      <!-- FULL WIDTH PARAGRAPH DISPLAY -->
      <template v-else-if="question.field_type === 'HTMLParagraph'">
        <template
          v-if="
            question.name && question.name.startsWith('pet_details_confirm')
          "
        >
          <spark-warning
            :label="question.label"
            :sub_label="question.sub_label"
          ></spark-warning>
        </template>

        <template
          v-else-if="question.name.startsWith('bank_account_validation')"
        >
          <spark-bank-validation
            :ref="question.name + '_answer_component_' + counter"
            :id="question.id"
            :label="question.label"
            :sub_label="question.sub_label"
            :fields="question.fields"
          ></spark-bank-validation>
        </template>

        <spark-paragraph
          v-else
          :label="question.label"
          :sub_label="question.sub_label"
        ></spark-paragraph>
      </template>
      <!-- END FULL WIDTH PARAGRAPH DISPLAY -->

      <!-- START QUESTION CAPTURE -->
      <template v-else>
        <!-- HIDDEN AUTOGENERATED UUID -->
        <template v-if="question.field_type === 'UUIDField'">
          <spark-uid
            :id="question.id"
            :ref="question.name + '_answer_component_' + counter"
          ></spark-uid>
        </template>
        <!-- END HIDDEN AUTOGENERATED UUID -->

        <!-- BOOLEAN  -->
        <template v-else-if="question.field_type === 'BooleanField'">
          <spark-boolean
            :label="question.label"
            :sub_label="question.sub_label"
            :id="question.id"
            :watched_by="watched_by"
            :question_name="question.name"
            :ref="question.name + '_answer_component_' + counter"
          ></spark-boolean>
        </template>
        <!-- END BOOLEAN -->

        <!-- CHOICE  -->
        <template v-else-if="question.field_type === 'ChoiceField'">
          <template
            v-if="
              $parent.addressChoices &&
                $parent.addressChoices.hasOwnProperty(
                  $parent.in_obj.name + '_' + counter
                ) &&
                $parent.addressChoices[$parent.in_obj.name + '_' + counter]
                  .length > 0 &&
                question.name.startsWith('line_1_select')
            "
          >
            <spark-choice
              :label="question.label"
              :sub_label="question.sub_label"
              :id="question.id"
              :watched_by="watched_by"
              :question_name="question.name"
              :ref="question.name + '_answer_component_' + counter"
              :choices="
                $parent.addressChoices[$parent.in_obj.name + '_' + counter]
              "
            ></spark-choice>
          </template>
          <template
            v-else-if="
              $parent.vetsChoices && question.name.startsWith('vet_name_select')
            "
          >
            <spark-choice
              :label="question.label"
              :sub_label="question.sub_label"
              :id="question.id"
              :watched_by="watched_by"
              :question_name="question.name"
              :ref="question.name + '_answer_component_' + counter"
              :choices="$parent.vetsChoices"
              :not_listed="(question.disallow_not_listed === true ? false : true)"
            ></spark-choice>
          </template>
          <template v-else-if="!question.choices">
            <spark-choice
              :label="question.label"
              :sub_label="question.sub_label"
              :id="question.id"
              :watched_by="watched_by"
              :question_name="question.name"
              :ref="question.name + '_answer_component_' + counter"
              :choices="[]"
              :not_listed="(question.disallow_not_listed === true ? false : true)"
            ></spark-choice>
          </template>
          <template v-else>
            <spark-choice
              :label="question.label"
              :sub_label="question.sub_label"
              :id="question.id"
              :watched_by="watched_by"
              :question_name="question.name"
              :ref="question.name + '_answer_component_' + counter"
              :choices="question.choices"
            ></spark-choice>
          </template>
        </template>
        <!-- END CHOICE -->

        <!-- MULTIPLE CHOICE  -->
        <template v-else-if="question.field_type === 'MultipleChoiceField'">
          <spark-multiple-choice
            :label="question.label"
            :sub_label="question.sub_label"
            :id="question.id"
            :watched_by="watched_by"
            :question_name="question.name"
            :ref="question.name + '_answer_component_' + counter"
            :choices="question.choices"
          ></spark-multiple-choice>
        </template>
        <!-- END MULTIPLE CHOICE -->

        <!-- DATE  -->
        <template v-else-if="question.field_type === 'DateField'">
          <spark-date
            :label="question.label"
            :sub_label="question.sub_label"
            :id="question.id"
            :watched_by="watched_by"
            :question_name="question.name"
            :ref="question.name + '_answer_component_' + counter"
          ></spark-date>
        </template>
        <!-- END DATE -->

        <!-- TIME  -->
        <template v-else-if="question.field_type === 'TimeField'">
          <spark-time
            :label="question.label"
            :sub_label="question.sub_label"
            :id="question.id"
            :watched_by="watched_by"
            :question_name="question.name"
            :ref="question.name + '_answer_component_' + counter"
          ></spark-time>
        </template>
        <!-- END TIME -->

        <!-- DATETIME  -->
        <template v-else-if="question.field_type === 'DateTimeField'">
          <spark-datetime
            :label="question.label"
            :sub_label="question.sub_label"
            :id="question.id"
            :watched_by="watched_by"
            :question_name="question.name"
            :ref="question.name + '_answer_component_' + counter"
          ></spark-datetime>
        </template>
        <!-- END DATETIME -->

        <!-- MONEY  -->
        <template v-else-if="question.field_type === 'DecimalField'">
          <spark-money
            :label="question.label"
            :sub_label="question.sub_label"
            :id="question.id"
            :watched_by="watched_by"
            :question_name="question.name"
            :ref="question.name + '_answer_component_' + counter"
            :disabled="question.readonly"
          ></spark-money>
        </template>
        <!-- END MONEY -->

        <!-- POSTCODE  -->
        <template v-else-if="question.label === 'Postcode'">
          <spark-postcode
            :label="question.label"
            :sub_label="question.sub_label"
            :id="question.id"
            :watched_by="watched_by"
            :question_name="question.name"
            :ref="question.name + '_answer_component_' + counter"
          ></spark-postcode>
        </template>
        <!-- END POSTCODE -->

        <!-- TEXTBOX  -->
        <template v-else-if="question.field_type === 'TextField'">
          <spark-textbox
            :label="question.label"
            :sub_label="question.sub_label"
            :id="question.id"
            :watched_by="watched_by"
            :question_name="question.name"
            :ref="question.name + '_answer_component_' + counter"
            :disabled="question.readonly"
          ></spark-textbox>
        </template>
        <!-- END TEXTBOX -->

        <!-- TEXT SMALL  -->
        <template
          v-else-if="
            question.name.startsWith('account_number') ||
              question.name.startsWith('account_sort_code') ||
              question.field_type === 'PositiveIntegerField'
          "
        >
          <spark-text-small
            :label="question.label"
            :sub_label="question.sub_label"
            :id="question.id"
            :watched_by="watched_by"
            :question_name="question.name"
            :ref="question.name + '_answer_component_' + counter"
            :disabled="question.readonly"
          ></spark-text-small>
        </template>
        <!-- END TEXT SMALL -->

        <!-- TEXT DEFAULT  -->
        <template v-else>
          <spark-text
            :label="question.label"
            :sub_label="question.sub_label"
            :id="question.id"
            :watched_by="watched_by"
            :question_name="question.name"
            :ref="question.name + '_answer_component_' + counter"
            :disabled="question.readonly"
          ></spark-text>
        </template>
        <!-- END TEXT DEFAULT -->
      </template>
      <!-- END QUESTION CAPTURE -->
    </div>
  </div>
</template>

<script>
import {
  CLAIM_FORM_ADD_CONDITION,
  CLAIM_FORM_ADD_WATCHED_FIELD,
  CLAIM_FORM_ADD_FIELD_VALIDATION,
  SET_CLAIM_FORM_SECTION_VALIDATION_TRIGGER,
  SET_QUESTION_FIELD_DATA,
  SET_QUESTION_FIELD_CHOICES,
  //CLAIM_FORM_ADD_WATCH_ID_UNIQUE,
  CLAIM_FORM_ADD_FIELD_DATA
} from "@/core/services/vars.service";

export default {
  name: "spark-question",
  props: {
    obj: {
      type: Object,
      required: true
    },
    counter: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      in_obj: null,
      data_path:
        this.$parent.data_path + "." + this.obj.name + "_" + this.counter,
      can_show: false,
      deleted: false,
      watched_by: [],
      question: this.obj,
      subscription: null,
      error_messages: [],
      validation_passed: false,
      multiple_conds: Array(),
      value: "",
      watchers: {}
    };
  },
  computed: {
    canShow() {
      if (this.obj.name === "sections_claiming_for_1") return true;
      //if (!this.$parent.canShow) return false;
      if (!this.obj.conditional) return true;
      return this.can_show;
    },
    conditionsMet() {
      return this.canShow && !this.deleted;
    },
    validationPassed() {
      return this.validation_passed;
    },
    Value() {
      return this.value;
    },
    child() {
      if (
        !(this.in_obj.name + "_answer_component_" + this.counter in this.$refs)
      )
        return null;
      return this.$refs[this.in_obj.name + "_answer_component_" + this.counter];
    },
    nestedPassedValidation() {
      if (!this.$parent.canShow) return true;
      if (this.conditionsMet === false) return true;
      if (!this.error_messages) return true;
      return this.error_messages.length === 0;
    },
    dataManipulation() {
      return Object.keys(this.$parent.manipulation).filter(key => {
        return (
          (this.$parent.manipulation[key].type === "set_field_value" &&
            this.$parent.manipulation[key].value.type === "form_field" &&
            this.obj.name.startsWith(
              this.$parent.manipulation[key].value.field
            )) ||
          (this.$parent.manipulation[key].type === "set_field_value" &&
            this.$parent.manipulation[key].value.type ===
              "form_field_combine" &&
            (this.obj.name.startsWith(
              this.$parent.manipulation[key].value.field_1
            ) ||
              this.obj.name.startsWith(
                this.$parent.manipulation[key].value.field_2
              ))) ||
          (this.$parent.manipulation[key].type === "lookup" &&
            this.obj.name.startsWith(this.$parent.manipulation[key].field))
        );
      });
    },
    dataManipulationTrigger() {
      return this.dataManipulation.length > 0;
    },
    dataManipulationRecipient() {
      return (
        Object.keys(this.$parent.manipulation).filter(key => {
          return (
            (this.$parent.manipulation[key].type === "set_field_value" &&
              this.obj.name.startsWith(this.$parent.manipulation[key].field)) ||
            (this.$parent.manipulation[key].type === "lookup" &&
              this.obj.name.startsWith(
                this.$parent.manipulation[key].response.field
              ))
          );
        }).length > 0
      );
    },
    dataManipulationOnChange() {
      return (
        Object.keys(this.$parent.manipulation).filter(key => {
          return (
            this.$parent.manipulation[key].type === "lookup" &&
            this.$parent.manipulation[key].response.on_choice_change &&
            this.obj.name.startsWith(
              this.$parent.manipulation[key].response.field
            )
          );
        }).length > 0
      );
    },
    dataManipulationOnChangeRecipient() {
      let is_recipient = false;
      Object.keys(this.$parent.manipulation).forEach(key => {
        if (
          this.$parent.manipulation[key].type === "lookup" &&
          this.$parent.manipulation[key].response.on_choice_change &&
          this.$parent.manipulation[key].response.on_choice_change.data_map
        ) {
          Object.keys(
            this.$parent.manipulation[key].response.on_choice_change.data_map
          ).forEach(map_key => {
            if (this.obj.name.startsWith(map_key)) {
              is_recipient = true;
            }
          });
        }
      });
      return is_recipient;
    },
    dataManipulationShow() {
      return (
        Object.keys(this.$parent.manipulation_show).filter(key => {
          return (
            this.$parent.manipulation_show[key].filter(m => {
              return this.obj.name.startsWith(m);
            }).length > 0
          );
        }).length > 0
      );
    },
    dataManipulationShowTrigger() {
      return (
        Object.keys(this.$parent.manipulation_show).filter(key => {
          return (
            this.$parent.manipulation_show[key].filter(show => {
              return this.obj.name.startsWith(show.field);
            }).length > 0
          );
        }).length > 0
      );
    }
  },
  /*watch: {
    conditionsMet: function(new_val) {
      if (
        this.obj.field_type === "ChoiceField" &&
        this.obj.default_choice
      ) {
        console.error("CHOICE", this.obj, this.counter);
        if (new_val === true) {
          this.child.value = this.obj.default_choice;
          this.child.$forceUpdate();
        }
      }
    },
    value: function(new_val, old_val){
      if (this.conditionsMet) {
        this.hasValidationPassed();
        if (this.dataManipulationTrigger) {
          this.$parent.performDataManipulation(this.in_obj.name, this.Value, this.validationPassed, this.counter);
        }
      }
      if(this.in_obj.watched_by && new_val !== old_val){
        this.$spark.$dispatch(CLAIM_FORM_ADD_FIELD_DATA, {
          name: this.in_obj.name,
          key: this.data_path, 
          spark_unique: this.in_obj.spark_unique,
          value: this.Value,
          counter: this.counter
        });
      } 
    },
    validation_passed: function() {
      if(this.in_obj.watched_by){
        this.$spark.$dispatch(CLAIM_FORM_ADD_FIELD_VALIDATION, {
          name: this.in_obj.name,
          key: this.data_path, 
          spark_unique: this.in_obj.spark_unique,
          value: this.validationPassed,
          counter: this.counter,
        });
      }
    }
  },*/
  methods: {
    emitEvent(event) {
      console.log("EVENT EMITTING FROM Q", this.in_obj.name, event);
      return this.emitHandler(event);
    },
    emitHandler(event) {
      if (!this.deleted && this.in_obj.conditional) {
        this.can_show = this.$spark.hasPassedAllConditions(
          this.in_obj.conditional,
          event
        );
      }
      if (!this.in_obj.conditional) {
        console.log("NO CHECKS, CAN SHOW");
        this.can_show = true;
      }
      //this.$forceUpdate();
      return Number(this.can_show);
    },
    recomputeCanShow() {
      if (
        !this.deleted &&
        this.in_obj.conditional &&
        this.in_obj.conditional.length > 0
      ) {
        this.can_show = this.$spark.hasPassedAllConditions(
          this.in_obj.conditional,
          this.counter,
          this.$refs
        );
      } else {
        this.can_show = true;
      }
      this.$forceUpdate();
      return Number(this.can_show);
    },
    buildDataObject() {
      try {
        if (this.in_obj.field_type === "BooleanField") {
          return Boolean(this.Value);
        } else if (this.in_obj.field_type == "IntegerField") {
          return parseInt(this.Value);
        } else if (this.in_obj.field_type == "PositiveIntegerField") {
          return parseInt(this.Value) >= 0 ? parseInt(this.Value) : null;
        } else if (this.in_obj.field_type == "FloatField") {
          return parseFloat(this.Value);
        } else if (this.in_obj.field_type == "DecimalField") {
          return parseFloat(this.Value).toFixed(2);
        } else if (this.in_obj.field_type == "ChoiceField") {
          if (this.Value == "not_listed") {
            return this.Value;
          }
          return this.Value;
        } else {
          //console.error("NO CAST ", this.Value, this.in_obj.field_type);
          return this.Value;
        }
      } catch (e) {
        //console.error("CANT CAST ", this.Value, this.in_obj.field_type, e);
        return this.Value;
      }
    },
    hasValidationPassed(value = null) {
      var res = null;
      if (!this.conditionsMet) return true;
      if (!this.in_obj.validation) return true;
      if (this.in_obj.name.startsWith("bank_account_validation")) {
        res = { passed: this.validationPassed };
      } else if (this.in_obj.optional === true && this.value.length === 0) {
        res = { passed: true };
      } else {
        if (value === null) {
          //value = this.child.Value;
          alert("OOPS");
        }
        res = this.$spark.hasValidationPassed(
          value,
          this.in_obj.validation,
          this.in_obj.field_type
        );
      }
      if (res.passed) {
        this.error_messages = [];
      } else {
        this.error_messages = res.errors;
      }
      this.validation_passed = res.passed;
      return res.passed;
    }
  },
  beforeDestroy() {
    if (this.subscription) {
      // the subscribe method returns a function to call to unsubscribe
      this.subscription();
    }
  },
  beforeMount() {
    let mapped_key = null;
    this.in_obj = Object.assign({}, this.obj);
    if (
      this.dataManipulationRecipient ||
      this.dataManipulationOnChangeRecipient
    ) {
      Object.keys(this.$parent.manipulation).forEach(key => {
        if (this.$parent.manipulation[key].type === "set_field_value") {
          if (this.obj.name.startsWith(this.$parent.manipulation[key].field)) {
            this.$parent.manipulation_fields[
              this.$parent.manipulation[key].field + "_" + this.counter
            ] = this.obj.name + "_" + this.counter;
          }
        }
        if (this.$parent.manipulation[key].type === "lookup") {
          if (
            this.obj.name.startsWith(
              this.$parent.manipulation[key].response.field
            )
          ) {
            this.$parent.manipulation_fields[
              this.$parent.manipulation[key].response.field + "_" + this.counter
            ] = this.obj.name + "_" + this.counter;
          }
          if (this.$parent.manipulation[key].response.on_choice_change) {
            if (
              this.$parent.manipulation[key].response.on_choice_change.data_map
            ) {
              Object.keys(
                this.$parent.manipulation[key].response.on_choice_change
                  .data_map
              ).forEach(map_key => {
                mapped_key = this.$parent.manipulation[key].response
                  .on_choice_change.data_map[map_key];
                if (this.obj.name.startsWith(mapped_key)) {
                  this.$parent.manipulation_fields[
                    map_key + "_" + this.counter
                  ] = this.obj.name + "_" + this.counter;
                }
              });
            }
          }
        }
      });
    }
    if (this.in_obj.conditional) {
      let new_conds = Array();
      let new_cond = null;
      this.in_obj.conditional.forEach(cond => {
        new_cond = Object.assign({}, cond);
        if (cond.multiple) {
          if (this.counter > 0) {
            new_cond.element_name = this.data_path;
            //new_cond.spark_unique = new_cond.spark_unique.replace(/^([a-zA-Z0-9_]+)_[0-9]+$/, "$1_" + this.counter);
            new_cond.spark_unique = new_cond.spark_unique + "_" + this.counter;
          } else {
            new_cond.element_name = this.data_path;
            new_cond.spark_unique = new_cond.spark_unique + "_" + this.counter;
          }
          this.multiple_conds.push(new_cond.spark_unique);
        } else {
          if (this.counter > 0) {
            new_cond.element_name = this.data_path;
            //new_cond.spark_unique = new_cond.spark_unique.replace(/^([a-zA-Z0-9_]+)_[0-9]+$/, "$1_" + this.counter);
            new_cond.spark_unique = new_cond.spark_unique + "_" + this.counter;
          } else {
            new_cond.element_name = this.data_path;
            new_cond.spark_unique = new_cond.spark_unique + "_" + this.counter;
          }
        }
        new_conds.push(new_cond);
        this.$spark.$dispatch(CLAIM_FORM_ADD_CONDITION, {
          key: new_cond.spark_unique,
          value: new_cond
        });
      });
      this.in_obj.conditional = new_conds;
    }
    if (this.in_obj.spark_unique) {
      let new_unique = Array();
      this.in_obj.spark_unique.forEach(obj => {
        //if (!this.multiple_conds.includes(obj)) {
        //if (this.counter > 0) {
        //new_unique.push(obj.replace(/^([a-zA-Z0-9_]+)_[0-9]+$/, "$1_" + this.counter));
        //} else {
        new_unique.push(obj + "_" + this.counter);
        //}
        //}
      });
      this.in_obj.spark_unique = new_unique;
      this.$spark.$dispatch(CLAIM_FORM_ADD_WATCHED_FIELD, {
        key: this.data_path,
        value: {
          spark_unique: this.in_obj.spark_unique
        }
      });
    }
    /*if (this.in_obj.spark_unique) {
      let new_unique = Array();
      //console.error(this.in_obj.data_path)
      this.in_obj.spark_unique.forEach(obj => {
        new_unique.push(this.data_path.replace(/^([a-zA-Z0-9_.]+_)[0-9]+$/, "$1") + obj + "_" + this.counter);
      })
      this.in_obj.spark_unique = new_unique;

      this.$spark.$dispatch(CLAIM_FORM_ADD_WATCHED_FIELD, {
        key: this.data_path, 
        value: {
          spark_unique: this.in_obj.spark_unique,
        }
      });
    }*/
    var filtered = null;
    this.subscription = this.$store.subscribe(mutation => {
      if (mutation.type === SET_QUESTION_FIELD_DATA) {
        if (mutation.payload.name === this.obj.name + "_" + this.counter) {
          this.child.value = mutation.payload.value;
        }
      }
      if (mutation.type === SET_QUESTION_FIELD_CHOICES) {
        if (mutation.payload.name === this.obj.name + "_" + this.counter) {
          this.child.replacement_choice_keys = mutation.payload.choice_keys;
          this.child.replacement_choices = mutation.payload.choices;
          this.hasValidationPassed("null");
        }
      }
      if (mutation.type === SET_CLAIM_FORM_SECTION_VALIDATION_TRIGGER) {
        if (mutation.payload === this.data_path.split(".")[0]) {
          this.hasValidationPassed(this.Value);
        }
      }
      if (this.in_obj.conditional) {
        if (mutation.type === CLAIM_FORM_ADD_FIELD_DATA) {
          //console.log("SP", mutation.payload.spark_unique);
          filtered = this.in_obj.conditional.filter(cond => {
            //console.log("CND", cond.spark_unique);
            return mutation.payload.spark_unique.includes(cond.spark_unique);
          });
          //console.log("Q fiiltered", filtered);
          if (filtered.length > 0) {
            ///} && filtered[0].comparison_type !== "validation_passed"){
            //console.log("Checking conditions VALUE", this.in_obj.name, mutation.payload.value)
            let pass_status = this.$spark.hasPassedAllConditions(
              this.in_obj.conditional,
              mutation.payload,
              "value"
            );
            if (pass_status !== null) {
              this.can_show = pass_status;
            } else {
              this.can_show = false;
            }
          }
          /*if (state.claim_form.watched_fields.hasOwnProperty(mutation.payload.key)) {
            filtered = this.in_obj.conditional.filter(cond => {
              return state.claim_form.watched_fields[mutation.payload.key].spark_unique.includes(cond.spark_unique)
            })
            if (filtered.length > 0){ ///} && filtered[0].comparison_type !== "validation_passed"){
              console.log("Checking conditions VALUE", this.in_obj.name, mutation.payload.value)
              let pass_status = this.$spark.hasPassedAllConditions(this.in_obj.conditional, mutation.payload, "value");
              if  (pass_status !== null)  {
                this.can_show = pass_status;
              } else {
                this.can_show = false;
              }
            } else {
              this.can_show = false;
            }
          }*/
        }
        if (mutation.type === CLAIM_FORM_ADD_FIELD_VALIDATION) {
          //console.log("SP", mutation.payload.spark_unique);
          filtered = this.in_obj.conditional.filter(cond => {
            //console.log("CND", cond.spark_unique);
            return mutation.payload.spark_unique.includes(cond.spark_unique);
          });
          //console.log("Q fiiltered", filtered);
          if (filtered.length > 0) {
            //} === 1 && filtered[0].comparison_type === "validation_passed"){
            //console.log("Checking conditions VALIDATION", this.in_obj.name, mutation.payload.value, state)
            let pass_status = this.$spark.hasPassedAllConditions(
              this.in_obj.conditional,
              mutation.payload,
              "validation"
            );
            if (pass_status !== null) {
              this.can_show = pass_status;
            } else {
              this.can_show = false;
            }
          }
          /*if (state.claim_form.watched_fields.hasOwnProperty(mutation.payload.key)) {
            filtered = this.in_obj.conditional.filter(cond => {
              return state.claim_form.watched_fields[mutation.payload.key].spark_unique.includes(cond.spark_unique)
            })
            console.log("Q fiiltered", filtered);
            if (filtered.length > 0){//} === 1 && filtered[0].comparison_type === "validation_passed"){
              console.log("Checking conditions VALIDATION", this.in_obj.name, mutation.payload.value, state)
              let pass_status = this.$spark.hasPassedAllConditions(this.in_obj.conditional, mutation.payload, "validation");
              if  (pass_status !== null)  {
                this.can_show = pass_status;
              } else {
                this.can_show = false;
              }
            } else {
              this.can_show = false;
            }
          }*/
        }
      }
    });
  },
  mounted() {
    if (
      this.in_obj.field_type === "ChoiceField" &&
      this.in_obj.default_choice
    ) {
      this.$watch("conditionsMet", function(new_val) {
        //console.error("CHOICE", this.obj, this.counter);
        if (new_val === true) {
          this.child.value = this.in_obj.default_choice;
          this.child.$forceUpdate();
        }
      });
    }
    if (
      this.in_obj.watched_by ||
      this.dataManipulationTrigger ||
      this.dataManipulationOnChange
    ) {
      this.$watch("value", function(new_val, old_val) {
        if (this.dataManipulationTrigger) {
          this.$parent.performDataManipulation(
            this.in_obj.name,
            new_val,
            this.validationPassed,
            this.counter
          );
        }
        if (this.dataManipulationOnChange) {
          this.$parent.performDataManipulationOnChange(
            this.in_obj.name,
            new_val,
            this.validationPassed,
            this.counter
          );
        }
        if (this.in_obj.watched_by) {
          if (new_val !== old_val) {
            this.$spark.$dispatch(CLAIM_FORM_ADD_FIELD_DATA, {
              name: this.in_obj.name,
              key: this.data_path,
              spark_unique: this.in_obj.spark_unique,
              value: this.Value,
              counter: this.counter
            });
          }
        }
      });
      if (this.in_obj.watched_by) {
        this.$watch("validation_passed", function() {
          this.$spark.$dispatch(CLAIM_FORM_ADD_FIELD_VALIDATION, {
            name: this.in_obj.name,
            key: this.data_path,
            spark_unique: this.in_obj.spark_unique,
            value: this.validationPassed,
            counter: this.counter
          });
        });
      }
    }
    if (this.in_obj.watched_by && this.in_obj.property_value) {
      this.value = this.in_obj.property_value;
    }
    /*this.$watch('value', function(new_val, old_val){
      if (this.conditionsMet) {
        this.hasValidationPassed();
      }
      if (this.dataManipulationTrigger) {
        this.$parent.performDataManipulation(this.in_obj.name, new_val, this.validationPassed, this.counter);
      }
      if (this.in_obj.watched_by) {
        if(new_val !== old_val){
          this.$spark.$dispatch(CLAIM_FORM_ADD_FIELD_DATA, {
            name: this.in_obj.name,
            key: this.data_path, 
            spark_unique: this.in_obj.spark_unique,
            value: this.Value,
            counter: this.counter
          });
        }
      }
    });*/

    /*if(this.in_obj.conditional){
      let new_conds = Array();
      let new_cond = null;
      this.in_obj.conditional.forEach((cond) => {
        new_cond = Object.assign({}, cond);
        if (cond.multiple) {
          if (this.counter > 0) {
            new_cond.element_name = this.data_path;
            new_cond.spark_unique = new_cond.spark_unique.replace(/^([a-zA-Z0-9_]+)_[0-9]+$/, "$1_" + this.counter);
            //new_cond.spark_unique = new_cond.spark_unique + "_" + this.counter;
          } else {
            new_cond.element_name = this.data_path;
            new_cond.spark_unique = new_cond.spark_unique + "_" + this.counter;
          }
          this.multiple_conds.push(new_cond.spark_unique);
        } else {
          if (this.counter > 0) {
            new_cond.element_name = this.data_path;
            new_cond.spark_unique = new_cond.spark_unique.replace(/^([a-zA-Z0-9_]+)_[0-9]+$/, "$1_" + this.counter);
            //new_cond.spark_unique = new_cond.spark_unique + "_" + this.counter;
          } else {
            new_cond.element_name = this.data_path;
            new_cond.spark_unique = new_cond.spark_unique + "_" + this.counter;
          }
        }
        new_conds.push(new_cond);
        this.$spark.$dispatch(CLAIM_FORM_ADD_CONDITION, {key: new_cond.spark_unique, value: new_cond});
      });
      this.in_obj.conditional = new_conds;
    }
    if (this.in_obj.spark_unique) {
      let new_unique = Array();
      this.in_obj.spark_unique.forEach(obj => {
        //if (!this.multiple_conds.includes(obj)) {
          if (this.counter > 0) {
            new_unique.push(obj.replace(/^([a-zA-Z0-9_]+)_[0-9]+$/, "$1_" + this.counter));
          } else {
            new_unique.push(obj + "_" + this.counter);
          }
        //}
      })
      this.in_obj.spark_unique = new_unique;

      this.$spark.$dispatch(CLAIM_FORM_ADD_WATCHED_FIELD, {
        key: this.data_path, 
        value: {
          spark_unique: new_unique,
        }
      });
      /*this.in_obj.spark_unique.forEach(unique => {
        this.in_obj.watched_by.forEach(watched_by => {
          if (this.$parent.chidren_are_multiples) {
            this.$spark.$dispatch(CLAIM_FORM_ADD_WATCH_ID_UNIQUE, {key: unique + "_" + watched_by + "_" + this.counter, value: this.data_path});
          } else {
            this.$spark.$dispatch(CLAIM_FORM_ADD_WATCH_ID_UNIQUE, {key: unique + "_" + watched_by, value: this.data_path});
          }
          
          //this.$spark.getNextWatchId(unique + "_" + watched_by, this.data_path);

        });
      });*/

    //this.$spark.$dispatch(SET_CLAIM_FORM_ROOT_COMPONENTS, {key: this.in_obj.name, component: this});
    /*if(this.in_obj.conditional){
      this.in_obj.conditional.forEach((cond) => {
        cond.element_name = this.data_path;
        this.$spark.$dispatch(CLAIM_FORM_ADD_CONDITION, {key: cond.condition_unique, value: cond});
        //console.log(CLAIM_FORM_ADD_WATCHED_FIELD)
      });
    }
    if (this.in_obj.watched_by) {
      this.watched_by = this.in_obj.watched_by;
      this.watched_by.forEach(watch_unique => {
        let new_unique = this.$spark.getNextWatchId(
          watch_unique,
          this.data_path
        );
        this.$spark.$dispatch(CLAIM_FORM_ADD_WATCHED_FIELD, {
          key: watch_unique + "_" + new_unique, 
          value: this._computedWatchers.Value.vm._computedWatchers.Value
        });
      });
    }*/
    /*
    let component = this.$parent.$refs[
      this.in_obj.name + "_question_component_" + this.counter
    ];
    this.$spark.questions[this.in_obj.name + "_" + this.counter] = component;
    if (this.in_obj.watched_by) {
      //this.$spark.watch_bindings[this.in_obj.name+"_"+this.counter] = this._computedWatchers.Value;
      this.watched_by = this.in_obj.watched_by;
      //this.watched_field_data
      this.watched_by.forEach(watch_unique => {

        let new_unique = this.$spark.getNextWatchId(
          watch_unique,
          this.in_obj.name + "_" + this.counter
        );
        this._computedWatchers.Value._question_name =
          this.in_obj.name + "_" + this.counter;
        this.$spark.watched_fields[
          watch_unique + "_" + new_unique
        ] = this._computedWatchers.Value.vm._computedWatchers.Value;
        //this.$spark.watched_fields[watch_unique] = //this.$spark.watch_bindings[this.in_obj.name+"_"+this.counter]
        //this.$spark.conditions[watch_unique] = this.in_obj.name+"_"+this.counter;
      });
      this.$spark.watched_field_data[
        this.in_obj.name + "_" + this.counter
      ] = this.watched_by;
      //this.$spark.watched_fields[this.in_obj.name+"_"+this.counter] = this.watched_by;
      this.$spark.setStore(
        this.in_obj.id,
        this.value,
        this.in_obj.name + "_" + this.counter,
        true
      );
    }
    if (this.in_obj.conditional) {
      this.in_obj.conditional.forEach(cond => {
        cond.element_name = this.in_obj.name + "_" + this.counter;
        this.$spark.conditions[cond.condition_unique] = cond;
        //this.condition_unique.push(cond.condition_unique);
        //Object.keys(this.$spark.watched_field_data).forEach((q_name) => {
        //    if(this.$spark.watched_field_data[q_name].includes(cond.watch_unique)){
        //cond.watcher = this.$spark.watch_bindings[q_name];
        //        if(cond.comparison_type === "validation_passed"){
        //cond.validation_passed =  this._computedWatchers.validationPassed;
        //this.$spark.watch_bindings[cond.watch_unique+"_"+] = this._computedWatchers.validationPassed;
        //        }
        //    }
        //});
      });
    }*/
  }
};
</script>
// router.js
export const SECURE = "secure";
export const ADMIN_SECURITY = "admin";
export const ADMIN_URL_PATH = "admin";
export const ADMIN_NAME = "Admin";
export const PORTAL_SECURITY = "portal";
export const PORTAL_URL_PATH = "portal";
export const PORTAL_NAME = "Portal";
export const ERROR_URL_PATH = "error";
export const CUSTOMER = "customer";
export const VET = "vet";
export const DIV = "div";
export const COLOR = "color";

export const DELETE_STORAGE_ITEM = "deleteStorageItem";
export const APPEND_BREADCRUMB = "appendBreadcrumb";
export const SET_BREADCRUMB = "setBreadcrumb";
export const ADD_BREADCRUMB = "addBreadcrumb";

export const PROBLEM_PAYMENT_ISSUE = "payments_up_to_date";
export const PROBLEM_RENEWAL_DUE = "due_for_renewal_soon";
export const PROBLEM_AWAITING_CUSTOMER_INFO = "awaiting_ph_info";
export const PROBLEM_AWAITING_CUSTOMER_DOCS = "awaiting_ph_docs";
export const PROBLEM_AWAITING_VET_INFO = "awaiting_vet_info";
export const PROBLEM_AWAITING_VET_DOCS = "awaiting_vet_docs";
export const PROBLEM_AWAITING_CUSTOMER_APPROVAL = "awaiting_customer_approval";
export const PROBLEM_AWAITING_CUSTOMER_FURTHER_INFO = "further_info_required";

export const CLAIM = "claim";
export const POLICY = "policy";
export const TYPE = "type";
export const TEAM = "team";
export const PERMISSION = "permission";
export const QUEUE = "queue";
export const MEMBER = "member";
export const ADDON_PATH = "addon";
export const AGGREGATOR_PATH = "aggregator";
export const BRAND_PATH = "brand";
export const CALLCENTRE_PATH = "call-centre";
export const COMMISSION_PATH = "commission";
export const CORRESPONDENCE_PATH = "correspondence";
export const DOCUMENT_PATH = "document";
export const ENDORSEMENT_PATH = "endorsement";
export const EXCLUSION_PATH = "exclusion";
export const PRODUCT_PATH = "product";
export const UNDERWRITER_PATH = "underwriter";
export const USER_PATH = "user";
export const MENU_ITEMS = "menuItems";

// spark dynamic store
export const API_DOMAIN = "api_domain";
export const LOAD = "LOAD_";
export const SET = "SET_";
export const PAGE = "PAGE_";
export const CLIENT_CONFIG = "client_config";

// getter types
export const AUTH_EXPIRY = 60; // 60 mins
export const ALERT_ERROR_TIME = 15;
export const ALERT_SUCCESS_TIME = 10;
export const ALERT_DATA = "alertData";
export const AUTH_TOKEN = "authToken";
export const CURRENT_USER = "currentUser";
export const IS_AUTHENTICATED = "isAuthenticated";
export const HAS_GROUP = "hasGroup";
export const HAS_PERMISSION = "hasPermission";
export const CONFIRM_DATA = "confirmData";
export const CONFIRM_COMPONENT = "confirmComponent";
export const CLIENT_CONTACT_DETAILS = "clientContactDetails";
export const CLIENT_STYLES = "clientStyles";
export const CLIENT_REMOTE_STYLES = "clientRemoteStyles";
export const CLIENT_RESOURCES = "clientResources";
export const CLIENT_REMOTE_RESOURCES = "clientRemoteResources";
export const CLIENT_NAME = "clientName";
export const CLIENT_FOOTER_TOP = "clientFooterTop";
export const CLIENT_FOOTER_BOTTOM = "clientFooterBottom";
export const ABOUT_PAGES = "aboutPages";
export const PROBLEMS = "problems";
export const FIX_PROBLEMS = "fixProblems";
export const CLEAR_FIX_PROBLEMS = "clearFixProblems";
export const PORTAL_DASHBOARD = "portalDashboard";
export const NAV_BLOCKED = "navBlocked";
export const NAV_BLOCKED_LOGOUT_DATA = "navBlockedLogoutData";
export const PORTAL_LOGIN = "portalLogin";
export const SPARK_TEMPLATE = "spark-template";
export const SPARK_TEMPLATE_HEADER = "spark-template-header";
export const SPARK_TEMPLATE_SUB_HEADER = "spark-template-sub-header";
export const SPARK_TEMPLATE_MENU = "spark-template-menu";
export const SPARK_TEMPLATE_MENU_BUTTONS = "spark-template-menu-buttons";
export const SPARK_TEMPLATE_FOOTER_TOP = "spark-template-footer-top";
export const SPARK_TEMPLATE_FOOTER_BOTTOM = "spark-template-footer-bottom";
export const SPARK_TEMPLATE_CONTENT = "spark-template-content";
export const SPARK_TEMPLATE_SECONDARY = "spark-template-secondary";
export const SPARK_TEMPLATE_PRIMARY = "spark-template-primary";
export const SPARK_TEMPLATE_SECONDARY_LIGHT = "spark-template-secondary-light";
export const SPARK_TEMPLATE_PRIMARY_LIGHT = "spark-template-primary-light";
export const SPARK_TEMPLATE_TEXT_LIGHT = "spark-template-text-light";
export const SPARK_TEMPLATE_TEXT_MID = "spark-template-text-mid";
export const SPARK_TEMPLATE_TEXT_DARK = "spark-template-text-dark";
export const POLICY_LIST = "policyList";
export const POLICY_DETAIL = "policyDetail";
export const POLICY_DETAIL_PK = "policyDetailPK";
export const CLAIM_CUSTOMER_DISCLAIMER = "claimCustomerDisclaimer";
export const CLAIM_VET_DISCLAIMER = "claimVetDisclaimer";
export const CLAIM_FORM = "claimForm";
export const CLAIM_FORM_AUTO_POPULATED = "claimFormAutoPopulated";
export const CLAIM_FORM_AUTO_POPULATED_DATA = "claimFormAutoPopulatedData";
export const CLAIM_FORM_CURRENT_SECTION = "claimFormCurrentSection";
export const CLAIM_FORM_SECTION_CAN_SHOW = "claimFormSectionCanShow";
export const CLAIM_FORM_HAS_SELECTED_SECTIONS_CLAIMING =
  "claimFormHasSelectedSectionsClaiming";
export const CLAIM_FORM_HAS_CONDITIONAL_SHOW_PASSED =
  "claimFormHasConditionalShowPassed";
export const CLAIM_FORM_ADD_CONDITION = "claimFormAddCondition";
export const CLAIM_FORM_ADD_WATCHED_FIELD = "claimFormAddWatchedField";
export const CLAIM_FORM_GET_WATCH_VALUE = "claimFormGetWatchValue";
export const CLAIM_FORM_WATCH_ID_UNIQUE = "claimFormWatchIDUnique";
export const CLAIM_FORM_CONDITION_FROM_ELEMENT_NAME =
  "claimFormConditionFromElementName";
export const CLAIM_FORM_GET_FIELD_VALIDATION = "claimFormGetFieldValidation";
export const CLAIM_FORM_GET_FIELD_DATA = "claimFormGetFieldData";
export const CLAIM_INFORMATION_COMPONENT = "claimInformationComponent";
export const PETS_CLAIM_LIST_PAGE = "petsClaimListPage";
export const PETS_CLAIM_CREATE_PAGE = "petsClaimCreatePage";
export const PETS_CLAIM_MANAGE_PAGE = "petsClaimManagePage";
export const PETS_CLAIM_UPDATE_PAGE = "petsClaimUpdatePage";
export const CLAIM_LIST = "claimList";
export const CLAIM_LIST_PAGE = "claimListPage";
export const CLAIM_LIST_PAGINATION = "claimListPagination";
export const CLAIM_DETAIL = "claimDetail";
export const CLAIM_DETAIL_PK = "claimDetailPK";
export const CLAIM_DOCUMENT_LIST = "claimDocumentList";
export const CLAIM_DOCUMENT_DETAIL = "claimDocumentDetail";
export const CLAIM_DOCUMENT_DETAIL_PK = "claimDocumentDetailPK";
export const CORRESPONDENCE_CONTACT_REFERENCES =
  "correspondenceContactReferences";
export const CORRESPONDENCE_LIST_PAGE = "correspondenceListPage";
export const CORRESPONDENCE_LIST_PAGINATION = "correspondenceListPagination";
export const CORRESPONDENCE_LIST = "correspondenceList";
export const CORRESPONDENCE_DETAIL = "correspondenceDetail";
export const CORRESPONDENCE_DETAIL_PK = "correspondenceDetailPK";
export const CORRESPONDENCE_DOCUMENT_LIST = "correspondenceDocumentList";
export const ACCOUNT_PASSWORD_CHANGE_COMPONENT =
  "accountPassswordChangeComponent";
export const ACCOUNT_DETAIL = "accountDetail";
export const ACCOUNT_DETAIL_PK = "accountDetailPK";
export const COMPLAINT_LIST = "complaintList";
export const COMPLAINT_DETAIL = "complaintDetail";
export const COMPLAINT_DETAIL_PK = "complaintDetailPK";
export const COMPLAINT_DOCUMENT_LIST = "complaintDocumentList";
export const COMPLAINT_DOCUMENT_DETAIL = "complaintDocumentDetail";
export const COMPLAINT_DOCUMENT_DETAIL_PK = "complaintDocumentDetailPK";
export const PORTAL_DASHBOARD_STATUS = "portalDashboardStatus";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const REFRESH_AUTH = "refreshAuth";
export const LOAD_AUTH = "loadAuth";
export const PUSH_TO = "pushTo";
export const LOGIN = "login";
export const AUTO_LOGIN = "autoLogin";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";
export const LOOKUP_VET = "lookupVet";
export const LOOKUP_VET_MEDICATION = "lookupVetMedication";
export const LOOKUP_CUSTOMER = "lookupCustomer";
export const LOOKUP_POSTCODE = "lookupPostcode";
export const LOOKUP_BANK_DETAILS = "lookupBankDetails";
export const LOAD_PROBLEMS = "loadProblems";
export const LOAD_POLICY_LIST = "loadPolicyList";
export const LOAD_POLICY_DETAIL = "loadPolicyDetail";
export const LOAD_CLAIM_FORM = "loadClaimForm";
export const SUBMIT_CLAIM_FORM = "submitClaimForm";
export const SUBMIT_CLAIM_APPROVE = "submitClaimApprove";
export const LOAD_CLAIM_LIST = "loadClaimList";
export const LOAD_CLAIM_DETAIL = "loadClaimDetail";
export const LOAD_CLAIM_DOCUMENT_LIST = "loadClaimDocumentList";
export const LOAD_CLAIM_DOCUMENT_DETAIL = "loadClaimDocumentDetail";
export const UPLOAD_CLAIM_DOCUMENT = "uploadClaimDocument";
export const UPLOAD_CLAIM_EXTRA_DOCUMENT = "uploadClaimExtraDocument";
export const LOAD_CORRESPONDENCE_CONTACT_REFERENCES =
  "loadCorrespondenceContactReferences";
export const SUBMIT_CORRESPONDENCE_CONTACT_FORM =
  "submitCorrespondenceContactForm";
export const SUBMIT_CORRESPONDENCE_PUBLIC_CONTACT_FORM =
  "submitCorrespondencePublicContactForm";
export const LOAD_CORRESPONDENCE_LIST = "loadCorrespondenceList";
export const LOAD_CORRESPONDENCE_DETAIL = "loadCorrespondenceDetail";
export const SUBMIT_PASSWORD_CHANGE_FORM = "submitPasswordChangeForm";
export const LOAD_ACCOUNT_DETAIL = "loadAccountDetail";
export const LOAD_COMPLAINT_LIST = "loadComplaintList";
export const LOAD_COMPLAINT_DETAIL = "loadComplaintDetail";
export const LOAD_COMPLAINT_DOCUMENT_LIST = "loadComplaintDocumentList";
export const LOAD_COMPLAINT_DOCUMENT_DETAIL = "loadComplaintDocumentDetail";
export const UPLOAD_COMPLAINT_DOCUMENT = "uploadComplaintDocument";
export const LOAD_PORTAL_DASHBOARD_STATUS = "loadPortalDashboardStatus";
export const BLOCK_NAV_HANDLER = "blockNavHandler";

// mutation types
export const SET_SPARK_LOADING = "setSparkLoading";
export const LOAD_TOKEN = "loadToken";
export const PURGE_AUTH = "logOut";
export const AUTH_START_TIMER = "authStartTimer";
export const AUTH_CANCEL_TIMER = "authCancelTimer";
export const SET_AUTH = "setUser";
export const SET_USER_TYPE = "setUserType";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";
export const SET_ALERT = "setAlert";
export const RESET_ALERT = "resetAlert";
export const SET_CONFIRM = "setConfirm";
export const SET_CONFIRM_COMPONENT = "setConfirmComponent";
export const RESET_CONFIRM = "resetConfirm";
export const SET_HTML_FIELDS = "setHTMLFields";
export const SET_CLIENT_REMOTE_STYLES = "setClientRemoteStyles";
export const SET_CLIENT_DETAILS = "setClientDetails";
export const SET_CLIENT_REMOTE_RESOURCES = "setClientRemoteResources";
export const SET_PROBLEMS = "setProblems";
export const SET_MENU = "setMenuItems";
export const SET_VET_RESULTS = "setVetLookupResults";
export const SET_VET_MEDICATION_RESULTS = "setVetMedicationResults";
export const SET_CUSTOMER_RESULTS = "setCustomerLookupResults";
export const SET_POSTCODE_RESULTS = "setPostcodeLookupResults";
export const SET_BANK_DETAILS_RESULTS = "setBankDetailsLookupResults";
export const SET_POLICY_LIST = "setPolicyList";
export const SET_POLICY_DETAIL = "setPolicyDetail";
export const SET_POLICY_DETAIL_PK = "setPolicyDetailPK";
export const RESET_CLAIM_FORM = "resetClaimForm";
export const SET_CLAIM_FORM = "setClaimForm";
export const SET_SUBMIT_CLAIM_FORM_RESPONSE = "setSubmitClaimFormResponse";
export const SET_SUBMIT_CLAIM_APPROVE_RESPONSE =
  "setSubmitClaimApproveResponse";
export const SET_CLAIM_FORM_CURRENT_SECTION = "setClaimFormCurrentSection";
export const SET_CLAIM_FORM_ROOT_COMPONENTS = "setClaimFormRootComponents";
export const CLAIM_FORM_ADD_WATCH_ID_UNIQUE = "claimFormAddWatchIDUnique";
export const CLAIM_FORM_ADD_FIELD_DATA = "claimFormAddFieldData";
export const CLAIM_FORM_ADD_FIELD_VALIDATION = "claimFormAddFieldValidation";
export const SET_CLAIM_FORM_SECTION_VALIDATION_TRIGGER =
  "setClaimFormSectionValidationTrigger";
export const SET_CLAIM_FORM_SECTION_CAN_SHOW = "setClaimFormSectionCanShow";
export const SET_CLAIM_INFORMATION_COMPONENT = "setClaimInformationComponent";
export const SET_PETS_CLAIM_PAGES = "setPetsClaimPages";
export const SET_CLAIM_LIST_PAGE = "setClaimListPage";
export const SET_CLAIM_LIST = "setClaimList";
export const SET_CLAIM_DETAIL = "setClaimDetail";
export const SET_CLAIM_DETAIL_PK = "setClaimDetailPK";
export const SET_CLAIM_DOCUMENT_LIST = "setClaimDocumentList";
export const SET_CLAIM_DOCUMENT_DETAIL = "setClaimDocumentDetail";
export const SET_CLAIM_DOCUMENT_DETAIL_PK = "setClaimDocumentDetailPK";
export const SET_CLAIM_DOCUMENT_UPLOAD_RESPONSE =
  "setClaimDocumentUploadResponse";
export const SET_CORRESPONDENCE_CONTACT_REFERENCES =
  "setCorrespondenceContactReferences";
export const SET_CORRESPONDENCE_CONTACT_FORM_RESPONSE =
  "setCorrespondenceContactFormResponse";
export const SET_CORRESPONDENCE_PUBLIC_CONTACT_FORM_RESPONSE =
  "setCorrespondencePublicContactFormResponse";
export const SET_CORRESPONDENCE_LIST_PAGE = "setCorrespondenceListPage";
export const SET_CORRESPONDENCE_LIST = "setCorrespondenceList";
export const SET_CORRESPONDENCE_DETAIL = "setCorrespondenceDetail";
export const SET_CORRESPONDENCE_DETAIL_PK = "setCorrespondenceDetailPK";
export const SET_SUBMIT_PASSWORD_CHANGE_FORM_RERSPONSE =
  "setSubmitPasswordChangeFormResponse";
export const SET_ACCOUNT_PASSWORD_CHANGE_COMPONENT =
  "setAccountPassswordChangeComponent";
export const SET_ACCOUNT_DETAIL = "setAccountDetail";
export const SET_ACCOUNT_DETAIL_PK = "setAccountDetailPK";
export const SET_COMPLAINT_LIST = "setComplaintList";
export const SET_COMPLAINT_DETAIL = "setComplaintDetail";
export const SET_COMPLAINT_DETAIL_PK = "setComplaintDetailPK";
export const SET_COMPLAINT_DOCUMENT_LIST = "setComplaintDocumentList";
export const SET_COMPLAINT_DOCUMENT_DETAIL = "setComplaintDocumentDetail";
export const SET_COMPLAINT_DOCUMENT_DETAIL_PK = "setComplaintDocumentDetailPK";
export const SET_PORTAL_DASHBOARD_STATUS = "setPortalDashboardStatus";
export const SET_NAV_BLOCKED = "setNavBlocked";
export const SET_NAV_BLOCKED_FN = "setNavBlockedFunctions";
export const SET_NAV_BLOCKED_LOGOUT_DATA = "setNavBlockedLogoutData";
export const SET_QUESTION_FIELD_DATA = "setQuestionFieldData";
export const SET_QUESTION_FIELD_CHOICES = "setQuestionFieldChoices";

// page names
export const PAGE_NAME_PORTAL_ACCOUNT_MANAGE = "Portal-Account-Manage";
export const PAGE_NAME_PORTAL_POLICY_MANAGE = "Portal-Policy-Manage";
export const PAGE_NAME_PORTAL_CORRESPONDENCE_MANAGE =
  "Portal-Correspondence-Manage";
export const PAGE_NAME_PORTAL_CORRESPONDENCE_LIST =
  "Portal-Correspondence-List";
export const PAGE_NAME_PORTAL_CORRESPONDENCE_CONTACT_US =
  "Portal-Correspondence-Contact-Us";
export const PAGE_NAME_PORTAL_CLAIM_CUSTOMER_LIST =
  "Portal-Claim-Pets-Customer-List";
export const PAGE_NAME_PORTAL_CLAIM_CUSTOMER_MANAGE =
  "Portal-Claim-Pets-Customer-Manage";
export const PAGE_NAME_PORTAL_CLAIM_CUSTOMER_CREATE =
  "Portal-Claim-Pets-Customer-Create";
export const PAGE_NAME_PORTAL_CLAIM_CUSTOMER_APPROVE =
  "Portal-Claim-Pets-Customer-Approve";
export const PAGE_NAME_PORTAL_CLAIM_CUSTOMER_FURTHER_INFO =
  "Portal-Claim-Pets-Customer-Further-Info";
export const PAGE_NAME_PORTAL_CLAIM_CUSTOMER_UPDATE =
  "Portal-Claim-Pets-Customer-Update"; // new
export const PAGE_NAME_PORTAL_CLAIM_VET_LIST = "Portal-Claim-Pets-Vet-List";
export const PAGE_NAME_PORTAL_CLAIM_VET_MANAGE = "Portal-Claim-Pets-Vet-Manage";
export const PAGE_NAME_PORTAL_CLAIM_VET_CREATE = "Portal-Claim-Pets-Vet-Create";
export const PAGE_NAME_PORTAL_CLAIM_VET_UPDATE = "Portal-Claim-Pets-Vet-Update"; // new
export const PAGE_NAME_PORTAL_DOCUMENT_CLAIM_LIST =
  "Portal-Document-Claim-List";
export const PAGE_NAME_PORTAL_DOCUMENT_CLAIM_UPLOAD =
  "Portal-Document-Claim-Upload";
export const PAGE_NAME_PORTAL_DOCUMENT_COMPLAINT_LIST =
  "Portal-Document-Complaint-List";
export const PAGE_NAME_PORTAL_DOCUMENT_COMPLAINT_UPLOAD =
  "Portal-Document-Complaint-Upload";
export const PAGE_NAME_PORTAL_FIX_PROBLEMS = "Portal-Fix-Problems";

export const POSTCODE_REGEX = /^([Gg][Ii][Rr]\s?0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))\s?[0-9][A-Za-z]{2})$/;


export const INTERNAL_ERROR = ": Internal Error";
export const ERROR_401 = "401: You must be logged in to access secure resources";
export const ERROR_403 = "403: Permission denied";
export const ERROR_404 = "404: Resource not found";
export const ERROR_427 = "427: Too many invalid login attempts";
export const SYSTEM_MESSAGE_LOGOUT_GOODBYE = "You have logged out successfully";

// URLs
// portal
export const PORTAL_URLS = {
  auth: {
    login: "/api/login/",
    auto_login: "/api/ignite/auth/",
    verify: "/api/verify/"
  },
  spark: {
    config: "/api/spark/config/",
    dashboard: "/api/spark/portal/dashboard/",
    problems: "/api/spark/portal/problems/"
  },
  bank_details: "/api/payments/validate-bank-details/",
  postcode: "/api/postcode/search/",
  vet_search: "/api/vet/search/",
  customer_search: "/api/customer/search/",
  vet_medication_search: "/api/claims/medication/search/",
  policy: {
    list: "/api/policies/",
    detail: "/api/policies/<POLICY_PK>/"
  },
  claim: {
    list: "/api/claims/",
    detail: "/api/claims/<CLAIM_PK>/",
    create: "/api/claims/dynamic/",
    approve: "/api/claims/<CLAIM_PK>/approve/"
  },
  document: {
    claim: {
      list: "/api/claims/<CLAIM_PK>/documents/",
      detail: "/api/claims/<CLAIM_PK>/documents/<DOCUMENT_PK>/",
      upload: "/api/claims/<CLAIM_PK>/documents/",
      extra: "/api/claims/<CLAIM_PK>/extra-docs/"
    },
    complaint: {
      list: "/api/complaints/<COMPLAINT_PK>/documents/",
      detail: "/api/complaints/<COMPLAINT_PK>/documents/<DOCUMENT_PK>/",
      upload: "/api/complaints/<COMPLAINT_PK>/documents/",
    }
  },
  correspondence: {
    list: "/api/correspondence/",
    detail: "/api/correspondence/<CORRESPONDENCE_PK>/",
    contact: {
      references: "/api/users/references/",
      send_message: "/api/correspondence/contact/",
      send_message_public: "/api/correspondence/contact-public/"
    }
  },
  account: {
    customer: {
      detail: "/api/users/customer/details/"
    },
    vet: {
      detail: "/api/users/vet/practice-details/"
    },
    change_password: "/api/users/change-password/"
  },
  complaint: {
    list: "/api/complaints/",
    detail: "/api/complaints/<COMPLAINT_PK>/"
  }
};

<template>
  <div class="spark-page">
    <div class="spark-page-title">{{ $options.short_name }}</div>
    <div
      class="spark-page-content column-fluid row justify-content-center"
      v-if="jumpToSingleItem"
    >
      <div class="col col-sm-12 col-md-12 col-lg-10">
        <spark-problem :item="jumpToSingleItem" :full="true"></spark-problem>
      </div>
    </div>
    <div
      class="spark-page-content column-fluid row"
      v-if="policyProblems && policyProblems.length > 0"
    >
      <div class="col-12 spark-page-title">Policy Problems</div>
      <div class="col col-sm-12 col-md-6 col-lg-6">
        <template v-for="(prob, index) in policyProblems">
          <spark-problem
            v-if="isEven(index)"
            :key="'policy_' + index"
            :item="prob"
          ></spark-problem>
        </template>
      </div>
      <div class="col col-sm-12 col-md-6 col-lg-6">
        <template v-for="(prob, index) in policyProblems">
          <spark-problem
            v-if="isOdd(index)"
            :key="'policy_' + index"
            :item="prob"
          ></spark-problem>
        </template>
      </div>
    </div>
    <div
      class="spark-page-content column-fluid row"
      v-if="claimProblems && claimProblems.length > 0"
    >
      <div v-if="$store.getters[CURRENT_USER].$customer" class="col-12 spark-page-title">
        Claim Problems
      </div>
      <div
        v-for="(prob, index) in claimProblems"
        :key="'claim_' + index"
        class="col col-sm-12 col-md-6 col-lg-6"
      >
        <spark-problem :item="prob"></spark-problem>
      </div>
    </div>
  </div>
</template>

<script>
import {
  PAGE_NAME_PORTAL_FIX_PROBLEMS, PROBLEMS, CURRENT_USER
} from "@/core/services/vars.service";
import SparkProblem from "@/view/spark_components/portal/Problem";

export default {
  name: PAGE_NAME_PORTAL_FIX_PROBLEMS,
  short_name: "Fix Problems",
  path: "fix",
  components: {
    "spark-problem": SparkProblem
  },
  data() {
    return {
      jump_to: null
    };
  },
  created() {
    this.CURRENT_USER = CURRENT_USER;
  },
  computed: {
    problems() {
      return this.$store.getters[PROBLEMS];
    },
    jumpToSingleItem() {
      let response = null;
      if (this.jump_to) {
        if (this.jump_to.section && this.jump_to.pk) {
          if (this.jump_to.section === "claim") {
            this.problems.active_claims.actions.forEach(prob => {
              if (prob.id === this.jump_to.pk) {
                response = prob;
              }
            });
          }
          if (this.jump_to.section === "policy") {
            this.problems.active_policies.actions.forEach(prob => {
              if (prob.id === this.jump_to.pk) {
                response = prob;
              }
            });
          }
        }
      }
      return response;
    },
    policyProblems() {
      if (!this.$store.getters[CURRENT_USER].$customer) return null;
      if (!this.problems) return null;
      if (!this.jump_to || !this.jump_to.pk) {
        return this.problems.active_policies.actions;
      }
      return this.problems.active_policies.actions.filter(a => {
        return a.id !== this.jump_to.pk;
      });
    },
    claimProblems() {
      if (!this.problems) return null;
      if (!this.jump_to || !this.jump_to.pk) {
        return this.problems.active_claims.actions;
      }
      return this.problems.active_claims.actions.filter(a => {
        return a.id !== this.jump_to.pk;
      });
    }
  },
  mounted() {
    this.$spark.$breadcrumbs(this.$options);
  }
};
</script>

<template>
  <div>
    <div>{{ $options.name }}</div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "Layout-Admin-Payment-Policy",
  path: "policy"
};
</script>

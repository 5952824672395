import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/core/services/store/index";

import $spark from "@/core/services/spark.service";
import { CLIENT_CONFIG } from "@/core/services/vars.service";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import moment from "moment";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";

import SparkQuestion from "@/view/spark_components/portal/claim/form/Question";
import SparkQuestionSet from "@/view/spark_components/portal/claim/form/QuestionSet";
import SparkBankValidation from "@/view/spark_components/portal/claim/form/BankValidation";
import SparkBoolean from "@/view/spark_components/portal/claim/form/Boolean";
import SparkChoice from "@/view/spark_components/portal/claim/form/Choice";
import SparkDate from "@/view/spark_components/portal/claim/form/Date";
import SparkDateTime from "@/view/spark_components/portal/claim/form/DateTime";
import SparkDisplayProperty from "@/view/spark_components/portal/claim/form/DisplayProperty";
import SparkMoney from "@/view/spark_components/portal/claim/form/Money";
import SparkMultipleChoice from "@/view/spark_components/portal/claim/form/MultipleChoice";
import SparkParagraph from "@/view/spark_components/portal/claim/form/Paragraph";
import SparkPostcode from "@/view/spark_components/portal/claim/form/Postcode";
import SparkText from "@/view/spark_components/portal/claim/form/Text";
import SparkTextBox from "@/view/spark_components/portal/claim/form/TextBox";
import SparkTextSmall from "@/view/spark_components/portal/claim/form/TextSmall";
import SparkTime from "@/view/spark_components/portal/claim/form/Time";
import SparkUID from "@/view/spark_components/portal/claim/form/UID";
import SparkWarning from "@/view/spark_components/portal/claim/form/Warning";
import SparkClaimData from "@/view/spark_components/portal/claim/ClaimData";
import SparkClaimDataItem from "@/view/spark_components/portal/claim/ClaimDataItem";
import SparkPageBootstrap from "@/view/spark_components/portal/PageBootstrap";


Vue.component("spark-page-bootstrap", SparkPageBootstrap);
Vue.component("spark-question-set", SparkQuestionSet);
Vue.component("spark-question", SparkQuestion);
Vue.component("spark-paragraph", SparkParagraph);
Vue.component("spark-warning", SparkWarning);
Vue.component("spark-display-property", SparkDisplayProperty);
Vue.component("spark-bank-validation", SparkBankValidation);
Vue.component("spark-boolean", SparkBoolean);
Vue.component("spark-money", SparkMoney);
Vue.component("spark-choice", SparkChoice);
Vue.component("spark-multiple-choice", SparkMultipleChoice);
Vue.component("spark-postcode", SparkPostcode);
Vue.component("spark-textbox", SparkTextBox);
Vue.component("spark-text", SparkText);
Vue.component("spark-text-small", SparkTextSmall);
Vue.component("spark-date", SparkDate);
Vue.component("spark-time", SparkTime);
Vue.component("spark-datetime", SparkDateTime);
Vue.component("spark-uid", SparkUID);
Vue.component("spark-claim-data", SparkClaimData);
Vue.component("spark-claim-data-item", SparkClaimDataItem);

import VueCookies from 'vue-cookies'
Vue.use(VueCookies);

import { Plugin } from 'vue2-storage';
Vue.use(Plugin, {prefix: 'SPARK_'});

//import axios from "axios";
//import VueAxios from "vue-axios";
//Vue.use(VueAxios, axios);

//import Vuelidate from "vuelidate"
//Vue.use(Vuelidate)

// our spark helper class gets inialized by itself before we import it
// so here we have an instantiated class, that we bind to vue, so we can use this.$spark like vuex uses this.$store
Vue.prototype.$spark = $spark;

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  //Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  // reset config to initial state
  $spark.$dispatch(RESET_LAYOUT_CONFIG);

  next();

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

String.prototype.truncate =
  String.prototype.truncate ||
  function(n, useWordBoundary) {
    if (this.length <= n) {
      return this;
    }
    const subString = this.substr(0, n - 1); // the original check
    return (
      (useWordBoundary
        ? subString.substr(0, subString.lastIndexOf(" "))
        : subString) + "&hellip;"
    );
  };

Vue.filter("capitalize", function(value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.prototype.truncate = function truncate(value, n) {
  if (!value || !n) return "";
  let useWordBoundary = true;
  if (value.length <= n) {
    return value;
  }
  const subString = value.substr(0, n - 1); // the original check
  return (
    (useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(" "))
      : subString) + "&hellip;"
  );
};

Vue.prototype.moment = moment;

Vue.prototype.openInNewTab = function openInNewTab(url) {
  var win = window.open(url, "_blank");
  win.focus();
};

Vue.prototype.isEven = function isEven(n) {
  return n % 2 == 0;
};

Vue.prototype.isOdd = function isOdd(n) {
  return Math.abs(n % 2) == 1;
};

Vue.prototype.uuidv4 = function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

Vue.prototype.cast = function cast(value, data_type) {
  try {
    if (data_type == "boolean") {
      return Boolean(value);
    } else if (data_type == "string") {
      return String(value);
    } else if (data_type == "integer") {
      return parseInt(value);
    } else if (data_type == "positive_integer") {
      /*if(!value){
          return 0;
      }*/
      return parseInt(value) >= 0 ? parseInt(value) : null;
    } else if (data_type == "float") {
      return parseFloat(value);
    } else if (data_type == "money") {
      return parseFloat(value).toFixed(2);
    } else if (data_type == "date") {
      alert("CAST FK");
      //return value.strftime("%Y-%m-%d")
    } else if (data_type == "time") {
      //return value.strftime("%H:%M:%S")
      alert("CAST FK");
    } else if (data_type == "datetime") {
      //return value.strftime("%Y-%m-%d %H:%M:%S")
      alert("CAST FK");
    }
  } catch (e) {
    //console.log("CANT CAST ", value, data_type, e);
    return null;
  }
};

Vue.prototype.onKeyUpHelper = function onKeyUpHelper(
  new_val,
  old_val,
  question_name
) {
  if (question_name.startsWith("account_sort_code")) {
    if (new_val.length > old_val.length) {
      if (new_val.length === 2) {
        return new_val + "-";
      }
      if (new_val.length === 5) {
        return new_val + "-";
      }
    }
    if (new_val.length < old_val.length) {
      if (new_val.length === 2) {
        return new_val.slice(0, 1);
      }
      if (new_val.length === 5) {
        return new_val.slice(0, 4);
      }
    }
  }
  return new_val;
};

$spark.$api(CLIENT_CONFIG).then(() => {
  new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: h => h(App)
  }).$mount("#app");
});

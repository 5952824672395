<template>
  <div>
    <b-row class="mt-4 mb-4">
      <b-col
        cols="12"
        sm="6"
        md="6"
        lg="6"
        xl="6"
        class="text-left text-sm-right text-md-right text-lg-right text-xl-right pt-4 pb-8"
      >
        <label
          :for="'question_' + question_name + '_time'"
          class="shift_input_label vue_property_display_label"
        >
          <span v-html="'Time ' + label" class="font-weight-bold"></span>
          <span
            v-html="'<br/>(' + sub_label + ')'"
            class="sub_label"
            v-if="sub_label"
          ></span>
        </label>
      </b-col>
      <b-col
        cols="10"
        sm="3"
        md="3"
        lg="3"
        xl="3"
        class="input_icon_fixed_width"
      >
        <b-input-group>
          <b-form-input
            :id="'question_' + question_name + '_time'"
            type="text"
            class="pl-15"
            v-model="time"
            disabled
          ></b-form-input>
          <i class="fas fa-paw date_paw"></i>
          <b-input-group-append>
            <b-form-timepicker
              button-only
              dropright
              locale="en"
              @context="onTimepickerContext"
              class="datepicker_button"
            ></b-form-timepicker>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="2" class="spark-desktop-spacer"></b-col>
      <b-col cols="2" sm="1" md="1" lg="1" xl="1">
        <i
          class="fas fa-exclamation-circle field_error_icon pt-2"
          v-if="$parent.error_messages.length > 0"
        ></i>
      </b-col>
    </b-row>
    <b-row class="mt-4 mb-4">
      <b-col
        cols="12"
        sm="6"
        md="6"
        lg="6"
        xl="6"
        class="text-left text-sm-right text-md-right text-lg-right text-xl-right pt-4"
      >
        <label
          :for="'question_' + question_name + '_date'"
          class="shift_input_label vue_property_display_label"
        >
          <span v-html="'Date ' + label" class="font-weight-bold"></span>
          <span
            v-html="'<br/>(' + sub_label + ')'"
            class="sub_label"
            v-if="sub_label"
          ></span>
        </label>
      </b-col>
      <b-col
        cols="10"
        sm="4"
        md="4"
        lg="4"
        xl="4"
        class="input_icon_fixed_width"
      >
        <b-input-group>
          <b-form-input
            :id="'question_' + question_name + '_date'"
            type="text"
            class="pl-15"
            v-model="date"
            disabled
          ></b-form-input>
          <i class="fas fa-paw date_paw"></i>
          <b-input-group-append>
            <b-form-datepicker
              button-only
              dropright
              locale="en-US"
              @context="onDatepickerContext"
              class="datepicker_button"
            ></b-form-datepicker>
          </b-input-group-append>
        </b-input-group>
        <div
          class="vue_field_error pt-2"
          v-if="$parent.error_messages.length > 0"
        >
          <ul>
            <li
              v-for="(message, index) in $parent.error_messages"
              :key="index"
              v-html="message"
            ></li>
          </ul>
        </div>
      </b-col>
      <b-col cols="1" class="spark-desktop-spacer"></b-col>
      <b-col cols="2" sm="1" md="1" lg="1" xl="1">
        <i
          class="fas fa-exclamation-circle field_error_icon pt-2"
          v-if="$parent.error_messages.length > 0"
        ></i>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "spark-datetime",
  props: {
    id: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    sub_label: {
      type: String,
      default: null
    },
    question_name: {
      type: String,
      required: true
    },
    watched_by: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      time: "",
      date: "",
      value: ""
    };
  },
  computed: {
    Value() {
      return this.value;
    }
  },
  watch: {
    value: function(new_val) {
      this.$spark.init_section = false;
      this.$parent.value = new_val;
      this.$parent.hasValidationPassed(new_val);
      this.$parent.value = new_val;
    }
  },
  methods: {
    onDatepickerContext(ctx) {
      this.date = ctx.selectedYMD;
      if (this.time.length > 0 && this.date.length > 0) {
        this.value = this.date + " " + this.time;
      }
    },
    onTimepickerContext(ctx) {
      this.time = ctx.value;
      if (this.time.length > 0 && this.date.length > 0) {
        this.value = this.date + " " + this.time;
      }
    }
  }
};
</script>

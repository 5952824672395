import $spark from "@/core/services/spark.service";
import {  
  LOOKUP_POSTCODE,
  LOOKUP_VET,
  LOOKUP_VET_MEDICATION,
  LOOKUP_CUSTOMER,
  SET_QUESTION_FIELD_CHOICES
} from "@/core/services/vars.service";

const state = {};
const getters = {};
const actions = {};
const mutations = {};

/* SERIALIZER NOTES */
/* INPUT PARAMS/DATA SERIALIZER, IS CALLED WITH 1X ARGUMENT. PAYLOAD */
/* ALL OTHER SERIALIZERS ARE CALLED WITH 2X ARGUMENTS, PAYLOAD / RESPONSE */

/* input data, one argument */
/*const postcode_input_serializer = (payload) => {
  return {
    postcode: payload.value
  }
}*/
/* end input data */

/* output data, two arguments */
/*const postcode_results_commit_serializer = (payload, response) => {
  return {
    name: payload.name,
    path: payload.path,
    value: response
  }
}
const postcode_resolve_serializer = (payload, response) => {
  return {
    manipulation: payload.manipulation,
    data: response
  }
}*/
/* end output data */
/*
const dynamic = [
  {
    name: LOOKUP_POSTCODE,
    method: "post",
    url: {
      path: $spark.$urls.$portal.postcode
    },
    commit: false,

    //default_serializer: // ## optional ## // if sent, will manipulate the response data pass to the default commit 
    //error_serializer:  // ## asap mandatory ## // i want rid of that bullshit block, and serialiers will do that !!

    //input_params_serializer: // ## optional ## // params is querystring payload, (get)
    input_data_serializer: postcode_input_serializer, // ## optional ## // data is dostuff payload, (post/put/patch)

    resolve_serializer: postcode_resolve_serializer, // ## optional ## // will manipulatee the response handed back to the component
    //reject_serializer:  // ## optional ## // will manipulate the response handed to $spark.$api().catch((HERE) => { // do stuff });

    // commit_on_success (and also dispatch_on_success) accept a list of actions/mutations to dispatch/commit, after the default commit
    commit_on_success: [{  // usually an array of CONSTANTS (fn names - String) that get passed the entire response to act on
      name: SET_POSTCODE_RESULTS, // however, you can pass an object with keys name/serializer to manipulate the data before the action/mutation
      serializer: postcode_results_commit_serializer // serializer is passed payload AND response for you to do whatever you want with
    }],
    //dispatch_on_success: [{
    //  name: SET_POSTCODE_RESULTS,
    //  serializer: ///////
    //}]
  }
];
*/

const lookup_postcode_input_serializer = (payload) => {
  return {
    postcode: payload.value,
    paginated: false
  }
}
const lookup_vet_input_serializer = (payload) => {
  return {
    name: payload.value,
    paginated: false
  }
}
const lookup_vet_medication_input_serializer = (payload) => {
  return {
    name: payload.value,
    paginated: false
  }
}
const lookup_customer_input_serializer = (payload) => {
  return {
    postcode: payload.value,
    paginated: false
  }
}

const lookup_results_manipulation_commit_serializer = (payload, response) => {
  return {
    name: payload.name,
    path: payload.path,
    value: response
  }
}
const lookup_results_manipulation_commit_select_choices_serializer = (payload, response) => {
  console.error("select_choices_serializer", payload, response)
  if (payload.params.manipulation.response.type === "form_choices") {
    return { 
      name: payload.params.field,
      choices: response,
      choice_keys: {
        label: payload.params.manipulation.response.label,
        value: payload.params.manipulation.response.value
      }
    }
  }
}
const lookup_results_manipulation_resolve_serializer = (payload, response) => {
  return {
    manipulation: payload.params.manipulation,
    data: response
  }
}

const dynamic = [
  {
    name: LOOKUP_POSTCODE,
    method: "post",
    url: {
      path: $spark.$urls.$portal.postcode
    },
    commit: false,
    input_data_serializer: lookup_postcode_input_serializer,
    //default_serializer: lookup_results_manipulation_commit_serializer,
    resolve_serializer: lookup_results_manipulation_resolve_serializer,
    commit_on_success: [{
      name: SET_QUESTION_FIELD_CHOICES,
      serializer: lookup_results_manipulation_commit_select_choices_serializer
    }]
  },
  {
    name: LOOKUP_VET,
    method: "post",
    url: {
      path: $spark.$urls.$portal.vet_search
    },
    input_data_serializer: lookup_vet_input_serializer,
    default_serializer: lookup_results_manipulation_commit_serializer,
    resolve_serializer: lookup_results_manipulation_resolve_serializer,
    commit_on_success: [{
      name: SET_QUESTION_FIELD_CHOICES,
      serializer: lookup_results_manipulation_commit_select_choices_serializer
    }]
  },
  {
    name: LOOKUP_VET_MEDICATION,
    method: "post",
    url: {
      path: $spark.$urls.$portal.vet_medication_search
    },
    input_data_serializer: lookup_vet_medication_input_serializer,
    default_serializer: lookup_results_manipulation_commit_serializer,
    resolve_serializer: lookup_results_manipulation_resolve_serializer,
    commit_on_success: [{
      name: SET_QUESTION_FIELD_CHOICES,
      serializer: lookup_results_manipulation_commit_select_choices_serializer
    }]
  },
  {
    name: LOOKUP_CUSTOMER,
    method: "post",
    url: {
      path: $spark.$urls.$portal.customer_search
    },
    input_data_serializer: lookup_customer_input_serializer
  }
];


const _store = $spark.$inject(dynamic, {
  state,
  actions,
  mutations,
  getters
});
export default _store;

<template>
  <div class="mb-10">
    <b-card
      class="mb-3 rounded-card spark-variant-primary-bg-light spark-variant-primary-border spark-variant-text-dark spark-block-content"
      header-class="card-header rounded-card-header fw-400 py-4 px-4 green-bottom-border spark-variant-primary-bg"
    >
      <template #header>
        <b-row>
          <b-col class="col-10">
            <div class="spark-block-title">
              <i
                class="fas fa-rocket icon-extra-large spark-variant-secondary-text mr-2"
              ></i>
              {{ $options.short_name }}
            </div>
          </b-col>
        </b-row>
      </template>

      <b-row class="mb-5 justify-content-center placeholder">
        <i class="fas fa-rocket spark-variant-primary-text"></i>
      </b-row>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "Portal-Empty",
  short_name: "Nothing To See Here"
};
</script>

<style>
.placeholder i {
  font-size: 10em;
}
</style>

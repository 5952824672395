<template>
  <div class="mb-10" v-if="item">
    <b-card
      class="mb-3 rounded-card spark-variant-primary-bg-light spark-variant-primary-border spark-variant-text-dark spark-block-content"
      header-class="card-header rounded-card-header fw-400 py-4 px-4 green-bottom-border spark-variant-primary-bg"
    >
      <template #header>
        <b-row>
          <b-col>
            <div class="spark-block-title">
              <i
                class="fas fa-user-tag icon-extra-large spark-variant-secondary-text mr-2"
              ></i>
              {{ $options.short_name }}
            </div>
          </b-col>
        </b-row>
      </template>

      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right"
          >Number of linked practices</b-col
        >
        <b-col class="col-6">
          <i class="fas fa-paw"></i>
          <span class="fa-stack">
            <i
              class="fas fa-circle fa-stack-1x icon-large spark-variant-primary-text"
            ></i>
            <i
              class="fas fa-hashtag fa-stack-1x ml-3 spark-variant-text-light"
              style="font-size: 0.8em;"
            ></i>
          </span>
          <span class="ml-13">{{ item.length }}</span>
        </b-col>
      </b-row>

      <b-button
        :disabled="true"
        size="md"
        class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
        >View Practices</b-button
      >

      <!--<b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">Title</b-col>
        <b-col class="col-6"><i class="fas fa-paw"></i>{{ item.title }}</b-col>
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">First name</b-col>
        <b-col class="col-6"
          ><i class="fas fa-paw"></i>{{ item.first_name }}</b-col
        >
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">Last name</b-col>
        <b-col class="col-6"
          ><i class="fas fa-paw"></i>{{ item.last_name }}</b-col
        >
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">Date of birth</b-col>
        <b-col class="col-6"
          ><i class="fas fa-paw"></i
          >{{ moment(item.date_of_birth).format("Do MMM YYYY") }}</b-col
        >
      </b-row>-->
    </b-card>
  </div>
</template>

<script>
import { ACCOUNT_DETAIL } from "@/core/services/vars.service";

export default {
  name: "Portal-Account-Linked-Practices-Summary",
  short_name: "Linked Practices Summary",
  computed: {
    item() {
      return this.$store.getters[ACCOUNT_DETAIL];
    }
  }
};
</script>

<template>
  <div>
    <div>{{ $options.name }}</div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "Layout-Admin-Correspondence",
  path: "correspondence"
};
</script>

<template>
  <div>
    <div class="row justify-content-md-center">
      <b-modal
        ref="spark-confirm-modal"
        centered
        hide-footer
        :title="confirm.title"
      >
        <div class="d-block text-left">
          <h4 v-html="confirm.sub_title"></h4>
          <p v-html="confirm.message"></p>
        </div>
        <b-row>
          <b-col>
            <b-button
              size="md"
              v-html="'Cancel'"
              class="mt-5 rounded green-bg green-border spark-variant-text-light font-weight-bold"
              @click="closeCancel"
            ></b-button>
          </b-col>
          <b-col>
            <b-button
              size="md"
              v-html="'OK, Exit'"
              class="mt-5 rounded red-bg red-border spark-variant-text-light font-weight-bold float-right"
              @click="closeOK"
            ></b-button>
          </b-col>
        </b-row>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  RESET_CONFIRM,
  SET_CONFIRM,
  SET_CONFIRM_COMPONENT,
  SET_NAV_BLOCKED,
  RESET_CLAIM_FORM
} from "@/core/services/vars.service";

export default {
  data() {
    return {
      confirm: {
        title: null,
        sub_title: null,
        message: null
      },
      promise: null,
      subscription: null
    };
  },
  methods: {
    closeCancel() {
      this.promise.reject();
    },
    closeOK() {
      this.$spark.$dispatch(SET_NAV_BLOCKED, {
        status: false,
        fn: this.$spark._navBlockHandler
      });
      this.$spark.$dispatch(RESET_CLAIM_FORM);
      this.promise.resolve();
    },
    openModal() {
      this.$refs["spark-confirm-modal"].show();
    },
    closeModal() {
      this.$refs["spark-confirm-modal"].hide();
      this.$spark.$dispatch(RESET_CONFIRM);
    },
    createPromise() {
      this.openModal();
      return new Promise((res, rej) => {
        const resolve = () => {
          this.closeModal();
          res();
        };
        const reject = () => {
          this.closeModal();
          rej();
        };
        this.promise = { resolve, reject };
      });
    }
  },
  mounted() {
    this.subscription = this.$store.subscribe(mutation => {
      try {
        if (mutation.type === SET_CONFIRM) {
          this.confirm = mutation.payload;
        }
      } catch(e) {
        console.error("ERROR IN STORE SUBSCRIPTION", e);
      }
    });
    this.$spark.$dispatch(SET_CONFIRM_COMPONENT, this);
  }
};
</script>

<template>
  <div class="spark-page" v-if="detail">
    <div class="spark-page-title">{{ $options.short_name }}</div>
    <div class="spark-page-content column-fluid row">
      <div class="col col-sm-12 col-md-6 col-lg-6">
        <spark-policy-detail-general
          :item="detail"
        ></spark-policy-detail-general>
      </div>
      <template v-for="(item, index) in detail.policy_data.pets">
        <div class="col col-sm-12 col-md-6 col-lg-6" :key="'col_' + index">
          <spark-policy-pet-detail :item="item"></spark-policy-pet-detail>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {
  PAGE_NAME_PORTAL_POLICY_MANAGE,
  SET_POLICY_DETAIL_PK,
  POLICY_DETAIL
} from "@/core/services/vars.service";
import SparkPolicyDetailGeneral from "@/view/spark_components/portal/policy/PolicyDetailGeneral";
import SparkPolicyPetDetail from "@/view/spark_components/portal/policy/PolicyPetDetail";

export default {
  name: PAGE_NAME_PORTAL_POLICY_MANAGE,
  short_name: "Manage Policy",
  path: "manage/:id?",
  pk: SET_POLICY_DETAIL_PK,
  components: {
    "spark-policy-detail-general": SparkPolicyDetailGeneral,
    "spark-policy-pet-detail": SparkPolicyPetDetail
  },
  computed: {
    detail() {
      return this.$store.getters[POLICY_DETAIL];
    }
  },
  mounted() {
    this.$spark.$breadcrumbs(this.$options);
  }
};
</script>

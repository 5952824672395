// mandatory for all clients

// portal
import Login from "@/view/spark/portal/public/PetsLogin";
import CustomerDashboard from "@/view/spark/portal/authenticated/CustomerDashboard";
import CustomerFixProblems from "@/view/spark/portal/authenticated/FixProblems";

// about
import AboutContactUs from "@/view/spark/portal/public/about/ContactUs";
import AboutCustom from "@/view/spark/portal/public/about/Custom";

// admin
import AdminLogin from "@/view/spark/admin/public/AdminLogin";
import AdminDashboard from "@/view/spark/admin/authenticated/AdminDashboard";

// dynamic per client
// portal
// account
import AccountManage from "@/view/spark/portal/authenticated/account/AccountManage";

// claim
import CustomerClaimList from "@/view/spark/portal/authenticated/claim/pets/customer/CustomerClaimList";
import CustomerClaimManage from "@/view/spark/portal/authenticated/claim/pets/customer/CustomerClaimManage";
import CustomerClaimCreate from "@/view/spark/portal/authenticated/claim/pets/customer/CustomerClaimCreate";
import CustomerClaimUpdate from "@/view/spark/portal/authenticated/claim/pets/customer/CustomerClaimUpdate";
import VetClaimList from "@/view/spark/portal/authenticated/claim/pets/vet/VetClaimList";
import VetClaimManage from "@/view/spark/portal/authenticated/claim/pets/vet/VetClaimManage";
import VetClaimCreate from "@/view/spark/portal/authenticated/claim/pets/vet/VetClaimCreate";
import VetClaimUpdate from "@/view/spark/portal/authenticated/claim/pets/vet/VetClaimUpdate";

// complaint
import ComplaintList from "@/view/spark/portal/authenticated/complaint/ComplaintList";
import ComplaintManage from "@/view/spark/portal/authenticated/complaint/ComplaintManage";
import ComplaintCreate from "@/view/spark/portal/authenticated/complaint/ComplaintCreate";

// correspondence
import ContactUs from "@/view/spark/portal/authenticated/correspondence/ContactUs";
import CorrespondenceList from "@/view/spark/portal/authenticated/correspondence/CorrespondenceList";
import CorrespondenceManage from "@/view/spark/portal/authenticated/correspondence/CorrespondenceManage";

// document
import ClaimDocumentList from "@/view/spark/portal/authenticated/document/claim/ClaimDocumentList";
import ClaimDocumentManage from "@/view/spark/portal/authenticated/document/claim/ClaimDocumentManage";
import ClaimDocumentUpload from "@/view/spark/portal/authenticated/document/claim/ClaimDocumentUpload";
import PolicyDocumentList from "@/view/spark/portal/authenticated/document/policy/PolicyDocumentList";
import PolicyDocumentManage from "@/view/spark/portal/authenticated/document/policy/PolicyDocumentManage";
import PolicyDocumentUpload from "@/view/spark/portal/authenticated/document/policy/PolicyDocumentUpload";
import ComplaintDocumentList from "@/view/spark/portal/authenticated/document/complaint/ComplaintDocumentList";
import ComplaintDocumentManage from "@/view/spark/portal/authenticated/document/complaint/ComplaintDocumentManage";
import ComplaintDocumentUpload from "@/view/spark/portal/authenticated/document/complaint/ComplaintDocumentUpload";

// policy
import PolicyList from "@/view/spark/portal/authenticated/policy/PolicyList";
import PolicyManage from "@/view/spark/portal/authenticated/policy/PolicyManage";
// MTA
//import MTAList from "@/view/spark/portal/authenticated/policy/mta/MTAList";
//import MTAManage from "@/view/spark/portal/authenticated/policy/mta/MTAManage";
//import MTACreate from "@/view/spark/portal/authenticated/policy/mta/MTACreate";

// admin
// callcentre
import AdminCallcentreDashboard from "@/view/spark/admin/authenticated/callcentre/CallcentreDashboard";
import AdminCallcentreTeam from "@/view/spark/admin/authenticated/callcentre/CallcentreTeam";
import AdminCallcentreQueue from "@/view/spark/admin/authenticated/callcentre/CallcentreQueue";
import AdminCallcentreMember from "@/view/spark/admin/authenticated/callcentre/CallcentreMember";

// claim
import AdminClaimList from "@/view/spark/admin/authenticated/claim/pets/ClaimList";
import AdminClaimManage from "@/view/spark/admin/authenticated/claim/pets/ClaimManage";
import AdminClaimCreate from "@/view/spark/admin/authenticated/claim/pets/ClaimCreate";

// complaint
import AdminComplaintList from "@/view/spark/admin/authenticated/complaint/ComplaintList";
import AdminComplaintManage from "@/view/spark/admin/authenticated/complaint/ComplaintManage";
import AdminComplaintCreate from "@/view/spark/admin/authenticated/complaint/ComplaintCreate";

// correspondence
import AdminCorrespondenceList from "@/view/spark/admin/authenticated/correspondence/CorrespondenceList";
import AdminCorrespondenceManage from "@/view/spark/admin/authenticated/correspondence/CorrespondenceManage";
import AdminCorrespondenceCreate from "@/view/spark/admin/authenticated/correspondence/CorrespondenceCreate";

// customer
import AdminCustomerList from "@/view/spark/admin/authenticated/customer/CustomerList";
import AdminCustomerManage from "@/view/spark/admin/authenticated/customer/CustomerManage";
import AdminCustomerCreate from "@/view/spark/admin/authenticated/customer/CustomerCreate";

// GDPR
import AdminGDPRManage from "@/view/spark/admin/authenticated/gdpr/GDPRManage";

// payment
import AdminClaimPaymentList from "@/view/spark/admin/authenticated/payment/claim/ClaimPaymentList";
import AdminClaimPaymentManage from "@/view/spark/admin/authenticated/payment/claim/ClaimPaymentManage";
import AdminClaimPaymentCreate from "@/view/spark/admin/authenticated/payment/claim/ClaimPaymentCreate";
import AdminPolicyPaymentList from "@/view/spark/admin/authenticated/payment/policy/PolicyPaymentList";
import AdminPolicyPaymentManage from "@/view/spark/admin/authenticated/payment/policy/PolicyPaymentManage";
import AdminPolicyPaymentCreate from "@/view/spark/admin/authenticated/payment/policy/PolicyPaymentCreate";

// policy
import AdminPolicyList from "@/view/spark/admin/authenticated/policy/PolicyList";
import AdminPolicyManage from "@/view/spark/admin/authenticated/policy/PolicyManage";
import AdminPolicyCreate from "@/view/spark/admin/authenticated/policy/PolicyCreate";
// MTA
//import AdminMTAList from "@/view/spark/admin/authenticated/policy/mta/MTAList";
//import AdminMTAManage from "@/view/spark/admin/authenticated/policy/mta/MTAManage";
//import AdminMTACreate from "@/view/spark/admin/authenticated/policy/mta/MTACreate";

// report
import AdminReportExportList from "@/view/spark/admin/authenticated/report/export/ExportList";
import AdminReportExportView from "@/view/spark/admin/authenticated/report/export/ExportView";
import AdminReportExportDownload from "@/view/spark/admin/authenticated/report/export/ExportDownload";
import AdminReportImportList from "@/view/spark/admin/authenticated/report/import/ImportList";
import AdminReportImportUpload from "@/view/spark/admin/authenticated/report/import/ImportUpload";
import AdminReportImportErrors from "@/view/spark/admin/authenticated/report/import/ImportErrors";

// search
import AdminSearchCallcentre from "@/view/spark/admin/authenticated/search/SearchCallcentre";
import AdminSearchClaim from "@/view/spark/admin/authenticated/search/SearchClaim";
import AdminSearchClaimPayment from "@/view/spark/admin/authenticated/search/SearchClaimPayment";
import AdminSearchComplaint from "@/view/spark/admin/authenticated/search/SearchComplaint";
import AdminSearchCorrespondence from "@/view/spark/admin/authenticated/search/SearchCorrespondence";
import AdminSearchCustomer from "@/view/spark/admin/authenticated/search/SearchCustomer";
import AdminSearchGDPR from "@/view/spark/admin/authenticated/search/SearchGDPR";
//import AdminSearchMTA from "@/view/spark/admin/authenticated/search/SearchMTA";
import AdminSearchPolicy from "@/view/spark/admin/authenticated/search/SearchPolicy";
import AdminSearchPolicyPayment from "@/view/spark/admin/authenticated/search/SearchPolicyPayment";
//import AdminSearchQuote from "@/view/spark/admin/authenticated/search/SearchQuote";
import AdminSearchTask from "@/view/spark/admin/authenticated/search/SearchTask";
import AdminSearchUser from "@/view/spark/admin/authenticated/search/SearchUser";
import AdminSearchVet from "@/view/spark/admin/authenticated/search/SearchVet";

// task
import AdminTaskDashboard from "@/view/spark/admin/authenticated/task/TaskDashboard";
import AdminTaskList from "@/view/spark/admin/authenticated/task/TaskList";
import AdminTaskManage from "@/view/spark/admin/authenticated/task/TaskManage";
import AdminTaskCreate from "@/view/spark/admin/authenticated/task/TaskCreate";

// vet
import AdminVetList from "@/view/spark/admin/authenticated/vet/VetList";
import AdminVetManage from "@/view/spark/admin/authenticated/vet/VetManage";
import AdminVetCreate from "@/view/spark/admin/authenticated/vet/VetCreate";

// system config
// addon
import ConfigAddonList from "@/view/spark/admin/authenticated/system/addon/AddonList";
import ConfigAddonManage from "@/view/spark/admin/authenticated/system/addon/AddonManage";
import ConfigAddonCreate from "@/view/spark/admin/authenticated/system/addon/AddonCreate";

// aggregator
import ConfigAggregatorList from "@/view/spark/admin/authenticated/system/aggregator/AggregatorList";
import ConfigAggregatorManage from "@/view/spark/admin/authenticated/system/aggregator/AggregatorManage";
import ConfigAggregatorCreate from "@/view/spark/admin/authenticated/system/aggregator/AggregatorCreate";

// brand
import ConfigBrandList from "@/view/spark/admin/authenticated/system/brand/BrandList";
import ConfigBrandManage from "@/view/spark/admin/authenticated/system/brand/BrandManage";
import ConfigBrandCreate from "@/view/spark/admin/authenticated/system/brand/BrandCreate";

// callcentre
import ConfigCallcentreTeamList from "@/view/spark/admin/authenticated/system/callcentre/team/CallcentreTeamList";
import ConfigCallcentreTeamManage from "@/view/spark/admin/authenticated/system/callcentre/team/CallcentreTeamManage";
import ConfigCallcentreTeamCreate from "@/view/spark/admin/authenticated/system/callcentre/team/CallcentreTeamCreate";
import ConfigCallcentreQueueList from "@/view/spark/admin/authenticated/system/callcentre/queue/CallcentreQueueList";
import ConfigCallcentreQueueManage from "@/view/spark/admin/authenticated/system/callcentre/queue/CallcentreQueueManage";
import ConfigCallcentreQueueCreate from "@/view/spark/admin/authenticated/system/callcentre/queue/CallcentreQueueCreate";
import ConfigCallcentreMemberList from "@/view/spark/admin/authenticated/system/callcentre/member/CallcentreMemberList";
import ConfigCallcentreMemberManage from "@/view/spark/admin/authenticated/system/callcentre/member/CallcentreMemberManage";
import ConfigCallcentreMemberCreate from "@/view/spark/admin/authenticated/system/callcentre/member/CallcentreMemberCreate";

// commission
import ConfigCommissionList from "@/view/spark/admin/authenticated/system/commission/CommissionList";
import ConfigCommissionManage from "@/view/spark/admin/authenticated/system/commission/CommissionManage";
import ConfigCommissionCreate from "@/view/spark/admin/authenticated/system/commission/CommissionCreate";

// correspondence
import ConfigCorrespondenceList from "@/view/spark/admin/authenticated/system/correspondence/CorrespondenceList";
import ConfigCorrespondenceManage from "@/view/spark/admin/authenticated/system/correspondence/CorrespondenceManage";

// document
import ConfigClaimDocumentList from "@/view/spark/admin/authenticated/system/document/claim/ClaimDocumentList";
import ConfigClaimDocumentManage from "@/view/spark/admin/authenticated/system/document/claim/ClaimDocumentManage";
import ConfigClaimDocumentCreate from "@/view/spark/admin/authenticated/system/document/claim/ClaimDocumentCreate";
import ConfigPolicyDocumentList from "@/view/spark/admin/authenticated/system/document/policy/PolicyDocumentList";
import ConfigPolicyDocumentManage from "@/view/spark/admin/authenticated/system/document/policy/PolicyDocumentManage";
import ConfigPolicyDocumentCreate from "@/view/spark/admin/authenticated/system/document/policy/PolicyDocumentCreate";

// endorsement
import ConfigEndorsementList from "@/view/spark/admin/authenticated/system/endorsement/EndorsementList";
import ConfigEndorsementManage from "@/view/spark/admin/authenticated/system/endorsement/EndorsementManage";
import ConfigEndorsementCreate from "@/view/spark/admin/authenticated/system/endorsement/EndorsementCreate";

// exclusion
import ConfigExclusionList from "@/view/spark/admin/authenticated/system/exclusion/ExclusionList";
import ConfigExclusionManage from "@/view/spark/admin/authenticated/system/exclusion/ExclusionManage";
import ConfigExclusionCreate from "@/view/spark/admin/authenticated/system/exclusion/ExclusionCreate";

// product
import ConfigProductList from "@/view/spark/admin/authenticated/system/product/ProductList";
import ConfigProductManage from "@/view/spark/admin/authenticated/system/product/ProductManage";
import ConfigProductCreate from "@/view/spark/admin/authenticated/system/product/ProductCreate";
import ConfigProductTypeList from "@/view/spark/admin/authenticated/system/product/type/ProductTypeList";
import ConfigProductTypeManage from "@/view/spark/admin/authenticated/system/product/type/ProductTypeManage";

// underwriter
import ConfigUnderwriterList from "@/view/spark/admin/authenticated/system/underwriter/UnderwriterList";
import ConfigUnderwriterManage from "@/view/spark/admin/authenticated/system/underwriter/UnderwriterManage";
import ConfigUnderwriterCreate from "@/view/spark/admin/authenticated/system/underwriter/UnderwriterCreate";

// user
import ConfigUserList from "@/view/spark/admin/authenticated/system/user/UserList";
import ConfigUserManage from "@/view/spark/admin/authenticated/system/user/UserManage";
import ConfigUserCreate from "@/view/spark/admin/authenticated/system/user/UserCreate";
import ConfigUserPermissionList from "@/view/spark/admin/authenticated/system/user/permission/UserPermissionList";
import ConfigUserTeamList from "@/view/spark/admin/authenticated/system/user/team/UserTeamList";
import ConfigUserTeamManage from "@/view/spark/admin/authenticated/system/user/team/UserTeamManage";
import ConfigUserTeamCreate from "@/view/spark/admin/authenticated/system/user/team/UserTeamCreate";

const ClientConfig = {
  client_code: "scratch_and_patch",
  client_name: "Only Pets Claims",
  main_brand: "Scratch & Patch Claims",
  api_root: null,
  redirect: "/portal/login", // this is the URL where root navigation gets sent to (portal login, admin login, public quote and buy etc)
  styles: {
    logo: {
      height: "117px",
      margin: "40px"
    },
    colours: {
      buttons: "#ffb819"
    }
  },
  resources: {
    logo_url: "scratch_and_patch-logo.png",
    logo_small_url: "scratch_and_patch-small.png"
  },
  views: {
    portal: {
      login: Login,
      register: null,
      quote: null,
      authenticated: {
        dashboard: CustomerDashboard,
        problems: CustomerFixProblems,
        account: [AccountManage],
        claim: {
          customer: [
            CustomerClaimList,
            CustomerClaimManage,
            CustomerClaimCreate,
            CustomerClaimUpdate
          ],
          vet: [VetClaimList, VetClaimManage, VetClaimCreate, VetClaimUpdate]
        },
        complaint: [ComplaintList, ComplaintManage, ComplaintCreate],
        correspondence: [ContactUs, CorrespondenceList, CorrespondenceManage],
        document: {
          claim: [ClaimDocumentList, ClaimDocumentManage, ClaimDocumentUpload],
          policy: [
            PolicyDocumentList,
            PolicyDocumentManage,
            PolicyDocumentUpload
          ],
          complaint: [
            ComplaintDocumentList,
            ComplaintDocumentManage,
            ComplaintDocumentUpload
          ]
        },
        policy: [PolicyList, PolicyManage]
      },
      public: {
        about: {
          contact_us: AboutContactUs,
          custom: AboutCustom
        }
      }
    },
    admin: {
      login: AdminLogin,
      authenticated: {
        dashboard: AdminDashboard,
        callcentre: [
          AdminCallcentreDashboard,
          AdminCallcentreTeam,
          AdminCallcentreQueue,
          AdminCallcentreMember
        ],
        claim: [AdminClaimList, AdminClaimManage, AdminClaimCreate],
        complaint: [
          AdminComplaintList,
          AdminComplaintManage,
          AdminComplaintCreate
        ],
        correspondence: [
          AdminCorrespondenceManage,
          AdminCorrespondenceList,
          AdminCorrespondenceCreate
        ],
        customer: [AdminCustomerManage, AdminCustomerList, AdminCustomerCreate],
        gdpr: [AdminGDPRManage],
        mta: [],
        payment: {
          claim: [
            AdminClaimPaymentManage,
            AdminClaimPaymentList,
            AdminClaimPaymentCreate
          ],
          policy: [
            AdminPolicyPaymentManage,
            AdminPolicyPaymentList,
            AdminPolicyPaymentCreate
          ]
        },
        policy: [AdminPolicyManage, AdminPolicyList, AdminPolicyCreate],
        quote: [],
        report: {
          export: [
            AdminReportExportList,
            AdminReportExportView,
            AdminReportExportDownload
          ],
          import: [
            AdminReportImportList,
            AdminReportImportUpload,
            AdminReportImportErrors
          ]
        },
        search: [
          AdminSearchCallcentre,
          AdminSearchClaim,
          AdminSearchComplaint,
          AdminSearchCorrespondence,
          AdminSearchCustomer,
          AdminSearchGDPR,
          AdminSearchClaimPayment,
          AdminSearchPolicyPayment,
          AdminSearchPolicy,
          AdminSearchTask,
          AdminSearchUser,
          AdminSearchVet
        ],
        task: [
          AdminTaskDashboard,
          AdminTaskList,
          AdminTaskManage,
          AdminTaskCreate
        ],
        vet: [AdminVetList, AdminVetManage, AdminVetCreate],
        system_config: {
          addon: [ConfigAddonList, ConfigAddonManage, ConfigAddonCreate],
          aggregator: [
            ConfigAggregatorList,
            ConfigAggregatorManage,
            ConfigAggregatorCreate
          ],
          brand: [ConfigBrandList, ConfigBrandManage, ConfigBrandCreate],
          callcentre: {
            team: [
              ConfigCallcentreTeamList,
              ConfigCallcentreTeamManage,
              ConfigCallcentreTeamCreate
            ],
            queue: [
              ConfigCallcentreQueueList,
              ConfigCallcentreQueueManage,
              ConfigCallcentreQueueCreate
            ],
            member: [
              ConfigCallcentreMemberList,
              ConfigCallcentreMemberManage,
              ConfigCallcentreMemberCreate
            ]
          },
          commission: [
            ConfigCommissionList,
            ConfigCommissionManage,
            ConfigCommissionCreate
          ],
          correspondence: [
            ConfigCorrespondenceList,
            ConfigCorrespondenceManage
          ],
          document: {
            claim: [
              ConfigClaimDocumentList,
              ConfigClaimDocumentManage,
              ConfigClaimDocumentCreate
            ],
            policy: [
              ConfigPolicyDocumentList,
              ConfigPolicyDocumentManage,
              ConfigPolicyDocumentCreate
            ]
          },
          endorsement: [
            ConfigEndorsementList,
            ConfigEndorsementManage,
            ConfigEndorsementCreate
          ],
          exclusion: [
            ConfigExclusionList,
            ConfigExclusionManage,
            ConfigExclusionCreate
          ],
          product: [
            ConfigProductList,
            ConfigProductManage,
            ConfigProductCreate
          ],
          product_type: [ConfigProductTypeList, ConfigProductTypeManage],
          underwriter: [
            ConfigUnderwriterList,
            ConfigUnderwriterManage,
            ConfigUnderwriterCreate
          ],
          user: [ConfigUserList, ConfigUserManage, ConfigUserCreate],
          user_team: [
            ConfigUserTeamList,
            ConfigUserTeamManage,
            ConfigUserTeamCreate
          ],
          user_permission: [ConfigUserPermissionList]
        }
      }
    }
  }
};

export default ClientConfig;

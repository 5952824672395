<template>
  <div class="spark-page" v-if="detail && docList">
    <div class="spark-page-title">{{ $options.short_name }}</div>
    <div class="spark-page-content column-fluid row">
      <div class="col col-sm-12 col-md-6 col-lg-6">
        <spark-claim-general-details
          :item="detail"
        ></spark-claim-general-details>
        <spark-document-list-summary
          :parent="detail.id"
          :item="docList.data"
          :button="detail.claim_data_complete"
          prefix="Claim"
        ></spark-document-list-summary>
      </div>
      <div class="col col-sm-12 col-md-6 col-lg-6">
        <spark-policy-pet-detail :item="detail.pet"></spark-policy-pet-detail>
        <spark-claim-information
          :item="detail.claim_data"
          :id="detail.id"
        ></spark-claim-information>
        <spark-claim-payment-details
          :item="detail.payment_details"
        ></spark-claim-payment-details>
      </div>
    </div>
  </div>
</template>

<script>
import {
  PAGE_NAME_PORTAL_CLAIM_CUSTOMER_MANAGE,
  CLAIM_DETAIL,
  SET_CLAIM_DETAIL_PK,
  CLAIM_DOCUMENT_LIST
} from "@/core/services/vars.service";
import SparkClaimInformation from "@/view/spark_components/portal/claim/ClaimInformation";
import SparkClaimGeneralDetails from "@/view/spark_components/portal/claim/ClaimGeneralDetails";
import SparkClaimPaymentDetails from "@/view/spark_components/portal/claim/ClaimPaymentDetails";
import SparkPolicyPetDetail from "@/view/spark_components/portal/policy/PolicyPetDetail";
import SparkDocumentListSummary from "@/view/spark_components/portal/document/DocumentListSummary";

export default {
  name: PAGE_NAME_PORTAL_CLAIM_CUSTOMER_MANAGE,
  short_name: "Manage Claim",
  path: "manage/:id?",
  pk: SET_CLAIM_DETAIL_PK,
  components: {
    "spark-claim-information": SparkClaimInformation,
    "spark-claim-general-details": SparkClaimGeneralDetails,
    "spark-policy-pet-detail": SparkPolicyPetDetail,
    "spark-claim-payment-details": SparkClaimPaymentDetails,
    "spark-document-list-summary": SparkDocumentListSummary
  },
  computed: {
    detail() {
      return this.$store.getters[CLAIM_DETAIL];
    },
    docList() {
      return this.$store.getters[CLAIM_DOCUMENT_LIST];
    }
  },
  mounted() {
    this.$spark.$breadcrumbs(this.$options);
  }
};
</script>

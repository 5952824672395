import {
  SET_BREADCRUMB,
} from "@/core/services/vars.service";

export default {
  state: {
    page_title: null,
    breadcrumbs: []
  },
  getters: {
    /**
     * Get list of breadcrumbs for current page
     * @param state
     * @returns {*}
     */
    breadcrumbs(state) {
      return state.breadcrumbs;
    },

    /**
     * Get the page title
     * @param state
     * @returns {*}
     */
    pageTitle(state) {
      return state.page_title;
    }
  },
  actions: {
    /**
     * Set the breadcrumbs list
     * @param state
     * @param payload
     */
    [SET_BREADCRUMB](state, payload) {
      state.commit(SET_BREADCRUMB, payload);
    }
  },
  mutations: {
    [SET_BREADCRUMB](state, payload) {
      let parts = payload.string.split("-");
      let crumbs = Array();
      parts.forEach(p => {
        crumbs.push({ title: p });
      });
      state.breadcrumbs = crumbs;
      state.page_title = payload.title;
    }
  }
};

<template>
  <div class="spark-page" v-if="item">
    <div class="spark-page-title">{{ $options.short_name }}</div>
    <div class="spark-page-content column-fluid row" v-if="$store.getters[CURRENT_USER].$customer">
      <div class="col col-sm-12 col-md-6 col-lg-6">
        <spark-account-personal-details :item="item.data"/>
        <spark-account-marketing-preferences :item="item.data.marketing"/>
      </div>
      <div class="col col-sm-12 col-md-6 col-lg-6">
        <spark-account-contact-details :item="item.data"/>
      </div>
    </div>
    <div class="spark-page-content column-fluid row" v-if="$store.getters[CURRENT_USER].$vet">
      <div class="col col-sm-12 col-md-6 col-lg-6">
        <spark-account-vet-personal-details :item="item"/>
      </div>
      <div class="col col-sm-12 col-md-6 col-lg-6">
        <spark-account-vet-linked-practices-summary :item="item"/>
      </div>
    </div>
  </div>
</template>

<script>
import { PAGE_NAME_PORTAL_ACCOUNT_MANAGE, ACCOUNT_DETAIL, CURRENT_USER } from "@/core/services/vars.service";

import SparkAccountVetPersonalDetails from "@/view/spark_components/portal/account/VetPersonalDetails";
import SparkAccountVetLinkedPracticesSummary from "@/view/spark_components/portal/account/LinkedPracticesSummary";
import SparkAccountPersonalDetails from "@/view/spark_components/portal/account/PersonalDetails";
import SparkAccountContactDetails from "@/view/spark_components/portal/account/ContactDetails";
import SparkAccountMarketingPreferences from "@/view/spark_components/portal/account/MarketingPreferences";
 
export default {
  name: PAGE_NAME_PORTAL_ACCOUNT_MANAGE,
  short_name: "Manage Account",
  path: "manage",
  menu: true,
  created() {
    this.CURRENT_USER = CURRENT_USER;
  },
  components: {
    "spark-account-vet-personal-details": SparkAccountVetPersonalDetails,
    "spark-account-vet-linked-practices-summary": SparkAccountVetLinkedPracticesSummary,
    "spark-account-personal-details": SparkAccountPersonalDetails,
    "spark-account-contact-details": SparkAccountContactDetails,
    "spark-account-marketing-preferences": SparkAccountMarketingPreferences
  },
  computed: {
    item() {
      return this.$store.getters[ACCOUNT_DETAIL];
    }
  },
  mounted() {
    this.$spark.$breadcrumbs(this.$options);
  }
};
</script>

<template>
  <div class="spark-page" v-if="list">
    <div class="spark-page-title">{{ $options.short_name }}</div>
    <div
      class="spark-page-content column-fluid row justify-content-center"
      v-if="list.length === 0"
    >
      <div class="col col-sm-12 col-md-12 col-lg-10">
        <spark-nothing-to-see-here></spark-nothing-to-see-here>
      </div>
    </div>
    <div class="spark-page-content column-fluid row" v-else>
      <spark-pagination
        :data="pagination"
        :to="paginationTo"
      ></spark-pagination>
      <template v-for="(item, index) in list">
        <div class="col col-sm-12 col-md-6 col-lg-6" :key="'list_' + index">
          <spark-claim-summary :item="item"></spark-claim-summary>
        </div>
      </template>
      <spark-pagination
        :data="pagination"
        :to="paginationTo"
      ></spark-pagination>
    </div>
  </div>
</template>

<script>
import {
  PAGE_NAME_PORTAL_CLAIM_VET_LIST,
  CLAIM_LIST,
  PAGE
} from "@/core/services/vars.service";

import SparkNothingToSeeHere from "@/view/spark_components/NothingToSeeHere";
import SparkClaimSummary from "@/view/spark_components/portal/claim/ClaimSummary";
import SparkPagination from "@/view/spark_components/portal/Pagination";

export default {
  name: PAGE_NAME_PORTAL_CLAIM_VET_LIST,
  short_name: "Claims",
  path: "list",
  menu: true,
  components: {
    "spark-claim-summary": SparkClaimSummary,
    "spark-nothing-to-see-here": SparkNothingToSeeHere,
    "spark-pagination": SparkPagination
  },
  computed: {
    list() {
      return this.$store.getters[CLAIM_LIST];
    },
    pagination() {
      return this.$store.getters[PAGE + CLAIM_LIST];
    },
    paginationTo() {
      return CLAIM_LIST;
    }
  },
  mounted() {
    this.$spark.$breadcrumbs(this.$options);
  }
};
</script>

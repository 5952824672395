var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$parent.$parent.menuOpen)?_c('ul',{staticClass:"spark-portal-menu-mobile"},[_vm._l((_vm.$store.getters[_vm.MENU_ITEMS]),function(menu,index){return [('children' in menu)?[_c('router-link',{key:'menu_link_' + index,attrs:{"to":menu.path || '#empty',"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('li',{key:'menu_parent_' + index,staticClass:"menu-item menu-item-submenu",class:{
              'menu-item-open': _vm.hasActiveChildren(menu.url)
            },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle h1",attrs:{"href":href},on:{"click":function($event){_vm.$parent.$parent.toggleMenu() && _vm.closeSubs() && navigate}}},[_c('div',{staticClass:"text-right spark-sub-menu-item",domProps:{"innerHTML":_vm._s(menu.label)}})]),_c('span',{staticClass:"svg-icon svg-icon-xl border border-dark bg-light rounded-small mb-1 mr-4",on:{"click":function($event){return _vm.toggleSub(index)}}},[_c('inline-svg',{staticClass:"svg-icon",attrs:{"src":"media/svg/icons/Navigation/Arrow-to-bottom.svg"}})],1),(_vm.canShow[index])?_c('div',{staticClass:"menu-submenu menu-submenu-classic menu-submenu-right"},[_c('ul',{staticClass:"menu-subnav"},[_vm._l((menu.children),function(sub,index1){return [(!sub.seperator)?_c('router-link',{key:'menu_sub_' + index1,attrs:{"to":sub.path,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active'
                      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link h2",attrs:{"href":href},on:{"click":function($event){_vm.$parent.$parent.toggleMenu() &&
                            _vm.closeSubs() &&
                            navigate}}},[_c('div',{staticClass:"spark-sub-menu-item-label",domProps:{"innerHTML":_vm._s(sub.label)}})])])]}}],null,true)}):_vm._e()]})],2)]):_vm._e()])]}}],null,true)})]:[_c('router-link',{key:'menu_link_' + index,attrs:{"to":menu.path,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var href = ref.href;
                            var navigate = ref.navigate;
                            var isActive = ref.isActive;
                            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item text-right",class:[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link h1",attrs:{"href":href},on:{"click":function($event){_vm.$parent.$parent.toggleMenu() && _vm.closeSubs() && navigate}}},[_c('div',{domProps:{"innerHTML":_vm._s(menu.label)}})])])]}}],null,true)})]]}),[(_vm.$store.getters[_vm.IS_AUTHENTICATED])?_c('li',{staticClass:"menu-item text-right",attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link h1",on:{"click":function($event){_vm.$parent.$parent.toggleMenu() && _vm.closeSubs() && _vm.logout()}}},[_c('div',[_vm._v("Logout")])])]):(_vm.$route.name !== _vm.$spark.$config.views.portal.login.name)?_c('li',{staticClass:"menu-item text-right",attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link h1",on:{"click":function($event){_vm.$parent.$parent.toggleMenu() &&
              _vm.closeSubs() &&
              _vm.$spark.$portalLogin();}}},[_c('div',[_vm._v("Login")])])]):_vm._e()]],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
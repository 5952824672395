<template>
  <div>
    <!-- ONLY SHOW SECTIONS IF CONDITIONS PASSED -->
    <div v-show="conditionsMet === true">
      <template v-if="obj.multiple">
        <template v-if="obj.name in multipleComponents">
          <div
            class="mt-2"
            v-if="multipleComponents && obj.name in multipleComponents"
          >
            <spark-paragraph
              :label="
                obj.label +
                  ' #1 of ' +
                  (multipleComponents[obj.name].length === 1
                    ? '1'
                    : multipleComponents[obj.name].slice(1).length + 1)
              "
            ></spark-paragraph>
          </div>
          <div
            v-for="(q_obj, obj_index) in obj.data"
            :key="'q_set_0_' + obj_index"
          >
            <!-- OBJECT IS A QUESTION -->
            <spark-question
              v-if="q_obj.hasOwnProperty('field_type')"
              :obj="q_obj"
              :ref="q_obj.name + '_question_component_0'"
              :counter="0"
            ></spark-question>
            <!-- END OBJECT IS A QUESTION -->
            <!-- OBJECT IS A QUESTION SET -->
            <spark-question-set
              v-else
              :obj="q_obj"
              :ref="q_obj.name + '_question_set_component_0'"
              :counter="0"
            ></spark-question-set>
            <!-- END OBJECT IS A QUESTION SET -->
          </div>
          <template v-if="multipleComponents[obj.name].length > 0">
            <template
              v-for="(child, index) in multipleComponents[obj.name].slice(1)"
            >
              <template v-if="child">
                <div class="mt-2" :key="'q_set_' + index">
                  <spark-paragraph
                    :label="
                      obj.label +
                        ' #' +
                        (index + 2) +
                        ' of ' +
                        (multipleComponents[obj.name].slice(1).length + 1)
                    "
                  ></spark-paragraph>
                  <b-row
                    v-if="
                      index + 2 ===
                        multipleComponents[obj.name].slice(1).length + 1
                    "
                    class="mb-10"
                  >
                    <b-col cols="0" sm="6" md="6" lg="6" xl="6"></b-col>
                    <b-col cols="12" sm="6" md="6" lg="6" xl="6">
                      <b-button
                        size="md"
                        class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold mr-4"
                        @click="deleteMultiple(obj.name, child.counter)"
                        >Delete</b-button
                      >
                    </b-col>
                  </b-row>
                </div>
                <div
                  v-for="(q_obj, obj_index) in obj.data"
                  :key="'q_set_' + child.counter + '_' + obj_index"
                >
                  <!-- OBJECT IS A QUESTION -->
                  <template v-if="q_obj.hasOwnProperty('field_type')">
                    <spark-question
                      :obj="q_obj"
                      :ref="q_obj.name + '_question_component_' + child.counter"
                      :counter="child.counter"
                      :key="index"
                    ></spark-question>
                  </template>
                  <!-- END OBJECT IS A QUESTION -->
                  <!-- OBJECT IS A QUESTION SET -->
                  <template v-else>
                    <spark-question-set
                      :obj="q_obj"
                      :ref="
                        q_obj.name + '_question_set_component_' + child.counter
                      "
                      :counter="child.counter"
                      :key="index"
                    ></spark-question-set>
                  </template>
                  <!-- END OBJECT IS A QUESTION SET -->
                </div>
              </template>
            </template>
          </template>
          <b-row class="mb-40">
            <b-col cols="0" sm="6" md="6" lg="6" xl="6"></b-col>
            <b-col cols="12" sm="6" md="6" lg="6" xl="6">
              <b-button
                size="md"
                class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold mr-4"
                @click="duplicate"
                >Add Another</b-button
              >
            </b-col>
          </b-row>
        </template>
      </template>
      <template v-else>
        <div v-for="(q_obj, obj_index) in obj.data" :key="'q_set_' + obj_index">
          <!-- OBJECT IS A QUESTION -->
          <spark-question
            v-if="q_obj.hasOwnProperty('field_type')"
            :obj="q_obj"
            :ref="q_obj.name + '_question_component_' + counter"
            :counter="counter"
          ></spark-question>
          <!-- END OBJECT IS A QUESTION -->
          <!-- OBJECT IS A QUESTION SET -->
          <spark-question-set
            v-else
            :obj="q_obj"
            :ref="q_obj.name + '_question_set_component_' + counter"
            :counter="counter"
          ></spark-question-set>
          <!-- END OBJECT IS A QUESTION SET -->
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  SET_CLAIM_FORM_ROOT_COMPONENTS,
  CLAIM_FORM_ADD_CONDITION,
  CLAIM_FORM_GET_WATCH_VALUE,
  CLAIM_FORM_ADD_FIELD_DATA,
  SET_CLAIM_FORM_SECTION_CAN_SHOW,
  CLAIM_FORM_ADD_FIELD_VALIDATION,
  SET_QUESTION_FIELD_DATA,
  //SET_QUESTION_FIELD_CHOICES
} from "@/core/services/vars.service";

export default {
  name: "spark-question-set",
  props: {
    obj: {
      type: Object,
      required: true
    },
    counter: {
      type: Number,
      default: 0
    },
    root: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      in_obj: null,
      data_path:
        this.root === true
          ? this.obj.name
          : this.$parent.data_path + "." + this.obj.name + "_" + this.counter,
      can_show: false,
      multiple_components: {},
      multiple_conds: Array(),
      subscription: null,
      deleted: false,
      childen_are_multiples: false,
      key: 1,
      address: {},
      address_options: {},
      vet_options: [],
      watchers: {},
      watch_store: {},
      refs_copy: null,
      manipulation: {},
      manipulation_show: {},
      manipulation_data: {},
      manipulation_validation: {},
      manipulation_fields: {},
      manipulation_lookup_results: {}
    };
  },
  /*watch: {
    conditionsMet: function(new_val) {
      if (new_val === true && this.root) {
        setTimeout(() => {
          this.$parent.layout();
        }, 1000);
      }
    }
  },*/
  computed: {
    hasChildrenThatAreVisible() {
      let visible = false;
      Object.keys(this.$refs).forEach(ref => {
        if (this.$refs[ref].length > 0 && this.$refs[ref][0].canShow === true) {
          visible = true;
        }
      });
      return visible;
    },
    conditionsMet() {
      return this.canShow && !this.deleted;
    },
    canShow() {
      if (this.obj.name === "ph_start_claim") return true;
      //if (!this.root && !this.$parent.canShow) return false;
      if (!this.obj.conditional) return true;
      return this.can_show;
    },

    multipleComponents() {
      return this.multiple_components;
    },
    nestedPassedValidation() {
      if (!this.conditionsMet) return true;
      let valid = true;
      Object.keys(this.$refs).forEach(ref => {
        if (
          this.$refs[ref].length > 0 &&
          this.$refs[ref][0].nestedPassedValidation === false
        ) {
          valid = false;
        }
      });
      return valid;
    },
    validationPassed() {
      return this.nestedPassedValidation;
    }
  },
  methods: {
    ...mapActions([
      SET_CLAIM_FORM_ROOT_COMPONENTS,
      SET_CLAIM_FORM_SECTION_CAN_SHOW
    ]),
    ...mapGetters([CLAIM_FORM_GET_WATCH_VALUE]),
    emitEvent(event) {
      console.log("EMITTING FROM QS", this.in_obj.name, event);
      return this.emitHandler(event);
    },
    emitHandler(event) {
      let nested_showing = 0;
      if (
        !this.deleted &&
        this.in_obj.conditional &&
        this.in_obj.conditional.length > 0
      ) {
        this.can_show = this.$spark.hasPassedAllConditions(
          this.in_obj.conditional,
          event
        );
      } else {
        console.log("NO CHECKS, CAN SHOW");
        this.can_show = true;
      }
      if (this.can_show) {
        var keys = Object.keys(this.$refs);
        for (var i = 0; i < keys.length; i++) {
          var name = keys[i];
          console.log("REF", this.$refs[name]);
          if (this.$refs[name][0].hasOwnProperty("emitEvent")) {
            nested_showing += this.$refs[name][0].emitEvent(event);
            console.log("NESTED COUNNT", nested_showing);
          }
        }
      }
      if (nested_showing === 0) {
        this.can_show = false;
      }
      console.log("CANSHOW", this.can_show, nested_showing);
      //this.refreshMultiple();
      this.$forceUpdate();
      return nested_showing;
    },
    recomputeCanShow() {
      let nested_showing = 0;
      if (
        !this.deleted &&
        this.in_obj.conditional &&
        this.in_obj.conditional.length > 0
      ) {
        this.can_show = this.$spark.hasPassedAllConditions(
          this.in_obj.conditional,
          this.counter,
          this.$refs
        );
      } else {
        this.can_show = true;
      }
      if (this.can_show) {
        Object.keys(this.$refs).forEach(key => {
          if (
            this.$refs[key].length > 0 &&
            this.$refs[key][0].hasOwnProperty("recomputeCanShow")
          ) {
            nested_showing += this.$refs[key][0].recomputeCanShow();
          }
        });
      }
      if (nested_showing === 0) {
        this.can_show = false;
      }
      this.refreshMultiple();
      this.$forceUpdate();
      return nested_showing;
    },
    refreshMultiple() {
      if (this.in_obj.multiple) {
        this.multiple_components = this.$spark.makeComputed.multiples;
      }
    },
    deleteMultiple(name, index) {
      this.multiple_components[this.in_obj.name] = this.multiple_components[
        this.in_obj.name
      ].filter(c => {
        return c.counter !== index;
      });
      this.$forceUpdate();
    },
    /*hasValidationPassed() {
      let passed = true;
      Object.keys(this.in_obj.data).forEach(obj => {
        let name = this.in_obj.data[obj].name;
        //console.log(name);
        let multi = this.in_obj.data[obj].multiple;
        if (multi) {
          //console.log("MULTI COMPONENT", name, this.$refs)
          let multiples = this.$spark.multiples[name];
          console.log(multiples);
          for (var i = 0; i < multiples.length; i++) {
            if (this.in_obj.data[obj].hasOwnProperty("field_type")) {
              //console.log(name);
              //console.log(this.$spark.questions);
              let question = this.$spark.getQuestion(name + "_" + i);
              if (question) {
                //console.log("1", name);
                //this.$spark.questions[name][0].$forceUpdate();
                if (question.conditionsMet) {
                  if (!question.hasValidationPassed()) {
                    passed = false;
                  }
                } else {
                  console.log("CONNDITIONS NOT MET", question);
                }
              } else {
                //console.log("2", name);
                //console.log(this.$spark.questions);
                passed = false;
              }
            } else {
              let set = this.$spark.getQuestionSet(name + "_" + i);
              if (set) {
                //console.log("3", name);
                //this.$spark.question_sets[name][0].$forceUpdate();
                if (set.conditionsMet) {
                  if (!set.hasValidationPassed()) {
                    passed = false;
                  }
                } else {
                  console.log("CONNDITIONS NOT MET SET", set);
                }
              } else {
                //console.log("4", name);
                //console.log(this.$spark.question_sets);
                passed = false;
              }
            }
          }
        } else if (this.$parent.childen_are_multiples) {
          //console.log("CHILDREN HAVE MULTIPLES", name, this.$refs)
          Object.keys(this.$refs).forEach(key => {
            if (
              this.$refs[key].length > 0 &&
              this.$refs[key][0].hasOwnProperty("hasValidationPassed")
            ) {
              if (!this.$refs[key][0].hasValidationPassed()) {
                passed = false;
              }
            }
          });
        } else {
          if (this.in_obj.data[obj].hasOwnProperty("field_type")) {
            //console.log(name);
            //console.log(this.$spark.questions);
            let question = this.$spark.getQuestion(name);
            if (question) {
              //console.log("1", name);
              //this.$spark.questions[name][0].$forceUpdate();
              if (question.conditionsMet) {
                if (!question.hasValidationPassed()) {
                  passed = false;
                }
              } else {
                console.log("CONNDITIONS NOT MET", question);
              }
            } else {
              //console.log("2", name);
              //console.log(this.$spark.questions);
              passed = false;
            }
          } else {
            let set = this.$spark.getQuestionSet(name);
            if (set) {
              //console.log("3", name);
              //this.$spark.question_sets[name][0].$forceUpdate();
              if (set.conditionsMet) {
                if (!set.hasValidationPassed()) {
                  passed = false;
                }
              } else {
                //console.log("CONNDITIONS NOT MET SET", set);
              }
            } else {
              //console.log("4", name);
              //console.log(this.$spark.question_sets);
              passed = false;
            }
          }
        }
      });
      return passed;
    },*/
    duplicate() {
      let component = {
        in_obj: this.in_obj,
        counter: this.multipleComponents[this.in_obj.name].length
      };
      this.addComponentToMultiple(this.in_obj.name, component, this.$parent);
      //this.refreshMultiple();
    },
    getChildByName(name, counter = false) {
      let component = null;
      Object.keys(this.$refs).filter(key => {
        if (key.startsWith(name) && counter === false) {
          //alert("GET CHILD BY NAME"+ key);
          component = this.$refs[key][0];
        } else if (key.startsWith(name) && key.endsWith("_" + counter)) {
          component = this.$refs[key][0];
        }
      });
      return component;
    },
    getChildByFullName(name) {
      let component = null;
      Object.keys(this.$refs).filter(key => {
        if (key === name) {
          component = this.$refs[key][0];
        }
      });
      return component;
    },
    buildDataObject() {
      let data = null;
      let component = null;
      let name = null;
      let obj = {};
      let inner_obj = {};
      Object.keys(this.$refs).filter(key => {
        component = this.$refs[key][0];
        console.log(key, component);
        name = component.obj.name.replace(/^([a-z0-9_]+)_[0-9]+$/, "$1");
        if (component.conditionsMet) {
          if (component.obj.multiple) {
            obj[name] = Array();
            Object.keys(component.$refs).forEach(ref => {
              //component.$refs[ref]
              console.error(ref, component.$refs[ref]);
              data = component.$refs[ref][0].buildDataObject();
              if (typeof data === "object") {
                //console.error(data);
                if (Object.keys(data).length > 0) {
                  if (
                    component.$refs[ref][0].obj.hasOwnProperty("original_name")
                  ) {
                    inner_obj[component.$refs[ref][0].obj.original_name] = data;
                  } else {
                    inner_obj[
                      component.$refs[ref][0].obj.name.replace(
                        /^([a-z0-9_]+)_[0-9]+$/,
                        "$1"
                      )
                    ] = data;
                  }
                  //obj[name].push(data);
                }
              } else {
                if (
                  component.$refs[ref][0].obj.hasOwnProperty("original_name")
                ) {
                  inner_obj[component.$refs[ref][0].obj.original_name] = data;
                } else {
                  inner_obj[
                    component.$refs[ref][0].obj.name.replace(
                      /^([a-z0-9_]+)_[0-9]+$/,
                      "$1"
                    )
                  ] = data;
                }
              }
            });
            if (Object.keys(inner_obj).length > 0) {
              obj[name].push(inner_obj);
            }
          } else {
            if (component.obj.hasOwnProperty("field_type")) {
              if (component.child) {
                obj[name] = component.buildDataObject();
              }
            } else {
              data = component.buildDataObject();
              if (Object.keys(data).length > 0) {
                obj[name] = data;
              }
            }
          }
        }
      });
      return obj;
    },
    performDataManipulation(
      question_name,
      value,
      validation,
      question_counter
    ) {
      //console.error(question_name, value, validation, question_counter);
      question_name = question_name.replace(/^([a-z0-9_]+)_[0-9]+$/, "$1");
      this.manipulation_data[question_name + "_" + question_counter] = value;
      this.manipulation_validation[
        question_name + "_" + question_counter
      ] = validation;
      this.$forceUpdate();
      let new_value = null;
      let m = null;
      Object.keys(this.manipulation).forEach(key => {
        m = this.manipulation[key];
        if (m.type === "lookup") {
          if (question_name === m.field) {
            if (
              this.manipulation_validation[m.field + "_" + question_counter] ===
              true
            ) {
              m.counter = question_counter;
              this.$spark.$api(m.action, {
                  value: this.manipulation_data[
                    m.field + "_" + question_counter
                  ],
                  manipulation: m,
                  field: this.manipulation_fields[m.response.field + "_" + m.counter],
                })
                .then(response => {
                  console.log("LOOKUP REESULTS", response);
                  this.manipulation_lookup_results[
                    response.manipulation.response.field +
                      "_" +
                      response.manipulation.counter
                  ] = response.data;
                }).catch(() => {});

                  /*if (response.manipulation.response.type === "form_choices") {
                    this.$store.commit(SET_QUESTION_FIELD_CHOICES, {
                      name: this.manipulation_fields[
                        response.manipulation.response.field +
                          "_" +
                          response.manipulation.counter
                      ],
                      choices: response.data,
                      choice_keys: {
                        label: response.manipulation.response.label,
                        value: response.manipulation.response.value
                      }
                    });
                  }*/
            }
          }
        }
        if (m.type === "set_field_value") {
          if (m.value.type === "form_field") {
            console.log(question_name, m);
            if (question_name === m.value.field) {
              console.log(this.manipulation_validation);
              if (
                this.manipulation_validation[
                  m.value.field + "_" + question_counter
                ] === true
              ) {
                new_value = this.transformDataValue(
                  m.value.transform,
                  this.manipulation_data[m.value.field + "_" + question_counter]
                );
              } else {
                new_value = "";
              }
              this.$store.commit(SET_QUESTION_FIELD_DATA, {
                name: this.manipulation_fields[
                  m.field + "_" + question_counter
                ],
                counter: question_counter,
                value: new_value
              });
            }
          }
          if (m.value.type === "form_field_combine") {
            if (
              question_name === m.value.field_1 ||
              question_name === m.value.field_2
            ) {
              if (
                this.manipulation_validation[
                  m.value.field_1 + "_" + question_counter
                ] === true &&
                this.manipulation_validation[
                  m.value.field_2 + "_" + question_counter
                ] === true
              ) {
                new_value = this.transformDataValue(
                  m.value.transform,
                  this.manipulation_data[
                    m.value.field_1 + "_" + question_counter
                  ],
                  this.manipulation_data[
                    m.value.field_2 + "_" + question_counter
                  ]
                );
              } else {
                new_value = "";
              }
              this.$store.commit(SET_QUESTION_FIELD_DATA, {
                name: this.manipulation_fields[
                  m.field + "_" + question_counter
                ],
                counter: question_counter,
                value: new_value
              });
            }
          }
        }
      });
    },
    performDataManipulationOnChange(
      question_name,
      value,
      validation,
      question_counter
    ) {
      //console.error(question_name, value, validation, question_counter)
      question_name = question_name.replace(/^([a-z0-9_]+)_[0-9]+$/, "$1");
      let mapped_data = null;
      let m = null;
      let choice_data = null;
      Object.keys(this.manipulation).forEach(key => {
        m = this.manipulation[key];
        if (m.type === "lookup" && m.response.on_choice_change) {
          if (question_name === m.response.field) {
            if (m.response.on_choice_change.type === "set_other_fields") {
              if (value === "null") {
                //
              } else {
                choice_data = this.manipulation_lookup_results[
                  m.response.field + "_" + question_counter
                ].filter(result => {
                  return result[m.response.value] === value;
                });
                console.log(m, choice_data);
                if (choice_data.length === 1) {
                  Object.keys(m.response.on_choice_change.data_map).forEach(
                    map_key => {
                      //mapped_key = m.response.on_choice_change.data_map[map_key];
                      mapped_data = choice_data[0][map_key];
                      if (mapped_data.length > 0) {
                        this.$store.commit(SET_QUESTION_FIELD_DATA, {
                          name: this.manipulation_fields[
                            map_key + "_" + question_counter
                          ],
                          counter: question_counter,
                          value: mapped_data
                        });
                      }
                    }
                  );
                }
              }
            }
          }
        }
      });
    },
    transformDataValue(type, value, second_value = null) {
      if (type === "to_vat") {
        return parseFloat((parseFloat(value) / 100) * 20).toFixed(2);
      }
      if (type === "add") {
        return parseFloat(parseFloat(value) + parseFloat(second_value)).toFixed(
          2
        );
      }
      if (type === "multiply") {
        return parseFloat(parseFloat(value) * parseFloat(second_value)).toFixed(
          2
        );
      }
    },
    addComponentToMultiple(name, component) {
      if (Object.keys(this.multiple_components).includes(name)) {
        this.multiple_components[name].push(component);
      } else {
        this.multiple_components[name] = Array(component);
      }
      this.$forceUpdate();
    }
  },
  beforeDestroy() {
    if (this.subscription) {
      // the subscribe method returns a function to call to unsubscribe
      this.subscription();
    }
  },
  beforeMount() {
    this.in_obj = Object.assign({}, this.obj);
    /*if(this.in_obj.conditional){
      let new_conds = Array();
      let new_cond = null;
      this.in_obj.conditional.forEach((cond) => {
        new_cond = Object.assign({}, cond);
        new_cond.spark_unique = new_cond.spark_unique + "_" + this.counter;
        new_conds.push(new_cond);
        this.$spark.$dispatch(CLAIM_FORM_ADD_CONDITION, {key: new_cond.spark_unique, value: new_cond});
      });
      this.in_obj.conditional = new_conds;
    }*/
    /*if (this.in_obj.spark_unique) {
      let new_unique = Array();
      console.error(this.in_obj.data_path)
      this.in_obj.spark_unique.forEach(obj => {
        new_unique.push(this.in_obj.data_path.replace(/^([a-zA-Z0-9_.]+_)[0-9]+_[0-9]+$/, "$1") + obj + "_" + this.counter);
      })
      this.in_obj.spark_unique = new_unique;
    }*/
    if (this.in_obj.manipulation) {
      this.in_obj.manipulation.forEach(m => {
        if (m.type === "show") {
          /*if (m.field in this.manipulation_show) {
            this.manipulation_show[m.field] = this.manipulation_show[m.field].concat(m);
          } else {
            this.manipulation_show[m.field] = Array(m);
          }*/
        } else {
          this.manipulation[m.field] = m;
        }
      });
    }
    if (this.in_obj.conditional) {
      let new_conds = Array();
      let new_cond = null;
      this.in_obj.conditional.forEach(cond => {
        new_cond = Object.assign({}, cond);
        if (cond.multiple) {
          if (this.counter > 0) {
            new_cond.element_name = this.data_path;
            //new_cond.spark_unique = new_cond.spark_unique.replace(/^([a-zA-Z0-9_]+)_[0-9]+$/, "$1_" + this.counter);
            new_cond.spark_unique = new_cond.spark_unique + "_" + this.counter;
          } else {
            new_cond.element_name = this.data_path;
            new_cond.spark_unique = new_cond.spark_unique + "_" + this.counter;
          }
          this.multiple_conds.push(new_cond.spark_unique);
        } else {
          if (this.counter > 0) {
            new_cond.element_name = this.data_path;
            //new_cond.spark_unique = new_cond.spark_unique.replace(/^([a-zA-Z0-9_]+)_[0-9]+$/, "$1_" + this.counter);
            new_cond.spark_unique = new_cond.spark_unique + "_" + this.counter;
          } else {
            new_cond.element_name = this.data_path;
            new_cond.spark_unique = new_cond.spark_unique + "_" + this.counter;
          }
        }
        new_conds.push(new_cond);
        this.$spark.$dispatch(CLAIM_FORM_ADD_CONDITION, {
          key: new_cond.spark_unique,
          value: new_cond
        });
      });
      this.in_obj.conditional = new_conds;
    }
    if (this.in_obj.spark_unique) {
      let new_unique = Array();
      this.in_obj.spark_unique.forEach(obj => {
        //if (!this.multiple_conds.includes(obj)) {
        //if (this.counter > 0) {
        //new_unique.push(obj.replace(/^([a-zA-Z0-9_]+)_[0-9]+$/, "$1_" + this.counter));
        //} else {
        new_unique.push(obj + "_" + this.counter);
        //}
        //}
      });
      this.in_obj.spark_unique = new_unique;
    }
    var filtered = null;
    if (this.in_obj.conditional) {
      this.subscription = this.$store.subscribe(mutation => {
        if (mutation.type === CLAIM_FORM_ADD_FIELD_DATA) {
          //console.log("SP", mutation.payload.spark_unique);
          filtered = this.in_obj.conditional.filter(cond => {
            //console.log("CND", cond.spark_unique);
            return mutation.payload.spark_unique.includes(cond.spark_unique);
          });
          //console.log("Q seet fiiltered", filtered);
          if (filtered.length > 0) {
            //} && filtered[0].comparison_type !== "validation_passed"){
            //console.log("Checking conditions VALUE", this.in_obj.name, mutation.payload.value)
            let pass_status = this.$spark.hasPassedAllConditions(
              this.in_obj.conditional,
              mutation.payload,
              "value"
            );
            if (pass_status !== null) {
              this.can_show = pass_status;
              if (this.root) {
                this.$forceUpdate();
                this.$spark.$dispatch(SET_CLAIM_FORM_SECTION_CAN_SHOW, {
                  key: this.in_obj.name,
                  value: this.can_show
                });
              }
            } else {
              this.can_show = false;
              if (this.root) {
                this.$forceUpdate();
                this.$spark.$dispatch(SET_CLAIM_FORM_SECTION_CAN_SHOW, {
                  key: this.in_obj.name,
                  value: this.can_show
                });
              }
            }
          }
          /*if (state.claim_form.watched_fields.hasOwnProperty(mutation.payload.key)) {
            filtered = this.in_obj.conditional.filter(cond => {
              return state.claim_form.watched_fields[mutation.payload.key].spark_unique.includes(cond.spark_unique)
            })
            if (filtered.length > 0){//} && filtered[0].comparison_type !== "validation_passed"){
              console.log("Checking conditions VALUE", this.in_obj.name, mutation.payload.value)
              let pass_status = this.$spark.hasPassedAllConditions(this.in_obj.conditional, mutation.payload, "value");
              if  (pass_status !== null)  {
                this.can_show = pass_status;
                if (this.root) {
                  this.$forceUpdate();
                  this.$spark.$dispatch(SET_CLAIM_FORM_SECTION_CAN_SHOW, {key: this.in_obj.name, value: this.can_show});
                }
              } else {
                this.can_show = false;
                if (this.root) {
                  this.$forceUpdate();
                  this.$spark.$dispatch(SET_CLAIM_FORM_SECTION_CAN_SHOW, {key: this.in_obj.name, value: this.can_show});
                }
              }
            } else {
              this.can_show = false;
              if (this.root) {
                this.$forceUpdate();
                this.$spark.$dispatch(SET_CLAIM_FORM_SECTION_CAN_SHOW, {key: this.in_obj.name, value: this.can_show});
              }
            }
          }*/
        }
        if (mutation.type === CLAIM_FORM_ADD_FIELD_VALIDATION) {
          //console.log("SP", mutation.payload.spark_unique);
          filtered = this.in_obj.conditional.filter(cond => {
            //console.log("CND", cond.spark_unique);
            return mutation.payload.spark_unique.includes(cond.spark_unique);
          });
          //console.log("Q set fiiltered", filtered);
          if (filtered.length > 0) {
            //} === 1 && filtered[0].comparison_type === "validation_passed"){
            //console.log("Checking conditions VALIDATION", this.in_obj.name, mutation.payload.value, state)
            let pass_status = this.$spark.hasPassedAllConditions(
              this.in_obj.conditional,
              mutation.payload,
              "validation"
            );
            if (pass_status !== null) {
              this.can_show = pass_status;
              if (this.root) {
                this.$forceUpdate();
                this.$spark.$dispatch(SET_CLAIM_FORM_SECTION_CAN_SHOW, {
                  key: this.in_obj.name,
                  value: this.can_show
                });
              }
            } else {
              this.can_show = false;
              if (this.root) {
                this.$forceUpdate();
                this.$spark.$dispatch(SET_CLAIM_FORM_SECTION_CAN_SHOW, {
                  key: this.in_obj.name,
                  value: this.can_show
                });
              }
            }
          }
          /*if (state.claim_form.watched_fields.hasOwnProperty(mutation.payload.key)) {
            filtered = this.in_obj.conditional.filter(cond => {
              return state.claim_form.watched_fields[mutation.payload.key].spark_unique.includes(cond.spark_unique)
            })
            console.log("Q set fiiltered", filtered);
            if (filtered.length > 0){ //} === 1 && filtered[0].comparison_type === "validation_passed"){
              console.log("Checking conditions VALIDATION", this.in_obj.name, mutation.payload.value, state)
              let pass_status = this.$spark.hasPassedAllConditions(this.in_obj.conditional, mutation.payload, "validation");
              if  (pass_status !== null)  {
                this.can_show = pass_status;
                if (this.root) {
                  this.$forceUpdate();
                  this.$spark.$dispatch(SET_CLAIM_FORM_SECTION_CAN_SHOW, {key: this.in_obj.name, value: this.can_show});
                }
              } else {
                this.can_show = false;
                if (this.root) {
                  this.$forceUpdate();
                  this.$spark.$dispatch(SET_CLAIM_FORM_SECTION_CAN_SHOW, {key: this.in_obj.name, value: this.can_show});
                }
              }
            } else {
              this.can_show = false;
              if (this.root) {
                this.$forceUpdate();
                this.$spark.$dispatch(SET_CLAIM_FORM_SECTION_CAN_SHOW, {key: this.in_obj.name, value: this.can_show});
              }
            }
          }*/
        }
      });
    } else {
      if (this.root) {
        this.can_show = true;
        this.$spark.$dispatch(SET_CLAIM_FORM_SECTION_CAN_SHOW, {
          key: this.in_obj.name,
          value: this.can_show
        });
      }
    }
  },
  mounted() {
    /*setTimeout(() => {
      if (this.in_obj.name.startsWith("account_details_")) {
        this.getChildByName("account_name").validation_passed = true;
        this.getChildByName("account_name").validation_passed = false;
        this.getChildByName("account_number").validation_passed = true;
        this.getChildByName("account_number").validation_passed = false;
        this.getChildByName("account_sort_code").validation_passed = true;
        this.getChildByName("account_sort_code").validation_passed = false;
      }
    },500);*/
    /*if(this.in_obj.conditional){
      let new_conds = Array();
      let new_cond = null;
      this.in_obj.conditional.forEach((cond) => {
        new_cond = Object.assign({}, cond);
        if (new_cond.multiple) {
          if (this.counter > 0) {
            new_cond.element_name = this.data_path;
            new_cond.spark_unique = new_cond.spark_unique.replace(/^([a-zA-Z0-9_]+)_[0-9]+$/, "$1_" + this.counter);
            //new_cond.spark_unique = new_cond.spark_unique + "_" + this.counter;
          } else {
            new_cond.element_name = this.data_path;
            new_cond.spark_unique = new_cond.spark_unique + "_" + this.counter;
          }
          this.multiple_conds.push(new_cond.spark_unique);
        } else {
          if (this.counter > 0) {
            new_cond.element_name = this.data_path;
            new_cond.spark_unique = new_cond.spark_unique.replace(/^([a-zA-Z0-9_]+)_[0-9]+$/, "$1_" + this.counter);
            //new_cond.spark_unique = new_cond.spark_unique + "_" + this.counter;
          } else {
            new_cond.element_name = this.data_path;
            new_cond.spark_unique = new_cond.spark_unique + "_" + this.counter;
          }
        }
        new_conds.push(new_cond);
        this.$spark.$dispatch(CLAIM_FORM_ADD_CONDITION, {key: new_cond.spark_unique, value: new_cond});
      });
      this.in_obj.conditional = new_conds;
    }
    if (this.in_obj.spark_unique) {
      let new_unique = Array();
      this.in_obj.spark_unique.forEach(obj => {
        //if (!this.multiple_conds.includes(obj)) {
          if (this.counter > 0) {
            new_unique.push(obj.replace(/^([a-zA-Z0-9_]+)_[0-9]+$/, "$1_" + this.counter));
          } else {
            new_unique.push(obj + "_" + this.counter);
          }
        //}
      })
      this.in_obj.spark_unique = new_unique;
    }*/
    if (this.root) {
      this.$spark.$dispatch(SET_CLAIM_FORM_ROOT_COMPONENTS, {
        key: this.in_obj.name,
        component: this
      });
    }
    if (this.obj.multiple) {
      this.childen_are_multiples = this.obj.name;
      if (this.counter === 0) {
        this.addComponentToMultiple(this.obj.name, {});
      }
      //this.refreshMultiple();
    }
    if (this.$parent.childen_are_multiples) {
      this.childen_are_multiples = this.$parent.childen_are_multiples;
    }

    /*if(this.in_obj.conditional){
      this.in_obj.conditional.forEach((cond) => {
        cond.element_name = this.in_obj.name+"_"+this.counter;
        this.$spark.$dispatch(CLAIM_FORM_ADD_CONDITION, {key: cond.condition_unique, value: cond});
      });
    }*/

    /*if (this.in_obj.multiple) {
      this.key += 1;
    }
    let name = null;
    let comp_name = null;
    let component = null;
    if (this.root) {
      //console.log("ROOT", this.in_obj, this.$spark.$refs);
      this.refs_copy = this.$spark.$refs;
      this.$spark.show_section[
        this.in_obj.name
      ] = this._computedWatchers.conditionsMet.vm._computedWatchers.conditionsMet.value;
    } else {
      //console.log("NESTED", this.in_obj, this.$parent.$refs);
      this.refs_copy = this.$parent.$refs;
      console.log(this.refs_copy, this.in_obj);
    }
    if (this.in_obj.hasOwnProperty("field_type")) {
      name = this.in_obj.name + "_" + this.counter;
      comp_name = this.in_obj.name + "_question_component_" + this.counter;
      component = this.refs_copy[comp_name];
      this.$spark.questions[name] = component;
    } else {
      name = this.in_obj.name + "_" + this.counter;
      comp_name = this.in_obj.name + "_question_set_component_" + this.counter;
      component = this.refs_copy[comp_name];
      this.$spark.question_sets[name] = component;
    }
    if (!component) {
      console.log("QSET ERROR");
      console.log(this.in_obj.name, this.counter);
      console.log(this, this.$refs);
      console.log(this.$parent, this.$parent.$refs);
    }

    if (this.in_obj.multiple) {
      this.childen_are_multiples = this.in_obj.name;
      if (this.counter === 0) {
        this.$spark.addComponentToMultiple(this.in_obj.name, component);
      }
      this.refreshMultiple();
    }
    if (this.$parent.childen_are_multiples) {
      this.childen_are_multiples = this.$parent.childen_are_multiples;
    }
    if (this.in_obj.conditional) {
      this.in_obj.conditional.forEach(cond => {
        cond.element_name = this.in_obj.name + "_" + this.counter;
        this.$spark.conditions[cond.condition_unique] = cond;
      });
    }*/
  }
};
</script>
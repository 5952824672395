<template>
  <div>
    <template v-if="all_validated">
      <template v-if="bank">
        <b-row class="mt-4 mb-4 justify-content-center">
          <b-col cols="10" class="p-4 m-4 rounded row spark-variant-primary-bg">
            <b-row class="w-100 m-0">
              <b-col cols="2" class="text-right">
                <i
                  class="fas fa-dog paragraph_warning spark-variant-secondary-text"
                ></i>
              </b-col>
              <b-col cols="8" class="spark-variant-text-light text-center pt-4">
                <span v-html="label" class="font-weight-bold h3"></span>
              </b-col>
              <b-col cols="2">
                <i
                  class="fas fa-cat paragraph_warning spark-variant-secondary-text"
                ></i>
              </b-col>
            </b-row>
            <b-row
              class="h6 pt-2 text-center spark-variant-text-light"
              v-if="bank['allow'] === true"
            >
              <b-col cols="6" class="bank_validation_label"
                >Validation Passed</b-col
              >
              <b-col cols="6" class="bank_validation_value">
                <i class="fas fa-check-circle green"></i>
              </b-col>
              <b-col cols="6" class="bank_validation_label bank_name_row">
                Bank Name
              </b-col>
              <b-col cols="6" class="bank_validation_value bank_name_row">
                <img
                  v-if="bank['bank_logo']"
                  :src="'/media/logos/banks/' + bank['bank_logo']"
                  class="bank_logo"
                />
                <i style="vertical-align:top;">{{
                  bank["bank_name_short"].toUpperCase()
                }}</i>
              </b-col>
              <b-col cols="6" class="bank_validation_label">Branch Name</b-col>
              <b-col cols="6" class="bank_validation_value">
                {{ bank["branch_name"].toUpperCase() }}
              </b-col>
              <b-col cols="6" class="bank_validation_label"
                >Head Office Address</b-col
              >
              <b-col
                cols="6"
                class="bank_validation_value"
                v-html="
                  bank['bank_name'].toUpperCase() +
                    '<br/>' +
                    bank['head_office_address'].join('<br/>').toUpperCase()
                "
              ></b-col>
              <b-col cols="6" class="bank_validation_label"
                >Branch Accepts BACS</b-col
              >
              <b-col cols="6" class="bank_validation_value">
                <i
                  v-if="bank['accepts_bacs']"
                  class="fas fa-check-circle green"
                ></i>
                <i v-else class="fas fa-times-circle red"></i>
              </b-col>
              <b-col cols="6" class="bank_validation_label">
                Branch Accepts FasterPayments
              </b-col>
              <b-col cols="6" class="bank_validation_value">
                <i
                  v-if="bank['accepts_faster_payments']"
                  class="fas fa-check-circle green"
                ></i>
                <i v-else class="fas fa-times-circle red"></i>
              </b-col>
              <b-col cols="6" class="bank_validation_label">
                Branch Accepts Cheques
              </b-col>
              <b-col cols="6" class="bank_validation_value">
                <i
                  v-if="bank['accepts_cheques']"
                  class="fas fa-check-circle green"
                ></i>
                <i v-else class="fas fa-times-circle red"></i>
              </b-col>
            </b-row>
            <b-row v-else>
              <b-col cols="6" class="bank_validation_label"
                >Validation Passed</b-col
              >
              <b-col cols="6" class="bank_validation_value">
                <i class="fas fa-times-circle red"></i>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </template>
      <template v-else>
        <b-row class="mt-4 mb-4 justify-content-center">
          <b-col cols="1" class="spark-desktop-spacer">
            <i class="fas fa-spinner fa-2x fa-spin loading_spinner"></i>
          </b-col>
        </b-row>
      </template>
    </template>
  </div>
</template>

<script>
import {
  LOOKUP_BANK_DETAILS,
  SET,
  //SET_BANK_DETAILS_RESULTS,
  //CLAIM_FORM_GET_FIELD_DATA,
  //CLAIM_FORM_GET_FIELD_VALIDATION,
  CLAIM_FORM_ADD_FIELD_VALIDATION
} from "@/core/services/vars.service";

export default {
  name: "spark-bank-validation",
  props: {
    id: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    sub_label: {
      type: String,
      default: null
    },
    fields: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      value: this.fields,
      all_validated: false,
      bank: null,
      cached: {},
      error_messages: [],
      subscription: null,
      bank_logos: {
        BARCLAYS: "barclays.png",
        CLEARBANK: "clearbank.png",
        COOP: "coop.png",
        HSBC: "hsbc.png",
        LLOYDS: "barclays.png",
        METRO_BANK: "metro_bank.png",
        MONZO_BANK: "monzo.png",
        NATWEST: "natwest.png",
        RBS: "rbs.png",
        SAINSBURYS: "sainsburys.png",
        SANTANDER: "santander.png",
        STARLING: "starling.png",
        TESCO: "tesco.png",
        TSB: "tsb.png"
      }
    };
  },
  computed: {
    Value() {
      return this.value;
    }
  },
  methods: {
    performBankDetailsValidation() {
      let data = {};
      let ac_name = this.$parent.$parent.getChildByName(
        "account_name",
        this.$parent.counter
      );
      let ac_number = this.$parent.$parent.getChildByName(
        "account_number",
        this.$parent.counter
      );
      let ac_sc = this.$parent.$parent.getChildByName(
        "account_sort_code",
        this.$parent.counter
      );
      if (
        ac_name.validationPassed === true &&
        ac_number.validationPassed === true &&
        ac_sc.validationPassed === true
      ) {
        this.all_validated = true;
        data.account_name = ac_name.Value;
        data.account_number = ac_number.Value;
        data.account_sort_code = ac_sc.Value;
        this.$spark.$api(LOOKUP_BANK_DETAILS, data).catch(() => {});
      } else {
        this.all_validated = false;
      }
    },
    getLogo(bank_name) {
      var bank_logo = null;
      Object.keys(this.bank_logos).forEach(name => {
        if (String(bank_name).search(name.replace("_", " ")) >= 0) {
          bank_logo = this.bank_logos[name];
        }
      });
      return bank_logo;
    }
  },
  beforeDestroy() {
    this.subscription(); // the subscribe method returns a function to call to unsubscribe
  },
  beforeMount() {
    this.subscription = this.$store.subscribe(mutation => {
      try {
        if (mutation.type === SET + LOOKUP_BANK_DETAILS) {
          this.bank = mutation.payload;
          this.bank.bank_logo = this.getLogo(this.bank.bank_name);
          //this.cached[ac_sc_val+ac_no_val] = data;
          this.$parent.validation_passed = this.bank.allow;
          this.$parent.$forceUpdate();
        }
        if (mutation.type === CLAIM_FORM_ADD_FIELD_VALIDATION) {
          if (this.$parent.conditionsMet && mutation.payload.value === true) {
            let parts = mutation.payload.key.split(".");
            if (parts[parts.length - 1].startsWith("account_")) {
              this.performBankDetailsValidation();
            }
          }
        }
      } catch(e) {
        console.error("ERROR IN STORE SUBSCRIPTION", e);
      }
    });
  }
};
</script>
<style>
#vue_claim_form .bank_validation_label {
  font-weight: bold;
  padding-top: 15px;
  text-align: right;
}
#vue_claim_form .bank_validation_value {
  padding-top: 15px;
  text-align: left;
}
#vue_claim_form .bank_name_row {
  vertical-align: middle;
}
#vue_claim_form .bank_logo {
  height: 50px;
  background-color: #ffffff;
  padding: 3px;
  border-radius: 5px;
  border: 1px solid black;
  margin-right: 10px;
}
</style>

<template>
  <div class="mb-10">
    <b-card
      class="mb-3 rounded-card spark-variant-primary-bg-light spark-variant-primary-border spark-variant-text-dark spark-block-content"
      header-class="card-header rounded-card-header fw-400 py-4 px-4 green-bottom-border spark-variant-primary-bg"
    >
      <template #header>
        <b-row>
          <b-col class="col-10">
            <div class="spark-block-title">
              <i
                class="fas fa-file-alt icon-extra-large spark-variant-secondary-text mr-2"
              ></i>
              <span v-html="title"></span>
            </div>
          </b-col>
        </b-row>
      </template>

      <b-row class="mb-5 justify-content-center row spark-html-block-content py-1">
        <div class="col-12 col-sm-10 col-lg-10 col-xl-10" v-html="pageContent"></div>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { CLIENT_REMOTE_RESOURCES } from "@/core/services/vars.service";

export default {
  name: "Portal-HTML-Block",
  props: {
    content: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  computed: {
    pageContent() {
      let new_content = this.content;
      if (/\{\{RESOURCE->\$[0-9]+\}\}/.test(new_content)) {
        this.$store.getters[CLIENT_REMOTE_RESOURCES].forEach(res => {
          new_content = new_content.replace(
            "{{RESOURCE->$" + res.id + "}}",
            res.filedata
          );
        });
      }
      return new_content;
    }
  }
};
</script>

<style>
.spark-html-block-content p {
  margin-bottom: 2em;
}
</style>

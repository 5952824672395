<template>
  <div class="spark-page" id="vue_claim_form" v-if="claim">
    <div class="spark-page-title">{{ $options.short_name }}</div>
    <div class="spark-page-content column-fluid row justify-content-center">
      <div class="col col-sm-12 col-md-12 col-lg-10">
        <div class="mb-10">
          <slide-up-down :active="disclaimerShow">
            <b-card
              class="mb-3 rounded-card spark-variant-primary-bg-light spark-variant-primary-border spark-variant-text-dark spark-block-content"
              header-class="card-header rounded-card-header fw-400 py-4 px-4 green-bottom-border spark-variant-primary-bg"
            >
              <template #header>
                <b-row>
                  <b-col>
                    <div class="spark-block-title">
                      <i
                        class="fas fa-exclamation-triangle icon-extra-large spark-variant-secondary-text mr-4"
                      ></i>
                      Disclaimer
                    </div>
                  </b-col>
                </b-row>
              </template>

              <b-row
                class="mb-5 justify-content-center row spark-html-block-content"
              >
                <div class="col-12 p-8" v-html="pageContent"></div>
              </b-row>
              <b-row class="mb-5 justify-content-center row">
                <b-button
                  size="md"
                  v-html="'Agree &amp; Proceed'"
                  class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
                  @click="
                    disclaimerShow = false;
                    showClaimForm();
                  "
                ></b-button>
              </b-row>
            </b-card>
          </slide-up-down>
          <spark-magic
            v-if="show_claim_form"
            :update_claim="updateClaimProps"
          ></spark-magic>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  PAGE_NAME_PORTAL_CLAIM_VET_UPDATE,
  CLAIM_DETAIL,
  CLAIM_DETAIL_PK,
  CLAIM_VET_DISCLAIMER,
  CLIENT_REMOTE_RESOURCES
} from "@/core/services/vars.service";

import SparkSlideUpDown from "@/view/spark_components/portal/claim/form/Slider";
import SparkDynamicFormGenerator from "@/view/spark_components/portal/claim/form/DynamicFormGenerator";

//import SparkClaimGeneralDetails from "@/view/spark_components/portal/claim/ClaimGeneralDetails";
//import SparkPolicyPetDetail from "@/view/spark_components/portal/policy/PolicyPetDetail";

export default {
  name: PAGE_NAME_PORTAL_CLAIM_VET_UPDATE,
  short_name: "Update Claim",
  path: "update",
  components: {
    //"spark-claim-general-details": SparkClaimGeneralDetails,
    //"spark-policy-pet-detail": SparkPolicyPetDetail
    "slide-up-down": SparkSlideUpDown,
    "spark-magic": SparkDynamicFormGenerator
  },
  data() {
    return {
      disclaimerShow: true,
      show_claim_form: false
    };
  },
  computed: {
    detail() {
      if (!this.claim) return null;
      return this.$store.getters[CLAIM_DETAIL];
    },
    claim() {
      return this.$store.getters[CLAIM_DETAIL_PK];
    },
    pageContent() {
      let new_content = this.$store.getters[CLAIM_VET_DISCLAIMER];
      if (/\{\{RESOURCE->\$[0-9]+\}\}/.test(new_content)) {
        this.$store.getters[CLIENT_REMOTE_RESOURCES].forEach(res => {
          new_content = new_content.replace(
            "{{RESOURCE->$" + res.id + "}}",
            res.filedata
          );
        });
      }
      return new_content;
    },
    updateClaimProps() {
      return {
        policy: this.detail.policy,
        pet: this.detail.pet.id,
        claim: this.claim
      };
    }
  },
  methods: {
    showClaimForm() {
      this.show_claim_form = true;
    }
  },
  mounted() {
    this.$spark.$breadcrumbs(this.$options);
  }
};
</script>

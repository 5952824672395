<template>
  <div class="spark-page" v-if="list && parentDetail">
    <div class="spark-page-title">
      {{ $options.short_name }}: {{ parentDetail.claim_number }}
    </div>
    <div class="spark-page-content column-fluid row">
      <div class="col col-sm-12 col-md-6 col-lg-6">
        <spark-claim-summary :item="parentDetail"></spark-claim-summary>
      </div>
      <div class="col col-sm-12 col-md-6 col-lg-6">
        <spark-document
          v-for="(doc, index) in orderedList"
          :item="doc"
          :key="index"
          prefix="Claim"
          :parent="parentDetail.id"
        ></spark-document>
        <spark-extra-document
            v-if="parentDetail.extra_documents.length > 0"
            :parent="parentDetail.id"
            :list="parentDetail.extra_documents"
          ></spark-extra-document>
      </div>
    </div>
  </div>
</template>

<script>
import {
  PAGE_NAME_PORTAL_DOCUMENT_CLAIM_LIST,
  CLAIM_DETAIL,
  CLAIM_DOCUMENT_LIST
} from "@/core/services/vars.service";

import SparkClaimSummary from "@/view/spark_components/portal/claim/ClaimSummary";
import SparkDocument from "@/view/spark_components/portal/document/Document";
import SparkExtraDocument from "@/view/spark_components/portal/document/ExtraDocument";

export default {
  name: PAGE_NAME_PORTAL_DOCUMENT_CLAIM_LIST,
  short_name: "Claim Documents",
  path: "list",
  components: {
    "spark-claim-summary": SparkClaimSummary,
    "spark-document": SparkDocument,
    "spark-extra-document": SparkExtraDocument
  },
  computed: {
    orderedList() {
      if (!this.list) return null;
      let new_list = this.list.data;
      return new_list.sort((x, y) => {
        if (x.required === y.required) return 0;
        if (x.required && !y.required) return -1;
        return 1;
      });
    },
    parentDetail() {
      return this.$store.getters[CLAIM_DETAIL];
    },
    list() {
      return this.$store.getters[CLAIM_DOCUMENT_LIST];
    }
  },
  mounted() {
    this.$spark.$breadcrumbs(this.$options);
  }
};
</script>

import { State, Action, Mutation, Getter, PaginationGetter } from "@/core/services/spark_api/action.module";
import { LOAD, SET, PAGE } from "@/core/services/vars.service";

const $inject = (dynamic, response) => {

  dynamic.forEach(api => {
    response.state[api.name] = State(api);
    response.getters[api.name] = Getter(api);
    if (api.paginated && api.paginated === true) {
      response.getters[PAGE + api.name] = PaginationGetter(api);
    }
    response.actions[LOAD + api.name] = Action(api);
    response.mutations[SET + api.name] = Mutation(api);
  });

  return response;
}

export default $inject;
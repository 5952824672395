import $spark from "@/core/services/spark.service";
import {
  SET_CLAIM_FORM,
  CLAIM_FORM_AUTO_POPULATED,
  CLAIM_FORM_AUTO_POPULATED_DATA,
  CLAIM_FORM_CURRENT_SECTION,
  SET_CLAIM_FORM_CURRENT_SECTION,
  SET_CLAIM_FORM_ROOT_COMPONENTS,
  CLAIM_FORM_SECTION_CAN_SHOW,
  SET_CLAIM_FORM_SECTION_CAN_SHOW,
  //CLAIM_FORM_HAS_CONDITIONAL_SHOW_PASSED,
  CLAIM_FORM_GET_FIELD_DATA,
  CLAIM_FORM_GET_FIELD_VALIDATION,
  CLAIM_FORM_ADD_CONDITION,
  CLAIM_FORM_ADD_WATCHED_FIELD,
  CLAIM_FORM_WATCH_ID_UNIQUE,
  CLAIM_FORM_ADD_WATCH_ID_UNIQUE,
  CLAIM_FORM_ADD_FIELD_DATA,
  CLAIM_FORM_ADD_FIELD_VALIDATION,
  CLAIM_FORM_GET_WATCH_VALUE,
  CLAIM_FORM_CONDITION_FROM_ELEMENT_NAME,
  CLAIM_FORM_HAS_SELECTED_SECTIONS_CLAIMING,
  SET_CLAIM_FORM_SECTION_VALIDATION_TRIGGER,
  SET_QUESTION_FIELD_DATA,
  SET_QUESTION_FIELD_CHOICES,
  RESET_CLAIM_FORM,
  SET_SUBMIT_CLAIM_FORM_RESPONSE,
  CLAIM_FORM,
  SUBMIT_CLAIM_FORM
} from "@/core/services/vars.service";

const getDefaultState = () => {
  return {
    auto_populated: false,
    claim_form_data: null,
    claim_form_section_show: [],
    current_section: null,
    claim_form_root_components: {},
    conditions: {},
    watched_fields: {},
    watch_id_unique: {},
    claim_form_field_data: {},
    claim_form_field_validation: {},
    has_selected_sections_claiming: false,
    claim_form_submit_response: null,
    page_data: {
      disclaimerShow: true,
      startClaim: false,
      policy_list: null,
      selected_policy: "null",
      selected_pet: "null",
      claim_form_data: null
    }
  };
};

const state = getDefaultState();

const getters = {
  [CLAIM_FORM_AUTO_POPULATED]: state => {
    return state.auto_populated;
  },
  [CLAIM_FORM_AUTO_POPULATED_DATA]: state => {
    return state.page_data;
  },
  [CLAIM_FORM_CURRENT_SECTION]: state => {
    return state.current_section;
  },
  [CLAIM_FORM_SECTION_CAN_SHOW]: state => {
    if (!state.claim_form_data) return null;
    return Object.keys(state.claim_form_data).filter(key => {
      return state.claim_form_section_show.includes(key);
    });
  },
  [CLAIM_FORM_CONDITION_FROM_ELEMENT_NAME]: state => name => {
    if (!(name in state.conditions)) return null;
    return Object.keys(state.conditions).filter(cond_unique => {
      return state.conditions[cond_unique].element_name === name;
    });
  },
  [CLAIM_FORM_GET_FIELD_VALIDATION]: state => name => {
    if (!(name in state.claim_form_field_validation)) return false;
    return state.claim_form_field_validation[name];
  },
  [CLAIM_FORM_GET_FIELD_DATA]: state => name => {
    if (!(name in state.claim_form_field_data)) {
      let fullkey = null;
      Object.keys(state.claim_form_field_data).forEach(key => {
        if (key.startsWith(name)) {
          fullkey = key;
        }
      });
      if (fullkey) {
        name = fullkey;
      } else {
        return null;
      }
    }
    return {
      name: name,
      value: state.claim_form_field_data[name]
    };
  },
  [CLAIM_FORM_WATCH_ID_UNIQUE]: state => {
    return state.watch_id_unique;
  },
  [CLAIM_FORM_GET_WATCH_VALUE]: state => element_name => {
    return state.watched_fields[element_name];
  },
  [CLAIM_FORM_HAS_SELECTED_SECTIONS_CLAIMING]: state => {
    return state.has_selected_sections_claiming;
  }
  /*[CLAIM_LIST]: state => {
    return state.list;
  },
  [CLAIM_DETAIL]: (state) => (pk) => {
    if (!(pk in state.detail)) return {};
    return state.detail[pk];
  }*/
};

const actions = {
  /*[LOAD_CLAIM_FORM](context, payload) {
    if ((!payload.policy || !payload.pet) && !payload.claim) {
      try {
        return router.push({ name: PAGE_NAME_PORTAL_CLAIM_CUSTOMER_LIST });
      } catch (e) {
        // do nothing
      }
    }
    context.commit(SET_SPARK_LOADING, { fn: LOAD_CLAIM_FORM, status: true });
    return new Promise(resolve => {
      var options = {
        auth: store.getters[AUTH_TOKEN],
        params: payload
      };
      API.query(PORTAL_URLS.claim.create, options, error => {
        let messages = API.parseErrors(error);
        //console.log("MESSAGES", messages);
        context.commit(SET_SPARK_LOADING, {
          fn: LOAD_CLAIM_FORM,
          status: false
        });
        context.commit(SET_ALERT, {
          message: Array(messages).join("<br/>"),
          variant: "danger",
          timer: 10
        });
      })
        .then(({ data }) => {
          context.commit(SET_SPARK_LOADING, {
            fn: LOAD_CLAIM_FORM,
            status: false
          });
          context.commit(SET_CLAIM_FORM, data);
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [SUBMIT_CLAIM_FORM](context, payload) {
    if ((!payload.policy || !payload.pet) && !payload.claim) {
      try {
        return router.push({ name: PAGE_NAME_PORTAL_CLAIM_CUSTOMER_LIST });
      } catch (e) {
        // do nothing
      }
    }
    context.commit(SET_SPARK_LOADING, { fn: SUBMIT_CLAIM_FORM, status: true });
    return new Promise(resolve => {
      var options = {
        auth: store.getters[AUTH_TOKEN],
        params: payload
      };
      API.post(PORTAL_URLS.claim.create, options, error => {
        let messages = API.parseErrors(error);
        //console.log("MESSAGES", messages);
        context.commit(SET_SPARK_LOADING, {
          fn: SUBMIT_CLAIM_FORM,
          status: false
        });
        context.commit(SET_ALERT, {
          message: Array(messages).join("<br/>"),
          variant: "danger",
          timer: 10
        });
      })
        .then(({ data }) => {
          context.commit(SET_SPARK_LOADING, {
            fn: SUBMIT_CLAIM_FORM,
            status: false
          });
          context.commit(SET_SUBMIT_CLAIM_FORM_RESPONSE, data);
          resolve(data);
        })
        .catch(() => {});
    });
  },*/
  [SET_CLAIM_FORM_ROOT_COMPONENTS](context, payload) {
    context.commit(SET_CLAIM_FORM_ROOT_COMPONENTS, payload);
  },
  [CLAIM_FORM_ADD_CONDITION](context, payload) {
    context.commit(CLAIM_FORM_ADD_CONDITION, payload);
  },
  [CLAIM_FORM_ADD_WATCHED_FIELD](context, payload) {
    context.commit(CLAIM_FORM_ADD_WATCHED_FIELD, payload);
  },
  [CLAIM_FORM_ADD_WATCH_ID_UNIQUE](context, payload) {
    context.commit(CLAIM_FORM_ADD_WATCH_ID_UNIQUE, payload);
  },
  [CLAIM_FORM_ADD_FIELD_DATA](context, payload) {
    context.commit(CLAIM_FORM_ADD_FIELD_DATA, payload);
  },
  [CLAIM_FORM_ADD_FIELD_VALIDATION](context, payload) {
    context.commit(CLAIM_FORM_ADD_FIELD_VALIDATION, payload);
  },
  [SET_CLAIM_FORM_SECTION_CAN_SHOW](context, payload) {
    context.commit(SET_CLAIM_FORM_SECTION_CAN_SHOW, payload);
  },
  [SET_CLAIM_FORM_SECTION_VALIDATION_TRIGGER](context, payload) {
    context.commit(SET_CLAIM_FORM_SECTION_VALIDATION_TRIGGER, payload);
  },
  [RESET_CLAIM_FORM](context) {
    context.commit(RESET_CLAIM_FORM);
  }
};

const mutations = {
  [SET_CLAIM_FORM](state, data) {
    state.claim_form_data = data.data;
    if (state.current_section === null) {
      Object.keys(state.claim_form_data).forEach(key => {
        if (state.current_section === null) {
          state.current_section = key;
        }
      });
    }
  },
  [SET_SUBMIT_CLAIM_FORM_RESPONSE](state, data) {
    state.claim_form_submit_response = data;
  },
  [SET_CLAIM_FORM_CURRENT_SECTION](state, data) {
    state.current_section = data;
  },
  [SET_CLAIM_FORM_ROOT_COMPONENTS](state, data) {
    state.claim_form_root_components[data.key] = data.component;
  },
  [CLAIM_FORM_ADD_CONDITION](state, data) {
    state.conditions[data.key] = data.value;
  },
  [CLAIM_FORM_ADD_WATCHED_FIELD](state, data) {
    state.watched_fields[data.key] = data.value;
  },
  [CLAIM_FORM_ADD_WATCH_ID_UNIQUE](state, data) {
    state.watch_id_unique[data.key] = data.value;
  },
  [CLAIM_FORM_ADD_FIELD_DATA](state, data) {
    state.claim_form_field_data[data.key] = data.value;
    console.log(data);
    if (data.key === "ph_start_claim.sections_claiming_for_1") {
      if (data.value.length > 0) {
        state.has_selected_sections_claiming = true;
      } else {
        state.has_selected_sections_claiming = false;
      }
    }
  },
  [CLAIM_FORM_ADD_FIELD_VALIDATION](state, data) {
    state.claim_form_field_validation[data.key] = data.value;
  },
  [SET_CLAIM_FORM_SECTION_CAN_SHOW](state, data) {
    if (data.value === true) {
      if (!state.claim_form_section_show.includes(data.key)) {
        state.claim_form_section_show.push(data.key);
      }
    }
    if (data.value === false) {
      if (state.claim_form_section_show.indexOf(data.key) !== -1) {
        state.claim_form_section_show.splice(
          state.claim_form_section_show.indexOf(data.key)
        );
      }
    }
  },
  [RESET_CLAIM_FORM](state) {
    Object.assign(state, getDefaultState());
  },
  [SET_CLAIM_FORM_SECTION_VALIDATION_TRIGGER]() {
    return;
  },
  [SET_QUESTION_FIELD_DATA]() {
    return;
  },
  [SET_QUESTION_FIELD_CHOICES]() {
    return;
  }
};



const dynamic = [
  {
    name: CLAIM_FORM,
    url: {
      path: $spark.$urls.$portal.claim.create
    },
    commit: false,
    commit_on_success: [SET_CLAIM_FORM]
  },
  {
    name: SUBMIT_CLAIM_FORM,
    method: "post",
    url: {
      path: $spark.$urls.$portal.claim.create
    },
    commit: false,
    commit_on_success: [SET_SUBMIT_CLAIM_FORM_RESPONSE]
  }
];

const _store = $spark.$inject(dynamic, {
  state,
  actions,
  mutations,
  getters
});
export default _store;

import Vue from "vue";
import Router from "vue-router";
import store from "@/core/services/store/index";

import ClientConfig from "@/core/config/deployment";
import SparkError403 from "@/view/spark/SparkError403";
import SparkError404 from "@/view/spark/SparkError404";
import Layout from "@/view/spark_layout/Layout";
import EmptyLayout from "@/view/spark_layout/EmptyLayout";
import ErrorLayout from "@/view/spark_layout/ErrorLayout";

import PortalLayout from "@/view/spark_layout/portal/wireframe/Layout";
import PortalLayoutProblems from "@/view/spark_layout/portal/Problems";
import PortalLayoutAbout from "@/view/spark_layout/portal/About";
import PortalLayoutAuthenticated from "@/view/spark_layout/portal/Authenticated";
import PortalLayoutAccount from "@/view/spark_layout/portal/Account";
import PortalLayoutClaim from "@/view/spark_layout/portal/Claim";
import PortalLayoutClaimCustomer from "@/view/spark_layout/portal/PetsClaimCustomer";
import PortalLayoutClaimVet from "@/view/spark_layout/portal/PetsClaimVet";
import PortalLayoutClaimDocument from "@/view/spark_layout/portal/ClaimDocument";
import PortalLayoutComplaint from "@/view/spark_layout/portal/Complaint";
import PortalLayoutComplaintDocument from "@/view/spark_layout/portal/ComplaintDocument";
import PortalLayoutCorrespondence from "@/view/spark_layout/portal/Correspondence";
import PortalLayoutPolicy from "@/view/spark_layout/portal/Policy";
import PortalLayoutPolicyDocument from "@/view/spark_layout/portal/PolicyDocument";

import AdminLayout from "@/view/spark_layout/admin/Layout";
import LayoutAuthenticated from "@/view/spark_layout/admin/Authenticated";
import LayoutCustomerService from "@/view/spark_layout/admin/CustomerService";
import LayoutSearch from "@/view/spark_layout/admin/Search";
import LayoutClaim from "@/view/spark_layout/admin/Claim";
import LayoutComplaint from "@/view/spark_layout/admin/Complaint";
import LayoutPolicy from "@/view/spark_layout/admin/Policy";
import LayoutMTA from "@/view/spark_layout/admin/MTA";
import LayoutQuote from "@/view/spark_layout/admin/Quote";
import LayoutCallcentre from "@/view/spark_layout/admin/Callcentre";
import LayoutCorrespondence from "@/view/spark_layout/admin/Correspondence";
import LayoutCustomer from "@/view/spark_layout/admin/Customer";
import LayoutGDPR from "@/view/spark_layout/admin/GDPR";
import LayoutPayment from "@/view/spark_layout/admin/Payment";
import LayoutPaymentClaim from "@/view/spark_layout/admin/PaymentClaim";
import LayoutPaymentPolicy from "@/view/spark_layout/admin/PaymentPolicy";
import LayoutReport from "@/view/spark_layout/admin/Report";
import LayoutReportExport from "@/view/spark_layout/admin/ReportExport";
import LayoutReportImport from "@/view/spark_layout/admin/ReportImport";
import LayoutTask from "@/view/spark_layout/admin/Task";
import LayoutVet from "@/view/spark_layout/admin/Vet";

import SysLayout from "@/view/spark_layout/admin/SystemConfig";

import {
  ADMIN_URL_PATH,
  ADMIN_NAME,
  ADMIN_SECURITY,
  PORTAL_URL_PATH,
  PORTAL_NAME,
  PORTAL_SECURITY,
  ERROR_URL_PATH,
  CLAIM,
  POLICY,
  TYPE,
  TEAM,
  PERMISSION,
  QUEUE,
  MEMBER,
  ADDON_PATH,
  AGGREGATOR_PATH,
  BRAND_PATH,
  CALLCENTRE_PATH,
  COMMISSION_PATH,
  CORRESPONDENCE_PATH,
  DOCUMENT_PATH,
  ENDORSEMENT_PATH,
  EXCLUSION_PATH,
  PRODUCT_PATH,
  UNDERWRITER_PATH,
  USER_PATH,
  DELETE_STORAGE_ITEM
} from "@/core/services/vars.service";

Vue.use(Router);

// empty object, we fill once config created
var SparkRoutes = {
  routes: []
};

var SparkMainLayout = {
  name: "root",
  path: "/", // root path
  redirect: ClientConfig.redirect, // this is where the root path gets sent based on config (portal/admin login / public quote and buy etc)
  component: Layout,
  children: []
};

var SparkPortalRoot = {
  path: PORTAL_URL_PATH,
  redirect: "/" + PORTAL_URL_PATH + "/" + ClientConfig.views.portal.login.path,
  component: PortalLayout,
  children: [
    {
      path: ClientConfig.views.portal.login.path,
      name: ClientConfig.views.portal.login.name,
      component: ClientConfig.views.portal.login
    }
  ]
};

var SparkPortalError = {
  path: ERROR_URL_PATH,
  redirect: "/",
  component: ErrorLayout,
  children: [
    {
      path: SparkError404.path,
      name: PORTAL_NAME + "-" + SparkError404.name,
      component: SparkError404
    },
    {
      path: SparkError403.path,
      name: PORTAL_NAME + "-" + SparkError403.name,
      component: SparkError403
    }
  ]
};

SparkPortalRoot.children.push(SparkPortalError);

// (public) register is optional per client
if (ClientConfig.views.portal.register) {
  SparkPortalRoot.children.push({
    path: ClientConfig.views.portal.register.path,
    name: ClientConfig.views.portal.register.name,
    component: ClientConfig.views.portal.register
  });
}

// (public) quote and buy is optional per client
if (ClientConfig.views.portal.quote) {
  SparkPortalRoot.children.push({
    path: ClientConfig.views.portal.quote.path,
    name: ClientConfig.views.portal.quote.name,
    component: ClientConfig.views.portal.quote
  });
}

// (public) about pages
var SparkPortalAbout = {
  path: PortalLayoutAbout.path,
  redirect: SparkError404.path,
  component: PortalLayoutAbout,
  children: [
    {
      path: ClientConfig.views.portal.public.about.contact_us.path,
      name: ClientConfig.views.portal.public.about.contact_us.name,
      component: ClientConfig.views.portal.public.about.contact_us
    }
  ]
};

// custom database driven about pages, need to grab list from API to know which routes we can create
// does this happen once or every new vue instance (fresh pageload) - will this affect page load times?
// should I look into injecting routes after initial router creation, eg, once vue has loaded and user is navigating ???

// use api (public auth) to access custom about page list
// execute HTTP call here
/*
response.pages.forEach(){
  SparkPortalAbout.children.push({
    path: ClientConfig.views.portal.public.about.custom.path,
    name: ClientConfig.views.portal.public.about.custom.name,
    component: ClientConfig.views.portal.public.custom.contact_us,
  });
};
*/

SparkPortalRoot.children.push(SparkPortalAbout);

// authenticated portal routes
var SparkPortalAuthenticated = {
  path: PortalLayoutAuthenticated.path,
  redirect: SparkError404.path,
  component: PortalLayoutAuthenticated,
  meta: {
    security: PORTAL_SECURITY
  },
  children: [
    {
      path: ClientConfig.views.portal.authenticated.dashboard.path,
      name: ClientConfig.views.portal.authenticated.dashboard.name,
      component: ClientConfig.views.portal.authenticated.dashboard
    }
  ]
};

// problems routes
var SparkPortalProblems = {
  path: PortalLayoutProblems.path,
  redirect: SparkError404.path,
  component: PortalLayoutProblems,
  children: [
    {
      path: ClientConfig.views.portal.authenticated.problems.path,
      name: ClientConfig.views.portal.authenticated.problems.name,
      component: ClientConfig.views.portal.authenticated.problems
    }
  ]
};

SparkPortalAuthenticated.children.push(SparkPortalProblems);

// account routes
var SparkPortalAccount = {
  path: PortalLayoutAccount.path,
  redirect: SparkError404.path,
  component: PortalLayoutAccount,
  children: []
};

// populate account routes
ClientConfig.views.portal.authenticated.account.forEach(obj => {
  SparkPortalAccount.children.push({
    path: obj.path,
    name: obj.name,
    component: obj
  });
});

// push claim customer routes to claim routes
SparkPortalAuthenticated.children.push(SparkPortalAccount);

// claim routes
var SparkPortalClaim = {
  path: PortalLayoutClaim.path,
  redirect: SparkError404.path,
  component: PortalLayoutClaim,
  children: []
};

if (ClientConfig.client_code === "scratch_and_patch") {
  // claim customer routes
  var SparkPortalClaimCustomer = {
    path: PortalLayoutClaimCustomer.path,
    redirect: SparkError404.path,
    component: PortalLayoutClaimCustomer,
    children: []
  };

  // populate claim customer routes
  ClientConfig.views.portal.authenticated.claim.customer.forEach(obj => {
    SparkPortalClaimCustomer.children.push({
      path: obj.path,
      name: obj.name,
      component: obj
    });
  });

  // push claim customer routes to claim routes
  SparkPortalClaim.children.push(SparkPortalClaimCustomer);

  // claim vet routes
  var SparkPortalClaimVet = {
    path: PortalLayoutClaimVet.path,
    redirect: SparkError404.path,
    component: PortalLayoutClaimVet,
    children: []
  };

  // populate claim vet routes
  ClientConfig.views.portal.authenticated.claim.vet.forEach(obj => {
    SparkPortalClaimVet.children.push({
      path: obj.path,
      name: obj.name,
      component: obj
    });
  });

  // push claim vet routes to claim routes
  SparkPortalClaim.children.push(SparkPortalClaimVet);
} else {
  // claim routes
  SparkPortalClaim = {
    path: PortalLayoutClaim.path,
    redirect: SparkError404.path,
    component: PortalLayoutClaim,
    children: []
  };

  // populate claim routes
  ClientConfig.views.portal.authenticated.claim.forEach(obj => {
    SparkPortalClaim.children.push({
      path: obj.path,
      name: obj.name,
      component: obj
    });
  });
}

// add nested claim documents routes
var SparkPortalClaimDocuments = {
  path: PortalLayoutClaimDocument.path,
  redirect: SparkError404.path,
  component: PortalLayoutClaimDocument,
  children: []
};

// populate claim document routes
ClientConfig.views.portal.authenticated.document.claim.forEach(obj => {
  SparkPortalClaimDocuments.children.push({
    path: obj.path,
    name: obj.name,
    component: obj
  });
});

// push claim document routes to claim routes
SparkPortalClaim.children.push(SparkPortalClaimDocuments);

// push claim routes to authenticated
SparkPortalAuthenticated.children.push(SparkPortalClaim);

// complaint routes
var SparkPortalComplaint = {
  path: PortalLayoutComplaint.path,
  redirect: SparkError404.path,
  component: PortalLayoutComplaint,
  children: []
};

// populate complaint routes
ClientConfig.views.portal.authenticated.complaint.forEach(obj => {
  SparkPortalComplaint.children.push({
    path: obj.path,
    name: obj.name,
    component: obj
  });
});

// add nested complaint documents routes
var SparkPortalComplaintDocuments = {
  path: PortalLayoutComplaintDocument.path,
  redirect: SparkError404.path,
  component: PortalLayoutComplaintDocument,
  children: []
};

// populate complaint document routes
ClientConfig.views.portal.authenticated.document.complaint.forEach(obj => {
  SparkPortalComplaintDocuments.children.push({
    path: obj.path,
    name: obj.name,
    component: obj
  });
});

// push complaint document routes to complaint routes
SparkPortalComplaint.children.push(SparkPortalComplaintDocuments);

// push complaint routes to authenticated
SparkPortalAuthenticated.children.push(SparkPortalComplaint);

// correspondence routes
var SparkPortalCorrespondence = {
  path: PortalLayoutCorrespondence.path,
  redirect: SparkError404.path,
  component: PortalLayoutCorrespondence,
  children: []
};

// populate correspondence routes
ClientConfig.views.portal.authenticated.correspondence.forEach(obj => {
  SparkPortalCorrespondence.children.push({
    path: obj.path,
    name: obj.name,
    component: obj
  });
});

// push correspondence routes to authenticated
SparkPortalAuthenticated.children.push(SparkPortalCorrespondence);

// policy routes
var SparkPortalPolicy = {
  path: PortalLayoutPolicy.path,
  redirect: SparkError404.path,
  component: PortalLayoutPolicy,
  children: []
};

// populate policy routes
ClientConfig.views.portal.authenticated.policy.forEach(obj => {
  SparkPortalPolicy.children.push({
    path: obj.path,
    name: obj.name,
    component: obj
  });
});

// add nested policy documents routes
var SparkPortalPolicyDocuments = {
  path: PortalLayoutPolicyDocument.path,
  redirect: SparkError404.path,
  component: PortalLayoutPolicyDocument,
  children: []
};

// populate policy document routes
ClientConfig.views.portal.authenticated.document.policy.forEach(obj => {
  SparkPortalPolicyDocuments.children.push({
    path: obj.path,
    name: obj.name,
    component: obj
  });
});

// push policy document routes to policy routes
SparkPortalPolicy.children.push(SparkPortalPolicyDocuments);

// push policy routes to authenticated
SparkPortalAuthenticated.children.push(SparkPortalPolicy);

SparkPortalRoot.children.push(SparkPortalAuthenticated);

SparkMainLayout.children.push(SparkPortalRoot);

// admin routes
var SparkAdminRoot = {
  path: AdminLayout.path,
  redirect: "/" + ADMIN_URL_PATH + "/" + ClientConfig.views.admin.login.path,
  component: AdminLayout,
  children: [
    {
      path: ClientConfig.views.admin.login.path,
      name: ClientConfig.views.admin.login.name,
      component: ClientConfig.views.admin.login
    }
  ]
};

var SparkAdminError = {
  path: ERROR_URL_PATH,
  redirect: "/",
  component: ErrorLayout,
  children: [
    {
      path: SparkError404.path,
      name: ADMIN_NAME + "-" + SparkError404.name,
      component: SparkError404
    },
    {
      path: SparkError403.path,
      name: ADMIN_NAME + "-" + SparkError403.name,
      component: SparkError403
    }
  ]
};

SparkAdminRoot.children.push(SparkAdminError);

// authenticated admin routes
var SparkAdminAuthenticated = {
  path: LayoutAuthenticated.path,
  redirect: SparkError404.path,
  component: LayoutAuthenticated,
  meta: {
    security: ADMIN_SECURITY
  },
  children: [
    {
      path: ClientConfig.views.admin.authenticated.dashboard.path,
      name: ClientConfig.views.admin.authenticated.dashboard.name,
      component: ClientConfig.views.admin.authenticated.dashboard
    }
  ]
};

// customer service routes
var SparkAdminCustomerService = {
  path: LayoutCustomerService.path,
  redirect: SparkError404.path,
  component: LayoutCustomerService,
  children: []
};

// search routes
var SparkAdminSearch = {
  path: LayoutSearch.path,
  redirect: SparkError404.path,
  component: LayoutSearch,
  children: []
};

// populate search routes
ClientConfig.views.admin.authenticated.search.forEach(obj => {
  SparkAdminSearch.children.push({
    path: obj.path,
    name: obj.name,
    component: obj
  });
});

// push search routes to authenticated
SparkAdminCustomerService.children.push(SparkAdminSearch);

// claim routes
var SparkAdminClaim = {
  path: LayoutClaim.path,
  redirect: SparkError404.path,
  component: LayoutClaim,
  children: []
};

// populate claim routes
ClientConfig.views.admin.authenticated.claim.forEach(obj => {
  SparkAdminClaim.children.push({
    path: obj.path,
    name: obj.name,
    component: obj
  });
});

// push claim routes to customer service
SparkAdminCustomerService.children.push(SparkAdminClaim);

// complaint routes
var SparkAdminComplaint = {
  path: LayoutComplaint.path,
  redirect: SparkError404.path,
  component: LayoutComplaint,
  children: []
};

// populate complaint routes
ClientConfig.views.admin.authenticated.complaint.forEach(obj => {
  SparkAdminComplaint.children.push({
    path: obj.path,
    name: obj.name,
    component: obj
  });
});

// push complaint routes to customer service
SparkAdminCustomerService.children.push(SparkAdminComplaint);

// policy routes
var SparkAdminPolicy = {
  path: LayoutPolicy.path,
  redirect: SparkError404.path,
  component: LayoutPolicy,
  children: []
};

// populate policy routes
ClientConfig.views.admin.authenticated.policy.forEach(obj => {
  SparkAdminPolicy.children.push({
    path: obj.path,
    name: obj.name,
    component: obj
  });
});

// policy MTA routes
var SparkAdminPolicyMTA = {
  path: LayoutMTA.path,
  redirect: SparkError404.path,
  component: LayoutMTA,
  children: []
};

// populate policy MTA routes
ClientConfig.views.admin.authenticated.mta.forEach(obj => {
  SparkAdminPolicyMTA.children.push({
    path: obj.path,
    name: obj.name,
    component: obj
  });
});

// push policy MTA routes to policy
SparkAdminPolicy.children.push(SparkAdminPolicyMTA);

// push policy routes to customer service
SparkAdminCustomerService.children.push(SparkAdminPolicy);

// quote routes
var SparkAdminQuote = {
  path: LayoutQuote.path,
  redirect: SparkError404.path,
  component: LayoutQuote,
  children: []
};

// populate quote routes
ClientConfig.views.admin.authenticated.quote.forEach(obj => {
  SparkAdminQuote.children.push({
    path: obj.path,
    name: obj.name,
    component: obj
  });
});

// push quote routes to customer service
SparkAdminCustomerService.children.push(SparkAdminQuote);

// push customer service routes to authenticated
SparkAdminAuthenticated.children.push(SparkAdminCustomerService);

// callcentre routes
var SparkAdminCallcentre = {
  path: LayoutCallcentre.path,
  redirect: SparkError404.path,
  component: LayoutCallcentre,
  children: []
};

// populate callcentre routes
ClientConfig.views.admin.authenticated.callcentre.forEach(obj => {
  SparkAdminCallcentre.children.push({
    path: obj.path,
    name: obj.name,
    component: obj
  });
});

// push callcentre routes to authenticated
SparkAdminAuthenticated.children.push(SparkAdminCallcentre);

// correspondence routes
var SparkAdminCorrespondence = {
  path: LayoutCorrespondence.path,
  redirect: SparkError404.path,
  component: LayoutCorrespondence,
  children: []
};

// populate correspondence routes
ClientConfig.views.admin.authenticated.correspondence.forEach(obj => {
  SparkAdminCorrespondence.children.push({
    path: obj.path,
    name: obj.name,
    component: obj
  });
});

// push correspondence routes to authenticated
SparkAdminAuthenticated.children.push(SparkAdminCorrespondence);

// customer routes
var SparkAdminCustomer = {
  path: LayoutCustomer.path,
  redirect: SparkError404.path,
  component: LayoutCustomer,
  children: []
};

// populate customer routes
ClientConfig.views.admin.authenticated.customer.forEach(obj => {
  SparkAdminCustomer.children.push({
    path: obj.path,
    name: obj.name,
    component: obj
  });
});

// push customer routes to authenticated
SparkAdminAuthenticated.children.push(SparkAdminCustomer);

// gdpr routes
var SparkAdminGDPR = {
  path: LayoutGDPR.path,
  redirect: SparkError404.path,
  component: LayoutGDPR,
  children: []
};

// populate gdpr routes
ClientConfig.views.admin.authenticated.gdpr.forEach(obj => {
  SparkAdminGDPR.children.push({
    path: obj.path,
    name: obj.name,
    component: obj
  });
});

// push gdpr routes to authenticated
SparkAdminAuthenticated.children.push(SparkAdminGDPR);

// payment routes
var SparkAdminPayment = {
  path: LayoutPayment.path,
  redirect: SparkError404.path,
  component: LayoutPayment,
  children: []
};

// claim payment routes
var SparkAdminPaymentClaim = {
  path: LayoutPaymentClaim.path,
  redirect: SparkError404.path,
  component: LayoutPaymentClaim,
  children: []
};

// populate claim payment routes
ClientConfig.views.admin.authenticated.payment.claim.forEach(obj => {
  SparkAdminPaymentClaim.children.push({
    path: obj.path,
    name: obj.name,
    component: obj
  });
});

// push claim payment routes to payment routes
SparkAdminPayment.children.push(SparkAdminPaymentClaim);

// policy payment routes
var SparkAdminPaymentPolicy = {
  path: LayoutPaymentPolicy.path,
  redirect: SparkError404.path,
  component: LayoutPaymentPolicy,
  children: []
};

// populate policy payment routes
ClientConfig.views.admin.authenticated.payment.policy.forEach(obj => {
  SparkAdminPaymentPolicy.children.push({
    path: obj.path,
    name: obj.name,
    component: obj
  });
});

// push policy payment routes to payment routes
SparkAdminPayment.children.push(SparkAdminPaymentPolicy);

// push payment routes to authenticated
SparkAdminAuthenticated.children.push(SparkAdminPayment);

// report routes
var SparkAdminReport = {
  path: LayoutReport.path,
  redirect: SparkError404.path,
  component: LayoutReport,
  children: []
};

// report export routes
var SparkAdminReportExport = {
  path: LayoutReportExport.path,
  redirect: SparkError404.path,
  component: LayoutReportExport,
  children: []
};

// populate report export routes
ClientConfig.views.admin.authenticated.report.export.forEach(obj => {
  SparkAdminReportExport.children.push({
    path: obj.path,
    name: obj.name,
    component: obj
  });
});

// push report export routes to report routes
SparkAdminReport.children.push(SparkAdminReportExport);

// report import routes
var SparkAdminReportImport = {
  path: LayoutReportImport.path,
  redirect: SparkError404.path,
  component: LayoutReportImport,
  children: []
};

// populate report import routes
ClientConfig.views.admin.authenticated.report.import.forEach(obj => {
  SparkAdminReportImport.children.push({
    path: obj.path,
    name: obj.name,
    component: obj
  });
});

// push report import routes to report routes
SparkAdminReport.children.push(SparkAdminReportImport);

// push report routes to authenticated
SparkAdminAuthenticated.children.push(SparkAdminReport);

// task routes
var SparkAdminTask = {
  path: LayoutTask.path,
  redirect: SparkError404.path,
  component: LayoutTask,
  children: []
};

// populate task routes
ClientConfig.views.admin.authenticated.task.forEach(obj => {
  SparkAdminTask.children.push({
    path: obj.path,
    name: obj.name,
    component: obj
  });
});

// push task routes to authenticated
SparkAdminAuthenticated.children.push(SparkAdminTask);

// vet routes
var SparkAdminVet = {
  path: LayoutVet.path,
  redirect: SparkError404.path,
  component: LayoutVet,
  children: []
};

// populate vet routes
ClientConfig.views.admin.authenticated.vet.forEach(obj => {
  SparkAdminVet.children.push({
    path: obj.path,
    name: obj.name,
    component: obj
  });
});

// push vet routes to authenticated
SparkAdminAuthenticated.children.push(SparkAdminVet);

// system config
var SparkSys = {
  path: SysLayout.path,
  redirect: SparkError404.path,
  component: EmptyLayout,
  children: []
};

// addon routes
var SparkSysAddon = {
  path: ADDON_PATH,
  redirect: SparkError404.path,
  component: SysLayout,
  children: []
};

// populate addon routes
ClientConfig.views.admin.authenticated.system_config.addon.forEach(obj => {
  SparkSysAddon.children.push({
    path: obj.path,
    name: obj.name,
    component: obj
  });
});

// push addon routes to system config
SparkSys.children.push(SparkSysAddon);

// aggregator routes
var SparkSysAggregator = {
  path: AGGREGATOR_PATH,
  redirect: SparkError404.path,
  component: SysLayout,
  children: []
};

// populate aggregator routes
ClientConfig.views.admin.authenticated.system_config.aggregator.forEach(obj => {
  SparkSysAggregator.children.push({
    path: obj.path,
    name: obj.name,
    component: obj
  });
});

// push aggregator routes to system config
SparkSys.children.push(SparkSysAggregator);

// brand routes
var SparkSysBrand = {
  path: BRAND_PATH,
  redirect: SparkError404.path,
  component: SysLayout,
  children: []
};

// populate brand routes
ClientConfig.views.admin.authenticated.system_config.brand.forEach(obj => {
  SparkSysBrand.children.push({
    path: obj.path,
    name: obj.name,
    component: obj
  });
});

// push brand routes to system config
SparkSys.children.push(SparkSysBrand);

// callcentre routes
var SparkSysCallcentre = {
  path: CALLCENTRE_PATH,
  redirect: SparkError404.path,
  component: EmptyLayout,
  children: [
    {
      path: TEAM,
      redirect: SparkError404.path,
      component: SysLayout,
      children: []
    },
    {
      path: QUEUE,
      redirect: SparkError404.path,
      component: SysLayout,
      children: []
    },
    {
      path: MEMBER,
      redirect: SparkError404.path,
      component: SysLayout,
      children: []
    }
  ]
};

// populate callcentre team routes
ClientConfig.views.admin.authenticated.system_config.callcentre.team.forEach(
  obj => {
    SparkSysCallcentre.children[0].children.push({
      path: obj.path,
      name: obj.name,
      component: obj
    });
  }
);

// populate callcentre queue routes
ClientConfig.views.admin.authenticated.system_config.callcentre.queue.forEach(
  obj => {
    SparkSysCallcentre.children[1].children.push({
      path: obj.path,
      name: obj.name,
      component: obj
    });
  }
);

// populate callcentre member routes
ClientConfig.views.admin.authenticated.system_config.callcentre.member.forEach(
  obj => {
    SparkSysCallcentre.children[2].children.push({
      path: obj.path,
      name: obj.name,
      component: obj
    });
  }
);

// push callcentre routes to system config
SparkSys.children.push(SparkSysCallcentre);

// commission routes
var SparkSysCommission = {
  path: COMMISSION_PATH,
  redirect: SparkError404.path,
  component: SysLayout,
  children: []
};

// populate commission routes
ClientConfig.views.admin.authenticated.system_config.commission.forEach(obj => {
  SparkSysCommission.children.push({
    path: obj.path,
    name: obj.name,
    component: obj
  });
});

// push commission routes to system config
SparkSys.children.push(SparkSysCommission);

// correspondence routes
var SparkSysCorrespondence = {
  path: CORRESPONDENCE_PATH,
  redirect: SparkError404.path,
  component: SysLayout,
  children: []
};

// populate correspondence routes
ClientConfig.views.admin.authenticated.system_config.correspondence.forEach(
  obj => {
    SparkSysCorrespondence.children.push({
      path: obj.path,
      name: obj.name,
      component: obj
    });
  }
);

// push correspondence routes to system config
SparkSys.children.push(SparkSysCorrespondence);

// document routes
var SparkSysDocument = {
  path: DOCUMENT_PATH,
  redirect: SparkError404.path,
  component: EmptyLayout,
  children: [
    {
      path: CLAIM,
      redirect: SparkError404.path,
      component: SysLayout,
      children: []
    },
    {
      path: POLICY,
      redirect: SparkError404.path,
      component: SysLayout,
      children: []
    }
  ]
};

// populate claim document routes
ClientConfig.views.admin.authenticated.system_config.document.claim.forEach(
  obj => {
    SparkSysDocument.children[0].children.push({
      path: obj.path,
      name: obj.name,
      component: obj
    });
  }
);

// populate policy document routes
ClientConfig.views.admin.authenticated.system_config.document.policy.forEach(
  obj => {
    SparkSysDocument.children[1].children.push({
      path: obj.path,
      name: obj.name,
      component: obj
    });
  }
);

// push document routes to system config
SparkSys.children.push(SparkSysDocument);

// endorsement routes
var SparkSysEndorsement = {
  path: ENDORSEMENT_PATH,
  redirect: SparkError404.path,
  component: SysLayout,
  children: []
};

// populate endorsement routes
ClientConfig.views.admin.authenticated.system_config.endorsement.forEach(
  obj => {
    SparkSysEndorsement.children.push({
      path: obj.path,
      name: obj.name,
      component: obj
    });
  }
);

// push endorsement routes to system config
SparkSys.children.push(SparkSysEndorsement);

// exclusion routes
var SparkSysExclusion = {
  path: EXCLUSION_PATH,
  redirect: SparkError404.path,
  component: SysLayout,
  children: []
};

// populate exclusion routes
ClientConfig.views.admin.authenticated.system_config.exclusion.forEach(obj => {
  SparkSysExclusion.children.push({
    path: obj.path,
    name: obj.name,
    component: obj
  });
});

// push exclusion routes to system config
SparkSys.children.push(SparkSysExclusion);

// product routes
var SparkSysProduct = {
  path: PRODUCT_PATH,
  redirect: SparkError404.path,
  component: SysLayout,
  children: []
};

// populate product routes
ClientConfig.views.admin.authenticated.system_config.product.forEach(obj => {
  SparkSysProduct.children.push({
    path: obj.path,
    name: obj.name,
    component: obj
  });
});

// product type routes
var SparkSysProductType = {
  path: TYPE,
  redirect: SparkError404.path,
  component: EmptyLayout,
  children: []
};

// populate product type routes
ClientConfig.views.admin.authenticated.system_config.product_type.forEach(
  obj => {
    SparkSysProductType.children.push({
      path: obj.path,
      name: obj.name,
      component: obj
    });
  }
);

// push product type routes to product routes
SparkSysProduct.children.push(SparkSysProductType);

// push product routes to system config
SparkSys.children.push(SparkSysProduct);

// underwriter routes
var SparkSysUnderwriter = {
  path: UNDERWRITER_PATH,
  redirect: SparkError404.path,
  component: SysLayout,
  children: []
};

// populate underwriter routes
ClientConfig.views.admin.authenticated.system_config.underwriter.forEach(
  obj => {
    SparkSysUnderwriter.children.push({
      path: obj.path,
      name: obj.name,
      component: obj
    });
  }
);

// push underwriter routes to system config
SparkSys.children.push(SparkSysUnderwriter);

// user routes
var SparkSysUser = {
  path: USER_PATH,
  redirect: SparkError404.path,
  component: SysLayout,
  children: []
};

// populate user routes
ClientConfig.views.admin.authenticated.system_config.user.forEach(obj => {
  SparkSysUser.children.push({
    path: obj.path,
    name: obj.name,
    component: obj
  });
});

// user team routes
var SparkSysUserTeam = {
  path: TEAM,
  redirect: SparkError404.path,
  component: EmptyLayout,
  children: []
};

// populate user team routes
ClientConfig.views.admin.authenticated.system_config.user_team.forEach(obj => {
  SparkSysUserTeam.children.push({
    path: obj.path,
    name: obj.name,
    component: obj
  });
});

// push user team routes to user
SparkSysUser.children.push(SparkSysUserTeam);

// user permission routes
var SparkSysUserPermission = {
  path: PERMISSION,
  redirect: SparkError404.path,
  component: EmptyLayout,
  children: []
};

// populate user permission routes
ClientConfig.views.admin.authenticated.system_config.user_permission.forEach(
  obj => {
    SparkSysUserPermission.children.push({
      path: obj.path,
      name: obj.name,
      component: obj
    });
  }
);

// push user permission routes to user
SparkSysUser.children.push(SparkSysUserPermission);

// push user routes to system config
SparkSys.children.push(SparkSysUser);

// push system config routes to authenticated
SparkAdminAuthenticated.children.push(SparkSys);

SparkAdminRoot.children.push(SparkAdminAuthenticated);

SparkMainLayout.children.push(SparkAdminRoot);

var SparkError = {
  path: ERROR_URL_PATH,
  redirect: "/",
  component: ErrorLayout,
  children: [
    {
      path: SparkError404.path,
      name: SparkError404.name,
      component: SparkError404
    },
    {
      path: SparkError403.path,
      name: SparkError403.name,
      component: SparkError403
    }
  ]
};

SparkMainLayout.children.push(SparkError);

SparkRoutes.routes.push(SparkMainLayout);

// MUST be very last route added
SparkRoutes.routes.push({
  path: "*",
  redirect: "/" + ERROR_URL_PATH + "/" + SparkError404.path
});

const router = new Router(SparkRoutes);

router.beforeEach((to, from, next) => {
  if (to.hash === "#empty") {
    return false;
  }
  let new_component = to.matched[to.matched.length - 1];
  if (new_component.components.default.pk) {
    if (to.params.id) {
      store.$dispatch(DELETE_STORAGE_ITEM, new_component.components.default.pk).then(() => {
        store.$dispatch(new_component.components.default.pk, to.params.id).then(() => {
          delete to.params.id
          next(to);
        });
      });
    }
  }
  next();
});

/*router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.security)) {
    console.log("TO", to);
    //let perm = to.name;
    //let section = to.meta.security;
    // if not logged in {
    if (!store.getters[IS_AUTHENTICATED]){ //&& to.name !== ClientConfig.views.portal.login.name){
      next({name: ClientConfig.views.portal.login.name});
    }
    //next("/"+section+"/login");
    // if doesn't have permission {
    //next("/error/permission-denied");
    next();
  } else {
    next();
  }
});*/

export default router;

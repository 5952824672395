var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.conditionsMet === true),expression:"conditionsMet === true"}]},[(_vm.obj.multiple)?[(_vm.obj.name in _vm.multipleComponents)?[(_vm.multipleComponents && _vm.obj.name in _vm.multipleComponents)?_c('div',{staticClass:"mt-2"},[_c('spark-paragraph',{attrs:{"label":_vm.obj.label +
                ' #1 of ' +
                (_vm.multipleComponents[_vm.obj.name].length === 1
                  ? '1'
                  : _vm.multipleComponents[_vm.obj.name].slice(1).length + 1)}})],1):_vm._e(),_vm._l((_vm.obj.data),function(q_obj,obj_index){return _c('div',{key:'q_set_0_' + obj_index},[(q_obj.hasOwnProperty('field_type'))?_c('spark-question',{ref:q_obj.name + '_question_component_0',refInFor:true,attrs:{"obj":q_obj,"counter":0}}):_c('spark-question-set',{ref:q_obj.name + '_question_set_component_0',refInFor:true,attrs:{"obj":q_obj,"counter":0}})],1)}),(_vm.multipleComponents[_vm.obj.name].length > 0)?[_vm._l((_vm.multipleComponents[_vm.obj.name].slice(1)),function(child,index){return [(child)?[_c('div',{key:'q_set_' + index,staticClass:"mt-2"},[_c('spark-paragraph',{attrs:{"label":_vm.obj.label +
                      ' #' +
                      (index + 2) +
                      ' of ' +
                      (_vm.multipleComponents[_vm.obj.name].slice(1).length + 1)}}),(
                    index + 2 ===
                      _vm.multipleComponents[_vm.obj.name].slice(1).length + 1
                  )?_c('b-row',{staticClass:"mb-10"},[_c('b-col',{attrs:{"cols":"0","sm":"6","md":"6","lg":"6","xl":"6"}}),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"6","xl":"6"}},[_c('b-button',{staticClass:"mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold mr-4",attrs:{"size":"md"},on:{"click":function($event){return _vm.deleteMultiple(_vm.obj.name, child.counter)}}},[_vm._v("Delete")])],1)],1):_vm._e()],1),_vm._l((_vm.obj.data),function(q_obj,obj_index){return _c('div',{key:'q_set_' + child.counter + '_' + obj_index},[(q_obj.hasOwnProperty('field_type'))?[_c('spark-question',{key:index,ref:q_obj.name + '_question_component_' + child.counter,refInFor:true,attrs:{"obj":q_obj,"counter":child.counter}})]:[_c('spark-question-set',{key:index,ref:q_obj.name + '_question_set_component_' + child.counter,refInFor:true,attrs:{"obj":q_obj,"counter":child.counter}})]],2)})]:_vm._e()]})]:_vm._e(),_c('b-row',{staticClass:"mb-40"},[_c('b-col',{attrs:{"cols":"0","sm":"6","md":"6","lg":"6","xl":"6"}}),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"6","xl":"6"}},[_c('b-button',{staticClass:"mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold mr-4",attrs:{"size":"md"},on:{"click":_vm.duplicate}},[_vm._v("Add Another")])],1)],1)]:_vm._e()]:_vm._l((_vm.obj.data),function(q_obj,obj_index){return _c('div',{key:'q_set_' + obj_index},[(q_obj.hasOwnProperty('field_type'))?_c('spark-question',{ref:q_obj.name + '_question_component_' + _vm.counter,refInFor:true,attrs:{"obj":q_obj,"counter":_vm.counter}}):_c('spark-question-set',{ref:q_obj.name + '_question_set_component_' + _vm.counter,refInFor:true,attrs:{"obj":q_obj,"counter":_vm.counter}})],1)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }
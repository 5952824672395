<template>
  <div class="spark-page" v-if="list">
    <div class="spark-page-title">{{ $options.short_name }}</div>
    <div class="spark-page-content column-fluid row">
      <template v-for="(item, index) in list">
        <div class="col col-sm-12 col-md-6 col-lg-6" :key="'list_' + index">
          <spark-policy-summary :item="item"></spark-policy-summary>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { POLICY_LIST, PAGE } from "@/core/services/vars.service";

import SparkPolicySummary from "@/view/spark_components/portal/policy/PolicySummary";

export default {
  name: "Portal-Policy-List",
  short_name: "Policies",
  path: "list",
  menu: true,
  components: {
    "spark-policy-summary": SparkPolicySummary
  },
  computed: {
    list() {
      return this.$store.getters[POLICY_LIST];
    },
    pagination() {
      return this.$store.getters[PAGE + POLICY_LIST];
    },
    paginationTo() {
      return POLICY_LIST;
    }
  },
  mounted() {
    this.$spark.$breadcrumbs(this.$options);
  }
};
</script>

<template>
  <div class="mb-10">
    <b-card
      class="mb-3 rounded-card spark-variant-primary-bg-light spark-variant-primary-border spark-variant-text-dark spark-block-content"
      header-class="card-header rounded-card-header fw-400 py-4 px-4 green-bottom-border spark-variant-primary-bg"
    >
      <template #header>
        <b-row>
          <b-col>
            <div class="spark-block-title">
              <i
                class="far fa-file-alt icon-extra-large spark-variant-secondary-text mr-4"
              ></i>
              {{ $options.short_name }}
            </div>
          </b-col>
        </b-row>
      </template>

      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">Payment to</b-col>
        <b-col class="col-6"
          ><i class="fas fa-paw"></i>{{ item.payment_to }}</b-col
        >
      </b-row>
      <b-row class="mb-5" v-if="item.payment_type">
        <b-col class="col-6 font-weight-bold text-right">Payment type</b-col>
        <b-col class="col-6"
          ><i class="fas fa-paw"></i>{{ item.payment_type }}</b-col
        >
      </b-row>
      <b-row class="mb-5" v-if="item.account_name">
        <b-col class="col-6 font-weight-bold text-right"
          >Account holder name</b-col
        >
        <b-col class="col-6"
          ><i class="fas fa-paw"></i>{{ item.account_name }}</b-col
        >
      </b-row>
      <b-row class="mb-5" v-if="item.account_sort_code">
        <b-col class="col-6 font-weight-bold text-right"
          >Account sort code</b-col
        >
        <b-col class="col-6"
          ><i class="fas fa-paw"></i>{{ item.account_sort_code }}</b-col
        >
      </b-row>
      <b-row class="mb-5" v-if="item.account_number">
        <b-col class="col-6 font-weight-bold text-right">Account number</b-col>
        <b-col class="col-6"
          ><i class="fas fa-paw"></i>{{ item.account_number }}</b-col
        >
      </b-row>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "Portal-Claim-Payment-Details",
  short_name: "Payment Details",
  props: {
    item: {
      type: Object,
      required: true
    }
  }
};
</script>

<template>
  <div>
    <p>ERRR 403</p>
  </div>
</template>

<script>

export default {
  name: "Spark-Error-403-Permission-Denied",
  path: "permission-denied",
  mounted() {
    this.$spark.$breadcrumbs(this.$options);
  }
};
</script>

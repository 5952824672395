// mutation types
import { SET_MENU, MENU_ITEMS } from "../vars.service";

export default {
  state: {
    menu_items: []
  },
  getters: {
    /**
     * Get list of menu items for current page
     * @param state
     * @returns {*}
     */
    [MENU_ITEMS](state) {
      return state.menu_items;
    }
  },
  actions: {
    /**
     * Set the menu items list
     * @param state
     * @param payload
     */
    [SET_MENU](state, payload) {
      state.commit(SET_MENU, payload);
    }
  },
  mutations: {
    [SET_MENU](state, payload) {
      state.menu_items = payload;
    }
  }
};

<template>
  <div>
    <!--<KTMenu></KTMenu>-->
    <component :is="template">
      <spark-page-bootstrap :columns="1">
        <router-view />
      </spark-page-bootstrap>
    </component>
  </div>
</template>

<script>
import {
  CLIENT_REMOTE_STYLES,
  SPARK_TEMPLATE_CONTENT
} from "@/core/services/vars.service";

export default {
  name: "Layout-Portal-About",
  short_name: "About Us",
  path: "about",
  data() {
    return {
      template: null
    };
  },
  beforeMount() {
    this.template = this.$store.getters[CLIENT_REMOTE_STYLES](SPARK_TEMPLATE_CONTENT);
  }
};
</script>

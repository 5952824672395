<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "Layout-Portal-PolicyDocument",
  short_name: "Policy Documents",
  path: "document"
};
</script>

import $spark from "@/core/services/spark.service";
import {
  CORRESPONDENCE_LIST,
  CORRESPONDENCE_DETAIL,
  CORRESPONDENCE_DETAIL_PK,
  //CORRESPONDENCE_DOCUMENT_LIST,
  SET_CORRESPONDENCE_DETAIL_PK,
  SET_CORRESPONDENCE_CONTACT_REFERENCES,
  CORRESPONDENCE_CONTACT_REFERENCES,
  SUBMIT_CORRESPONDENCE_CONTACT_FORM,
  SUBMIT_CORRESPONDENCE_PUBLIC_CONTACT_FORM
} from "@/core/services/vars.service";

const state = {
  detail_pk: null
};

const getters = {
  [CORRESPONDENCE_DETAIL_PK]: state => {
    return state.detail_pk;
  }
};

const actions = {
  [SET_CORRESPONDENCE_DETAIL_PK](context, payload) {
    $spark.$storage.set(SET_CORRESPONDENCE_DETAIL_PK, payload);
    context.commit(SET_CORRESPONDENCE_DETAIL_PK, payload);
    //return $spark.$api(CORRESPONDENCE_DETAIL);
  },
  [SET_CORRESPONDENCE_CONTACT_REFERENCES](context, payload) {
    context.commit(SET_CORRESPONDENCE_CONTACT_REFERENCES, payload);
  }
};

const mutations = {
  [SET_CORRESPONDENCE_DETAIL_PK](state, data) {
    state.detail_pk = data;
  }
};


const dynamic = [
  {
    name: CORRESPONDENCE_LIST,
    paginated: true,
    url: {
      path: $spark.$urls.$portal.correspondence.list
    }
  },
  {
    name: CORRESPONDENCE_CONTACT_REFERENCES,
    url: {
      path: $spark.$urls.$portal.correspondence.contact.references
    }
  },
  {
    name: CORRESPONDENCE_DETAIL,
    pk: true,
    url: {
      path: $spark.$urls.$portal.correspondence.detail,
      replace: {
        "<CORRESPONDENCE_PK>": CORRESPONDENCE_DETAIL_PK
      }
    },
    //dispatch_on_success: [CORRESPONDENCE_DOCUMENT_LIST, CORRESPONDENCE_DETAIL]
  },
  {
    name: SUBMIT_CORRESPONDENCE_CONTACT_FORM,
    method: "post",
    url: {
      path: $spark.$urls.$portal.correspondence.contact.send_message
    }
  },
  {
    auth: false,
    name: SUBMIT_CORRESPONDENCE_PUBLIC_CONTACT_FORM,
    method: "post",
    url: {
      path: $spark.$urls.$portal.correspondence.contact.send_message_public
    }
  },
];


const _store = $spark.$inject(dynamic, {
  state,
  actions,
  mutations,
  getters
});
export default _store;

import $spark from "@/core/services/spark.service";
import {
  CLAIM_DETAIL,
  CLAIM_DETAIL_PK,
  CLAIM_DOCUMENT_LIST,
  CLAIM_DOCUMENT_DETAIL,
  CLAIM_DOCUMENT_DETAIL_PK,
  SET_CLAIM_DOCUMENT_DETAIL_PK,
  UPLOAD_CLAIM_DOCUMENT,
  UPLOAD_CLAIM_EXTRA_DOCUMENT,
  LOAD
} from "@/core/services/vars.service";

const state = {
  detail_pk: null
};

const getters = {
  [CLAIM_DOCUMENT_DETAIL_PK]: state => {
    return state.detail_pk;
  }
};

const actions = {
  [SET_CLAIM_DOCUMENT_DETAIL_PK](context, payload) {
    $spark.$storage.set(SET_CLAIM_DOCUMENT_DETAIL_PK, payload);
    context.commit(SET_CLAIM_DOCUMENT_DETAIL_PK, payload);
    //return $spark.$api(CLAIM_DOCUMENT_DETAIL);
  }
};

const mutations = {
  [SET_CLAIM_DOCUMENT_DETAIL_PK](state, data) {
    state.detail_pk = data;
  }
};

const document_upload = (payload) => {
  return payload.filedata;
}

const dynamic = [
  {
    name: CLAIM_DOCUMENT_LIST,
    url: {
      path: $spark.$urls.$portal.document.claim.list,
      replace: {
        "<CLAIM_PK>": CLAIM_DETAIL_PK
      }
    }
  },
  {
    name: CLAIM_DOCUMENT_DETAIL,
    url: {
      path: $spark.$urls.$portal.document.claim.detail,
      pk: true,
      replace: {
        "<CLAIM_PK>": CLAIM_DETAIL_PK,
        "<DOCUMENT_PK>": CLAIM_DOCUMENT_DETAIL_PK
      }
    }
  },
  {
    name: UPLOAD_CLAIM_DOCUMENT,
    method: "post",
    file: true,
    url: {
      path: $spark.$urls.$portal.document.claim.upload,
      replace: {
        "<CLAIM_PK>": CLAIM_DETAIL_PK
      }
    },
    input_data_serializer: document_upload,
    dispatch_on_success: [LOAD + CLAIM_DOCUMENT_LIST, LOAD + CLAIM_DETAIL]
  },
  {
    name: UPLOAD_CLAIM_EXTRA_DOCUMENT,
    method: "post",
    file: true,
    url: {
      path: $spark.$urls.$portal.document.claim.extra,
      replace: {
        "<CLAIM_PK>": CLAIM_DETAIL_PK
      }
    },
    input_data_serializer: document_upload,
    dispatch_on_success: [LOAD + CLAIM_DETAIL]
  }
];

const _store = $spark.$inject(dynamic, {
  state,
  actions,
  mutations,
  getters
});
export default _store;

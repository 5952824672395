<template>
  <!-- begin:: Footer -->
  <div class="spark-portal-footer d-flex flex-lg-column" id="kt_footer">
    <div>
      <!--v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >-->
      <component
        :is="template_top"
        class="spark-footer-top pt-4 px-4 pb-2"
        v-html="$store.getters[CLIENT_FOOTER_TOP]"
      ></component>
      <component
        :is="template_bottom"
        class="spark-footer-bottom pb-4 px-4 pt-2"
        v-html="$store.getters[CLIENT_FOOTER_BOTTOM]"
      ></component>
    </div>
  </div>
  <!-- end:: Footer -->
</template>

<script>
import {
  CLIENT_FOOTER_TOP,
  CLIENT_FOOTER_BOTTOM,
  CLIENT_REMOTE_STYLES,
  SPARK_TEMPLATE_FOOTER_TOP,
  SPARK_TEMPLATE_FOOTER_BOTTOM
} from "@/core/services/vars.service";

export default {
  name: "KTFooter",
  data() {
    return {
      scroll: 0,
      template_top: null,
      template_bottom: null
    };
  },
  created() {
    this.CLIENT_FOOTER_TOP = CLIENT_FOOTER_TOP;
    this.CLIENT_FOOTER_BOTTOM = CLIENT_FOOTER_BOTTOM;
  },
  beforeMount() {
    this.template_top = this.$store.getters[CLIENT_REMOTE_STYLES](SPARK_TEMPLATE_FOOTER_TOP);
    this.template_bottom = this.$store.getters[CLIENT_REMOTE_STYLES](SPARK_TEMPLATE_FOOTER_BOTTOM);
  }
};
</script>

<style>
.spark-portal-footer {
  width: 100%;
  margin-top: 2em;
  /*height: var(--portal-footer-height);*/
}
</style>

<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import {
  CURRENT_USER,
  ACCOUNT_DETAIL,
  CLAIM_LIST,
  CLAIM_DETAIL,
  CLAIM_DOCUMENT_LIST,
  SET_CLAIM_DETAIL_PK,
  PETS_CLAIM_LIST_PAGE,
  PETS_CLAIM_MANAGE_PAGE,
  PETS_CLAIM_CREATE_PAGE,
  PETS_CLAIM_UPDATE_PAGE,
  SET_NAV_BLOCKED,
  SET_NAV_BLOCKED_LOGOUT_DATA,
  PAGE_NAME_PORTAL_DOCUMENT_CLAIM_UPLOAD
} from "@/core/services/vars.service";

export default {
  name: "Layout-Portal-Claim",
  path: "claim",
  data() {
    return {
      subscription: null,
      create_confirm_data: {
        title: "Warning",
        sub_title: "New Claim In Progress!",
        message:
          "You have started to make a new claim.<br/>Are you sure you wish to exit?"
      },
      update_confirm_data: {
        title: "Warning",
        sub_title: "Claim Update In Progress!",
        message:
          "You have started to update a claim.<br/>Are you sure you wish to exit?"
      }
    };
  },
  methods: {
    loadData() { 
      if (this.$route.name === this.$store.getters[PETS_CLAIM_LIST_PAGE]) {
        this.$spark.$api(CLAIM_LIST).catch(() => {});
      } else if (this.$route.name === this.$store.getters[PETS_CLAIM_UPDATE_PAGE]) {
        this.$spark.$api(CLAIM_DETAIL).catch(() => {});
        this.$spark.$dispatch(SET_NAV_BLOCKED, {
          status: true,
          fn: this.$spark._navBlockHandler
        });
        this.$spark.$dispatch(
          SET_NAV_BLOCKED_LOGOUT_DATA,
          this.update_confirm_data
        );
      } else if (this.$route.name === this.$store.getters[PETS_CLAIM_CREATE_PAGE]) {
        if (this.$store.getters[CURRENT_USER].$customer) {
          this.$spark.$api(ACCOUNT_DETAIL).catch(() => {});
        }
        if (this.$store.getters[CURRENT_USER].$vet) {
          this.$spark.$api(ACCOUNT_DETAIL).catch(() => {});
        }
        this.$spark.$dispatch(SET_NAV_BLOCKED, {
          status: true,
          fn: this.$spark._navBlockHandler
        });
        this.$spark.$dispatch(
          SET_NAV_BLOCKED_LOGOUT_DATA,
          this.create_confirm_data
        );
      } else if (this.$route.name === this.$store.getters[PETS_CLAIM_MANAGE_PAGE] ||
        this.$route.name === PAGE_NAME_PORTAL_DOCUMENT_CLAIM_UPLOAD) {
        this.$spark.$api(CLAIM_DETAIL).catch(() => {});
        this.$spark.$api(CLAIM_DOCUMENT_LIST).catch(() => {});
      }
    }
  },
  watch: {
    $route: function() {
      this.loadData();
    }
  },
  mounted() {
    this.$spark.$loading(CLAIM_DETAIL, true);
    this.$nextTick(() => {
      let claim_pk = this.$spark.$storage.get(SET_CLAIM_DETAIL_PK);
      if (claim_pk) {
        this.$spark.$dispatch(SET_CLAIM_DETAIL_PK, claim_pk);
      }
      setTimeout(() => {
        this.$spark.$loading(CLAIM_DETAIL, false).then(() => {
          this.loadData();
        }); 
      },300);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (from.name === this.$store.getters[PETS_CLAIM_CREATE_PAGE]) {
      this.$spark._showNavBlockedConfirm(
        this.create_confirm_data,
        { fn: next },
        { fn: next, args: [false] }
      );
    } else if (from.name === this.$store.getters[PETS_CLAIM_UPDATE_PAGE]) {
      this.$spark._showNavBlockedConfirm(
        this.update_confirm_data,
        { fn: next },
        { fn: next, args: [false] }
      );
    } else {
      next();
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (from.name === this.$store.getters[PETS_CLAIM_CREATE_PAGE]) {
      this.$spark._showNavBlockedConfirm(
        this.create_confirm_data,
        { fn: next },
        { fn: next, args: [false] }
      );
    } else if (from.name === this.$store.getters[PETS_CLAIM_UPDATE_PAGE]) {
      this.$spark._showNavBlockedConfirm(
        this.update_confirm_data,
        { fn: next },
        { fn: next, args: [false] }
      );
    } else {
      next();
    }
  }
};
</script>

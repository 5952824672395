<template>
  <!-- begin:: Header -->
  <component :is="template">
    <div
      id="kt_header"
      ref="kt_header"
      class="spark-portal-header"
      :class="headerClasses"
    >
      <div class="row">
        <div class="col-3">
          <img
            class="spark-portal-header-logo"
            :src="'/media/logos/clients/' + $spark.$config.resources.logo_url"
            :style="$spark.$config.styles.logo"
          />
        </div>
        <div class="col-6 text-left">
          <p class="h2 spark-portal-header-client-name">
            {{ $spark.$config.main_brand }} Customer Portal
          </p>
        </div>
        <div
          class="col-3 text-right spark-portal-header-contact-details"
          v-html="$store.getters[CLIENT_CONTACT_DETAILS]"
        ></div>
      </div>
    </div>
  </component>
  <!-- end:: Header -->
</template>

<script>
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
//import SparkStyle from "@/view/spark_layout/portal/wireframe/Style";
//import ClientConfig from "@/core/config/deployment";
import {
  CLIENT_CONTACT_DETAILS,
  CLIENT_REMOTE_STYLES,
  SPARK_TEMPLATE_HEADER,
  CURRENT_USER
} from "@/core/services/vars.service";

export default {
  name: "KTHeader",
  components: {
    //SparkStyle
  },
  data() {
    return {
      template: null
    };
  },
  created() {
    this.CLIENT_CONTACT_DETAILS = CLIENT_CONTACT_DETAILS;
    this.CURRENT_USER = CURRENT_USER;
  },
  mounted() {
    this.$parent.$parent.$forceUpdate();
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");

    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_menu_wrapper"]
    );
    const headerRef = this.$refs["kt_header"];

    headerRef.querySelectorAll("a[class='menu-link']").forEach(item => {
      item.addEventListener("click", () => {
        KTLayoutHeaderMenu.getOffcanvas().hide();
      });
    });
  },
  beforeMount() {
    this.template = this.$store.getters[CLIENT_REMOTE_STYLES](SPARK_TEMPLATE_HEADER);
  },
  computed: {

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.$store.getters["layoutConfig"]("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.$store.getters["getClasses"]("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.$store.getters["getClasses"]("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    }
  }
};
</script>

<style>
.spark-portal-header-logo {
  max-height: 200px;
}
.spark-portal-header-client-name {
  margin-top: 30px;
}
.spark-portal-header-contact-details {
  padding-right: 40px;
}
@media (max-width: 991.98px) {
  .spark-portal-header {
    display: none;
  }
}
</style>

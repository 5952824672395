<template>
  <div class="mb-10">
    <b-card
      class="mb-3 rounded-card spark-variant-primary-bg-light spark-variant-primary-border spark-variant-text-dark spark-block-content pb-15"
      header-class="card-header rounded-card-header fw-400 py-4 px-4 green-bottom-border spark-variant-primary-bg"
    >
      <template #header>
        <b-row>
          <b-col class="col-8">
            <div class="spark-block-title">
              <i
                class="fas fa-at icon-extra-large spark-variant-secondary-text mr-2"
              ></i>
              {{ $options.short_name }}
            </div>
          </b-col>
        </b-row>
      </template>

      <div class="mb-10">
        <b-row class="mt-4 mb-4">
          <b-col class="col-6 text-right pt-4">
            <span class="font-weight-bold"
              >Please select the type of query</span
            >
          </b-col>
          <b-col class="col-5 input_icon_fixed_width">
            <select class="select-css" v-model="selected_type">
              <option value="null">-- Please Select --</option>
              <option
                v-for="(choice, index) in type_choices"
                v-bind:value="choice[0]"
                :key="'type_' + index"
              >
                {{ choice[1] }}
              </option>
            </select>
            <i class="fas fa-paw"></i>
          </b-col>
          <b-col class="col-1 mt-2">
            <i
              v-if="selected_type === 'null'"
              class="fas fa-exclamation-circle field_error_icon"
            ></i>
          </b-col>
        </b-row>
        <b-row class="justify-content-right">
          <b-col
            v-if="selected_type === 'null'"
            cols="11"
            class="vue_field_error text-right"
          >
            <ul>
              <li>You must select the type of query</li>
            </ul>
          </b-col>
          <b-col cols="1"></b-col>
        </b-row>
      </div>

      <div class="mb-10">
        <b-row class="mt-4 mb-4">
          <b-col class="col-6 text-right pt-4">
            <span class="font-weight-bold">Please enter your first name</span>
          </b-col>
          <b-col class="col-5 input_icon_fixed_width">
            <input type="text" v-model="first_name" class="pl-15" />
            <i class="fas fa-paw"></i>
          </b-col>
          <b-col class="col-1 mt-2">
            <i
              v-if="first_name.length < 3"
              class="fas fa-exclamation-circle field_error_icon"
            ></i>
          </b-col>
        </b-row>
        <b-row class="justify-content-right">
          <b-col
            v-if="first_name.length < 3"
            cols="11"
            class="vue_field_error text-right"
          >
            <ul>
              <li>
                Please enter your first name<br />Must be at least 3 characters
                long
              </li>
            </ul>
          </b-col>
          <b-col cols="1"></b-col>
        </b-row>
      </div>

      <div class="mb-10">
        <b-row class="mt-4 mb-4">
          <b-col class="col-6 text-right pt-4">
            <span class="font-weight-bold">Please enter your last name</span>
          </b-col>
          <b-col class="col-5 input_icon_fixed_width">
            <input type="text" v-model="last_name" class="pl-15" />
            <i class="fas fa-paw"></i>
          </b-col>
          <b-col class="col-1 mt-2">
            <i
              v-if="last_name.length < 3"
              class="fas fa-exclamation-circle field_error_icon"
            ></i>
          </b-col>
        </b-row>
        <b-row class="justify-content-right">
          <b-col
            v-if="last_name.length < 3"
            cols="11"
            class="vue_field_error text-right"
          >
            <ul>
              <li>
                Please enter your last name<br />Must be at least 3 characters
                long
              </li>
            </ul>
          </b-col>
          <b-col cols="1"></b-col>
        </b-row>
      </div>

      <div class="mb-10">
        <b-row class="mt-4 mb-4">
          <b-col class="col-6 text-right pt-4">
            <span class="font-weight-bold"
              >Please enter your email address</span
            >
          </b-col>
          <b-col class="col-5 input_icon_fixed_width">
            <input type="text" v-model="email" class="pl-15" />
            <i class="fas fa-paw"></i>
          </b-col>
          <b-col class="col-1 mt-2">
            <i
              v-if="!emailIsValid"
              class="fas fa-exclamation-circle field_error_icon"
            ></i>
          </b-col>
        </b-row>
        <b-row class="justify-content-right">
          <b-col
            v-if="!emailIsValid"
            cols="11"
            class="vue_field_error text-right"
          >
            <ul>
              <li>Please enter a valid email address<br />name@example.com</li>
            </ul>
          </b-col>
          <b-col cols="1"></b-col>
        </b-row>
      </div>

      <div class="mb-10">
        <b-row class="mt-4 mb-4">
          <b-col class="col-6 text-right pt-4">
            <span class="font-weight-bold"
              >Please enter your telephone number</span
            >
          </b-col>
          <b-col class="col-5 input_icon_fixed_width">
            <input type="text" v-model="telephone" class="pl-15" />
            <i class="fas fa-paw"></i>
          </b-col>
          <b-col class="col-1 mt-2">
            <i
              v-if="!telephoneIsValid"
              class="fas fa-exclamation-circle field_error_icon"
            ></i>
          </b-col>
        </b-row>
        <b-row class="justify-content-right">
          <b-col
            v-if="!telephoneIsValid"
            cols="11"
            class="vue_field_error text-right"
          >
            <ul>
              <li>Please enter a valid mobile number<br />077912345678</li>
            </ul>
          </b-col>
          <b-col cols="1"></b-col>
        </b-row>
      </div>

      <div class="mb-10">
        <b-row class="mt-4 mb-4">
          <b-col cols="1"></b-col>
          <b-col cols="10" class="input_icon_fixed_width">
            <textarea v-model="message"></textarea>
            <i class="fas fa-paw"></i>
          </b-col>
          <b-col cols="1" class="mt-2">
            <i
              v-if="message.length < 20"
              class="fas fa-exclamation-circle field_error_icon"
            ></i>
          </b-col>
        </b-row>
        <b-row class="justify-content-right" v-if="message.length < 20">
          <b-col cols="1"></b-col>
          <b-col cols="10" class="vue_field_error text-right">
            <ul>
              <li>Your message must be at least 20 characters long</li>
            </ul>
          </b-col>
          <b-col cols="1"></b-col>
        </b-row>
      </div>

      <b-row class="justify-content-right" v-if="canSendMessage">
        <b-col cols="11" class="text-right">
          <b-button
            size="md"
            class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
            @click="sendContactFormMessage()"
            >Send Message</b-button
          >
        </b-col>
        <b-col cols="1"></b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import validator from "validator";

import {
  SUBMIT_CORRESPONDENCE_PUBLIC_CONTACT_FORM
} from "@/core/services/vars.service";

export default {
  name: "Portal-Public-Contact-Us-Component",
  short_name: "Contact Us",
  data() {
    return {
      first_name: "",
      last_name: "",
      email: "",
      telephone: "",
      message: "",
      selected_type: "null",
      type_choices: [
        ["general", "General Query"],
        ["claim", "Claim Query"],
        ["complaint", "Complaint Query"],
        ["policy", "Policy Query"]
      ]
    };
  },
  methods: {
    sendContactFormMessage() {
      let payload = {
        ref_type: this.selected_type,
        message_body: this.message,
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        telephone: this.telephone
      };
      this.$spark.$api(SUBMIT_CORRESPONDENCE_PUBLIC_CONTACT_FORM, payload)
        .then(() => {
          this.$spark.$success("Message sent successfully.<br/>The relevant team will be in contact soon.");
          this.$spark.$root();
        }).catch(() => {});
    }
  },
  computed: {
    canSendMessage() {
      return (
        this.selected_type !== "null" &&
        this.first_name.length >= 3 &&
        this.last_name.length >= 3 &&
        this.emailIsValid &&
        this.telephoneIsValid &&
        this.message.length >= 20
      );
    },
    emailIsValid() {
      return validator.isEmail(this.email);
    },
    telephoneIsValid() {
      return validator.isMobilePhone(this.telephone, "en-GB");
    }
  }
};
</script>

<template>
  <div class="spark-page">
    <div class="spark-page-title">{{ $options.short_name }}</div>
    <div class="spark-page-content column-fluid row justify-content-center">
      <div class="col col-sm-12 col-md-12 col-lg-10">
        <spark-nothing-to-see-here></spark-nothing-to-see-here>
      </div>
    </div>
  </div>
</template>

<script>

import SparkNothingToSeeHere from "@/view/spark_components/NothingToSeeHere";

export default {
  name: "Portal-Document-Policy-List",
  short_name: "Policy Documents",
  path: "list",
  menu: true,
  components: {
    "spark-nothing-to-see-here": SparkNothingToSeeHere
  },
  mounted() {
   this.$spark.$breadcrumbs(this.$options);
  }
};
</script>

<template>
  <div class="mb-10">
    <b-card
      class="mb-3 rounded-card spark-variant-primary-bg-light spark-variant-primary-border spark-variant-text-dark spark-block-content"
      header-class="card-header rounded-card-header fw-400 py-4 px-4 green-bottom-border spark-variant-primary-bg"
    >
      <template #header>
        <b-row>
          <b-col>
            <div class="spark-block-title">
              <i
                class="far fa-file-alt icon-extra-large spark-variant-secondary-text mr-4"
              ></i>
              {{ $options.short_name }}
            </div>
          </b-col>
        </b-row>
      </template>
      <b-row>
        <b-col class="col-6 font-weight-bold text-right"
          >Policy holder claim information provided</b-col
        >
        <b-col class="col-6">
          <i class="fas fa-paw"></i>
          <i
            v-if="Object.keys(item.policy_holder).length > 0"
            class="fas fa-check-circle green icon-large"
          ></i>
          <i v-else class="fas fa-times-circle red icon-large"></i>
          {{ Object.keys(item.policy_holder).length > 0 ? "Yes" : "No" }}
        </b-col>
      </b-row>
      <div
        v-if="Object.keys(item.policy_holder).length > 0"
        class="text-right mb-10"
      >
        <b-button
          size="md"
          class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
          @click="showClaimData('policy_holder')"
        >
          <span v-if="$store.getters[CURRENT_USER].$customer">View Your Claim Information</span>
          <span v-if="$store.getters[CURRENT_USER].$vet">View Customer Claim Information</span>
        </b-button>
      </div>
      <div v-else class="text-right mb-10">
        <b-button
          v-if="linkParamsClaimAddInformation && this.$store.getters[CURRENT_USER].$customer"
          size="md"
          class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
          @click="
            $spark.$nav(
              linkParamsClaimAddInformation.manage_name,
              linkParamsClaimAddInformation.manage_params
            )
          "
          >Add Claim Information</b-button
        >
      </div>
      <b-row>
        <b-col class="col-6 font-weight-bold text-right"
          >Vet claim information provided</b-col
        >
        <b-col class="col-6">
          <i class="fas fa-paw"></i>
          <i
            v-if="Object.keys(item.vet).length > 0"
            class="fas fa-check-circle green icon-large"
          ></i>
          <i v-else class="fas fa-times-circle red icon-large"></i>
          {{ Object.keys(item.vet).length > 0 ? "Yes" : "No" }}
        </b-col>
      </b-row>
      <div v-if="Object.keys(item.vet).length > 0" class="text-right mb-10">
        <b-button
          size="md"
          class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
          @click="showClaimData('vet')"
        >
          <span v-if="$store.getters[CURRENT_USER].$vet">View Your Claim Information</span>
          <span v-if="$store.getters[CURRENT_USER].$customer">View Vet Claim Information</span>
        </b-button>
      </div>
      <div v-else class="text-right mb-10">
        <b-button
          v-if="linkParamsClaimAddInformation && this.$store.getters[CURRENT_USER].$vet"
          size="md"
          class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
          @click="
            $spark.$nav(
              linkParamsClaimAddInformation.manage_name,
              linkParamsClaimAddInformation.manage_params
            )
          "
          >Add Claim Information</b-button
        >
      </div>
    </b-card>
    <b-modal
      ref="spark-claim-data-modal"
      centered
      hide-footer
      size="lg"
      title="View Claim Information"
    >
      <div class="d-block text-left" v-if="modalData">
        <h4 v-html="modal_title" class="mb-10"></h4>
        <div v-for="(obj, index) in modalData" :key="index">
          <spark-claim-data
            :type="modal_type"
            :item="obj"
            :root="true"
          ></spark-claim-data>
        </div>
      </div>
      <b-row>
        <b-col>
          <b-button
            size="md"
            v-html="'Close'"
            class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
            @click="closeModal"
          ></b-button>
        </b-col>
        <b-col>
          <b-button
            v-if="modal_approve"
            size="md"
            v-html="'OK, Approve'"
            class="mt-5 rounded green-bg green-border spark-variant-text-light font-weight-bold float-right"
            @click="approveClaim"
          ></b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  PAGE_NAME_PORTAL_CLAIM_CUSTOMER_UPDATE,
  PAGE_NAME_PORTAL_CLAIM_VET_UPDATE,
  SET_CLAIM_DETAIL_PK,
  SET_CLAIM_INFORMATION_COMPONENT,
  SUBMIT_CLAIM_APPROVE,
  CLAIM_DETAIL,
  CURRENT_USER
} from "@/core/services/vars.service";

export default {
  name: "Portal-Claim-Information",
  short_name: "Claim Information",
  props: {
    id: {
      type: Number,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  created() {
    this.CURRENT_USER = CURRENT_USER;
  },
  data() {
    return {
      modal_title: null,
      modal_data: null,
      modal_type: null,
      modal_approve: false
    };
  },
  computed: {
    modalData() {
      if (!this.modal_data) return null;
      return Object.values(this.modal_data).sort((a, b) => a.order - b.order);
    },
    updateName() {
      if (this.$store.getters[CURRENT_USER].$customer) {
        return PAGE_NAME_PORTAL_CLAIM_CUSTOMER_UPDATE;
      }
      if (this.$store.getters[CURRENT_USER].$vet) {
        return PAGE_NAME_PORTAL_CLAIM_VET_UPDATE;
      }
      return null;
    },
    linkParamsClaimAddInformation() {
      return {
        title: "Add Claim Information",
        manage_name: this.updateName,
        manage_params: {
          pk_name: SET_CLAIM_DETAIL_PK,
          pk: this.id
        }
      };
    }
  },
  methods: {
    showClaimData(type, approve = false) {
      if (type === "vet") {
        this.modal_title = "Vet Claim Information";
        this.modal_data = this.item.vet;
      }
      if (type === "policy_holder") {
        this.modal_title = "Policy Holder Claim Information";
        this.modal_data = this.item.policy_holder;
      }
      this.modal_type = type;
      this.modal_approve = approve;
      this.openModal();
    },
    openModal() {
      this.$refs["spark-claim-data-modal"].show();
    },
    closeModal() {
      this.$refs["spark-claim-data-modal"].hide();
    },
    approveClaim() {
      this.closeModal();
      this.$spark.$api(SUBMIT_CLAIM_APPROVE, {
        approve: true
      }).then(() => {
        this.$spark.$api(CLAIM_DETAIL);
      }).catch(() => {});
    }
  },
  mounted() {
    this.$spark.$dispatch(SET_CLAIM_INFORMATION_COMPONENT, this);
  }
};
</script>

<template>
  <div>{{ $options.name }}</div>
</template>

<script>

export default {
  name: "Portal-Document-Policy-Manage",
  short_name: "Manage Policy Document",
  path: "manage/:id?",
  mounted() {
   this.$spark.$breadcrumbs(this.$options);
  }
};
</script>

<template>
  <div>
    <div>{{ $options.name }}</div>
    <router-view />
  </div>
</template>

<script>
import {
  //SET_COMPLAINT_DOCUMENT_DETAIL_PK,
  COMPLAINT_DOCUMENT_LIST,
  COMPLAINT_DOCUMENT_DETAIL
} from "@/core/services/vars.service";

export default {
  name: "Layout-Portal-ComplaintDocument",
  short_name: "Complaint Documents",
  path: "document",
  methods: {
    loadData() {
      let url_parts = this.$route.path.split("/");
      let action = url_parts[url_parts.length - 1];
      if (action === "list") {
        this.$spark.$api(COMPLAINT_DOCUMENT_LIST).catch(() => {});
      } else if (action === "manage") {
        this.$spark.$api(COMPLAINT_DOCUMENT_DETAIL).catch(() => {});
      }
    }
  },
  watch: {
    $route: function() {
      this.loadData();
    }
  },
  mounted() {
    /*let complaint_document_pk = this.$spark.$storage.get(SET_COMPLAINT_DOCUMENT_DETAIL_PK);
    if (complaint_document_pk) {
      this.$spark.$dispatch(SET_COMPLAINT_DOCUMENT_DETAIL_PK, complaint_document_pk);
    }
    this.loadData();*/
  }
};
</script>

<template>
  <component :is="template" class="spark-portal-menu">
    <div class="row justify-content-center spark-portal-menu py-2">
      <!--:DYNAMIC MENU ITEMS:-->
      <template v-for="(menu, index) in $store.getters[MENU_ITEMS]">
        <!--:MENU ITEM - HAS CHILDREN:-->
        <template v-if="'children' in menu">
          <div class="btn-group m-2" v-bind:key="'menu_parent_link_' + index">
            <router-link
              v-bind:key="'menu_link_' + index"
              :to="menu.path || '#empty'"
              v-slot="{ navigate }"
              custom
            >
              <b-dropdown
                v-bind:class="{
                  'menu-item-open': hasActiveChildren(menu.path)
                }"
                split-class="rounded-left font-weight-bold spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light"
                toggle-class="rounded-right font-weight-bold spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light"
                right
                split
                size="lg"
                :text="menu.label"
                @click="navigate"
              >
                <template v-for="(sub, index1) in menu.children">
                  <router-link
                    v-bind:key="'menu_sub_' + index1"
                    :to="sub.path"
                    v-if="!sub.seperator"
                    v-slot="{ navigate, isActive, isExactActive }"
                    custom
                  >
                    <b-dropdown-item
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active'
                      ]"
                      class="bg-light"
                      @click="navigate"
                    >
                      <span v-html="sub.label"></span>
                    </b-dropdown-item>
                  </router-link>
                  <b-dropdown-divider
                    v-else
                    v-bind:key="'menu_sub_' + index1"
                  ></b-dropdown-divider>
                </template>
              </b-dropdown>
            </router-link>
          </div>
        </template>
        <!--:MENU ITEM - HAS CHILDREN:-->

        <!--:MENU ITEM:-->
        <template v-else>
          <div class="btn-group m-2" v-bind:key="'menu_item_' + index">
            <router-link
              :to="menu.path"
              v-slot="{ navigate, isActive, isExactActive }"
              custom
            >
              <button
                class="menu-item rounded px-4 font-weight-bold spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
                type="button"
                size="lg"
                aria-haspopup="true"
                aria-expanded="false"
                @click="navigate"
              >
                <span v-html="menu.label"></span>
              </button>
            </router-link>
          </div>
          <!--<router-link
            v-bind:key="'menu_link_' + index"
            :to="menu.path"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <span class="menu-text">{{ menu.label }}</span>
              </a>
            </li>
          </router-link>-->
        </template>
        <!--:MENU ITEM:-->
      </template>
      <!--:DYNAMIC MENU ITEMS:-->
      <template>
        <!--<li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
          >
            <a class="menu-link" @click="logout()">
              <span class="menu-text">Logout</span>
            </a>
          </li>-->
        <div class="btn-group m-2" v-if="$store.getters[IS_AUTHENTICATED]">
          <button
            class="menu-item rounded px-4 font-weight-bold spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light"
            type="button"
            size="lg"
            aria-haspopup="true"
            aria-expanded="false"
            @click="logout()"
          >
            Logout
          </button>
        </div>
        <div class="btn-group m-2" v-else-if="$route.name !== $spark.$config.views.portal.login.name">
          <button
            class="menu-item rounded px-4 font-weight-bold spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light"
            type="button"
            size="lg"
            aria-haspopup="true"
            aria-expanded="false"
            @click="$spark.$portalLogin()"
          >
            Login
          </button>
        </div>
      </template>
    </div>
  </component>
</template>

<script>
import {
  LOGOUT,
  IS_AUTHENTICATED,
  MENU_ITEMS,
  CLIENT_REMOTE_STYLES,
  SPARK_TEMPLATE_MENU,
  NAV_BLOCKED_LOGOUT_DATA
} from "@/core/services/vars.service";

export default {
  name: "KTMenu",
  data() {
    return {
      template: null
    };
  },
  created() {
    this.MENU_ITEMS = MENU_ITEMS;
    this.IS_AUTHENTICATED = IS_AUTHENTICATED;
  },
  beforeMount() {
    this.template = this.$store.getters[CLIENT_REMOTE_STYLES](SPARK_TEMPLATE_MENU);
  },
  methods: {
    logout() {
      this.$spark._showNavBlockedConfirm(this.$store.getters[NAV_BLOCKED_LOGOUT_DATA], {
        fn: this.$spark.$dispatch,
        args: [LOGOUT]
      });
    },
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>

<style>
@media (max-width: 991.98px) {
  .spark-portal-menu {
    display: none;
  }
}
</style>

<template>
  <component :is="template" class="d-flex flex-column flex-root">
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page spark-page-body">
      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid">
        <!-- begin:: Header -->
        <KTHeader></KTHeader>
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <div id="kt_content" class="d-flex flex-column flex-column-fluid">
          <!-- begin:: Content Head -->

          <!-- begin:: Content Head -->
          <KTSubheader
            v-if="subheaderDisplay"
            v-bind:breadcrumbs="$store.getters['breadcrumbs']"
            v-bind:title="$store.getters['pageTitle']"
          />
          <KTMenu></KTMenu>
          <!-- end:: Content Head -->

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid">
            <div
              :class="{
                'container-fluid': contentFluid,
                container: !contentFluid
              }"
            >
              <transition name="fade-in-up">
                <div class="spark-portal-content">
                  <component :is="template_content">
                    <spark-alert ref="spark-alert"></spark-alert>
                    <spark-confirm ref="spark-confirm"></spark-confirm>
                    <router-view />
                  </component>
                </div>
              </transition>
            </div>
          </div>
        </div>
        <KTFooter></KTFooter>
      </div>
    </div>
    <KTScrollTop></KTScrollTop>
  </component>
</template>

<script>
import KTMenu from "@/view/spark_layout/portal/wireframe/Menu";
import KTHeader from "./Header";
import KTHeaderMobile from "./HeaderMobile";
import KTSubheader from "./Subheader";
import KTFooter from "./Footer";
import SparkAlert from "@/view/spark_components/Alert";
import SparkConfirm from "@/view/spark_components/Confirm";


import HtmlClass from "@/core/services/htmlclass.service";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import Loader from "@/view/spark_components/Loader";
/*import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";*/

import { injectGlobal } from "vue-styled-components";

import {
  CLIENT_REMOTE_STYLES,
  SPARK_TEMPLATE,
  SPARK_TEMPLATE_CONTENT,
  SPARK_TEMPLATE_SECONDARY,
  SPARK_TEMPLATE_PRIMARY,
  SPARK_TEMPLATE_SECONDARY_LIGHT,
  SPARK_TEMPLATE_PRIMARY_LIGHT,
  SPARK_TEMPLATE_TEXT_LIGHT,
  SPARK_TEMPLATE_TEXT_MID,
  SPARK_TEMPLATE_TEXT_DARK,
  SET_SPARK_LOADING
} from "@/core/services/vars.service";

export default {
  name: "Layout-Template",
  components: {
    KTMenu,
    KTHeader,
    KTHeaderMobile,
    KTFooter,
    KTSubheader,
    KTScrollTop,
    Loader,
    "spark-alert": SparkAlert,
    "spark-confirm": SparkConfirm
  },
  data() {
    return {
      template: null,
      template_content: null,
      spark_primary: null,
      spark_secondary: null,
      spark_primary_light: null,
      spark_secondary_light: null,
      spark_text_light: null,
      spark_text_mid: null,
      spark_text_dark: null
    };
  },
  beforeMount() {
    // show page loading
    //this.$spark.$dispatch(ADD_BODY_CLASSNAME, "page-loading");
    this.$spark.$dispatch(SET_SPARK_LOADING, { fn: "page", status: true });

    // initialize html element classes
    //let config = this.$store.getters["layoutConfig"]();
    //config.config.colors.theme.

    HtmlClass.init(this.$store.getters["layoutConfig"]());

    this.spark_primary = this.getClientRemoteStyle(SPARK_TEMPLATE_PRIMARY);
    this.spark_secondary = this.getClientRemoteStyle(SPARK_TEMPLATE_SECONDARY);
    this.spark_primary_light = this.getClientRemoteStyle(
      SPARK_TEMPLATE_PRIMARY_LIGHT
    );
    this.spark_secondary_light = this.getClientRemoteStyle(
      SPARK_TEMPLATE_SECONDARY_LIGHT
    );
    this.spark_text_light = this.getClientRemoteStyle(
      SPARK_TEMPLATE_TEXT_LIGHT
    );
    this.spark_text_mid = this.getClientRemoteStyle(SPARK_TEMPLATE_TEXT_MID);
    this.spark_text_dark = this.getClientRemoteStyle(SPARK_TEMPLATE_TEXT_DARK);

    injectGlobal`
      :root {
        --spark-variant-primary: ${this.spark_primary} !important;
        --spark-variant-secondary: ${this.spark_secondary} !important;
        --spark-variant-primary-light: ${this.spark_primary_light} !important;
        --spark-variant-secondary-light: ${this.spark_secondary_light} !important;
        --spark-variant-text-light: ${this.spark_text_light} !important;
        --spark-variant-text-mid: ${this.spark_text_mid} !important;
        --spark-variant-text-dark: ${this.spark_text_dark} !important;
      }
      .spark-variant-primary-bg {
        background-color: ${this.spark_primary} !important;
      }
      .spark-variant-primary-border {
        border: 1px solid ${this.spark_primary} !important;
      }
      .spark-variant-secondary-bg {
        background-color: ${this.spark_secondary} !important;
      }
      .spark-variant-secondary-border {
        border: 1px solid ${this.spark_secondary} !important;
      }
      .spark-variant-primary-bg-light {
        background-color: ${this.spark_primary_light} !important;
      }
      .spark-variant-primary-border-light {
        border: 1px solid ${this.spark_primary_light} !important;
      }
      .spark-variant-secondary-bg-light {
        background-color: ${this.spark_secondary_light} !important;
      }
      .spark-variant-secondary-border-light {
        border: 1px solid ${this.spark_secondary_light} !important;
      }
      .spark-variant-primary-text {
        color: ${this.spark_primary} !important;
      }
      .spark-variant-secondary-text {
        color: ${this.spark_secondary} !important;
      }
      .spark-variant-text-light {
        color: ${this.spark_text_light} !important;
      }
      .spark-variant-text-mid {
        color: ${this.spark_text_mid} !important;
      }
      .spark-variant-text-dark {
        color: ${this.spark_text_dark} !important;
      }
    `;

    this.template = this.getClientRemoteStyle(SPARK_TEMPLATE);
    this.template_content = this.getClientRemoteStyle(SPARK_TEMPLATE_CONTENT);
  },
  mounted() {
    setTimeout(() => {
      this.$spark.$dispatch(SET_SPARK_LOADING, { fn: "page", status: false });
    }, 300);
  },
  methods: {
    getClientRemoteStyle(class_name) {
      return this.$store.getters[CLIENT_REMOTE_STYLES](class_name);
    }
  },
  computed: {

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.$store.getters["layoutConfig"]("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.$store.getters["layoutConfig"]("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.$store.getters["layoutConfig"]("loader.logo");
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.$store.getters["layoutConfig"]("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.$store.getters["layoutConfig"]("toolbar.display");
      return true;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.$store.getters["layoutConfig"]("subheader.display");
    }
  }
};
</script>

<style>
html {
  position: relative;
  min-height: 100%;
}
button {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
button:hover {
  background-color: var(--spark-variant-secondary-light) !important;
  border: 1px solid var(--spark-variant-secondary-light) !important;
  color: var(--spark-variant-text-mid) !important;
}
ul {
  list-style-type: none;
}
.rounded {
  border-radius: 25px !important;
  font-size: 16px !important;
  padding: 0.7em 1em;
}
.rounded-left {
  border-top-left-radius: 25px !important;
  border-bottom-left-radius: 25px !important;
  font-size: 16px !important;
  padding: 0.7em 1em;
}
.rounded-right {
  border-top-right-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
  font-size: 16px !important;
  padding: 0.7em 1em;
}
.rounded-card {
  border-radius: 28px !important;
}
.rounded-card-header {
  border-top-left-radius: 25px !important;
  border-top-right-radius: 25px !important;
  border: 1px solid var(--spark-variant-primary);
}
.spark-page-title {
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 2em;
  font-weight: 600;
  color: var(--spark-variant-primary);
  text-decoration: underline;
  text-align: center;
}
.spark-block-title {
  padding-top: 0.5em;
  font-size: 1.4em;
  font-weight: bold;
  color: var(--spark-variant-text-light);
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.spark-block-title-small {
  font-size: 1.2em;
  padding-top: 0.8em;
}
.spark-block-content {
  font-size: 1.2rem;
}
.red {
  color: #ff0000 !important;
}
.red-border {
  border: 1px solid #ff0000 !important;
}
.red-bg {
  background-color: #ff0000 !important;
}
.green {
  color: #00b050 !important;
}
.green-border {
  border: 1px solid #00b050 !important;
}
.green-bg {
  background-color: #00b050 !important;
}
.icon-large {
  font-size: 1.4em;
  margin-right: 0.5em;
  margin-left: 0.2em;
}
.icon-extra-large {
  font-size: 1.8em;
  margin-right: 0.5em;
  margin-left: 0.2em;
  margin-top: -0.3em;
}
.fa-stack {
  width: auto;
}
@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: calc(100% - 2em);
  }
}
@media (min-width: 1100px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: calc(100% - 4em);
  }
}
@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: calc(100% - 2em);
  }
}
@media (max-width: 575px) {
  .spark-section-buttons {
    text-align: center;
  }
  .card-body {
    padding: 1em;
  }
  .spark-block-title {
    white-space: normal;
  }
}
</style>

import $spark from "@/core/services/spark.service";
import {
  COMPLAINT_LIST,
  COMPLAINT_DETAIL,
  COMPLAINT_DETAIL_PK,
  SET_COMPLAINT_DETAIL_PK
} from "@/core/services/vars.service";

const state = {
  detail_pk: null
};

const getters = {
  [COMPLAINT_DETAIL_PK]: state => {
    return state.detail_pk;
  }
};

const actions = {
  [SET_COMPLAINT_DETAIL_PK](context, payload) {
    $spark.$storage.set(SET_COMPLAINT_DETAIL_PK, payload);
    context.commit(SET_COMPLAINT_DETAIL_PK, payload);
    //return $spark.$api(COMPLAINT_DETAIL);
  }
};

const mutations = {
  [SET_COMPLAINT_DETAIL_PK](state, data) {
    state.detail_pk = data;
  }
};


const dynamic = [
  {
    name: COMPLAINT_LIST,
    paginated: true,
    url: {
      path: $spark.$urls.$portal.complaint.list
    }
  },
  {
    name: COMPLAINT_DETAIL,
    pk: true,
    url: {
      path: $spark.$urls.$portal.complaint.detail,
      replace: {
        "<COMPLAINT_PK>": COMPLAINT_DETAIL_PK
      }
    }
  }
];

const _store = $spark.$inject(dynamic, {
  state,
  actions,
  mutations,
  getters
});
export default _store;

import {
  ALERT_DATA,
  SET_ALERT,
  RESET_ALERT
} from "@/core/services/vars.service";

const state = {
  message: null,
  variant: null,
  timer: null
};

const getters = {
  [ALERT_DATA]: state => {
    return state;
  }
};

const actions = {
  [SET_ALERT]({ commit }, payload) {
    commit(SET_ALERT, payload);
  },
  [RESET_ALERT]({ commit }) {
    commit(RESET_ALERT);
  }
};

const mutations = {
  [SET_ALERT](state, payload) {
    state.message = payload.message;
    state.variant = payload.variant;
    state.timer = payload.timer;
  },
  [RESET_ALERT](state) {
    state.message = null;
    state.variant = null;
    state.timer = null;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};

<template>
  <b-row class="mt-4 mb-4">
    <b-col
      cols="10"
      sm="11"
      md="11"
      lg="11"
      xl="11"
      v-html="label"
      class="font-weight-bold mb-4 text-center h4"
    >
    </b-col>
    <b-col cols="2" sm="1" md="1" lg="1" xl="1">
      <i
        class="fas fa-exclamation-circle field_error_icon"
        v-if="$parent.error_messages.length > 0"
      ></i>
    </b-col>
    <b-col cols="12" v-html="sub_label" class="mb-4" v-if="sub_label"></b-col>
    <template v-if="$parent.error_messages.length > 0">
      <b-col cols="12" class="vue_field_error text-center">
        <ul>
          <li
            v-for="(message, index) in $parent.error_messages"
            :key="index"
            v-html="message"
          ></li>
        </ul>
      </b-col>
    </template>
    <template v-for="(choice, choice_index) in choices">
      <b-row
        class="col-12 mt-4"
        :key="'question_' + question_name + '+' + choice_index + '_option'"
      >
        <b-col
          cols="12"
          sm="7"
          md="7"
          lg="7"
          xl="7"
          class="text-right pr-7 mb-4"
        >
          <input
            type="checkbox"
            :id="'question_' + question_name + '_option_' + choice_index"
            :value="choice.value"
            v-model="value"
          />
          <label
            :for="'question_' + question_name + '_option_' + choice_index"
            class="multiple_choice_label font-weight-bold"
          >
            {{ choice.label }}
          </label>
        </b-col>
      </b-row>
    </template>
  </b-row>
</template>

<script>
export default {
  name: "spark-multiple-choice",
  props: {
    id: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    sub_label: {
      type: String,
      default: null
    },
    question_name: {
      type: String,
      required: true
    },
    choices: {
      type: Array,
      required: true
    },
    watched_by: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      value: [],
      error_messages: null
    };
  },
  computed: {
    Value() {
      return this.value;
    }
  },
  watch: {
    value: function(new_val) {
      this.$spark.init_section = false;
      this.$parent.hasValidationPassed(new_val);
      this.$parent.value = new_val;
    }
  }
};
</script>

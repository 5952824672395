<template>
  <div class="spark-page">
    <div class="spark-page-title" v-html="title"></div>
    <spark-html-block :title="title" :content="content"></spark-html-block>
  </div>
</template>
<script>

import SparkHTMLBlock from "@/view/spark_components/portal/HTMLBlock";

export default {
  name: "Portal-About-Custom",
  path: "custom",
  props: {
    content: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  components: {
    "spark-html-block": SparkHTMLBlock
  },
  mounted() {
    this.$spark.$breadcrumbs(this.$options);
  }
};
</script>

<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "Layout-Portal-Claim-Vet",
  short_name: "Vet Claim",
  path: "vet"
};
</script>

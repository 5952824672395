<template>
  <!-- eslint-disable max-len -->
  <div class="spark-page" id="vue_claim_form" v-if="policyList">
    <div class="spark-page-title">{{ $options.short_name }}</div>
    <div class="spark-page-content column-fluid row justify-content-center">
      <div class="col col-sm-12 col-md-12 col-lg-10">
        <div class="mb-10">
          <slide-up-down :active="disclaimerShow">
            <b-card
              class="mb-3 rounded-card spark-variant-primary-bg-light spark-variant-primary-border spark-variant-text-dark spark-block-content"
              header-class="card-header rounded-card-header fw-400 py-4 px-4 green-bottom-border spark-variant-primary-bg"
            >
              <template #header>
                <b-row>
                  <b-col>
                    <div class="spark-block-title">
                      <i
                        class="fas fa-exclamation-triangle icon-extra-large spark-variant-secondary-text mr-4"
                      ></i>
                      Disclaimer
                    </div>
                  </b-col>
                </b-row>
              </template>

              <b-row
                class="mb-5 justify-content-center row spark-html-block-content"
              >
                <div class="col-12 p-8" v-html="pageContent"></div>
              </b-row>
              <b-row class="mb-5 justify-content-center row">
                <b-button
                  size="md"
                  v-html="'Agree &amp; Proceed'"
                  class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
                  @click="
                    disclaimerShow = false;
                    startClaim = true;
                  "
                ></b-button>
              </b-row>
            </b-card>
          </slide-up-down>
          <slide-up-down :active="startClaim">
            <b-card
              class="mb-3 rounded-card spark-variant-primary-bg-light spark-variant-primary-border spark-variant-text-dark spark-block-content"
              header-class="card-header rounded-card-header fw-400 py-4 px-4 green-bottom-border spark-variant-primary-bg"
            >
              <template #header>
                <b-row>
                  <b-col>
                    <div class="spark-block-title">
                      <i
                        class="fas fa-tasks icon-extra-large spark-variant-secondary-text mr-4"
                      ></i>
                      Start Claim
                    </div>
                  </b-col>
                </b-row>
              </template>

              <b-row class="mt-4 mb-4">
                <b-col class="col-6 text-right pt-4">
                  <span class="font-weight-bold"
                    >Please select the policy you are claiming on</span
                  >
                </b-col>
                <b-col class="col-5 input_icon_fixed_width">
                  <select
                    v-if="policyList"
                    class="select-css"
                    v-model="selected_policy"
                  >
                    <option value="null">-- Please Select --</option>
                    <option
                      v-for="(policy, index) in policyList"
                      v-bind:value="policy.id"
                      :key="'policy_' + index"
                    >
                      {{ policy.reference }}
                    </option>
                  </select>
                  <i class="fas fa-paw"></i>
                </b-col>
                <b-col class="col-1 mt-2">
                  <i
                    v-if="selected_policy === 'null'"
                    class="fas fa-exclamation-circle field_error_icon"
                  ></i>
                </b-col>
              </b-row>
              <b-row class="justify-content-right">
                <b-col class="col-6"></b-col>
                <b-col
                  v-if="selected_policy === 'null'"
                  class="col-6 vue_field_error"
                >
                  <ul>
                    <li>You must select the policy you are claiming on</li>
                  </ul>
                </b-col>
              </b-row>
              <template v-if="selected_policy !== 'null'">
                <b-row class="mt-4 mb-4">
                  <b-col class="col-6 text-right pt-4">
                    <span class="font-weight-bold"
                      >Please select the pet you are claiming for</span
                    >
                  </b-col>
                  <b-col class="col-5 input_icon_fixed_width">
                    <select
                      v-if="petDetails"
                      class="select-css"
                      v-model="selected_pet"
                    >
                      <option value="null">-- Please Select --</option>
                      <option
                        v-for="(pet, index) in petDetails"
                        v-bind:value="pet.id"
                        :key="'pet_' + index"
                      >
                        {{ pet.name }}
                      </option>
                    </select>
                    <i class="fas fa-paw"></i>
                  </b-col>
                  <b-col class="col-1 mt-2">
                    <i
                      v-if="selected_pet === 'null'"
                      class="fas fa-exclamation-circle field_error_icon"
                    ></i>
                  </b-col>
                </b-row>
                <b-row class="justify-content-right">
                  <b-col class="col-6"></b-col>
                  <b-col
                    v-if="selected_pet === 'null'"
                    class="col-6 vue_field_error"
                  >
                    <ul>
                      <li>You must select the pet you are claiming for</li>
                    </ul>
                  </b-col>
                </b-row>
                <b-row v-if="selected_pet !== 'null'">
                  <b-col class="col-6"></b-col>
                  <b-col class="col-6">
                    <b-button
                      size="md"
                      class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
                      @click="
                        startClaim = false;
                        showClaimForm();
                      "
                      >Start Claim</b-button
                    >
                  </b-col>
                </b-row>
              </template>
            </b-card>
          </slide-up-down>
          <spark-magic
            v-if="show_claim_form"
            :create_claim="createClaimProps"
          ></spark-magic>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  CLAIM_CUSTOMER_DISCLAIMER,
  CLIENT_REMOTE_RESOURCES,
  ACCOUNT_DETAIL,
  PAGE_NAME_PORTAL_CLAIM_CUSTOMER_CREATE
} from "@/core/services/vars.service";
import SparkSlideUpDown from "@/view/spark_components/portal/claim/form/Slider";
import SparkDynamicFormGenerator from "@/view/spark_components/portal/claim/form/DynamicFormGenerator";

export default {
  name: PAGE_NAME_PORTAL_CLAIM_CUSTOMER_CREATE,
  short_name: "New Claim",
  path: "create",
  sub_menu: true,
  data() {
    return {
      show_claim_form: false,
      subscription: null,
      disclaimerShow: true,
      startClaim: false,
      selected_policy: "null",
      selected_pet: "null"
    };
  },
  components: {
    "slide-up-down": SparkSlideUpDown,
    "spark-magic": SparkDynamicFormGenerator
  },
  computed: {
    pageContent() {
      let new_content = this.$store.getters[CLAIM_CUSTOMER_DISCLAIMER];
      if (/\{\{RESOURCE->\$[0-9]+\}\}/.test(new_content)) {
        this.$store.getters[CLIENT_REMOTE_RESOURCES].forEach(res => {
          new_content = new_content.replace(
            "{{RESOURCE->$" + res.id + "}}",
            res.filedata
          );
        });
      }
      return new_content;
    },
    petDetails() {
      if (!this.selected_policy || !this.policyList) return null;
      return this.policyList.filter(policy => {
        if (policy.id === this.selected_policy) {
          return true;
        }
        return false;
      })[0].pets;
    },
    createClaimProps() {
      return {
        policy: this.selected_policy,
        pet: this.selected_pet
      };
    },
    policyList() {
      let detail = this.$store.getters[ACCOUNT_DETAIL];
      if (!detail) return null;
      return detail.data.policies;
    },
  },
  watch: {
    policyList: function() {
      if (!this.policyList) return;
      if (this.policyList.length === 1) {
        this.selected_policy = this.policyList[0].id;
        if (this.policyList[0].pets.length === 1) {
          this.selected_pet = this.policyList[0].pets[0].id;
        }
      }
    }
  },
  methods: {
    showClaimForm() {
      this.show_claim_form = true;
    }
  },
  mounted() {
    this.$spark.$breadcrumbs(this.$options);
  }
};
</script>

<template>
  <router-view />
</template>

<script>

import {
  //LOAD_AUTH,
  SET_MENU,
  PORTAL_URL_PATH,
  ABOUT_PAGES,
  IS_AUTHENTICATED,
  CURRENT_USER,
} from "@/core/services/vars.service";

import AboutContactUs from "@/view/spark/portal/public/about/ContactUs";

import PortalLayoutAbout from "@/view/spark_layout/portal/About";
import PortalLayoutAuthenticated from "@/view/spark_layout/portal/Authenticated";
import PortalLayoutAccount from "@/view/spark_layout/portal/Account";
import PortalLayoutClaim from "@/view/spark_layout/portal/Claim";
import PortalLayoutClaimCustomer from "@/view/spark_layout/portal/PetsClaimCustomer";
import PortalLayoutClaimVet from "@/view/spark_layout/portal/PetsClaimVet";
import PortalLayoutPolicyDocument from "@/view/spark_layout/portal/PolicyDocument";
import PortalLayoutComplaint from "@/view/spark_layout/portal/Complaint";
import PortalLayoutCorrespondence from "@/view/spark_layout/portal/Correspondence";
import PortalLayoutPolicy from "@/view/spark_layout/portal/Policy";

export default {
  name: "Layout-Template",
  methods: {
    portalMenu() {
      let base_path_about =
        "/" + PORTAL_URL_PATH + "/" + PortalLayoutAbout.path + "/";
      let base_path_auth =
        "/" + PORTAL_URL_PATH + "/" + PortalLayoutAuthenticated.path + "/";
      let menu_items = Array();
      let comp = null;
      if (this.$store.getters[IS_AUTHENTICATED]) {
        comp = this.$spark.$config.views.portal.authenticated.dashboard;
        menu_items.push({
          label: comp.short_name,
          path: base_path_auth + comp.path
        });

        if (this.$spark.$config.client_code === "scratch_and_patch") {
          if (this.$store.getters[CURRENT_USER].$customer) {
            comp = this.$spark.$config.views.portal.authenticated.policy;
            comp.forEach(item => {
              if (item.menu) {
                var new_item = {
                  label: item.short_name,
                  path:
                    base_path_auth + PortalLayoutPolicy.path + "/" + item.path,
                  children: []
                };
                var inner =
                  this.$spark.$config.views.portal.authenticated.document.policy;
                inner.forEach(inner_item => {
                  if (inner_item.menu) {
                    new_item.children.push({
                      label: inner_item.short_name,
                      path:
                        base_path_auth +
                        PortalLayoutPolicy.path +
                        "/" +
                        PortalLayoutPolicyDocument.path +
                        "/" +
                        inner_item.path
                    });
                  }
                });
                menu_items.push(new_item);
              }
            });
          }
        } else {
          comp = this.$spark.$config.views.portal.authenticated.policy;
          comp.forEach(item => {
            if (item.menu) {
              var new_item = {
                label: item.short_name,
                path:
                  base_path_auth + PortalLayoutPolicy.path + "/" + item.path,
                children: []
              };
              var inner =
                this.$spark.$config.views.portal.authenticated.document.policy;
              inner.forEach(inner_item => {
                if (inner_item.menu) {
                  new_item.children.push({
                    label: inner_item.short_name,
                    path:
                      base_path_auth +
                      PortalLayoutPolicy.path +
                      "/" +
                      PortalLayoutPolicyDocument.path +
                      "/" +
                      inner_item.path
                  });
                }
              });
              menu_items.push(new_item);
            }
          });
        }

        if (this.$spark.$config.client_code === "scratch_and_patch") {
          if (this.$store.getters[CURRENT_USER].$customer) {
            comp = this.$spark.$config.views.portal.authenticated.claim.customer;
            comp.forEach(item => {
              if (item.menu) {
                var new_item = {
                  label: item.short_name,
                  path:
                    base_path_auth +
                    PortalLayoutClaim.path +
                    "/" +
                    PortalLayoutClaimCustomer.path +
                    "/" +
                    item.path,
                  children: []
                };
                comp.forEach(inner_item => {
                  if (inner_item.sub_menu) {
                    var new_inner = {
                      label: inner_item.short_name,
                      path:
                        base_path_auth +
                        PortalLayoutClaim.path +
                        "/" +
                        PortalLayoutClaimCustomer.path +
                        "/" +
                        inner_item.path
                    };
                    new_item.children.push(new_inner);
                  }
                });
                menu_items.push(new_item);
              }
            });
          }
          if (this.$store.getters[CURRENT_USER].$type === "vet") {
            comp = this.$spark.$config.views.portal.authenticated.claim.vet;
            comp.forEach(item => {
              if (item.menu) {
                var new_item = {
                  label: item.short_name,
                  path:
                    base_path_auth +
                    PortalLayoutClaim.path +
                    "/" +
                    PortalLayoutClaimVet.path +
                    "/" +
                    item.path,
                  children: []
                };
                comp.forEach(inner_item => {
                  if (inner_item.sub_menu) {
                    var new_inner = {
                      label: inner_item.short_name,
                      path:
                        base_path_auth +
                        PortalLayoutClaim.path +
                        "/" +
                        PortalLayoutClaimVet.path +
                        "/" +
                        inner_item.path
                    };
                    new_item.children.push(new_inner);
                  }
                });
                menu_items.push(new_item);
              }
            });
          }
        } else {
          comp = this.$spark.$config.views.portal.authenticated.claim;
          comp.forEach(item => {
            if (item.menu) {
              menu_items.push({
                label: item.short_name,
                path: base_path_auth + PortalLayoutClaim.path + "/" + item.path
              });
            }
          });
        }

        comp = this.$spark.$config.views.portal.authenticated.complaint;
        comp.forEach(item => {
          if (item.menu) {
            menu_items.push({
              label: item.short_name,
              path:
                base_path_auth + PortalLayoutComplaint.path + "/" + item.path
            });
          }
        });

        comp = this.$spark.$config.views.portal.authenticated.correspondence;
        comp.forEach(item => {
          if (item.menu) {
            menu_items.push({
              label: item.short_name,
              path:
                base_path_auth +
                PortalLayoutCorrespondence.path +
                "/" +
                item.path
            });
          }
        });

        comp = this.$spark.$config.views.portal.authenticated.account;
        comp.forEach(item => {
          if (item.menu) {
            menu_items.push({
              label: item.short_name,
              path: base_path_auth + PortalLayoutAccount.path + "/" + item.path
            });
          }
        });
      }

      comp = PortalLayoutAbout;
      let about_pages = {
        label: comp.short_name,
        //path: base_path_about,
        children: []
      };
      var inner = null;

      this.$store.getters[ABOUT_PAGES].forEach(page => {
        //console.log("PAGE", page);
        about_pages.children.push({
          label: page.about.title,
          path: base_path_about + page.about.path
        });
      });

      about_pages.children.push({ seperator: true });

      inner = AboutContactUs;
      about_pages.children.push({
        label: inner.short_name,
        path: base_path_about + inner.path
      });

      menu_items.push(about_pages);

      this.$spark.$dispatch(SET_MENU, menu_items);
    },
  },

  mounted() {
    this.$nextTick(() => {
    //this.$spark.$dispatch(LOAD_AUTH).then(() => {
      //this.$set(this, '$spark', this.$spark);
      this.portalMenu();
    //});
    });
  },
  beforeRouteUpdate(to, from, next) {
    //console.log(to, from)
    this.portalMenu();
    next();
  }
};
</script>

<style>
:root {
  --white: #ffffff;
  --system-light-grey: #e6e6e9;
  --main-page-background: var(--white);
  --mobile-menu-background: var(--system-light-grey);
}
</style>

<template>
  <div>
    <b-row class="justify-content-center">
      <b-col :cols="cols" :sm="sm" :md="md" :lg="lg" :xl="xl">
        <router-view />
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Portal-Page-Bootstrap",
  props: {
    columns: {
      type: Number,
      default: 2
    }
  },
  data() {
    return {
      cols: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12
    };
  },
  methods: {
    _sm() {
      // small screens, ipad pro / laptop
      if (this.columns === 1) return 12;
      if (this.columns === 2) return 12;
      if (this.columns === 3) return 12;
    },
    _md() {
      // desktop monitors.....
      if (this.columns === 1) return 12;
      if (this.columns === 2) return 12;
      if (this.columns === 3) return 12;
    },
    _lg() {
      if (this.columns === 1) return 12;
      if (this.columns === 2) return 12;
      if (this.columns === 3) return 12;
    },
    _xl() {
      if (this.columns === 1) return 10;
      if (this.columns === 2) return 11;
      if (this.columns === 3) return 12;
    }
  },
  mounted() {
    this.sm = this._sm();
    this.md = this._md();
    this.lg = this._lg();
    this.xl = this._xl();
  }
};
</script>

<template>
  <div class="mb-10">
    <b-card
      class="mb-3 rounded-card spark-variant-primary-bg-light spark-variant-primary-border spark-variant-text-dark spark-block-content"
      header-class="card-header rounded-card-header fw-400 py-4 px-4 green-bottom-border spark-variant-primary-bg"
    >
      <template #header>
        <b-row>
          <b-col>
            <div class="spark-block-title">
              <i
                class="fas fa-cat icon-extra-large spark-variant-secondary-text mr-2"
                v-if="item.type === 'cat'"
              ></i>
              <i
                class="fas fa-dog icon-extra-large spark-variant-secondary-text mr-2"
                v-if="item.type === 'dog'"
              ></i>
              {{ $options.short_name }}
            </div>
          </b-col>
        </b-row>
      </template>

      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">Name</b-col>
        <b-col class="col-6"><i class="fas fa-paw"></i>{{ item.name }}</b-col>
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">Species</b-col>
        <b-col class="col-6">
          <i class="fas fa-paw"></i>
          <i
            class="fas fa-cat icon-large spark-variant-primary-text"
            v-if="item.type === 'cat'"
          ></i>
          <i
            class="fas fa-dog icon-large spark-variant-primary-text"
            v-if="item.type === 'dog'"
          ></i>

          {{ item.type | capitalize }}</b-col
        >
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">Breed type</b-col>
        <b-col class="col-6"
          ><i class="fas fa-paw"></i>{{ item.breed_type }}</b-col
        >
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">Breed</b-col>
        <b-col class="col-6"><i class="fas fa-paw"></i>{{ item.breed }}</b-col>
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">Gender</b-col>
        <b-col class="col-6"
          ><i class="fas fa-paw"></i>{{ item.gender | capitalize }}</b-col
        >
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">Date of birth</b-col>
        <b-col class="col-6"
          ><i class="fas fa-paw"></i
          >{{ moment(item.date_of_birth).format("Do MMM YYYY") }}</b-col
        >
      </b-row>

      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">Purchase price</b-col>
        <b-col class="col-6"
          ><i class="fas fa-paw"></i>&pound;{{ item.purchase_price }}</b-col
        >
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">
          {{ item.gender === "male" ? "Neutered" : "Spayed" }}
        </b-col>
        <b-col class="col-6">
          <i class="fas fa-paw"></i>
          <i
            v-if="item.neutered === true"
            class="fas fa-check-circle green icon-large"
          ></i>
          <i v-else class="fas fa-times-circle red icon-large"></i>
          {{ item.neutered === true ? "Yes" : "No" }}
        </b-col>
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">Chipped</b-col>
        <b-col class="col-6">
          <i class="fas fa-paw"></i>
          <i
            v-if="item.chipped === true"
            class="fas fa-check-circle green icon-large"
          ></i>
          <i v-else class="fas fa-times-circle red icon-large"></i>
          {{ item.chipped === true ? "Yes" : "No" }}
          <div>
            <b-button
              v-if="item.chipped === false"
              size="md"
              class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
              @click="
                openInNewTab(
                  'https://www.pdsa.org.uk/taking-care-of-your-pet/looking-after-your-pet/all-pets/microchipping-your-pet'
                )
              "
              >Chip Your Pet&nbsp;&nbsp;<i class="fas fa-heart red"></i
            ></b-button>
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-5" v-if="item.chipped === true">
        <b-col class="col-6 font-weight-bold text-right">Chip number</b-col>
        <b-col class="col-6"
          ><i class="fas fa-paw"></i>{{ item.chip_number }}</b-col
        >
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right"
          >Vaccinations upto date</b-col
        >
        <b-col class="col-6">
          <i class="fas fa-paw"></i>
          <i
            v-if="item.vaccinated === true"
            class="fas fa-check-circle green icon-large"
          ></i>
          <i v-else class="fas fa-times-circle red icon-large"></i>
          {{ item.vaccinated === true ? "Yes" : "No" }}
          <div>
            <b-button
              v-if="item.vaccinated === false"
              size="md"
              class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
              @click="
                openInNewTab(
                  'https://www.pdsa.org.uk/taking-care-of-your-pet/looking-after-your-pet/all-pets/why-vaccinating-your-pet-is-important'
                )
              "
              >Vaccinate Your Pet&nbsp;&nbsp;<i class="fas fa-heart red"></i
            ></b-button>
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-5" v-if="item.breed_type === 'dog'">
        <b-col class="col-6 font-weight-bold text-right">
          Has {{ item.name }} bitten or attacked anyone
        </b-col>
        <b-col class="col-6">
          <i class="fas fa-paw"></i>
          <i
            v-if="item.aggressive_behaviour === true"
            class="fas fa-check-circle red icon-large"
          ></i>
          <i v-else class="fas fa-times-circle green icon-large"></i>
          {{ item.paggressive_behaviour === true ? "Yes" : "No" }}
        </b-col>
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">
          Is {{ item.name }} owned by <span v-if="$store.getters[CURRENT_USER].$customer">you</span
          ><span v-if="$store.getters[CURRENT_USER].$vet">the policy holder</span>
        </b-col>
        <b-col class="col-6">
          <i class="fas fa-paw"></i>
          <i
            v-if="item.owned === true"
            class="fas fa-check-circle green icon-large"
          ></i>
          <i v-else class="fas fa-times-circle red icon-large"></i>
          {{ item.owned === true ? "Yes" : "No" }}
        </b-col>
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">
          Has {{ item.name }} ever seen a vet for illness or injury
        </b-col>
        <b-col class="col-6">
          <i class="fas fa-paw"></i>
          <i
            v-if="item.seen_a_vet === true"
            class="fas fa-check-circle red icon-large"
          ></i>
          <i v-else class="fas fa-times-circle green icon-large"></i>
          {{ item.seen_a_vet === true ? "Yes" : "No" }}
        </b-col>
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right"
          >Medical Conditions</b-col
        >
        <b-col
          class="col-6"
          v-if="item.medical_conditions && item.medical_conditions.length > 0"
        >
          <b-row>
            <b-col class="col-2">
              <i class="fas fa-paw"></i>
            </b-col>
            <b-col class="col-10">
              <ul style="display: inline-block;">
                <li
                  class="pb-4"
                  v-for="(condition, index) in item.medical_conditions"
                  :key="index"
                >
                  {{ condition }}
                </li>
              </ul>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="col-6" v-else>
          <i class="fas fa-paw"></i>
          <i class="fas fa-times-circle green icon-large"></i>
          None
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  CURRENT_USER
} from "@/core/services/vars.service";
export default {
  name: "Portal-Policy-Pet-Detail",
  short_name: "Pet Details",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  created() {
    this.CURRENT_USER = CURRENT_USER;
  }
};
</script>

<template>
  <div>
    <ul class="spark-portal-menu-mobile" v-if="$parent.$parent.menuOpen">
      <!--:DYNAMIC MENU ITEMS:-->
      <template v-for="(menu, index) in $store.getters[MENU_ITEMS]">
        <!--:MENU ITEM - HAS CHILDREN:-->
        <template v-if="'children' in menu">
          <router-link
            v-bind:key="'menu_link_' + index"
            :to="menu.path || '#empty'"
            v-slot="{ href, navigate }"
            custom
          >
            <li
              v-bind:key="'menu_parent_' + index"
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item menu-item-submenu"
              v-bind:class="{
                'menu-item-open': hasActiveChildren(menu.url)
              }"
            >
              <a
                :href="href"
                class="menu-link menu-toggle h1"
                @click="$parent.$parent.toggleMenu() && closeSubs() && navigate"
              >
                <div
                  class="text-right spark-sub-menu-item"
                  v-html="menu.label"
                ></div>
              </a>
              <span
                class="svg-icon svg-icon-xl border border-dark bg-light rounded-small mb-1 mr-4"
                @click="toggleSub(index)"
              >
                <!--begin::Svg Icon | path:svg/icons/General/User.svg-->
                <inline-svg
                  class="svg-icon"
                  src="media/svg/icons/Navigation/Arrow-to-bottom.svg"
                />
                <!--end::Svg Icon-->
              </span>
              <div
                class="menu-submenu menu-submenu-classic menu-submenu-right"
                v-if="canShow[index]"
              >
                <ul class="menu-subnav">
                  <template v-for="(sub, index1) in menu.children">
                    <router-link
                      v-bind:key="'menu_sub_' + index1"
                      v-if="!sub.seperator"
                      :to="sub.path"
                      v-slot="{ href, navigate, isActive, isExactActive }"
                      custom
                    >
                      <li
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                        class="menu-item"
                        :class="[
                          isActive && 'menu-item-active',
                          isExactActive && 'menu-item-active'
                        ]"
                      >
                        <a
                          :href="href"
                          class="menu-link h2"
                          @click="
                            $parent.$parent.toggleMenu() &&
                              closeSubs() &&
                              navigate
                          "
                        >
                          <div
                            class="spark-sub-menu-item-label"
                            v-html="sub.label"
                          ></div>
                        </a>
                      </li>
                    </router-link>
                  </template>
                </ul>
              </div>
            </li>
          </router-link>
        </template>
        <!--:MENU ITEM - HAS CHILDREN:-->

        <!--:MENU ITEM:-->
        <template v-else>
          <router-link
            v-bind:key="'menu_link_' + index"
            :to="menu.path"
            v-slot="{ href, navigate, isActive, isExactActive }"
            custom
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item text-right"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a
                :href="href"
                class="menu-link h1"
                @click="$parent.$parent.toggleMenu() && closeSubs() && navigate"
              >
                <div v-html="menu.label"></div>
              </a>
            </li>
          </router-link>
        </template>
        <!--:MENU ITEM:-->
      </template>
      <!--:DYNAMIC MENU ITEMS:-->
      <template>
        <li
          v-if="$store.getters[IS_AUTHENTICATED]"
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item text-right"
        >
          <a
            class="menu-link h1"
            @click="$parent.$parent.toggleMenu() && closeSubs() && logout()"
          >
            <!--<i class="menu-icon flaticon2-expand"></i>-->
            <div>Logout</div>
          </a>
        </li>
        <li
          v-else-if="$route.name !== $spark.$config.views.portal.login.name"
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item text-right"
        >
          <a
            class="menu-link h1"
            @click="
              $parent.$parent.toggleMenu() &&
                closeSubs() &&
                $spark.$portalLogin();
            "
          >
            <!--<i class="menu-icon flaticon2-expand"></i>-->
            <div>Login</div>
          </a>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import {
  LOGOUT,
  IS_AUTHENTICATED,
  MENU_ITEMS,
  NAV_BLOCKED_LOGOUT_DATA
} from "@/core/services/vars.service";

export default {
  name: "KTMenuMobile",
  data() {
    return {
      sub_show: {}
    };
  },
  created() {
    this.MENU_ITEMS = MENU_ITEMS;
    this.IS_AUTHENTICATED = IS_AUTHENTICATED;
  },
  computed: {
    canShow() {
      return this.sub_show;
    },
  },
  methods: {
    logout() {
      this.$spark._showNavBlockedConfirm(this.$store.getters[NAV_BLOCKED_LOGOUT_DATA], {
        fn: this.$spark.$dispatch,
        args: [LOGOUT]
      });
    },
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    toggleSub(sub_index) {
      this.sub_show[sub_index] = !this.sub_show[sub_index];
      this.$forceUpdate();
    },
    closeSubs() {
      Object.keys(this.sub_show).forEach(k => {
        this.sub_show[k] = false;
      });
      return true;
    }
  }
};
</script>

<style>
.rounded-small {
  border-radius: 30px !important;
  padding: 3px 0px;
}
.spark-portal-menu-mobile .spark-sub-menu-item-label {
  padding-right: 2em;
}
.spark-portal-menu-mobile {
  border-left: 1px solid var(--spark-variant-text-dark);
  border-bottom: 1px solid var(--spark-variant-text-dark);
  border-bottom-left-radius: 10px;
  background: var(--spark-variant-text-light);
  text-align: center;
  display: none;
  z-index: 10;
}
.spark-portal-menu-mobile {
  list-style: none;
  list-style-type: none;
  padding: 0;
}
.spark-portal-menu-mobile ul {
  list-style-type: none;
}
.spark-portal-menu-mobile li {
  text-align: right;
}
/*.spark-portal-menu-mobile li:not(:last-of-type) {
  //border-bottom: 1px solid var(--spark-variant-text-dark);
}*/
.spark-portal-menu-mobile li div:first-child:not(.spark-sub-menu-item) {
  border-top: 1px solid var(--spark-variant-text-dark);
}
.spark-portal-menu-mobile li a {
  /*padding: 15px;
  border: 1px solid #3e8ede;
  margin: 5px;
  font-family: "Open Sans",sans-serif;
  border-radius: 10px;
  font-size: 20px;
  line-height: 20px;*/
  cursor: pointer;
  transition-property: background-color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;
}
.spark-portal-menu-mobile li a div {
  padding: 0.75rem;
}
.spark-portal-menu-mobile .spark-sub-menu-item {
  width: calc(80%);
  display: inline-block;
}
/*.spark-portal-menu-mobile li a:hover,
.spark-portal-menu li a.active {
    background-color: #3e8ede;
    color: #fff;
}*/

.spark-portal-menu-mobile li a div > ul li {
  display: block;
  position: relative;
  /*min-width: 300px;
    max-width: 300px;*/
}
/*.spark-portal-menu li > ul li a { 
    min-width: 300px;
    max-width: 300px;
}*/

@media (max-width: 991.98px) {
  .spark-portal-menu-mobile {
    transition: all 0.5s ease-in-out;
    position: fixed;
    top: 55px;
    right: 0px;
    width: 80%;
    float: right;
    display: block;
    max-width: 400px;
  }
}
</style>

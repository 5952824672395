<template>
  <b-row class="w-100 justify-content-center" v-if="canShow">
    <b-col
      cols="8"
      class="rounded-card spark-variant-primary-bg my-10 py-5 px-10"
    >
      <b-row>
        <b-col class="text-left">
          <b-button
            :disabled="data.current_page === 1"
            size="md"
            class="rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
            @click="first()"
            >First Page</b-button
          >
        </b-col>
        <b-col class="text-left">
          <b-button
            :disabled="!data.previous_page || data.current_page === 1"
            size="md"
            class="rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
            @click="prev()"
            >Prev Page</b-button
          >
        </b-col>
        <b-col>
          <b-button
            size="md"
            class="rounded spark-variant-primary-bg-light spark-variant-primary-border-light spark-variant-primary-text-dark font-weight-bold"
            >Page {{ data.current_page }} of {{ data.last_page }}</b-button
          >
        </b-col>
        <b-col class="text-right">
          <b-button
            :disabled="!data.next_page || data.current_page === data.last_page"
            size="md"
            class="rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
            @click="next()"
            >Next Page</b-button
          >
        </b-col>
        <b-col class="text-right">
          <b-button
            :disabled="!data.last_page || data.current_page === data.last_page"
            size="md"
            class="rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
            @click="last()"
            >Last Page</b-button
          >
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "Portal-Pagination",
  props: {
    data: {
      type: Object,
      required: true
    },
    to: {
      type: String,
      required: true
    }
  },
  computed: {
    canShow() {
      return this.data.last_page !== 1;
    }
  },
  methods: {
    next() {
      if (this.data.next_page) {
        this.$spark.$api(this.to, {page: this.data.next_page}).catch(() => {});
      }
    },
    prev() {
      if (this.data.previous_page) {
        this.$spark.$api(this.to, {page: this.data.previous_page}).catch(() => {});
      }
    },
    first() {
      this.$spark.$api(this.to, {page: 1}).catch(() => {});
    },
    last() {
      if (this.data.last_page) {
        this.$spark.$api(this.to, {page: this.data.last_page}).catch(() => {});
      }
    }
  }
};
</script>

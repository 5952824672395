import store from "@/core/services/store/index";
import { LOAD } from "@/core/services/vars.service";

/*
Spark function to perform API calls from components, via vuex store, asynchronously <3
returns a promise <3

NOTE: most API calls should be done from the layout template, before the page view component
*/

/*
accessible via:

  Vue/this.$spark.$api(action: String, payload: optional);

  payload is an object

  for paginated requests, to poke page number > 1, add page number via get param key/value pairs using key name 'page'

  ## get, with no querystring (use getter to access data)
  this.$spark.$api(CLAIM_LIST);

  ## get/post/put/patch
  this.$spark.$api(CLAIM_LIST, { page: 2 });

  ## get/post/put/patch/delete, with additional header
  this.$spark.$api(CLAIM_LIST, { 
    params: {
      page: 2
    },
    headers: {
      IgniteToken: "=79n97f9o7f97n7=="
    }
  );

  ## get, wait for response, then do stuff, with or without accessing response data directly
  this.$spark.$api(CLAIM_LIST).then(response => {
    // process response data ?
    // close modal ?
  });

  ## get, wait for response, then do stuff, with or without accessing response data
  ## AND do stuff if an error happens
  this.$spark.$api(CLAIM_LIST).then(response => {
    // process response data ?
    // close modal ?
  }).catch(error => {
    // reset something in a modal ?
    // use part of the error data ?
    // show a custom message in a modal, because the default api error gets sent to the main alert component, which is out of view ?
  });

*/

/* synchronous function to wrap a handler for easier code mass production */
/* instantly returns a promise from the magical vuex action wrapper we have ;) */
const $api = (_action, _payload=null, prefix=true) => {

  let action = (prefix === true ? LOAD + _action : _action);

  console.log(action, _payload)

  /* no payload sent, just return the action dispatcher */
  if (!_payload) {
    
    /* return dispatched action (is a promise created by store population API function) */
    /* the component that calls $api() can await the response with then()/catch() */
    return store.dispatch(action);

  /* payload is sent, has it got headers, or just param data ? */
  } else {
    let payload = {};

    /* if the payload contains header kvp, extract headers, and possibly get/post/put/patch kvp params */
    if(_payload.headers) {

      /* add headers to axios payload */
      payload.headers = _payload.headers;

      /* because this isnt a pure param data object, and has headers, does it also have params ? */
      if (_payload.params) {

        /* add params to axios payload */
        payload.params = _payload.params;
      }
    
    /* headers were not included, this is just a raw params object */
    } else {

      /* pass entire incoming payload to axios */
      payload.params = _payload;
    }

    /* this is definitely not required right now, as the above checks make sure if payload is passed, its raw or headers */
    /* if your code makes a booboo and passes axios an empty params object, axios doesn't care */
    /* BUT because i hate random errors, and we may upgrade, i'm gonna future-proof this shizzle */
    if (Object.keys(payload).length > 0) {
      return store.dispatch(action, payload);
    } else {
      return store.dispatch(action);
    }
  }
}

export default $api;

<template>
  <b-row class="mt-4 mb-4">
    <b-col
      cols="12"
      sm="6"
      md="6"
      lg="6"
      xl="6"
      class="text-left text-sm-right text-md-right text-lg-right text-xl-right pt-4"
    >
      <label
        :for="'question_' + question_name + '_date'"
        class="shift_input_label vue_property_display_label"
      >
        <span v-html="label" class="font-weight-bold"></span>
        <span
          v-html="'<br/>(' + sub_label + ')'"
          class="sub_label"
          v-if="sub_label"
        ></span>
      </label>
    </b-col>
    <b-col cols="10" sm="5" md="5" lg="5" xl="5" class="input_icon_fixed_width">
      <textarea
        :id="'question_' + question_name"
        v-model="value"
        :disabled="disabled"
      ></textarea>
      <i class="fas fa-paw"></i>
      <div
        class="vue_field_error pt-2"
        v-if="$parent.error_messages.length > 0"
      >
        <ul>
          <li
            v-for="(message, index) in $parent.error_messages"
            :key="index"
            v-html="message"
          ></li>
        </ul>
      </div>
    </b-col>
    <b-col cols="2" sm="1" md="1" lg="1" xl="1">
      <i
        class="fas fa-exclamation-circle field_error_icon pt-2"
        v-if="$parent.error_messages.length > 0"
      ></i>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "spark-textbox",
  props: {
    id: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    sub_label: {
      type: String,
      default: null
    },
    question_name: {
      type: String,
      required: true
    },
    watched_by: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      value: ""
    };
  },
  computed: {
    Value() {
      return this.value;
    }
  },
  watch: {
    value: function(new_val, old_val) {
      this.$spark.init_section = false;
      this.value = this.onKeyUpHelper(
        new_val,
        old_val,
        this.$parent.question.name,
        this.$parent.question.field_type
      );
      this.$parent.hasValidationPassed(new_val);
      this.$parent.value = new_val;
    }
  }
};
</script>

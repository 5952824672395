<template>
  <div>{{ $options.name }}</div>
</template>

<script>

export default {
  name: "Admin-Search-Task",
  path: "task",
  mounted() {
   this.$spark.$breadcrumbs(this.$options);
  }
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-10"},[_c('b-card',{staticClass:"mb-3 rounded-card spark-variant-primary-bg-light spark-variant-primary-border spark-variant-text-dark spark-block-content",attrs:{"header-class":"card-header rounded-card-header fw-400 py-4 px-4 green-bottom-border spark-variant-primary-bg"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-row',[_c('b-col',{staticClass:"col-10"},[_c('div',{staticClass:"spark-block-title"},[_c('i',{staticClass:"fas fa-exclamation-triangle icon-extra-large red mr-2"}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.blockTitle)}}),_vm._v(" "+_vm._s(_vm.$options.short_name)+" ")])])],1)]},proxy:true}])},[_c('b-row',{staticClass:"mb-5"},[_c('b-col',{staticClass:"col-6 font-weight-bold text-right"},[_vm._v(_vm._s(_vm.problemType)+" reference number")]),_c('b-col',{staticClass:"col-6"},[_c('i',{staticClass:"fas fa-paw"}),_vm._v(_vm._s(_vm.item.reference))])],1),(_vm.full)?[_c('b-row',{staticClass:"mb-5"},[_c('b-col',{staticClass:"col-6"}),_c('b-col',{staticClass:"col-6"},[_c('div',[(_vm.linkParamsPolicyPayment)?_c('b-button',{staticClass:"mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold",attrs:{"size":"md","disabled":true},domProps:{"innerHTML":_vm._s(_vm.linkParamsPolicyPayment.title)}}):_vm._e()],1),_c('div',[(_vm.linkParamsClaimAddInformation)?_c('b-button',{staticClass:"mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold",attrs:{"size":"md"},domProps:{"innerHTML":_vm._s(_vm.linkParamsClaimAddInformation.title)},on:{"click":function($event){return _vm.$spark.$nav(
                  _vm.linkParamsClaimAddInformation.manage_name,
                  _vm.linkParamsClaimAddInformation.manage_params
                )}}}):_vm._e()],1),_c('div',[(_vm.linkParamsClaimUploadDocs)?_c('b-button',{staticClass:"mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold",attrs:{"size":"md"},domProps:{"innerHTML":_vm._s(_vm.linkParamsClaimUploadDocs.title)},on:{"click":function($event){return _vm.$spark.$nav(
                  _vm.linkParamsClaimUploadDocs.manage_name,
                  _vm.linkParamsClaimUploadDocs.manage_params
                )}}}):_vm._e()],1),_c('div',[(_vm.linkParamsClaimCustomerApprove)?_c('b-button',{staticClass:"mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold",attrs:{"size":"md"},domProps:{"innerHTML":_vm._s(_vm.linkParamsClaimCustomerApprove.title)},on:{"click":function($event){return _vm.$spark.$nav(
                  _vm.linkParamsClaimCustomerApprove.manage_name,
                  _vm.linkParamsClaimCustomerApprove.manage_params
                )}}}):_vm._e()],1),_c('div',[(_vm.linkParamsClaimAddFurtherInfo)?_c('b-button',{staticClass:"mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold",attrs:{"size":"md"},domProps:{"innerHTML":_vm._s(_vm.linkParamsClaimAddFurtherInfo.title)},on:{"click":function($event){return _vm.$spark.$nav(
                  _vm.linkParamsClaimAddFurtherInfo.manage_name,
                  _vm.linkParamsClaimAddFurtherInfo.manage_params
                )}}}):_vm._e()],1)])],1)]:[_c('div',[(_vm.linkParamsPolicyPayment)?_c('b-button',{staticClass:"mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold",attrs:{"size":"md","disabled":true},domProps:{"innerHTML":_vm._s(_vm.linkParamsPolicyPayment.title)}}):_vm._e()],1),_c('div',[(_vm.linkParamsPolicyRenewal)?_c('b-button',{staticClass:"mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold",attrs:{"size":"md","disabled":true},domProps:{"innerHTML":_vm._s(_vm.linkParamsPolicyRenewal.title)}}):_vm._e()],1),_c('div',[(_vm.linkParamsClaimAddInformation)?_c('b-button',{staticClass:"mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold",attrs:{"size":"md"},domProps:{"innerHTML":_vm._s(_vm.linkParamsClaimAddInformation.title)},on:{"click":function($event){return _vm.$spark.$nav(
              _vm.linkParamsClaimAddInformation.manage_name,
              _vm.linkParamsClaimAddInformation.manage_params
            )}}}):_vm._e()],1),_c('div',[(_vm.linkParamsClaimUploadDocs)?_c('b-button',{staticClass:"mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold",attrs:{"size":"md"},domProps:{"innerHTML":_vm._s(_vm.linkParamsClaimUploadDocs.title)},on:{"click":function($event){return _vm.$spark.$nav(
              _vm.linkParamsClaimUploadDocs.manage_name,
              _vm.linkParamsClaimUploadDocs.manage_params
            )}}}):_vm._e()],1),_c('div',[(_vm.linkParamsClaimCustomerApprove)?_c('b-button',{staticClass:"mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold",attrs:{"size":"md"},domProps:{"innerHTML":_vm._s(_vm.linkParamsClaimCustomerApprove.title)},on:{"click":function($event){return _vm.$spark.$nav(
              _vm.linkParamsClaimCustomerApprove.manage_name,
              _vm.linkParamsClaimCustomerApprove.manage_params,
              _vm.linkParamsClaimCustomerApprove.after
            )}}}):_vm._e()],1),_c('div',[(_vm.linkParamsClaimAddFurtherInfo)?_c('b-button',{staticClass:"mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold",attrs:{"size":"md"},domProps:{"innerHTML":_vm._s(_vm.linkParamsClaimAddFurtherInfo.title)},on:{"click":function($event){return _vm.$spark.$nav(
              _vm.linkParamsClaimAddFurtherInfo.manage_name,
              _vm.linkParamsClaimAddFurtherInfo.manage_params
            )}}}):_vm._e()],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
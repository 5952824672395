import Vue from "vue";

const $storage = {
  local_storage_accessible: false,
  /*
  on vue load, init storage service, and check if local is accessible
  it should be available on all modern browsers, but apple had to be apple
  */
  $init() {
    this.local_storage_accessible = (('localStorage' in window) && window['localStorage'] !== null);
  },

  /*
  set storage object
  ttl = minutes until expire - default, never expire
  */
  set(key, value, ttl = null) {
    if (this.local_storage_accessible) {
      this.setLocal(key, value, ttl);
    } else {
      this.setCookie(key, value, ttl);
    }
  },
  get(key) {
    if (this.local_storage_accessible) {
      return this.getLocal(key);
    } else {
      return this.getCookie(key);
    }
  },
  delete(key) {
    if (this.local_storage_accessible) {
      return this.deleteLocal(key);
    } else {
      return this.deleteCookie(key);
    }
  },
  nuke() {
    if (this.local_storage_accessible) {
      return this.nukeLocal();
    } else {
      return this.nukeCookie();
    }
  },
  /*setLocal(key, value, ttl) {
    let options = {};
    if (ttl !== null) {
      options.ttl = ttl * 1000 * 60;
    }
    Vue.$storage.set(key, value, options);
  },
  getLocal(key) {
    return Vue.$storage.get(key, null);
  },
  deleteLocal(key) {
    Vue.$storage.remove(key);
  },*/
  setLocal(name, value, ttl){
    var now = new Date();
    var string = null;
    if (ttl) {
      string = JSON.stringify({value: value, expiry: now.getTime() + (ttl*60*1000)});
    } else {
      string = JSON.stringify({value: value, expiry: 0});
    }
    localStorage.setItem(name, string);
  },

  getLocal(name){
    var string = localStorage.getItem(name)
    if (!string) {
      return null;
    }
    var item = JSON.parse(string);
    if (item.expiry === 0) return item.value;
    var now = new Date();
    if (now.getTime() > item.expiry){
      localStorage.removeItem(name);
      return null;
    }
    return item.value;
  },
  deleteLocal(name) {
    localStorage.removeItem(name);
  },
  nukeLocal() {
    localStorage.clear();
  },
  setCookie(key, value, ttl) {
    ttl = (ttl === null ? Infinity : ttl);
    Vue.$cookies.set(key, value, ttl);
  },
  getCookie(key) {
    if (!Vue.$cookies.isKey(key)) return null;
    return Vue.$cookies.get(key);
  },
  deleteCookie(key) {
    Vue.$cookies.remove(key);
  },
  nukeCookie() {
    Vue.$cookies.keys().forEach(cookie => {
      Vue.$cookies.remove(cookie);
    })
  }
};

export default $storage;

<template>
  <div>
    <spark-change-password></spark-change-password>
    <router-view />
  </div>
</template>

<script>
import { ACCOUNT_DETAIL } from "@/core/services/vars.service";

import SparkChangePassword from "@/view/spark_components/portal/account/PasswordChange";

export default {
  name: "Layout-Portal-Account",
  path: "account",
  components: {
    "spark-change-password": SparkChangePassword
  },
  methods: {
    loadData() {
      let url_parts = this.$route.path.split("/");
      let action = url_parts[url_parts.length - 1];
      if (action === "manage") {
        this.$spark.$api(ACCOUNT_DETAIL).catch(() => {});
      }
    }
  },
  watch: {
    $route: function() {
      this.loadData();
    }
  },
  mounted() {
    this.$spark.$loading(ACCOUNT_DETAIL, true);
    this.$nextTick(() => {
      setTimeout(() => {
        this.$spark.$loading(ACCOUNT_DETAIL, false).then(() => {
          this.loadData();
        }); 
      },300);
    });
  }
};
</script>

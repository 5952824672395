import {
  CONFIRM_DATA,
  SET_CONFIRM,
  RESET_CONFIRM,
  CONFIRM_COMPONENT,
  SET_CONFIRM_COMPONENT
} from "@/core/services/vars.service";

const initial_state = {
  title: null,
  sub_title: null,
  message: null
};

const state = {
  data: Object.assign({}, initial_state),
  component: null
};

const getters = {
  [CONFIRM_DATA]: state => {
    return state.data;
  },
  [CONFIRM_COMPONENT]: state => {
    return state.component;
  }
};

const actions = {
  [SET_CONFIRM]({ commit }, payload) {
    commit(SET_CONFIRM, payload);
  },
  [SET_CONFIRM_COMPONENT]({ commit }, payload) {
    commit(SET_CONFIRM_COMPONENT, payload);
  },
  [RESET_CONFIRM]({ commit }) {
    commit(RESET_CONFIRM);
  }
};

const mutations = {
  [SET_CONFIRM](state, payload) {
    state.data.title = payload.title;
    state.data.sub_title = payload.sub_title;
    state.data.message = payload.message;
  },
  [RESET_CONFIRM](state) {
    state.data = Object.assign({}, initial_state);
  },
  [SET_CONFIRM_COMPONENT](state, payload) {
    state.component = payload;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};

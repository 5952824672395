<template>
  <div class="mb-10">
    <b-card
      class="mb-3 rounded-card spark-variant-primary-bg-light spark-variant-primary-border spark-variant-text-dark spark-block-content"
      header-class="card-header rounded-card-header fw-400 py-4 px-4 green-bottom-border spark-variant-primary-bg"
    >
      <template #header>
        <b-row>
          <b-col class="col-10">
            <div class="spark-block-title">
              <i
                class="fas fa-exclamation-triangle icon-extra-large red mr-2"
              ></i>
              <span v-html="blockTitle"></span>
              {{ $options.short_name }}
            </div>
          </b-col>
        </b-row>
      </template>

      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right"
          >{{ problemType }} reference number</b-col
        >
        <b-col class="col-6"
          ><i class="fas fa-paw"></i>{{ item.reference }}</b-col
        >
      </b-row>

      <template v-if="full">
        <b-row class="mb-5">
          <b-col class="col-6"></b-col>
          <b-col class="col-6">
            <div>
              <!--<b-button v-if="linkParamsPolicyPayment"
                size="md"
                class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
                @click="
                  $spark.$nav(linkParamsPolicyPayment.manage_name, linkParamsPolicyPayment.manage_params)
                "
                v-html="linkParamsPolicyPayment.title"
              ></b-button>-->
              <b-button
                v-if="linkParamsPolicyPayment"
                size="md"
                class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
                :disabled="true"
                v-html="linkParamsPolicyPayment.title"
              ></b-button>
            </div>
            <div>
              <b-button
                v-if="linkParamsClaimAddInformation"
                size="md"
                class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
                @click="
                  $spark.$nav(
                    linkParamsClaimAddInformation.manage_name,
                    linkParamsClaimAddInformation.manage_params
                  )
                "
                v-html="linkParamsClaimAddInformation.title"
              ></b-button>
            </div>
            <div>
              <b-button
                v-if="linkParamsClaimUploadDocs"
                size="md"
                class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
                @click="
                  $spark.$nav(
                    linkParamsClaimUploadDocs.manage_name,
                    linkParamsClaimUploadDocs.manage_params
                  )
                "
                v-html="linkParamsClaimUploadDocs.title"
              ></b-button>
            </div>
            <div>
              <b-button
                v-if="linkParamsClaimCustomerApprove"
                size="md"
                class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
                @click="
                  $spark.$nav(
                    linkParamsClaimCustomerApprove.manage_name,
                    linkParamsClaimCustomerApprove.manage_params
                  )
                "
                v-html="linkParamsClaimCustomerApprove.title"
              ></b-button>
            </div>
            <div>
              <b-button
                v-if="linkParamsClaimAddFurtherInfo"
                size="md"
                class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
                @click="
                  $spark.$nav(
                    linkParamsClaimAddFurtherInfo.manage_name,
                    linkParamsClaimAddFurtherInfo.manage_params
                  )
                "
                v-html="linkParamsClaimAddFurtherInfo.title"
              ></b-button>
            </div>
          </b-col>
        </b-row>
      </template>
      <template v-else>
        <div>
          <!--<b-button v-if="linkParamsPolicyPayment"
            size="md"
            class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
            @click="
              $spark.$nav(linkParamsPolicyPayment.manage_name, linkParamsPolicyPayment.manage_params)
            "
            v-html="linkParamsPolicyPayment.title"
          ></b-button>-->
          <b-button
            v-if="linkParamsPolicyPayment"
            size="md"
            class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
            :disabled="true"
            v-html="linkParamsPolicyPayment.title"
          ></b-button>
        </div>
        <div>
          <!--<b-button v-if="linkParamsPolicyRenewal"
            size="md"
            class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
            @click="
              $spark.$nav(linkParamsPolicyRenewal.manage_name, linkParamsPolicyRenewal.manage_params)
            "
            v-html="linkParamsPolicyRenewal.title"
          ></b-button>-->
          <b-button
            v-if="linkParamsPolicyRenewal"
            size="md"
            class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
            :disabled="true"
            v-html="linkParamsPolicyRenewal.title"
          ></b-button>
        </div>
        <div>
          <b-button
            v-if="linkParamsClaimAddInformation"
            size="md"
            class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
            @click="
              $spark.$nav(
                linkParamsClaimAddInformation.manage_name,
                linkParamsClaimAddInformation.manage_params
              )
            "
            v-html="linkParamsClaimAddInformation.title"
          ></b-button>
        </div>
        <div>
          <b-button
            v-if="linkParamsClaimUploadDocs"
            size="md"
            class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
            @click="
              $spark.$nav(
                linkParamsClaimUploadDocs.manage_name,
                linkParamsClaimUploadDocs.manage_params
              )
            "
            v-html="linkParamsClaimUploadDocs.title"
          ></b-button>
        </div>
        <div>
          <b-button
            v-if="linkParamsClaimCustomerApprove"
            size="md"
            class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
            @click="
              $spark.$nav(
                linkParamsClaimCustomerApprove.manage_name,
                linkParamsClaimCustomerApprove.manage_params,
                linkParamsClaimCustomerApprove.after
              )
            "
            v-html="linkParamsClaimCustomerApprove.title"
          ></b-button>
        </div>
        <div>
          <b-button
            v-if="linkParamsClaimAddFurtherInfo"
            size="md"
            class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
            @click="
              $spark.$nav(
                linkParamsClaimAddFurtherInfo.manage_name,
                linkParamsClaimAddFurtherInfo.manage_params
              )
            "
            v-html="linkParamsClaimAddFurtherInfo.title"
          ></b-button>
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import {
  PROBLEM_PAYMENT_ISSUE,
  PROBLEM_RENEWAL_DUE,
  PROBLEM_AWAITING_CUSTOMER_INFO,
  PROBLEM_AWAITING_CUSTOMER_DOCS,
  PROBLEM_AWAITING_VET_INFO,
  PROBLEM_AWAITING_VET_DOCS,
  PROBLEM_AWAITING_CUSTOMER_APPROVAL,
  PROBLEM_AWAITING_CUSTOMER_FURTHER_INFO,
  PAGE_NAME_PORTAL_CLAIM_CUSTOMER_UPDATE,
  PAGE_NAME_PORTAL_CLAIM_VET_UPDATE,
  PAGE_NAME_PORTAL_DOCUMENT_CLAIM_UPLOAD,
  PAGE_NAME_PORTAL_CLAIM_CUSTOMER_MANAGE,
  PAGE_NAME_PORTAL_CLAIM_VET_MANAGE,
  PAGE_NAME_PORTAL_CLAIM_CUSTOMER_FURTHER_INFO,
  SET_CLAIM_DETAIL_PK,
  CLAIM_INFORMATION_COMPONENT,
  CURRENT_USER
} from "@/core/services/vars.service";

export default {
  name: "Portal-Problem",
  short_name: "Problem",
  props: {
    item: {
      type: Object,
      required: true
    },
    full: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.CURRENT_USER = CURRENT_USER;
  },
  data() {
    return {
      payment_up_to_date: PROBLEM_PAYMENT_ISSUE,
      due_for_renewal_soon: PROBLEM_RENEWAL_DUE
    };
  },
  methods: {
    openVetClaimData() {
      setTimeout(() => {
        this.$store.$get(CLAIM_INFORMATION_COMPONENT).showClaimData("vet", true);
      }, 500);
    }
  },
  computed: {
    awaitingInfo() {
      if (
        this.$store.getters[CURRENT_USER].$customer &&
        this.item.action.hasOwnProperty(PROBLEM_AWAITING_CUSTOMER_INFO)
      )
        return PROBLEM_AWAITING_CUSTOMER_INFO;
      if (
        this.$store.getters[CURRENT_USER].$vet &&
        this.item.action.hasOwnProperty(PROBLEM_AWAITING_VET_INFO)
      )
        return PROBLEM_AWAITING_VET_INFO;
      return false;
    },
    awaitingDocs() {
      if (
        this.$store.getters[CURRENT_USER].$customer &&
        this.item.action.hasOwnProperty(PROBLEM_AWAITING_CUSTOMER_DOCS)
      )
        return PROBLEM_AWAITING_CUSTOMER_DOCS;
      if (
        this.$store.getters[CURRENT_USER].$vet &&
        this.item.action.hasOwnProperty(PROBLEM_AWAITING_VET_DOCS)
      )
        return PROBLEM_AWAITING_VET_DOCS;
      return false;
    },
    awaitingCustomerApproval() {
      if (this.item.action.hasOwnProperty(PROBLEM_AWAITING_CUSTOMER_APPROVAL))
        return PROBLEM_AWAITING_CUSTOMER_APPROVAL;
      return false;
    },
    awaitingFurtherInfo() {
      if (
        this.item.action.hasOwnProperty(PROBLEM_AWAITING_CUSTOMER_FURTHER_INFO)
      )
        return PROBLEM_AWAITING_CUSTOMER_FURTHER_INFO;
      return false;
    },
    claimManageName() {
      if (this.$store.getters[CURRENT_USER].$customer) {
        return PAGE_NAME_PORTAL_CLAIM_CUSTOMER_MANAGE;
      }
      if (this.$store.getters[CURRENT_USER].$vet) {
        return PAGE_NAME_PORTAL_CLAIM_VET_MANAGE;
      }
      return null;
    },
    claimUpdateName() {
      if (this.$store.getters[CURRENT_USER].$customer) {
        return PAGE_NAME_PORTAL_CLAIM_CUSTOMER_UPDATE;
      }
      if (this.$store.getters[CURRENT_USER].$vet) {
        return PAGE_NAME_PORTAL_CLAIM_VET_UPDATE;
      }
      return null;
    },
    problemType() {
      if (
        this.item.action.hasOwnProperty(this.payment_up_to_date) ||
        this.item.action.hasOwnProperty(this.due_for_renewal_soon)
      ) {
        return "Policy";
      } else if (
        this.item.action.hasOwnProperty(this.awaitingInfo) ||
        this.item.action.hasOwnProperty(this.awaitingDocs) ||
        this.item.action.hasOwnProperty(this.awaitingCustomerApproval) ||
        this.item.action.hasOwnProperty(this.awaitingFurtherInfo)
      ) {
        return "Claim";
      }
      return "";
    },
    blockTitle() {
      if (this.item.action.hasOwnProperty(this.payment_up_to_date)) {
        return "Payment";
      } else if (this.item.action.hasOwnProperty(this.due_for_renewal_soon)) {
        return "Renewal";
      } else if (this.item.action.hasOwnProperty(this.awaitingInfo)) {
        return "Information";
      } else if (this.item.action.hasOwnProperty(this.awaitingDocs)) {
        return "Documents";
      } else if (
        this.item.action.hasOwnProperty(this.awaitingCustomerApproval)
      ) {
        return "Approval";
      } else if (this.item.action.hasOwnProperty(this.awaitingFurtherInfo)) {
        return "Further Information";
      }
      return "";
    },
    linkParamsPolicyPayment() {
      if (this.item.action.hasOwnProperty(this.payment_up_to_date)) {
        return {
          title: "Make Payment",
          manage_name: null,
          manage_params: {}
        };
      }
      return null;
    },
    linkParamsPolicyRenewal() {
      if (this.item.action.hasOwnProperty(this.due_for_renewal_soon)) {
        return {
          title: "Confirm Renewal",
          manage_name: null,
          manage_params: {}
        };
      }
      return null;
    },
    linkParamsClaimAddInformation() {
      if (this.item.action.hasOwnProperty(this.awaitingInfo)) {
        return {
          title: "Add Claim Information",
          manage_name: this.claimUpdateName,
          manage_params: {
            pk_name: SET_CLAIM_DETAIL_PK,
            pk: this.item.id
          }
        };
      }
      return null;
    },
    linkParamsClaimUploadDocs() {
      if (this.item.action.hasOwnProperty(this.awaitingDocs)) {
        return {
          title: "Upload Claim Documents",
          manage_name: PAGE_NAME_PORTAL_DOCUMENT_CLAIM_UPLOAD,
          manage_params: {
            pk_name: SET_CLAIM_DETAIL_PK,
            pk: this.item.id
          }
        };
      }
      return null;
    },
    linkParamsClaimCustomerApprove() {
      if (!this.item.action) return null;
      if (this.item.action.hasOwnProperty(this.awaitingCustomerApproval)) {
        return {
          title: "Review &amp; Approve Claim",
          manage_name: this.claimManageName,
          manage_params: {
            pk_name: SET_CLAIM_DETAIL_PK,
            pk: this.item.id
          },
          after: {
            fn: this.openVetClaimData
          }
        };
      }
      return null;
    },
    linkParamsClaimAddFurtherInfo() {
      if (this.item.action.hasOwnProperty(this.awaitingFurtherInfo)) {
        return {
          title: "Provide Further Information",
          manage_name: PAGE_NAME_PORTAL_CLAIM_CUSTOMER_FURTHER_INFO,
          manage_params: {
            pk_name: SET_CLAIM_DETAIL_PK,
            pk: this.item.id
          }
        };
      }
      return null;
    }
  }
};
</script>

<template>
  <div class="mb-10">
    <b-card
      class="mb-3 rounded-card spark-variant-primary-bg-light spark-variant-primary-border spark-variant-text-dark spark-block-content"
      header-class="card-header rounded-card-header fw-400 py-4 px-4 green-bottom-border spark-variant-primary-bg"
    >
      <template #header>
        <b-row>
          <b-col>
            <div class="spark-block-title">
              <i
                class="fas fa-at icon-extra-large spark-variant-secondary-text mr-2"
              ></i>
              {{ $options.short_name }}
              <span v-if="item.direction === 1">(Sent to us)</span>
              <span v-if="item.direction === 2">(Sent to you)</span>
            </div>
          </b-col>
        </b-row>
      </template>

      <b-row class="mb-5">
        <b-col class="col-4 font-weight-bold text-right"
          >Date / time sent</b-col
        >
        <b-col class="col-8"
          ><i class="fas fa-paw"></i
          >{{ moment(item.created).format("Do MMM YYYY, h:mm:ss A") }}</b-col
        >
      </b-row>
      <template v-if="object">
        <b-row class="mb-5">
          <b-col class="col-4 font-weight-bold text-right">Query type</b-col>
          <b-col class="col-8"
            ><i class="fas fa-paw"></i>{{ objectType }}</b-col
          >
        </b-row>
        <div>
          <b-row class="mb-5">
            <b-col class="col-4 font-weight-bold text-right"
              >{{ objectType }} reference</b-col
            >
            <b-col class="col-8"
              ><i class="fas fa-paw"></i
              >{{ item.metadata.object.reference }}</b-col
            >
          </b-row>
          <b-row class="mb-5">
            <b-col class="col-4 font-weight-bold text-right"
              >{{ objectType }} created date / time</b-col
            >
            <b-col class="col-8"
              ><i class="fas fa-paw"></i
              >{{
                moment(item.metadata.object.created).format(
                  "Do MMM YYYY, h:mm:ss A"
                )
              }}</b-col
            >
          </b-row>
        </div>
      </template>
      <b-row class="mb-5" v-else>
        <b-col class="col-4 font-weight-bold text-right">Query type</b-col>
        <b-col class="col-8"><i class="fas fa-paw"></i>General</b-col>
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-4 font-weight-bold text-right">Message content</b-col>
        <b-col class="col-8">
          <i class="fas fa-paw"></i>
          <span v-if="full" v-html="item.message_body"></span>
          <span v-else v-html="truncate(item.message_body, 100)"></span>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-left">
          <b-button
            v-if="!full"
            size="md"
            class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
            @click="$spark.$nav(manage_name, manage_params)"
            >View Message</b-button
          >
        </b-col>
        <b-col class="text-right">
          <b-button
            v-if="linkParamsManageObject"
            size="md"
            class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
            @click="
              $spark.$nav(
                linkParamsManageObject.manage_name,
                linkParamsManageObject.manage_params
              )
            "
            >Manage {{ objectType }}</b-button
          >
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  PAGE_NAME_PORTAL_CORRESPONDENCE_MANAGE,
  SET_CORRESPONDENCE_DETAIL_PK,
  PAGE_NAME_PORTAL_CLAIM_CUSTOMER_MANAGE,
  PAGE_NAME_PORTAL_CLAIM_VET_MANAGE,
  SET_CLAIM_DETAIL_PK,
  CURRENT_USER
} from "@/core/services/vars.service";

export default {
  name: "Portal-Correspondence-Online-Contact",
  short_name: "Online Contact",
  props: {
    item: {
      type: Object,
      required: true
    },
    full: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      manage_name: PAGE_NAME_PORTAL_CORRESPONDENCE_MANAGE,
      manage_params: {
        pk_name: SET_CORRESPONDENCE_DETAIL_PK,
        pk: this.item.id
      }
    };
  },
  computed: {
    object() {
      if (!this.item.metadata.hasOwnProperty("object")) return null;
      return this.item.metadata.object;
    },
    objectType() {
      if (!this.object) return null;
      return this.item.metadata.object.type;
    },
    claimManageName() {
      if (this.$store.getters[CURRENT_USER].$customer) {
        return PAGE_NAME_PORTAL_CLAIM_CUSTOMER_MANAGE;
      }
      if (this.$store.getters[CURRENT_USER].$vet) {
        return PAGE_NAME_PORTAL_CLAIM_VET_MANAGE;
      }
      return null;
    },
    linkParamsManageObject() {
      if (this.objectType !== "Claim") return null;
      return {
        manage_name: this.claimManageName,
        manage_params: {
          pk_name: SET_CLAIM_DETAIL_PK,
          pk: this.item.metadata.object.pk
        }
      };
    }
  }
};
</script>

import { SET_SPARK_LOADING } from "@/core/services/vars.service";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";
import store from "@/core/services/store/index";

const state = {
  in_progress: {}
};

const actions = {
  [SET_SPARK_LOADING](context, payload) {
    context.commit(SET_SPARK_LOADING, payload);
  }
}

const mutations = {
  [SET_SPARK_LOADING](state, payload) {
    state.in_progress[payload.fn] = payload.status;
    if (payload.status === true) {
      state.in_progress[payload.fn] = true;
    } else {
      delete state.in_progress[payload.fn];
    }
    if (Object.keys(state.in_progress).length > 0) {
      store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
    } else {
      setTimeout(() => {
        if (Object.keys(state.in_progress).length === 0) {
          store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        }
      }, 300);
    }
  }
};

export default {
  state,
  actions,
  mutations
};

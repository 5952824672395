<template>
  <div class="mb-10">
    <b-card
      class="mb-3 rounded-card spark-variant-primary-bg-light spark-variant-primary-border spark-variant-text-dark spark-block-content"
      header-class="card-header rounded-card-header fw-400 py-4 px-4 green-bottom-border spark-variant-primary-bg"
    >
      <template #header>
        <b-row>
          <b-col>
            <div class="spark-block-title">
              <i
                v-if="prefix === 'Claim'"
                class="fas fa-cloud-upload-alt icon-extra-large spark-variant-secondary-text mr-4"
              ></i>
              {{ prefix }} {{ $options.short_name }}
              <span v-if="item.required === false">(Optional)</span>
            </div>
          </b-col>
        </b-row>
      </template>

      <b-row class="mb-5">
        <b-col class="col-4 font-weight-bold text-right">Document name</b-col>
        <b-col class="col-8"><i class="fas fa-paw"></i><span v-html="item.name"></span></b-col>
      </b-row>
      <template v-if="item.uploaded === false">
        <b-row class="mb-5 justify-content-center">
          <b-col class="col-8">
            <b-form-file
              ref="spark-file"
              class="spark-file-multiple"
              multiple
              v-model="file"
              :state="Boolean(file)"
              placeholder="Choose file(s) or drop..."
              drop-placeholder="Drop file(s) here..."
            ></b-form-file>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-4"></b-col>
          <b-col class="col-8">
            <div>
              <b-button
                v-if="showButton"
                size="md"
                class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
                @click="startUploadProcess"
                >{{ buttonTitle }}</b-button
              >
            </div>
          </b-col>
        </b-row>
      </template>
      <template v-else>
        <b-row class="mb-5">
          <b-col class="col-4 font-weight-bold text-right">Uploaded</b-col>
          <b-col class="col-8">
            <i class="fas fa-paw"></i>
            <i
              v-if="item.uploaded === true"
              class="fas fa-check-circle green icon-large"
            ></i>
            <i v-else class="fas fa-times-circle red icon-large"></i>
            {{ item.uploaded === true ? "Yes" : "No" }}
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col class="col-4 font-weight-bold text-right"
            >Number of files</b-col
          >
          <b-col class="col-8">
            <i class="fas fa-paw"></i>
            <span class="fa-stack">
              <i
                class="fas fa-circle fa-stack-1x icon-large spark-variant-primary-text"
              ></i>
              <i
                class="fas fa-hashtag fa-stack-1x ml-3 spark-variant-text-light"
                style="font-size: 0.8em;"
              ></i>
            </span>
            <span class="ml-13">{{ item.data.length }}</span>
          </b-col>
        </b-row>
        <template v-if="Array.isArray(item.data)">
          <b-row class="mb-5" v-for="(link, index) in item.data" :key="index">
            <b-col class="col-4"></b-col>
            <b-col class="col-8">
              <b-button
                size="md"
                class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
                @click="openInNewTab(link)"
                >View File {{ index + 1 }}</b-button
              >
            </b-col>
          </b-row>
        </template>
        <template v-else>
          <b-row class="mb-5">
            <b-col class="col-4"></b-col>
            <b-col class="col-8">
              <b-button
                size="md"
                class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
                @click="openInNewTab(item.data)"
                >View File</b-button
              >
            </b-col>
          </b-row>
        </template>
      </template>
    </b-card>
    <b-modal
      ref="spark-document-extra-modal"
      centered
      hide-footer
      size="lg"
      title="Add Document Extra Information"
    >
      <div class="d-block text-left" v-if="modalData">
        <h4 class="mb-10">Add Document Extra Information</h4>
        <div v-for="(obj, index) in modalData" :key="index">

          <template v-if="obj.field_type === 'BooleanField'">
            <b-row class="mt-4 mb-4">
              <b-col
                cols="12"
                sm="6"
                md="6"
                lg="6"
                xl="6"
                class="text-left text-sm-right text-md-right text-lg-right text-xl-right pt-4"
              >
                <label class="shift_boolean_label vue_property_display_label">
                  <span v-html="obj.label" class="font-weight-bold"></span>
                </label>
              </b-col>
              <b-col cols="10" sm="5" md="5" lg="5" xl="5" class="spark-boolean">
                <input
                  :id="'question_' + index"
                  type="checkbox"
                  v-model="ga_used"
                />
                <label :for="'question_' + index" class="spark-boolean-content" />
              </b-col>
            </b-row>
          </template>

          <template v-else-if="obj.field_type === 'DecimalField'">
            <b-row class="mt-4 mb-4">
              <b-col
                cols="12"
                sm="6"
                md="6"
                lg="6"
                xl="6"
                class="text-left text-sm-right text-md-right text-lg-right text-xl-right pt-4"
              >
                <label
                  class="shift_input_label vue_property_display_label"
                >
                  <span v-html="obj.label" class="font-weight-bold"></span>
                </label>
              </b-col>
              <b-col cols="10" sm="3" md="3" lg="3" xl="3" class="input_icon_fixed_width">
                <input
                  type="text"
                  class="pl-22"
                  v-model="invoice_amount"
                />
                <i class="fas fa-paw"></i>
                <i class="fas fa-pound-sign"></i>
              </b-col>
              <b-col cols="2" class="spark-desktop-spacer"></b-col>
              <b-col cols="2" sm="1" md="1" lg="1" xl="1">
                <i
                  class="fas fa-exclamation-circle field_error_icon pt-2"
                  v-if="amountError"
                ></i>
              </b-col>
              <b-col cols="6" class="spark-desktop-spacer"></b-col>
              <b-col
                cols="12"
                sm="6"
                md="6"
                lg="6"
                xl="6"
                class="vue_field_error pt-2"
                v-if="amountError"
              >
                <ul>
                  <li>You must enter a valid amount<br/>&pound;0.00</li>
                </ul>
              </b-col>
            </b-row>
          </template>

        </div>
      </div>
      <b-row>
        <b-col>
          <b-button
            size="md"
            v-html="'Cancel'"
            class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
            @click="closeExtraInfoModal"
          ></b-button>
        </b-col>
        <b-col>
          <b-button
            v-if="modalCanSubmit"
            size="md"
            v-html="'OK, Upload'"
            class="mt-5 rounded green-bg green-border spark-variant-text-light font-weight-bold float-right"
            @click="uploadDocument"
          ></b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>

import { UPLOAD_CLAIM_DOCUMENT, SET_CLAIM_DOCUMENT_DETAIL_PK } from "@/core/services/vars.service";

export default {
  name: "Portal-Document",
  short_name: "Document",
  props: {
    item: {
      type: Object,
      required: true
    },
    prefix: {
      type: String,
      required: true
    },
    parent: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      file: null,
      input: null,
      ga_used: false,
      invoice_amount: "",
    };
  },
  methods: {
    postData() {
      const form_data = new FormData();
      //formData.append("file", file);
      if (!this.file) return form_data;
      this.file.forEach((f, index) => {
        form_data.append(this.item.field_name + "[" + index + "]", f);
        /*if (this.modalData.length > 0) {
          let extra_data = {};
          this.modalData.forEach(extra => {
            extra_data[extra.name] = this[extra.name];
          });
          form_data.append(this.item.field_name + "[" + index + "]" + "_meta", JSON.stringify(extra_data));
        }*/
      });
      //console.log(form_data);
      return form_data;
    },
    uploadDocument() {
      let params = {
        pk: this.parent,
        filedata: this.postData()
      };
      this.$spark.$dispatch(SET_CLAIM_DOCUMENT_DETAIL_PK, this.item.id).then(() => {
        this.$spark.$api(UPLOAD_CLAIM_DOCUMENT, params);
      });
    },
    startUploadProcess() {
      this.uploadDocument();
      /*if (this.item.extra.length === 0) {
        this.uploadDocument();
      } else {
        this.uploadDocument();
        //this.openExtraInfoModal();
      }*/
    },
    openExtraInfoModal() {
      this.$refs["spark-document-extra-modal"].show();
    },
    closeExtraInfoModal() {
      this.$refs["spark-document-extra-modal"].hide();
    }
  },
  computed: {
    showButton() {
      if (this.file && this.file.length > 0) {
        return true;
      }
      return false;
    },
    buttonTitle() {
      if (this.file) {
        if (this.file.length === 1) {
          return "Upload Document";
        } else if (this.file.length > 1) {
          return "Upload Documents";
        }
      }
      return false;
    },
    modalData() {
      return this.item.extra;
    },
    modalCanSubmit() {
      return !this.amountError;
    },
    amountError() {
      return !/^[0-9]+.[0-9]{2}$/.test(this.invoice_amount);
    }
  }
};
</script>

<style>
.spark-file-multiple,
.spark-file-multiple label,
.spark-file-multiple label::after,
.spark-file-multiple input {
  height: 100px;
}
</style>

<template>
  <div>{{ $options.name }}</div>
</template>

<script>

export default {
  name: "Portal-Complaint-Manage",
  short_name: "Manage Complaint",
  path: "manage/:id?",
  //pk: SET_COMPLAINT_DETAIL,
  mounted() {
   this.$spark.$breadcrumbs(this.$options);
  }
};
</script>

<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "Layout-Portal-Claim-Customer",
  short_name: "Customer Claim",
  path: "customer"
};
</script>

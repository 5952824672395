<template>
  <div class="mb-10" v-if="item">
    <b-card
      class="mb-3 rounded-card spark-variant-primary-bg-light spark-variant-primary-border spark-variant-text-dark spark-block-content"
      header-class="card-header rounded-card-header fw-400 py-4 px-4 green-bottom-border spark-variant-primary-bg"
    >
      <template #header>
        <b-row>
          <b-col>
            <div class="spark-block-title">
              <i
                class="far fa-address-book icon-extra-large spark-variant-secondary-text  mr-2"
              ></i>
              {{ $options.short_name }}
            </div>
          </b-col>
        </b-row>
      </template>

      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">Email address</b-col>
        <b-col class="col-6"><i class="fas fa-paw"></i>{{ item.email }}</b-col>
      </b-row>
      <b-row class="mb-5" v-if="item.telephone">
        <b-col class="col-6 font-weight-bold text-right"
          >Home phone number</b-col
        >
        <b-col class="col-6"
          ><i class="fas fa-paw"></i>{{ item.telephone }}</b-col
        >
      </b-row>
      <b-row class="mb-5" v-if="item.mobile">
        <b-col class="col-6 font-weight-bold text-right"
          >Mobile phone number</b-col
        >
        <b-col class="col-6"><i class="fas fa-paw"></i>{{ item.mobile }}</b-col>
      </b-row>
      <b-row class="mb-5" v-if="item.work">
        <b-col class="col-6 font-weight-bold text-right"
          >Work phone number</b-col
        >
        <b-col class="col-6"><i class="fas fa-paw"></i>{{ item.work }}</b-col>
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">Address</b-col>
        <b-col class="col-6">
          <b-row>
            <b-col class="col-2">
              <i class="fas fa-paw"></i>
            </b-col>
            <b-col class="col-10">
              <ul style="display: inline-block;" v-if="item.address_line_1">
                <li
                  class="pb-4"
                  v-for="(line, index) in item.address_line_1.split('\n')"
                  :key="'line_' + index"
                >
                  <span
                    v-for="(word, index) in line.split(' ')"
                    :key="'word_' + index"
                    >{{ word | capitalize }}
                  </span>
                </li>
              </ul>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">Postcode</b-col>
        <b-col class="col-6"
          ><i class="fas fa-paw"></i>{{ item.postcode }}</b-col
        >
      </b-row>
      <b-button
        :disabled="true"
        size="md"
        class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
        >Update Details</b-button
      >
    </b-card>
  </div>
</template>

<script>
export default {
  name: "Portal-Account-Contact-Details",
  short_name: "Contact Details",
  props: {
    item: {
      type: Object,
      required: true
    }
  }
};
</script>

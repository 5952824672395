<template>
  <div>
    <p>{{ $options.name }}</p>
    <router-view />
  </div>
</template>

<script>

export default {
  name: "Admin-Login",
  path: "login",
  mounted() {
   this.$spark.$breadcrumbs(this.$options);
  }
};
</script>

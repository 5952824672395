<template>
  <div class="mb-10">
    <b-card
      class="mb-3 rounded-card spark-variant-primary-bg-light spark-variant-primary-border spark-variant-text-dark spark-block-content"
      header-class="card-header rounded-card-header fw-400 py-4 px-4 green-bottom-border spark-variant-primary-bg"
    >
      <template #header>
        <b-row>
          <b-col>
            <div class="spark-block-title">
              <i
                class="far fa-file-alt icon-extra-large spark-variant-secondary-text mr-4"
              ></i>
              <span v-if="recent">Newest </span>{{ $options.short_name }}:
              {{ item.reference }}
            </div>
          </b-col>
        </b-row>
      </template>

      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">Action required</b-col>
        <b-col class="col-6">
          <i class="fas fa-paw"></i>
          <i
            v-if="item.action_required !== false"
            class="fas fa-check-circle red icon-large"
          ></i>
          <i v-else class="fas fa-times-circle green icon-large"></i>
          {{ item.action_required !== false ? "Yes" : "No" }}
          <div>
            <b-button
              v-if="item.action_required !== false"
              size="md"
              :disabled="true"
              class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
              
              >Fix Problems</b-button
            ><!--@click="$spark.$fix('policy', item.id)"-->
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right"
          >Policy reference number</b-col
        >
        <b-col class="col-6"
          ><i class="fas fa-paw"></i>{{ item.reference }}</b-col
        >
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">Start date</b-col>
        <b-col class="col-6"
          ><i class="fas fa-paw"></i
          >{{ moment(item.inception_date).format("Do MMM YYYY") }}</b-col
        >
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">Renewal date</b-col>
        <b-col class="col-6"
          ><i class="fas fa-paw"></i>
          {{ moment(item.renewal_date).format("Do MMM YYYY") }}
          <div v-if="linkParamsPolicyRenewal" class="mt-5">
            <i class="fas fa-times-circle red icon-large"></i>Renewal due soon!
            <div>
              <!--<b-button
                size="md"
                class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
                @click="$spark.$nav(linkParamsPolicyRenewal.manage_name, linkParamsPolicyRenewal.manage_params)"
                >Confirm Renewal</b-button
              >-->
              <b-button
                size="md"
                :disabled="true"
                class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
                >Confirm Renewal</b-button
              >
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">Policy type</b-col>
        <b-col class="col-6"
          ><i class="fas fa-paw"></i>{{ item.product }}</b-col
        >
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">Policy status</b-col>
        <b-col class="col-6"><i class="fas fa-paw"></i>{{ item.status }}</b-col>
      </b-row>
      <b-row class="mb-5" v-if="item.monthly == true">
        <b-col class="col-6 font-weight-bold text-right"
          >Payments upto date</b-col
        >
        <b-col class="col-6">
          <i class="fas fa-paw"></i>
          <i
            v-if="linkParamsPolicyPayment"
            class="fas fa-times-circle red icon-large"
          ></i>
          <i v-else class="fas fa-check-circle green icon-large"></i>
          {{ linkParamsPolicyPayment ? "No" : "Yes" }}
          <div>
            <!--<b-button
              v-if="linkParamsPolicyPayment"
              size="md"
              class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
              @click="$spark.$nav(linkParamsPolicyPayment.manage_name, linkParamsPolicyPayment.manage_params)"
              >Make Payment</b-button
            >-->
            <b-button
              v-if="linkParamsPolicyPayment"
              :disabled="true"
              size="md"
              class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
              >Make Payment</b-button
            >
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">Number of pets</b-col>
        <b-col class="col-6">
          <i class="fas fa-paw"></i>
          <span class="fa-stack">
            <i
              class="fas fa-circle fa-stack-1x icon-large spark-variant-primary-text"
            ></i>
            <i
              class="fas fa-hashtag fa-stack-1x ml-3 spark-variant-text-light"
              style="font-size: 0.8em;"
            ></i>
          </span>
          <span class="ml-13">{{ item.number_of_pets }}</span>
        </b-col>
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right"
          >Number of claims submitted</b-col
        >
        <b-col class="col-6">
          <i class="fas fa-paw"></i>
          <span class="fa-stack">
            <i
              class="fas fa-circle fa-stack-1x icon-large spark-variant-primary-text"
            ></i>
            <i
              class="fas fa-hashtag fa-stack-1x ml-3 spark-variant-text-light"
              style="font-size: 0.8em;"
            ></i>
          </span>
          <span class="ml-13">{{ item.claims.length }}</span>
        </b-col>
      </b-row>
      <b-button
        size="md"
        class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
        @click="$spark.$nav(manage_name, manage_params)"
        >Manage Policy</b-button
      >
    </b-card>
  </div>
</template>

<script>
import {
  PAGE_NAME_PORTAL_POLICY_MANAGE,
  SET_POLICY_DETAIL_PK,
  PROBLEM_PAYMENT_ISSUE,
  PROBLEM_RENEWAL_DUE
} from "@/core/services/vars.service";

export default {
  name: "Portal-Policy-Summary",
  short_name: "Policy Summary",
  props: {
    item: {
      type: Object,
      required: true
    },
    recent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      manage_name: PAGE_NAME_PORTAL_POLICY_MANAGE,
      manage_params: {
        pk_name: SET_POLICY_DETAIL_PK,
        pk: this.item.id
      }
    };
  },
  computed: {
    linkParamsPolicyPayment() {
      if (this.item.action_required.hasOwnProperty(PROBLEM_PAYMENT_ISSUE)) {
        return {
          title: "Make Payment",
          manage_name: null,
          manage_params: {}
        };
      }
      return null;
    },
    linkParamsPolicyRenewal() {
      if (this.item.action_required.hasOwnProperty(PROBLEM_RENEWAL_DUE)) {
        return {
          title: "Confirm Renewal",
          manage_name: null,
          manage_params: {}
        };
      }
      return null;
    }
  }
};
</script>

import $spark from "@/core/services/spark.service";
import router from "@/router";
import {
  CLIENT_CONTACT_DETAILS,
  CLAIM_CUSTOMER_DISCLAIMER,
  CLAIM_VET_DISCLAIMER,
  SET_HTML_FIELDS,
  SET_CLIENT_REMOTE_STYLES,
  CLIENT_REMOTE_RESOURCES,
  SET_CLIENT_REMOTE_RESOURCES,
  CLIENT_REMOTE_STYLES,
  CLIENT_FOOTER_TOP,
  CLIENT_FOOTER_BOTTOM,
  ABOUT_PAGES,
  PORTAL_URL_PATH,
  DIV,
  COLOR,
  DELETE_STORAGE_ITEM,

  CLIENT_CONFIG,
} from "@/core/services/vars.service";

import styled from "vue-styled-components";

import SparkError404 from "@/view/spark/SparkError404";
import AboutCustom from "@/view/spark/portal/public/about/Custom";
import Layout from "@/view/spark_layout/Layout";
import PortalLayout from "@/view/spark_layout/portal/wireframe/Layout";
import PortalLayoutAbout from "@/view/spark_layout/portal/About";

const state = {
  client_contact_details: "",
  client_remote_styles: {},
  client_remote_resources: {},
  customer_claim_disclaimer: {},
  vet_claim_disclaimer: {},
  client_footer_top: "",
  client_footer_bottom: "",
  about_pages: [],
  templates: {}
};

const getters = {
  [CLIENT_CONTACT_DETAILS]: state => {
    if (state && state.client_contact_details) {
      return state.client_contact_details;
    }
  },
  [CLAIM_CUSTOMER_DISCLAIMER]: state => {
    if (state && state.customer_claim_disclaimer) {
      return state.customer_claim_disclaimer;
    }
  },
  [CLAIM_VET_DISCLAIMER]: state => {
    if (state && state.vet_claim_disclaimer) {
      return state.vet_claim_disclaimer;
    }
  },
  [CLIENT_REMOTE_STYLES]: state => payload => {
    if (
      state &&
      state.client_remote_styles &&
      payload in state.client_remote_styles
    ) {
      return state.client_remote_styles[payload];
    }
  },
  [CLIENT_REMOTE_RESOURCES]: state => {
    if (state && state.client_remote_resources) {
      return state.client_remote_resources;
    }
  },
  [CLIENT_FOOTER_TOP]: state => {
    if (state && state.client_footer_top) {
      return state.client_footer_top;
    }
  },
  [CLIENT_FOOTER_BOTTOM]: state => {
    if (state && state.client_footer_bottom) {
      return state.client_footer_bottom;
    }
  },
  [ABOUT_PAGES]: state => {
    if (state && state.about_pages) {
      return state.about_pages;
    }
  }
};

const actions = {
  [DELETE_STORAGE_ITEM]: (context, payload) => {
    $spark.$storage.delete(payload);
  }
};

const mutations = {
  [SET_HTML_FIELDS](state, payload) {
    let data = payload.html;
    data.forEach(field => {
      if (field.about !== null) {
        state.about_pages.push(field);
      } else {
        state[field.name] = field.html;
      }
    });
    if (state.about_pages.length > 0) {
      var SparkMainLayout = {
        path: "/", // root path
        redirect: $spark.$config.redirect, // this is where the root path gets sent based on config (portal/admin login / public quote and buy etc)
        component: Layout,
        children: []
      };

      var SparkPortalRoot = {
        path: PORTAL_URL_PATH,
        redirect:
          "/" + PORTAL_URL_PATH + "/" + $spark.$config.views.portal.login.path,
        component: PortalLayout,
        children: [
          {
            path: $spark.$config.views.portal.login.path,
            name: $spark.$config.views.portal.login.name + "-About",
            component: $spark.$config.views.portal.login
          }
        ]
      };

      var SparkPortalAbout = {
        path: PortalLayoutAbout.path,
        redirect: SparkError404.path,
        component: PortalLayoutAbout,
        children: []
      };

      state.about_pages.forEach(page => {
        var SparkCustomAboutPage = {
          path: `/portal/about/${page.about.path}`,
          component: AboutCustom,
          name: `${page.about.title}`,
          props: { title: page.about.title, content: page.html }
        };
        SparkPortalAbout.children.push(SparkCustomAboutPage);
      });

      SparkPortalRoot.children.push(SparkPortalAbout);
      SparkMainLayout.children.push(SparkPortalRoot);

      router.addRoute(SparkMainLayout);
    }
  },
  [SET_CLIENT_REMOTE_STYLES](state, payload) {
    let data = payload.css;
    data.forEach(field => {
      if (field.element === DIV) {
        state.client_remote_styles[field.class_name] = styled.div`
          ${field.css}
        `;
      } else if (field.element === COLOR) {
        state.client_remote_styles[field.class_name] = field.css;
      }
    });
  },
  [SET_CLIENT_REMOTE_RESOURCES](state, payload) {
    let data = payload.resources;
    state.client_remote_resources = data;
  }
};



const dynamic = [
  {
    auth: false,
    loader: false,
    name: CLIENT_CONFIG,
    url: {
      path: $spark.$urls.$portal.spark.config
    },
    commit: false,
    commit_on_success: [SET_HTML_FIELDS, SET_CLIENT_REMOTE_STYLES, SET_CLIENT_REMOTE_RESOURCES]
  }
];

const _store = $spark.$inject(dynamic, {
  state,
  actions,
  mutations,
  getters
});
export default _store;
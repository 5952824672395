<template>
  <router-view></router-view>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~animate.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";

// Main demo style scss
@import "assets/sass/style.vue";

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/
</style>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";
//import { LOAD_AUTH } from "@/core/services/vars.service";

export default {
  name: "MetronicVue",
  data() {
    return {
      menu: null,
      $spark: this.$spark,
    };
  },
  mounted() {
    this.$nextTick(() => {
      // lets tell our spark helper that vue is now ready to access its store and stuff
      this.$spark.$init();
      // we prototyped this.$spark, so its accessible anywheree vue is
      // now we add this beauty, to throw the active class at vue, and it becomes reactive
      // we still use this.$spark within components, but the getters are reactive !
      //this.$spark.$dispatch(LOAD_AUTH).then(() => {
        this.$set(this, '$spark', this.$spark);
      //});

      

      /*this.$watch("$spark.$secure", function(secure) {
        if (!secure) {
          console.error("AUTO LOGOUT")
          this.$spark.$portalLogin();
        }
      });*/

      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static json (@/core/config/layout.config.json)
       */
      this.$spark.$dispatch(OVERRIDE_LAYOUT_CONFIG);
    });
  }
};
</script>

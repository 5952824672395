<template>
  <div class="mb-10">
    <b-card
      class="mb-3 rounded-card spark-variant-primary-bg-light spark-variant-primary-border spark-variant-text-dark spark-block-content"
      header-class="card-header rounded-card-header fw-400 py-4 px-4 green-bottom-border spark-variant-primary-bg"
    >
      <template #header>
        <b-row>
          <b-col>
            <div class="spark-block-title">
              <i
                v-if="prefix === 'Claim'"
                class="fas fa-cloud-upload-alt icon-extra-large spark-variant-secondary-text mr-4"
              ></i>
              {{ prefix }} {{ $options.short_name }}
            </div>
          </b-col>
        </b-row>
      </template>

      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">Action required</b-col>
        <b-col class="col-6">
          <i class="fas fa-paw"></i>
          <i
            v-if="actionRequired"
            class="fas fa-check-circle red icon-large"
          ></i>
          <i v-else class="fas fa-times-circle green icon-large"></i>
          {{ actionRequired === true ? "Yes" : "No" }}
          <div>
            <b-button
              v-if="actionRequired === true"
              size="md"
              class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
              @click="$spark.$fix('claim')"
              >Fix Problems</b-button
            >
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right"
          >Required documents</b-col
        >
        <b-col class="col-6">
          <i class="fas fa-paw"></i>
          <span class="fa-stack">
            <i
              class="fas fa-circle fa-stack-1x icon-large spark-variant-primary-text"
            ></i>
            <i
              class="fas fa-hashtag fa-stack-1x ml-3 spark-variant-text-light"
              style="font-size: 0.8em;"
            ></i>
          </span>
          <span class="ml-13">{{ mandatory }}</span>
        </b-col>
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right"
          >Optional documents</b-col
        >
        <b-col class="col-6">
          <i class="fas fa-paw"></i>
          <span class="fa-stack">
            <i
              class="fas fa-circle fa-stack-1x icon-large spark-variant-primary-text"
            ></i>
            <i
              class="fas fa-hashtag fa-stack-1x ml-3 spark-variant-text-light"
              style="font-size: 0.8em;"
            ></i>
          </span>
          <span class="ml-13">{{ optional }}</span>
        </b-col>
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right"
          >Number of documents uploaded</b-col
        >
        <b-col class="col-6">
          <i class="fas fa-paw"></i>
          <span class="fa-stack">
            <i
              class="fas fa-circle fa-stack-1x icon-large spark-variant-primary-text"
            ></i>
            <i
              class="fas fa-hashtag fa-stack-1x ml-3 spark-variant-text-light"
              style="font-size: 0.8em;"
            ></i>
          </span>
          <span class="ml-13">{{ uploadedCount }}</span>
        </b-col>
      </b-row>

      <b-button
        v-if="button"
        size="md"
        class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
        @click="$spark.$nav(params.list.manage_name)"
        >Manage Documents</b-button
      >
      <div class="w-100"></div>
      <b-button
        v-if="button"
        size="md"
        class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
        @click="$spark.$nav(linkParamsClaimUploadDocs.manage_name, linkParamsClaimUploadDocs.manage_params)"
        v-html="linkParamsClaimUploadDocs.title"
        ></b-button
      >
    </b-card>
  </div>
</template>

<script>
import {
  PAGE_NAME_PORTAL_DOCUMENT_CLAIM_LIST,
  PAGE_NAME_PORTAL_FIX_PROBLEMS,
  SET_CLAIM_DETAIL_PK,
  PAGE_NAME_PORTAL_DOCUMENT_CLAIM_UPLOAD
} from "@/core/services/vars.service";

export default {
  name: "Portal-Document",
  short_name: "Documents Summary",
  props: {
    parent: {
      type: Number,
      required: true
    },
    item: {
      type: Array,
      required: true
    },
    prefix: {
      type: String,
      required: true
    },
    button: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      link_params: {
        claim: {
          list: {
            manage_name: PAGE_NAME_PORTAL_DOCUMENT_CLAIM_LIST
          }
        },
        problems: {
          manage_name: PAGE_NAME_PORTAL_FIX_PROBLEMS
        }
      }
    };
  },
  computed: {
    params() {
      if (this.prefix === "Claim") {
        return this.link_params.claim;
      }
      return null;
    },
    actionRequired() {
      let required = false;
      this.item.forEach(item => {
        if (item.uploaded === false && item.required === true) {
          required = true;
        }
      });
      return required;
    },
    uploadedCount() {
      let required = 0;
      this.item.forEach(item => {
        if (item.uploaded === true) {
          required += 1;
        }
      });
      return required;
    },
    mandatory() {
      let required = 0;
      this.item.forEach(item => {
        if (item.required === true) {
          required += 1;
        }
      });
      return required;
    },
    optional() {
      let required = 0;
      this.item.forEach(item => {
        if (item.required === false) {
          required += 1;
        }
      });
      return required;
    },
    linkParamsClaimUploadDocs() {
      return {
        title: "Upload Claim Documents",
        manage_name: PAGE_NAME_PORTAL_DOCUMENT_CLAIM_UPLOAD,
        manage_params: {
          pk_name: SET_CLAIM_DETAIL_PK,
          pk: this.parent
        }
      };
    }
  }
};
</script>

import $spark from "@/core/services/spark.service";
import {
  PROBLEMS,
  FIX_PROBLEMS,
  CLEAR_FIX_PROBLEMS,
  PAGE_NAME_PORTAL_FIX_PROBLEMS
} from "@/core/services/vars.service";

const state = {
  jump_to: null
};

const getters = {};

const actions = {
  [FIX_PROBLEMS](context, payload) {
    context.commit(FIX_PROBLEMS, payload);
    $spark.$nav({ name: PAGE_NAME_PORTAL_FIX_PROBLEMS });
  },
  [CLEAR_FIX_PROBLEMS](context) {
    context.commit(CLEAR_FIX_PROBLEMS);
  }
};

const mutations = {
  [FIX_PROBLEMS](state, data) {
    state.jump_to = data;
  },
  [CLEAR_FIX_PROBLEMS](state) {
    state.jump_to = null;
  }
};


const dynamic = [
  {
    name: PROBLEMS,
    url: {
      path: $spark.$urls.$portal.spark.problems
    }
  }
];

const _store = $spark.$inject(dynamic, {
  state,
  actions,
  mutations,
  getters
});
export default _store;

<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import {
  SET_CORRESPONDENCE_DETAIL_PK,
  CORRESPONDENCE_LIST,
  CORRESPONDENCE_DETAIL,
  CORRESPONDENCE_CONTACT_REFERENCES,
  PAGE_NAME_PORTAL_CORRESPONDENCE_MANAGE,
  PAGE_NAME_PORTAL_CORRESPONDENCE_LIST,
  PAGE_NAME_PORTAL_CORRESPONDENCE_CONTACT_US
} from "@/core/services/vars.service";

export default {
  name: "Layout-Portal-Correspondence",
  path: "correspondence",
  methods: {
    loadData() {
      if (this.$route.name === PAGE_NAME_PORTAL_CORRESPONDENCE_LIST) {
        this.$spark.$api(CORRESPONDENCE_LIST).catch(() => {});
      } else if (this.$route.name === PAGE_NAME_PORTAL_CORRESPONDENCE_MANAGE) {
        this.$spark.$api(CORRESPONDENCE_DETAIL).catch(() => {});
      } else if (
        this.$route.name === PAGE_NAME_PORTAL_CORRESPONDENCE_CONTACT_US
      ) {
        this.$spark.$api(CORRESPONDENCE_CONTACT_REFERENCES).catch(() => {});
      }
    }
  },
  watch: {
    $route: function() {
      this.loadData();
    }
  },
  mounted() {
    this.$spark.$loading(CORRESPONDENCE_DETAIL, true);
    this.$nextTick(() => {
      let correspondence_pk = this.$spark.$storage.get(SET_CORRESPONDENCE_DETAIL_PK);
      if (correspondence_pk) {
        this.$spark.$dispatch(SET_CORRESPONDENCE_DETAIL_PK, correspondence_pk);
      }
      setTimeout(() => {
        this.$spark.$loading(CORRESPONDENCE_DETAIL, false).then(() => {
          this.loadData();
        }); 
      },300);
    });
  }
};
</script>

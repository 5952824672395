<template>
  <div>{{ $options.name }}</div>
</template>

<script>

export default {
  name: "Admin-System-Commission-List",
  path: "list",
  mounted() {
   this.$spark.$breadcrumbs(this.$options);
  }
};
</script>

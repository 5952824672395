import $spark from "@/core/services/spark.service";
import {
  POLICY_LIST,
  POLICY_DETAIL,
  POLICY_DETAIL_PK,
  SET_POLICY_DETAIL_PK
} from "@/core/services/vars.service";

const state = {
  detail_pk: null
};

const getters = {
  [POLICY_DETAIL_PK]: state => {
    return state.detail_pk;
  }
};

const actions = {
  [SET_POLICY_DETAIL_PK](context, payload) {
    $spark.$storage.set(SET_POLICY_DETAIL_PK, payload);
    context.commit(SET_POLICY_DETAIL_PK, payload);
    //return $spark.$api(POLICY_DETAIL);
  }
};

const mutations = {
  [SET_POLICY_DETAIL_PK](state, data) {
    state.detail_pk = data;
  }
};


const dynamic = [
  {
    name: POLICY_LIST,
    paginated: true,
    url: {
      path: $spark.$urls.$portal.policy.list
    }
  },
  {
    name: POLICY_DETAIL,
    pk: true,
    url: {
      path: $spark.$urls.$portal.policy.detail,
      replace: {
        "<POLICY_PK>": POLICY_DETAIL_PK
      }
    }
  }
];

const _store = $spark.$inject(dynamic, {
  state,
  actions,
  mutations,
  getters
});
export default _store;

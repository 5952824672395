import $api from "@/core/services/api.service";
import $inject from "@/core/services/store/spark.module";
import $storage from "@/core/services/browser-storage.service";
import store from "@/core/services/store/index";
import router from "@/router";
//import { mapGetters } from "vuex";

import ClientConfig from "@/core/config/deployment";

import {
  PORTAL_URLS,
  IS_AUTHENTICATED,
  CURRENT_USER,
  SET_BREADCRUMB,
  SET_CONFIRM,
  SET_ALERT,
  RESET_ALERT,
  ALERT_SUCCESS_TIME,
  ALERT_ERROR_TIME,
  FIX_PROBLEMS,
  NAV_BLOCKED,
  CONFIRM_COMPONENT,
  SET_SPARK_LOADING,
  LOAD
} from "@/core/services/vars.service";

const $spark = class $spark {
  $config = ClientConfig;
  $urls = {
    $portal: PORTAL_URLS,
    $admin: {}
  }

  $api = $api;
  $inject = $inject;
  $storage = $storage;

  
  $secure = () => {};
  $user = () => {};

  $vue = null;

  constructor(){
    this.$storage.$init();
  }

  $init = () => {
    this.$secure = () => {
      return this.$get(IS_AUTHENTICATED);
    }
    this.$user = () => {
      return this.$get(CURRENT_USER);
    }
  }

  $dispatch = (action, payload) => {
    return store.dispatch(action, payload);
  }

  $get = (name) => {
    if (!store.getters) return null;
    if (!(name in store.getters)) return null;
    return store.getters[name];
  }

  $loading = (name, bool) => {
    return this.$dispatch(SET_SPARK_LOADING, { fn: LOAD + name, status: bool });
  }

  $breadcrumbs = (obj) => {
    this.$dispatch(SET_BREADCRUMB, {
      string: obj.name,
      title: (obj.short_name ? obj.short_name : obj.name)
    });
  }
  $success = (message) => {
    this.$alert(message);
  }
  $error = (message) => {
    this.$alert(message, false);
  }
  $alert = (message, success=true) => {
    if (message === false){
      this.$dispatch(RESET_ALERT);
    } else {
      this.$dispatch(SET_ALERT, {
        message: message,
        variant: (success ? "success" : "danger"),
        timer: (success ? ALERT_SUCCESS_TIME : ALERT_ERROR_TIME)
      });
    }
  }

  $root = () => {
    this.$nav({ name: "root" });
  }
  $portalLogin = ({ success=null, error=null }={}) => {
    console.log("$portalLogin", this, success, error);
    if (success) {
      this.$success(success);
    }
    if (error) {
      this.$error(error);
    }
    this.$nav(this.$config.views.portal.login.name);
  }
  $dashboard = ({ success=null, error=null }={}) => { 
    console.log("$dashboard", this, success, error);
    if (success) {
      this.$success(success);
    }
    if (error) {
      this.$error(error);
    }
    this.$nav(this.$config.views.portal.authenticated.dashboard.name);
  }

  _navPush = (to) => {
    return new Promise(resolve => {
      console.log(router.currentRoute, to)
      if (router.currentRoute.name === to.name) {
        console.log("Routes match, no push");
        resolve(to);
      } else {
        router.push(to).then(() => {
          resolve(to);
        }).catch((e) => {
          console.error("ROUTER", e)
          resolve(to);
        })
      }
    });
  }

  $nav = (_to, params = null, after_nav = null) => {
    let to = {name: null};
    if (!_to) {
      return false;
    }
    if (_to.hasOwnProperty("path") || _to.hasOwnProperty("name")) {
      to = _to;
    } else {
      to.name = _to;
    }
    if (params === null) {
      this._navPush(to).then(() => {
        if (after_nav) {
          if (after_nav.args) {
            after_nav.fn(...after_nav.args);
          } else {
            after_nav.fn();
          }
        }
      });
    } else {
      this.$dispatch(params.pk_name, params.pk).then(() => {
        //setTimeout(() => {
          this._navPush(to).then(() => {
            if (after_nav) {
              if (after_nav.args) {
                after_nav.fn(...after_nav.args);
              } else {
                after_nav.fn();
              }
            }
          });
        //},300);
      });
    }
  }

  $fix = (section, pk = null, type = null) => {
    this.$dispatch(FIX_PROBLEMS, { section, pk, type });
  }

  _navBlockHandler = (event) => {
    event.preventDefault();
    event.returnValue = "";
  }
  _showNavBlockedConfirm = (confirm_data, resolve, reject = null) => {
    if (store.getters[NAV_BLOCKED]) {
      this.$dispatch(SET_CONFIRM, confirm_data).then(() => {
        return store.getters[CONFIRM_COMPONENT].createPromise()
          .then(() => {
            if (resolve.args) {
              resolve.fn(...resolve.args);
            } else {
              resolve.fn();
            }
          })
          .catch(() => {
            if (reject) {
              if (reject.args) {
                reject.fn(...reject.args);
              } else {
                reject.fn();
              }
            }
          });
      });
    } else {
      if (resolve.args) {
        return resolve.fn(...resolve.args);
      } else {
        return resolve.fn();
      }
    }
  }
}

const spark = new $spark();

export default spark;
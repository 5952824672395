<template>
  <b-row class="mt-4 mb-4">
    <b-col cols="1" class="spark-desktop-spacer"></b-col>
    <b-col
      cols="11"
      sm="10"
      md="10"
      lg="10"
      xl="10"
      class="p-4 m-4 rounded row spark-variant-primary-bg justify-content-center"
    >
      <b-row class="w-100 justify-content-center">
        <b-col cols="2">
          <i
            class="fas fa-dog paragraph_warning spark-variant-secondary-text"
          ></i>
        </b-col>
        <b-col cols="8" class="spark-variant-text-light text-center">
          <div v-html="label" class="font-weight-bold h3 pt-4"></div>
        </b-col>
        <b-col cols="2" class="text-right">
          <i
            class="fas fa-cat paragraph_warning spark-variant-secondary-text"
          ></i>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="2" class="spark-desktop-spacer"></b-col>
        <b-col
          cols="12"
          sm="8"
          md="8"
          lg="8"
          xl="8"
          class="justify-content-center"
        >
          <div
            v-html="sub_label"
            class="h6 pt-2 text-center spark-variant-text-light"
            v-if="sub_label"
          ></div>
        </b-col>
        <b-col cols="2" class="spark-desktop-spacer"></b-col>
      </b-row>
    </b-col>
    <b-col cols="1" class="spark-desktop-spacer"></b-col>
  </b-row>
</template>

<script>
export default {
  name: "spark-paragraph",
  props: {
    label: {
      type: String,
      required: true
    },
    sub_label: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      value: ""
    };
  },
  computed: {
    Value() {
      return this.value;
    }
  }
};
</script>

<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import {
  COMPLAINT_LIST,
  COMPLAINT_DETAIL,
  SET_COMPLAINT_DETAIL_PK
} from "@/core/services/vars.service";
export default {
  name: "Layout-Portal-Complaint",
  path: "complaint",
  methods: {
    loadData() {
      let url_parts = this.$route.path.split("/");
      let action = url_parts[url_parts.length - 1];
      if (action === "list") {
        this.$spark.$api(COMPLAINT_LIST).catch(() => {});
      } else if (action === "manage") {
        this.$spark.$api(COMPLAINT_DETAIL).catch(() => {});
      }
    }
  },
  watch: {
    $route: function() {
      this.loadData();
    }
  },
  mounted() {
    this.$spark.$loading(COMPLAINT_DETAIL, true);
    this.$nextTick(() => {
      let complaint_pk = this.$spark.$storage.get(SET_COMPLAINT_DETAIL_PK);
      if (complaint_pk) {
        this.$spark.$dispatch(SET_COMPLAINT_DETAIL_PK, complaint_pk);
      }
      setTimeout(() => {
        this.$spark.$loading(COMPLAINT_DETAIL, false).then(() => {
          this.loadData();
        }); 
      },300);
    });
  }
};
</script>

<template>
  <div class="mt-4 mb-4 row">
    <b-col
      cols="12"
      sm="6"
      md="6"
      lg="6"
      xl="6"
      class="text-left text-sm-right text-md-right text-lg-right text-xl-right"
    >
      <label
        :for="'question_' + question_name"
        class="vue_property_display_label font-weight-bold"
      >
        <span v-html="label"></span>
        <span
          v-html="'<br/>(' + sub_label + ')'"
          class="sub_label"
          v-if="sub_label"
        ></span>
      </label>
    </b-col>
    <b-col cols="12" sm="6" md="6" lg="6" xl="6">
      <ul class="fa-ul">
        <li>
          <span class="fa-li">
            <i class="fas fa-paw"></i>
          </span>
          <template v-if="field_type === 'MultipleChoiceField'">
            <div
              class="mb-5"
              v-for="(choice, index) in choices"
              :key="'multi_' + index"
            >
              <i
                v-if="value.includes(choice.value)"
                class="fas fa-check-circle green icon-large"
              ></i>
              <i v-else class="fas fa-times-circle red icon-large"></i>
              <span v-html="choice.label"></span>
            </div>
          </template>
          <span v-else v-html="formatForDisplay(value, field_type)"></span>
        </li>
      </ul>
    </b-col>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "spark-display-property",
  props: {
    label: {
      type: String,
      required: true
    },
    sub_label: {
      type: String,
      default: null
    },
    question_name: {
      type: String,
      required: true
    },
    value: {
      required: true
    },
    field_type: {
      type: String,
      required: true
    },
    choices: {
      type: Array,
      required: false
    }
  },
  computed: {
    Value() {
      return this.value;
    }
  },
  methods: {
    formatForDisplay(value, field_type) {
      if (field_type === "BooleanField") {
        return value === true ? "Yes" : "No";
      }
      if (field_type === "DateField") {
        return moment(String(value)).format("Do MMM YYYY");
      }
      if (field_type === "DateTimeField") {
        return moment(String(value)).format("h:mm:ss a, Do MMM YYYY");
      }
      if (field_type === "TimeField") {
        return moment(String(value)).format("h:mm:ss a");
      }
      if (field_type === "DecimalField") {
        return "£" + value.toFixed(2);
      }
      if (Array.isArray(value)) {
        //value.sort()
        let str = "<ul>";
        value.forEach(val => {
          str += "<li>" + val + "</li>";
        });
        str += "</ul>";
        value = str;
      }
      return value;
    }
  }
};
</script>

<template>
  <div v-if="claimForm && visibleSections">
    <slide-up-down
      v-for="(root_q_set, index) in claimForm"
      :ref="'root_q_set_' + root_q_set.name"
      :key="'root_q_set_' + index"
      :active="currentSection === root_q_set.name"
      v-show="
        root_q_set.name === 'ph_start_claim' ||
          visibleSections.includes(root_q_set.name)
      "
      class="mb-10"
      ><!--:active="currentSection === root_q_set.name"
      v-show="visibleSections.includes(root_q_set.name)"-->
      <b-card
        class="mb-3 rounded-card spark-variant-primary-bg-light spark-variant-primary-border spark-variant-text-dark spark-block-content"
        header-class="card-header rounded-card-header fw-400 py-4 px-4 green-bottom-border spark-variant-primary-bg"
      >
        <template #header>
          <b-row>
            <b-col>
              <b-row class="spark-block-title">
                <b-col>
                  <i
                    class="fas fa-tasks icon-extra-large spark-variant-secondary-text mr-4 mt-1 align-top"
                  ></i>
                  <b-row class="spark-section-header-title-step">
                    <b-col
                      cols="12"
                      sm="8"
                      md="8"
                      lg="8"
                      xl="8"
                      class="order-2 order-sm-1 order-md-1 order-lg-1 order-xl-1"
                      v-html="root_q_set.label"
                    ></b-col>
                    <b-col
                      cols="12"
                      sm="4"
                      md="4"
                      lg="4"
                      xl="4"
                      class="order-1 order-sm-2 order-md-2 order-lg-2 order-xl-2 text-left text-sm-right text-md-right text-lg-right text-xl-right pr-8"
                      >Step
                      {{ visibleSections.indexOf(root_q_set.name) + 1 }} of
                      {{ visibleSections.length }}</b-col
                    >
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </template>
        <spark-question-set
          :obj="root_q_set"
          :ref="root_q_set.name + '_question_set_component_0'"
          :root="true"
        ></spark-question-set>
        <b-row>
          <b-col cols="0" sm="6" md="6" lg="6" xl="6"></b-col>
          <b-col
            cols="12"
            sm="6"
            md="6"
            lg="6"
            xl="6"
            class="spark-section-buttons"
          >
            <b-button
              size="md"
              class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold mr-4"
              @click="
                startClaim = false;
                previousSection();
              "
              >Back</b-button
            >
            <b-button
              size="md"
              class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
              @click="
                startClaim = false;
                nextSection();
              "
              >Next</b-button
            >
          </b-col>
        </b-row>
      </b-card>
    </slide-up-down>
  </div>
</template>

<script>
import validator from "validator";

import { mapGetters, mapActions } from "vuex";
import {
  SET_NAV_BLOCKED,
  SET_ALERT,
  HAS_GROUP,
  CLAIM_FORM,
  SET_CLAIM_FORM,
  RESET_CLAIM_FORM,
  CLAIM_FORM_CURRENT_SECTION,
  SET_CLAIM_FORM_CURRENT_SECTION,
  CLAIM_FORM_SECTION_CAN_SHOW,
  CLAIM_FORM_WATCH_ID_UNIQUE,
  CLAIM_FORM_CONDITION_FROM_ELEMENT_NAME,
  CLAIM_FORM_HAS_SELECTED_SECTIONS_CLAIMING,
  SET_CLAIM_FORM_SECTION_VALIDATION_TRIGGER,
  CLAIM_FORM_GET_FIELD_VALIDATION,
  CLAIM_FORM_GET_FIELD_DATA,
  SUBMIT_CLAIM_FORM,
  SET_CLAIM_DETAIL_PK,
  PETS_CLAIM_MANAGE_PAGE,
  POSTCODE_REGEX
} from "@/core/services/vars.service";
import SparkSlideUpDown from "@/view/spark_components/portal/claim/form/Slider";

export default {
  name: "Spark-Dynamic-Claim-Form-Generator",
  props: {
    create_claim: {
      type: Object,
      required: false
    },
    update_claim: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      subscription: null,
      claim_form_data: null,
      policy: null,
      pet: null,
      practice: null,
      claim: null
    };
  },
  components: {
    "slide-up-down": SparkSlideUpDown
  },
  computed: {
    ...mapGetters([
      HAS_GROUP,
      PETS_CLAIM_MANAGE_PAGE,
      CLAIM_FORM_CURRENT_SECTION,
      CLAIM_FORM_SECTION_CAN_SHOW,
      CLAIM_FORM_CONDITION_FROM_ELEMENT_NAME,
      CLAIM_FORM_WATCH_ID_UNIQUE,
      CLAIM_FORM_HAS_SELECTED_SECTIONS_CLAIMING
    ]),
    isCustomer() {
      return this[HAS_GROUP]("customer");
    },
    isVet() {
      return this[HAS_GROUP]("vet");
    },
    currentSection() {
      return this[CLAIM_FORM_CURRENT_SECTION];
    },
    claimForm() {
      if (!this.claim_form_data) return null;
      let new_data = Array();
      Object.keys(this.claim_form_data).forEach(key => {
        new_data.push(this.claim_form_data[key]);
      });
      return new_data;
    },
    visibleSections() {
      return this[CLAIM_FORM_SECTION_CAN_SHOW];
    },
    sectionHasErrors() {
      if (
        !this.$refs.hasOwnProperty(
          this.currentSection + "_question_set_component_0"
        )
      )
        return false;
      let qs = this.$refs[this.currentSection + "_question_set_component_0"][0];
      //console.log("QS", qs)
      if (!qs) return false;
      return !qs.nestedPassedValidation;
    }
  },
  methods: {
    ...mapActions([SET_ALERT]),
    ...mapGetters([CLAIM_FORM_GET_FIELD_VALIDATION]),
    generateClaimForm() {
      if (this.create_claim) {
        let payload = {
          policy: this.policy,
          pet: this.pet
        };
        if (this.isVet) {
          payload.practice = this.practice;
        }
        this.$spark.$api(CLAIM_FORM, payload);
      }
      if (this.update_claim) {
        let payload = {
          policy: this.policy,
          pet: this.pet,
          claim: this.claim
        };
        this.$spark.$api(CLAIM_FORM, payload);
      }
    },
    nextSection() {
      //this.buildDataObject();
      this.$spark.$dispatch(
          SET_CLAIM_FORM_SECTION_VALIDATION_TRIGGER,
          this.currentSection
        )
        .then(() => {
          if (this.sectionHasErrors === true) return;
          let current_position = this.visibleSections.indexOf(
            this.currentSection
          );
          if (current_position < this.visibleSections.length - 1) {
            let next_section = this.visibleSections[current_position + 1];
            this.$store.commit(SET_CLAIM_FORM_CURRENT_SECTION, next_section);
          }
          if (current_position === this.visibleSections.length - 1) {
            this.buildDataObject();
          }
        });
    },
    previousSection() {
      let current_position = this.visibleSections.indexOf(this.currentSection);
      if (current_position > 0) {
        let next_section = this.visibleSections[current_position - 1];
        this.$store.commit(SET_CLAIM_FORM_CURRENT_SECTION, next_section);
      }
    },
    buildDataObject() {
      let data = null;
      let qs = null;
      let obj = {};
      this.visibleSections.forEach(section => {
        qs = this.$refs[section + "_question_set_component_0"][0];
        if (qs.conditionsMet) {
          data = qs.buildDataObject();
          if (Object.keys(data).length > 0) {
            obj[section] = data;
          }
        }
      });
      obj.policy = this.policy;
      obj.pet = this.pet;
      if (this.isVet) {
        obj.practice = this.practice;
      }
      if (this.update_claim) {
        obj.claim = this.claim;
      }
      console.log(obj);
      this.$spark.$api(SUBMIT_CLAIM_FORM, obj).then(response => {
        this.$spark.$dispatch(SET_CLAIM_DETAIL_PK, response.claim).then(() => {
          if (this.create_claim) {
            this[SET_ALERT]({
              message:
                "Claim created successfully.<br/>Please check if any action is required.",
              variant: "success",
              timer: 20
            });
          }
          if (this.update_claim) {
            this[SET_ALERT]({
              message:
                "Claim updated successfully.<br/>Please check if any action is required.",
              variant: "success",
              timer: 20
            });
          }
          this.$spark.$dispatch(SET_NAV_BLOCKED, {
              status: false,
              fn: this.$spark._navBlockHandler
            })
            .then(() => {
              this.$spark.$dispatch(RESET_CLAIM_FORM);
              this.$spark.$nav({ name: this[PETS_CLAIM_MANAGE_PAGE] });
            });
        });
      });
    },
    hasPassedAllConditions(conditions, payload) {
      let full_pass = true;
      conditions.forEach(cond => {
        let pass = null;
        //console.log("CONDITIONN  LOOP", cond)
        pass = this.$spark.passedCheck(cond, payload);
        if (pass !== null && pass === false) {
          full_pass = false;
          return false;
        }
      });
      return full_pass;
    },
    passedCheck(condition, payload) {
      var has_passed = false;
      let value = payload.value;
      let _val = null;
      var i = 0;
      var x = 0;
      //console.log("$spark.passedCheck", value);

      var check = condition;
      //console.log(condition.watch_unique, check);

      if (check.comparison_type == "validation_passed") {
        value = this.$store.getters[CLAIM_FORM_GET_FIELD_VALIDATION](
          payload.key
        );
        if (value === null) return false;
        //console.log("CHECK VALIDATION OF FIELD PASSED", check, value);
        has_passed = value === true ? true : false;
      } else {
        _val = this.$store.getters[CLAIM_FORM_GET_FIELD_DATA](payload.key);
        if (_val === null) return false;
        value = _val.value;

        //console.log("CHECK VALUE PASSED", check, value);

        if (check.comparison_type == "in_list") {
          //console.log("CHECK IN LIST", check);
          for (i = 0; i < value.length; i++) {
            if (typeof check.property_value === "object") {
              for (x = 0; x < check.property_value.length; x++) {
                let property_value = this.cast(
                  check.property_value[x],
                  check.comparison_data_type
                );
                //console.log("MATCH?", value[i], property_value)
                if (
                  property_value ===
                  this.cast(value[i], check.comparison_data_type)
                ) {
                  has_passed = true;
                  x = check.property_value.length;
                  i = value.length;
                }
              }
            } else {
              let property_value = this.cast(
                check.property_value,
                check.comparison_data_type
              );
              //console.log("MATCH?", value[i], property_value)
              if (
                property_value ===
                this.cast(value[i], check.comparison_data_type)
              ) {
                has_passed = true;
                i = value.length;
              }
            }
          }
        } else if (check.comparison_type == "not_in_list") {
          //console.log("CHECK NOT IN LIST", check);
          if (value.length > 0) {
            has_passed = true;
            for (i = 0; i < value.length; i++) {
              if (typeof check.property_value === "object") {
                for (x = 0; x < check.property_value.length; x++) {
                  let property_value = this.cast(
                    check.property_value[x],
                    check.comparison_data_type
                  );
                  //console.log("VALID2", property_value)
                  if (
                    property_value ===
                    this.cast(value[i], check.comparison_data_type)
                  ) {
                    has_passed = false;
                    x = check.property_value.length;
                    i = value.length;
                  }
                }
              } else {
                let property_value = this.cast(
                  check.property_value,
                  check.comparison_data_type
                );
                if (
                  property_value ===
                  this.cast(value[i], check.comparison_data_type)
                ) {
                  has_passed = false;
                  i = value.length;
                }
              }
            }
          }
        } else if (check.comparison_type == "iequal") {
          let property_value = this.cast(
            check.property_value,
            check.comparison_data_type
          );
          //console.log("CHECK IEQUAL", check);
          has_passed =
            property_value.toLowerCase() ===
            this.cast(value, "string").toLowerCase();
        } else if (check.comparison_type == "boolean") {
          let property_value = this.cast(
            check.property_value,
            check.comparison_data_type
          );
          //console.log("CHECK BOOLEAN", check);
          has_passed = property_value === this.cast(value[i], "boolean");
        } else {
          let property_value = this.cast(
            check.property_value,
            check.comparison_data_type
          );
          //console.log("CHECK", check);
          var match = this.cast(value, check.comparison_data_type);
          if (check.comparison_type == "equal") {
            has_passed = property_value === match;
          } else if (check.comparison_type == "not_equal") {
            has_passed = property_value !== match;
          } else if (check.comparison_type == "less_than") {
            has_passed = property_value < match;
          } else if (check.comparison_type == "greater_than") {
            has_passed = property_value > match;
          } else if (check.comparison_type == "less_than_eq") {
            has_passed = property_value <= match;
          } else if (check.comparison_type == "greater_than_eq") {
            has_passed = property_value >= match;
          } else if (check.comparison_type == "starts_with") {
            has_passed = property_value.startsWith(match);
          } else if (check.comparison_type == "ends_with") {
            has_passed = property_value.endsWith(match);
          } else if (check.comparison_type == "contains") {
            has_passed = match.indexOf(property_value) > -1;
          } else {
            has_passed = null;
          }
        }
      }

      //console.log("CHECK STATUS", has_passed, check)
      return has_passed;
    },
    hasValidationPassed(value, validations, field_type = null) {
      let passed = true;
      let error_messages = Array();
      var date = null;
      var today = null;
      validations.forEach(val => {
        console.log(val.type, value);
        if (val.type === "empty") {
          if (value.length < 1) {
            passed = false;
            error_messages.push(val.error);
          }
        } else if (val.type === "null") {
          if (value === "" || value === "null") {
            passed = false;
            error_messages.push(val.error);
          }
        } else if (val.type === "not_before_from") {
          console.error("THIS NEEDS FIXING not_before_from");
        } else if (val.type === "not_in_future") {
          if (value === "") {
            passed = false;
            error_messages.push(val.error);
          } else {
            try {
              if (field_type === "DateField") {
                date = new Date(value).setHours(0, 0, 0, 0);
                today = new Date().setHours(0, 0, 0, 0);
                if (date > today) {
                  passed = false;
                  error_messages.push(val.error);
                }
              }
              if (field_type === "DateTimeField") {
                date = new Date(value);
                today = new Date();
                if (date > today) {
                  passed = false;
                  error_messages.push(val.error);
                }
              }
            } catch (e) {
              error_messages.push(e);
              passed = false;
              error_messages.push(val.error);
            }
          }
        } else if (val.type === "string" || val.type === "not_blank") {
          if (value === "") {
            passed = false;
            error_messages.push(val.error);
          }
        } else if (val.type === "min_length") {
          if (value.length < val.length) {
            passed = false;
            error_messages.push(val.error);
          }
        } else if (val.type === "account_number") {
          if (!/^[0-9]{8,10}$/.test(value)) {
            passed = false;
            error_messages.push(val.error);
          }
        } else if (val.type === "account_sort_code") {
          if (!/^[0-9]{2}-[0-9]{2}-[0-9]{2}$/.test(value)) {
            passed = false;
            error_messages.push(val.error);
          }
        } else if (val.type === "postcode") {
          if (!POSTCODE_REGEX.test(value)) {
            passed = false;
            error_messages.push(val.error);
          }
        } else if (val.type === "money") {
          if (
            !validator.isDecimal(value, {
              decimal_digits: "2",
              locale: "en-GB"
            })
          ) {
            passed = false;
            error_messages.push(val.error);
          }
        } else if (val.type === "email") {
          if (!validator.isEmail(value)) {
            passed = false;
            error_messages.push(val.error);
          }
        } else if (val.type === "telephone") {
          if (
            !/^(020|024|029)([0-9]{4})([0-9]{4})|(\+44)(20|24|29)([0-9]{4})([0-9]{4})|(0113|0114|0121|0131|0141|0151|0161)([0-9]{3})([0-9]{4})|(\+44)(113|114|121|131|141|151|161)([0-9]{3})([0-9]{4})|(0[0-9]{4})([0-9]{3})([0-9]{3})|(\+44)([0-9]{4})([0-9]{3})([0-9]{3})|(0[0-9]{4})([0-9]{5})|(\+44)([0-9]{4})([0-9]{5})$/.test(
              value
            )
          ) {
            passed = false;
            error_messages.push(val.error);
          }
        } else if (val.type === "checked") {
          if (value !== true) {
            passed = false;
            error_messages.push(val.error);
          }
        } else if (val.type === "float") {
          if (!validator.isFloat(value, "en-GB")) {
            passed = false;
            error_messages.push(val.error);
          }
        } else if (val.type === "positive_integer") {
          if (!validator.isInt(value, 1)) {
            passed = false;
            error_messages.push(val.error);
          }
        } else {
          alert("VALIIDATION TYPE", val.type, value, val);
          passed = false;
        }
      });
      return {
        passed: passed,
        errors: error_messages.length > 0 ? error_messages : null
      };
    }
  },
  beforeDestroy() {
    this.subscription(); // the subscribe method returns a function to call to unsubscribe
  },
  beforeMount() {
    this.$spark.hasPassedAllConditions = this.hasPassedAllConditions;
    this.$spark.hasValidationPassed = this.hasValidationPassed;
    this.$spark.passedCheck = this.passedCheck;
    this.subscription = this.$store.subscribe((mutation, state) => {
      if (mutation.type === SET_CLAIM_FORM) {
        this.claim_form_data = state.claim_form.claim_form_data;
      }
    });
  },
  mounted() {
    if (!this.create_claim && !this.update_claim) {
      this.$destroy();
    }
    if (this.create_claim) {
      this.policy = this.create_claim.policy;
      this.pet = this.create_claim.pet;
      if (this.isVet) {
        this.practice = this.create_claim.practice;
      }
    }
    if (this.update_claim) {
      this.claim = this.update_claim.claim;
      this.policy = this.update_claim.policy;
      this.pet = this.update_claim.pet;
    }
    this.generateClaimForm();
  }
};
</script>

<style>
*:focus-visible {
  outline: none;
}
.select-css {
  display: block;
  font-family: sans-serif;
  text-align: center;
  font-weight: 700;
  color: #444;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  /*linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);*/
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

.select-css::-ms-expand {
  display: none;
}
.select-css option {
  font-weight: normal;
}

*[dir="rtl"] .select-css,
:root:lang(ar) .select-css,
:root:lang(iw) .select-css {
  background-position: left 0.7em top 50%, 0 0;
  padding: 0.6em 0.8em 0.5em 1.4em;
}

.select-css:disabled,
.select-css[aria-disabled="true"] {
  color: graytext;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  /*linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);*/
}

.select-css:disabled:hover,
.select-css[aria-disabled="true"] {
  border-color: #aaa;
}
input:not([type="checkbox"]),
.select-css,
textarea {
  border: 1px solid var(--spark-variant-primary) !important;
  border-radius: 10px;
  color: #363636;
  background-color: #ffffff !important;
  /*font-size: 20px !important;*/
  /*line-height: 20px;*/
  font-size: 1.1em;
  font-weight: normal;
  font-family: "Poppins", sans-serif !important;
  width: 100%;
  padding: 10px 10px 10px 20px;
  /*margin-top: 35px;*/
}
.input_icon input:not([type="checkbox"]),
.input_icon .select-css,
.input_icon textarea {
  margin-top: 18px;
}
.select-css {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 50px;
}

/*.input_icon input:not([type=checkbox]),
.input_icon .select-css,
.input_icon textarea {
    margin-top: 18px;
}
.input_icon,
.input_icon_fixed_width { 
    position: relative; 
    display: inline-block;
}*/

/*.input_icon_fixed_width i { 
    position: absolute;
    left: 0;
    margin-left: 15px;
    margin-top: 17px;
    font-size: 1.2em;
    top: 15px;
    color: var(--spark-variant-primary);
} */
.input_icon_fixed_width i:last-of-type:not(:first-of-type) {
  margin-left: 60px !important;
}

/*

.input_icon .time-hours { 
    position: absolute;
    left: 535px;
    font-size: 24px;
    top: 15px;
    color: gray;
} 

.input_icon .time-mins { 
    position: absolute;
    left: 662px;
    font-size: 24px;
    top: 15px;
    color: gray;
} */
.input_icon_fixed_width i {
  position: absolute;
  left: 0;
  margin-left: 30px;
  margin-top: 0;
  font-size: 1.2em;
  top: 0.7em;
  color: var(--spark-variant-primary);
}

.field_error {
  font-weight: 400;
  color: #de403e !important;
  /*width: 480px;
    float: left;
    margin-left: 525px;
    text-align: left;
    margin-bottom: 30px;*/
}
.field_error_icon,
.lookup_error_icon {
  font-size: 35px;
  text-align: left;
  color: #de403e !important;
  vertical-align: top;
}
.field_error_icon i,
.lookup_error_icon i {
  line-height: 1.4;
  vertical-align: top;
}
.vue_field_error {
  color: #de403e !important;
  font-weight: 500;
}

/* Base for label styling */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  padding-right: 2.7em;
  cursor: pointer;
}

/*type="checkbox"] + label span {
    padding-left: 10px;
}*/
/* checkbox aspect */
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: "\0020";
  position: absolute;
  right: 0;
  top: 0;
  width: 1.7em;
  height: 1.7em;
  border: 1px solid var(--spark-variant-primary);
  background: #fff;
  border-radius: 5px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}
/* checked mark aspect */
[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  content: "\f1b0"; /*'\2713\0020';*/
  position: absolute;
  top: 0.15em;
  right: 0.22em;
  line-height: 0.8;
  color: #52a7dd;
  transition: all 0.2s;
  font-weight: 900;
  margin-top: 5px;
  margin-right: 2px;
  font-family: "Font Awesome 5 Free", "Lucida Sans Unicode", "Arial Unicode MS",
    Arial;
}
.terms_checkbox label {
  padding-top: 6px;
  display: inline-block;
}
/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
}
[type="checkbox"]:checked + label:after {
  opacity: 1;
}
[type="checkbox"] + label:after {
  -webkit-transform: rotate(330deg);
  -moz-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  -o-transform: rotate(330deg);
  transform: rotate(330deg);
}
[type="checkbox"] + label#text {
  margin-right: 10px;
}
/* disabled checkbox */
[type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}
[type="checkbox"]:disabled:checked + label:after {
  color: #999;
}
[type="checkbox"]:disabled + label {
  color: #aaa;
}

.decimal {
  padding-left: 3.5em !important;
}

.form-control {
  height: initial;
}

.date_paw {
  margin-left: 1em !important;
  z-index: 5;
  top: 1.2em !important;
}

#vue_claim_form
  .b-form-btn-label-control.dropdown.b-form-datepicker.datepicker_button.dropright.btn-group
  button.dropdown-toggle,
#vue_claim_form
  .b-form-btn-label-control.dropdown.b-form-timepicker.datepicker_button.dropright.btn-group
  button.dropdown-toggle {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: var(--spark-variant-primary) !important;
  border: 1px solid var(--spark-variant-primary) !important;
  color: var(--spark-variant-text-light) !important;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

#vue_claim_form
  .b-form-btn-label-control.dropdown.b-form-datepicker.datepicker_button.dropright.btn-group
  button.dropdown-toggle:hover,
#vue_claim_form
  .b-form-btn-label-control.dropdown.b-form-timepicker.datepicker_button.dropright.btn-group
  button.dropdown-toggle:hover {
  background-color: var(--spark-variant-primary) !important;
  border: 1px solid var(--spark-variant-primary) !important;
  color: var(--spark-variant-text-light) !important;
  opacity: 0.7;
}

textarea {
  height: 8em;
  text-indent: 1.4em !important;
}

.spark-boolean [type="checkbox"]:not(:checked) + label:before,
.spark-boolean [type="checkbox"]:checked + label:before {
  position: absolute;
  top: 0;
  left: 0;
}
.spark-boolean [type="checkbox"]:not(:checked) + label:after,
.spark-boolean [type="checkbox"]:checked + label:after {
  position: absolute;
  top: 0;
  left: 0.22em;
  margin-top: 0.2em;
  margin-left: 0.05em;
}

.spark-section-header-title-step {
  width: calc(100% - 1.8em);
  float: right;
}

.paragraph_warning {
  font-size: 3em;
}
@media (max-width: 575px) {
  .paragraph_warning {
    font-size: 1.2em !important;
  }
  .input_icon_fixed_width i {
    top: 0.8em;
  }
  .spark-desktop-spacer {
    display: none;
  }
}
/*
#vue_claim_form label {
    display: block;
    width: 100%;
    margin: 0;
    line-height: 20px;
}
#vue_claim_form span {
    line-height: 20px;
    display: block;
}


#vue_claim_form label.full_width {
    width: 100% !important;
    text-align: center;
}

#vue_claim_form label.multiple_checkbox_label {
    width: 500px !important;
    text-align: left;
    font-weight: 400;
    padding-top: 5px;
    vertical-align: top;
}


#vue_claim_form .field_error {
    margin:0;
}


#vue_claim_form button {
    margin: 0;
}
#vue_claim_form .second_button {
    margin-left: 10px !important;
}

#vue_claim_form .sub_label{
    font-size: 14px;
    font-weight: 200;
    margin-top: 5px;
}



.fa-paw {
    color: var(--spark-variant-primary);
    -webkit-transform: rotate(330deg);
    -moz-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    -o-transform: rotate(330deg);
    transform: rotate(330deg);
}
.fa-ul {
    margin-left: 2em !important;
}
.fa-ul i {
    font-size: 20px;
    margin-top: -5px;
}
#vue_claim_form .fa-ul li {
    text-align: left !important;
    padding-left: 1em;
}

#vue_claim_form input[type="text"] {
    padding-left: 50px;
}

#vue_claim_form .vue_property_item {
    padding-left: 15px;
}

#vue_claim_form .decimal {
    padding-left: 65px !important;
}

#vue_claim_form .postcode {
    text-transform: uppercase;
}


#vue_claim_form .lookup_button {
    font-size: 14px;
    width: 100%;
}

.field_error {
    font-weight: 400;
    color: #de403e!important;
    width: 480px;
    float: left;
    margin-left: 525px;
    text-align: left;
    margin-bottom: 30px;
}
.field_error_icon,
.lookup_error_icon {
    font-size: 35px;
    text-align: left;
    color: #de403e!important;
    vertical-align: top;
}
.field_error_icon i,
.lookup_error_icon i {
    line-height: 1.4;
    vertical-align: top;
}

#vue_claim_form .boolean_spacer {
    display: inline-block;
    width: 535px;
}

#vue_claim_form [type="checkbox"]:not(:checked) + label:before, 
#vue_claim_form [type="checkbox"]:checked + label:before {
    left: calc(100% + 20px);
}

#vue_claim_form [type="checkbox"]:not(:checked) + label:after,
#vue_claim_form [type="checkbox"]:checked + label:after {
    left: calc(100% + 23px);
}

#vue_claim_form .multiple_choice_label {
    padding-top: 6px;
}

#vue_claim_form .select-css {
    margin-top: 0;
    width: 100%;
}

#vue_claim_form .shift_input_label {
    padding-top: 1.1em;
}
#vue_claim_form .shift_boolean_label {
    padding-top: 0.3em;
}

#vue_claim_form .input_icon_fixed_width i { 
    position: absolute;
    left: 0;
    margin-left: 30px;
    margin-top: 0;
    font-size: 1.2em;
    top: 15px;
    color: var(--spark-variant-primary);
}

#vue_claim_form input[type="text"] {
    width: 100%;
    height: 52px;
}
#vue_claim_form textarea {
    width: 100%;
    height: 180px;
}

#vue_claim_form .date_paw {
    margin-left: 20px !important;
    z-index: 5;
}

#vue_claim_form .date {
    max-width: calc(100% - 50px);
}

#vue_claim_form .b-form-btn-label-control.dropdown.b-form-datepicker.datepicker_button.dropright.btn-group button.dropdown-toggle,
#vue_claim_form .b-form-btn-label-control.dropdown.b-form-timepicker.datepicker_button.dropright.btn-group button.dropdown-toggle {
    height: 52px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: var(--spark-variant-primary);
    border: 1px solid var(--spark-variant-primary);
    color: white;
}



#vue_claim_form .vue_field_error {
    font-weight: 400;
    color: #de403e !important;
    text-align: left;
    font-weight: 700;
    font-size: 1.2em;
    margin-bottom: 20px;
}
a {
    text-decoration: none !important;
}

#vue_claim_form .bg-danger a {
    color: rgb(209, 211, 215);
    text-decoration: underline !important;
}

#vue_claim_form .paragraph_warning {
    font-size: 40px;
}
#vue_claim_form .paragraph_info_wrap {
    background-color: #88dfff
}

#vue_claim_form .bank_validation_label {
    font-weight: bold;
    padding-top: 15px;
    text-align: right;
}
#vue_claim_form .bank_validation_value {
    padding-top: 15px;
    text-align: left;
}
#vue_claim_form .bank_name_row {
    vertical-align: middle;
}
#vue_claim_form .bank_logo {
    height: 50px;
    background-color: #ffffff;
    padding: 3px;
    border-radius: 5px;
    border: 1px solid black;
    margin-right: 10px;
}

#vue_claim_form .form-control:focus {
    border: 1px solid var(--spark-variant-primary);
    box-shadow: none;
}

#vue_claim_form .form-control:disabled,
#vue_claim_form .form-control[readonly]  {
    background-color: #ffffff;
}

#vue_claim_form .vue_field_error_full {
    text-align: right !important;
    padding-right: 2em;
    padding-bottom: 1em;
    padding-top: 0.8em;
}
#vue_claim_form .vue_field_error_full ul {
    text-align: right !important;
}

#vue_claim_form .vue_field_error ul li {
    padding-bottom: 10px;
}*/

.select-css {
  margin-top: 0;
  width: 100%;
}
input:disabled,
.select-css:disabled,
textarea:disabled {
  background-color: #d4d4d467 !important;
  color: #000000 !important;
}
</style>
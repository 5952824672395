<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import {
  CURRENT_USER,
  SET_POLICY_DETAIL_PK,
  POLICY_LIST,
  POLICY_DETAIL,
  ERROR_403,
  //LOAD_AUTH
} from "@/core/services/vars.service";

export default {
  name: "Layout-Portal-Policy",
  path: "policy",
  methods: {
    loadData() {
      let url_parts = this.$route.path.split("/");
      let action = url_parts[url_parts.length - 1];
      if (action === "list") {
        this.$spark.$api(POLICY_LIST).catch(() => {});
      } else if (action === "manage") {
        this.$spark.$api(POLICY_DETAIL).catch(() => {});
      }
    }
  },
  watch: {
    $route: function() {
      this.loadData();
    }
  },
  beforeMount() {
    /*//this.$spark.$dispatch(LOAD_AUTH).then(() => {
      if (!this.$store.getters[CURRENT_USER].$customer) {
        this.$spark.$dashboard({error: ERROR_403});
        this.$destroy();
      }
    //});
    let policy_pk = this.$spark.$storage.get(SET_POLICY_DETAIL_PK);
    if (policy_pk) {
      this.$spark.$dispatch(SET_POLICY_DETAIL_PK, policy_pk);
    }
    this.loadData();*/
  },
  mounted() {
    this.$spark.$loading(POLICY_DETAIL, true);
    this.$nextTick(() => {
      let policy_pk = this.$spark.$storage.get(SET_POLICY_DETAIL_PK);
      if (policy_pk) {
        this.$spark.$dispatch(SET_POLICY_DETAIL_PK, policy_pk);
      }
      setTimeout(() => {
        this.$spark.$loading(POLICY_DETAIL, false).then(() => {
          if (this.$store.getters[CURRENT_USER].$customer) {
            this.loadData();
          } else {
            this.$spark.$dashboard({error: ERROR_403});
            this.$destroy();
          }
        }); 
      },300);
    });
  },
};
</script>

<template>
  <div class="mb-10" v-if="item">
    <b-card
      class="mb-3 rounded-card spark-variant-primary-bg-light spark-variant-primary-border spark-variant-text-dark spark-block-content"
      header-class="card-header rounded-card-header fw-400 py-4 px-4 green-bottom-border spark-variant-primary-bg"
    >
      <template #header>
        <b-row>
          <b-col>
            <div class="spark-block-title">
              <i
                class="fas fa-tasks icon-extra-large spark-variant-secondary-text mr-2"
              ></i>
              {{ $options.short_name }}
            </div>
          </b-col>
        </b-row>
      </template>

      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">Email</b-col>
        <b-col class="col-6">
          <i class="fas fa-paw"></i>
          <i
            v-if="item.email === true"
            class="fas fa-check-circle green icon-large"
          ></i>
          <i v-else class="fas fa-times-circle red icon-large"></i>
          {{ item.email === true ? "Yes" : "No" }}</b-col
        >
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">SMS</b-col>
        <b-col class="col-6">
          <i class="fas fa-paw"></i>
          <i
            v-if="item.sms === true"
            class="fas fa-check-circle green icon-large"
          ></i>
          <i v-else class="fas fa-times-circle red icon-large"></i>
          {{ item.sms === true ? "Yes" : "No" }}</b-col
        >
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">Telephone</b-col>
        <b-col class="col-6">
          <i class="fas fa-paw"></i>
          <i
            v-if="item.telephone === true"
            class="fas fa-check-circle green icon-large"
          ></i>
          <i v-else class="fas fa-times-circle red icon-large"></i>
          {{ item.telephone === true ? "Yes" : "No" }}
        </b-col>
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">Post</b-col>
        <b-col class="col-6">
          <i class="fas fa-paw"></i>
          <i
            v-if="item.post === true"
            class="fas fa-check-circle green icon-large"
          ></i>
          <i v-else class="fas fa-times-circle red icon-large"></i>
          {{ item.post === true ? "Yes" : "No" }}
        </b-col>
      </b-row>
      <b-button
        :disabled="true"
        size="md"
        class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
        >Manage Preferences</b-button
      >
    </b-card>
  </div>
</template>

<script>
import { ACCOUNT_DETAIL } from "@/core/services/vars.service";

export default {
  name: "Portal-Account-Marketing-Preferences",
  short_name: "Marketing Preferences",
  computed: {
    item() {
      return this.$store.getters[ACCOUNT_DETAIL];
    }
  }
};
</script>

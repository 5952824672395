<template>
  <div class="mb-10">
    <b-card
      class="mb-3 rounded-card spark-variant-primary-bg-light spark-variant-primary-border spark-variant-text-dark spark-block-content pb-15"
      header-class="card-header rounded-card-header fw-400 py-4 px-4 green-bottom-border spark-variant-primary-bg"
    >
      <template #header>
        <b-row>
          <b-col class="col-8">
            <div class="spark-block-title">
              <i
                class="fas fa-at icon-extra-large spark-variant-secondary-text mr-2"
              ></i>
              {{ $options.short_name }}
            </div>
          </b-col>
        </b-row>
      </template>

      <div class="mb-10">
        <b-row class="mt-4 mb-4">
          <b-col class="col-6 text-right pt-4">
            <span class="font-weight-bold"
              >Please select the type of query</span
            >
          </b-col>
          <b-col class="col-5 input_icon_fixed_width">
            <select class="select-css" v-model="selected_type">
              <option value="null">-- Please Select --</option>
              <option
                v-for="(choice, index) in type_choices"
                v-bind:value="choice[0]"
                :key="'type_' + index"
              >
                {{ choice[1] }}
              </option>
            </select>
            <i class="fas fa-paw"></i>
          </b-col>
          <b-col class="col-1 mt-2">
            <i
              v-if="selected_type === 'null'"
              class="fas fa-exclamation-circle field_error_icon"
            ></i>
          </b-col>
        </b-row>
        <b-row class="justify-content-right">
          <b-col
            v-if="selected_type === 'null'"
            cols="11"
            class="vue_field_error text-right"
          >
            <ul>
              <li>You must select the type of query</li>
            </ul>
          </b-col>
          <b-col cols="1"></b-col>
        </b-row>
      </div>

      <template v-if="referencesForType">
        <div class="mb-10">
          <b-row class="mt-4 mb-4">
            <b-col cols="6" class="text-right pt-4">
              <span class="font-weight-bold"
                >Please select the {{ selected_type }} that this query relates
                to</span
              >
            </b-col>
            <b-col cols="5" class="input_icon_fixed_width">
              <select class="select-css" v-model="selected_reference">
                <option value="null">-- Please Select --</option>
                <option
                  v-for="(choice, index) in referencesForType"
                  v-bind:value="choice"
                  :key="'ref_' + index"
                >
                  {{ choice }}
                </option>
              </select>
              <i class="fas fa-paw"></i>
            </b-col>
            <b-col cols="1" class="mt-2">
              <i
                v-if="selected_reference === 'null'"
                class="fas fa-exclamation-circle field_error_icon"
              ></i>
            </b-col>
          </b-row>
          <b-row class="justify-content-right">
            <b-col
              v-if="selected_reference === 'null'"
              cols="11"
              class="vue_field_error text-right"
            >
              <ul>
                <li>
                  You must select the {{ selected_type }} that this query
                  relates to
                </li>
              </ul>
            </b-col>
            <b-col cols="1"></b-col>
          </b-row>
        </div>
      </template>

      <div class="mb-10">
        <b-row class="mt-4 mb-4" v-if="canShowTextBox">
          <b-col cols="1"></b-col>
          <b-col cols="10" class="input_icon_fixed_width">
            <textarea v-model="message"></textarea>
            <i class="fas fa-paw"></i>
          </b-col>
          <b-col cols="1" class="mt-2">
            <i
              v-if="message.length < 20"
              class="fas fa-exclamation-circle field_error_icon"
            ></i>
          </b-col>
        </b-row>
        <b-row
          class="justify-content-right"
          v-if="canShowTextBox && !canSendMessage"
        >
          <b-col cols="1"></b-col>
          <b-col cols="10" class="vue_field_error text-right">
            <ul>
              <li>Your message must be at least 20 characters long</li>
            </ul>
          </b-col>
          <b-col cols="1"></b-col>
        </b-row>
      </div>

      <b-row class="justify-content-right" v-if="canSendMessage">
        <b-col cols="11" class="text-right">
          <b-button
            size="md"
            class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
            @click="sendContactFormMessage()"
            >Send Message</b-button
          >
        </b-col>
        <b-col cols="1"></b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  SUBMIT_CORRESPONDENCE_CONTACT_FORM,
  PAGE_NAME_PORTAL_CORRESPONDENCE_LIST
} from "@/core/services/vars.service";

export default {
  name: "Portal-Correspondence-Contact-Us-Component",
  short_name: "Contact Us",
  props: {
    references: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      message: "",
      selected_type: "null",
      selected_reference: "null",
      type_choices: [
        ["general", "General Query"],
        ["claim", "Claim Query"],
        ["complaint", "Complaint Query"],
        ["policy", "Policy Query"]
      ]
    };
  },
  methods: {
    sendContactFormMessage() {
      let payload = {
        ref_type: this.selected_type,
        contact_ref:
          this.selected_reference === "null" ? null : this.selected_reference,
        message_body: this.message
      };
      this.$spark.$api(SUBMIT_CORRESPONDENCE_CONTACT_FORM, payload)
        .then(() => {
          this.$spark.$success("Message sent successfully.<br/>The relevant team will be in contact soon.");
          this.$spark.$nav({ name: PAGE_NAME_PORTAL_CORRESPONDENCE_LIST });
        }).catch(() => {});
    }
  },
  watch: {
    selected_type: function() {
      this.selected_reference = "null";
    }
  },
  computed: {
    canShowTextBox() {
      return (
        this.selected_reference !== "null" ||
        (this.selected_type !== "null" && !this.referencesForType)
      );
    },
    canSendMessage() {
      return this.canShowTextBox && this.message.length > 20;
    },
    referencesForType() {
      if (this.selected_type === "null") return null;
      if (this.selected_type === "claim") return this.references.claims;
      if (this.selected_type === "complaint") return this.references.complaints;
      return null;
    }
  }
};
</script>

<template>
  <div class="spark-page" v-if="list">
    <div class="spark-page-title">{{ $options.short_name }}</div>
    <div
      class="spark-page-content column-fluid row justify-content-center"
      v-if="list.length === 0"
    >
      <div class="col col-sm-12 col-md-12 col-lg-10">
        <spark-nothing-to-see-here></spark-nothing-to-see-here>
      </div>
    </div>
    <div class="spark-page-content column-fluid row" v-else>
      <spark-pagination
        :data="pagination"
        :to="paginationTo"
      ></spark-pagination>
      <template v-for="(item, index) in list">
        <div
          v-if="item.contact_type === 2"
          class="col col-sm-12 col-md-6 col-lg-6"
          :key="'email_' + index"
        >
          <spark-correspondence-email :item="item"></spark-correspondence-email>
        </div>
        <div
          v-if="item.contact_type === 1"
          class="col col-sm-12 col-md-6 col-lg-6"
          :key="'contact_' + index"
        >
          <spark-correspondence-display-contact-us
            :item="item"
          ></spark-correspondence-display-contact-us>
        </div>
      </template>
      <spark-pagination
        :data="pagination"
        :to="paginationTo"
      ></spark-pagination>
    </div>
  </div>
</template>

<script>
import {
  CORRESPONDENCE_LIST,
  PAGE,
  PAGE_NAME_PORTAL_CORRESPONDENCE_LIST
} from "@/core/services/vars.service";

import SparkNothingToSeeHere from "@/view/spark_components/NothingToSeeHere";
import SparkCorrespondenceEmail from "@/view/spark_components/portal/correspondence/Email";
import SparkCorrespondenceDisplayContactUs from "@/view/spark_components/portal/correspondence/DisplayContactUs";
import SparkPagination from "@/view/spark_components/portal/Pagination";

export default {
  name: PAGE_NAME_PORTAL_CORRESPONDENCE_LIST,
  short_name: "Correspondence",
  path: "list",
  menu: true,
  components: {
    "spark-correspondence-display-contact-us": SparkCorrespondenceDisplayContactUs,
    "spark-correspondence-email": SparkCorrespondenceEmail,
    "spark-nothing-to-see-here": SparkNothingToSeeHere,
    "spark-pagination": SparkPagination
  },
  computed: {
    list() {
      return this.$store.getters[CORRESPONDENCE_LIST];
    },
    pagination() {
      return this.$store.getters[PAGE + CORRESPONDENCE_LIST];
    },
    paginationTo() {
      return CORRESPONDENCE_LIST;
    }
  },
  mounted() {
    this.$spark.$breadcrumbs(this.$options);
  }
};
</script>

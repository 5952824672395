<template>
  <div></div>
</template>
<script>
export default {
  name: "spark-uid",
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      value: this.uuidv4()
    };
  },
  computed: {
    Value() {
      return this.value;
    }
  },
  mounted() {
    this.$parent.value = this.value;
  }
};
</script>

<template>
  <div>{{ $options.name }}</div>
</template>

<script>

export default {
  name: "Admin-Vet-Create",
  path: "create",
  mounted() {
   this.$spark.$breadcrumbs(this.$options);
  }
};
</script>

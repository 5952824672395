import $spark from "@/core/services/spark.service";
import {
  ACCOUNT_DETAIL,
  ACCOUNT_PASSWORD_CHANGE_COMPONENT,
  SET_ACCOUNT_PASSWORD_CHANGE_COMPONENT,
  SUBMIT_PASSWORD_CHANGE_FORM,
  PAGE_NAME_PORTAL_ACCOUNT_MANAGE
} from "@/core/services/vars.service";

const state = {
  password_change_component: null,
};

const getters = {
  [ACCOUNT_PASSWORD_CHANGE_COMPONENT]: state => {
    return state.password_change_component;
  }
};

const actions = {
  [SET_ACCOUNT_PASSWORD_CHANGE_COMPONENT](context, payload) {
    context.commit(SET_ACCOUNT_PASSWORD_CHANGE_COMPONENT, payload);
  },
};

const mutations = {
  [SET_ACCOUNT_PASSWORD_CHANGE_COMPONENT](state, data) {
    state.password_change_component = data;
  }
};


const dynamic = [
  {
    name: ACCOUNT_DETAIL,
    url: {
      customer: $spark.$urls.$portal.account.customer.detail,
      vet: $spark.$urls.$portal.account.vet.detail
    },
    autoload_on: [PAGE_NAME_PORTAL_ACCOUNT_MANAGE]
  },
  {
    name: SUBMIT_PASSWORD_CHANGE_FORM,
    method: "put",
    url: {
      path: $spark.$urls.$portal.account.change_password,
    }
  }
];

const _store = $spark.$inject(dynamic, {
  state,
  actions,
  mutations,
  getters
});
export default _store;

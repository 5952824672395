<template>
  <component :is="template">
    <div
      class="spark-portal-subheader py-2 py-lg-4 subheader-solid"
      id="kt_subheader"
    >
      <div
        class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap spark-portal-subheader-content"
        v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
      >
        <div class="d-flex align-items-center flex-wrap mr-1">
          <h5
            class="font-weight-bold my-2 mr-5 spark-portal-breadcrumbs-title spark-variant-text-dark"
          >
            <span v-html="title"></span>
          </h5>
          <ul
            class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2"
          >
            <li class="breadcrumb-item">
              <router-link :to="'/'" class="subheader-breadcrumbs-home">
                <i class="flaticon2-shelter icon-1x spark-variant-text-mid"></i>
              </router-link>
            </li>

            <template v-for="(breadcrumb, i) in breadcrumbs">
              <li class="breadcrumb-item" :key="`${i}-${breadcrumb.id}`">
                <router-link
                  v-if="breadcrumb.route"
                  :key="i"
                  :to="breadcrumb.route"
                  class="spark-variant-text-mid"
                >
                  <span v-html="breadcrumb.title"></span>
                </router-link>
                <span
                  class="spark-variant-text-mid"
                  :key="i"
                  v-if="!breadcrumb.route"
                >
                  <span v-html="breadcrumb.title"></span>
                </span>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import {
  CLIENT_REMOTE_STYLES,
  SPARK_TEMPLATE_SUB_HEADER
} from "@/core/services/vars.service";

export default {
  name: "KTSubheader",
  props: {
    breadcrumbs: Array,
    title: String
  },
  data() {
    return {
      template: null
    };
  },
  beforeMount() {
    this.template = this.$store.getters[CLIENT_REMOTE_STYLES](SPARK_TEMPLATE_SUB_HEADER);
  },
  computed: {

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.$store.getters["layoutConfig"]("subheader.width") === "fluid";
    },

    subheaderClasses() {
      const classes = [];
      const style = this.$store.getters["layoutConfig"]("subheader.style");
      if (style) {
        classes.push(style);

        if (style === "solid") {
          classes.push("bg-white");
        }

        if (this.$store.getters["layoutConfig"]("subheader.fixed")) {
          classes.push("border-top");
        }
      }
      return classes.join(" ");
    }
  }
};
</script>

<style>
.spark-portal-subheader-content {
  width: calc((100% / 12) * 8);
}
@media (max-width: 991.98px) {
  .spark-portal-subheader {
    margin-top: 35px;
  }
  .spark-portal-subheader-content {
    width: 100%;
  }
  .spark-portal-breadcrumbs-title {
    width: 100%;
  }
}
</style>

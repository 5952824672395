<template>
  <!-- eslint-disable max-len -->
  <div class="spark-page" id="vue_claim_form">
    <div class="spark-page-title">{{ $options.short_name }}</div>
    <div class="spark-page-content column-fluid row justify-content-center">
      <div class="col col-sm-12 col-md-12 col-lg-10">
        <div class="mb-10">
          <slide-up-down :active="disclaimerShow">
            <b-card
              class="mb-3 rounded-card spark-variant-primary-bg-light spark-variant-primary-border spark-variant-text-dark spark-block-content"
              header-class="card-header rounded-card-header fw-400 py-4 px-4 green-bottom-border spark-variant-primary-bg"
            >
              <template #header>
                <b-row>
                  <b-col>
                    <div class="spark-block-title">
                      <i
                        class="fas fa-exclamation-triangle icon-extra-large spark-variant-secondary-text mr-4"
                      ></i>
                      Disclaimer
                    </div>
                  </b-col>
                </b-row>
              </template>

              <b-row
                class="mb-5 justify-content-center row spark-html-block-content"
              >
                <div class="col-12 p-8" v-html="pageContent"></div>
              </b-row>
              <b-row class="mb-5 justify-content-center row">
                <b-button
                  size="md"
                  v-html="'Agree &amp; Proceed'"
                  class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
                  @click="
                    disclaimerShow = false;
                    startClaim = true;
                  "
                ></b-button>
              </b-row>
            </b-card>
          </slide-up-down>
          <slide-up-down :active="startClaim">
            <b-card
              class="mb-3 rounded-card spark-variant-primary-bg-light spark-variant-primary-border spark-variant-text-dark spark-block-content"
              header-class="card-header rounded-card-header fw-400 py-4 px-4 green-bottom-border spark-variant-primary-bg"
            >
              <template #header>
                <b-row>
                  <b-col>
                    <div class="spark-block-title">
                      <i
                        class="fas fa-tasks icon-extra-large spark-variant-secondary-text mr-4"
                      ></i>
                      Start Claim
                    </div>
                  </b-col>
                </b-row>
              </template>

              <b-row class="mt-4 mb-4">
                <b-col class="col-6 text-right pt-4">
                  <span class="font-weight-bold"
                    >Please select the veterinary surgery you are acting on
                    behalf of, to submit this claim</span
                  >
                </b-col>
                <b-col class="col-5 input_icon_fixed_width">
                  <select
                    v-if="practicesList"
                    class="select-css"
                    v-model="selected_practice"
                  >
                    <option value="null">-- Please Select --</option>
                    <option
                      v-for="(practice, index) in practicesList"
                      v-bind:value="practice.id"
                      :key="'practice_' + index"
                    >
                      {{ practice.practice_name }}
                    </option>
                  </select>
                  <i class="fas fa-paw"></i>
                </b-col>
                <b-col class="col-1 mt-2">
                  <i
                    v-if="selected_practice === 'null'"
                    class="fas fa-exclamation-circle field_error_icon"
                  ></i>
                </b-col>
              </b-row>
              <b-row class="justify-content-right">
                <b-col class="col-6"></b-col>
                <b-col
                  v-if="selected_practice === 'null'"
                  class="col-6 vue_field_error"
                >
                  <ul>
                    <li>
                      You must select the veterinary surgery you are acting on
                      behalf of, to submit this claim
                    </li>
                  </ul>
                </b-col>
              </b-row>
              <template v-if="selected_practice !== 'null'">
                <b-row class="mt-4 mb-4">
                  <b-col
                    cols="12"
                    sm="6"
                    md="6"
                    lg="6"
                    xl="6"
                    class="text-left text-sm-right text-md-right text-lg-right text-xl-right pt-4"
                  >
                    <span class="font-weight-bold"
                      >Please enter the customer postcode</span
                    >
                  </b-col>
                  <b-col
                    cols="6"
                    sm="3"
                    md="3"
                    lg="3"
                    xl="3"
                    class="input_icon_fixed_width"
                  >
                    <input
                      type="text"
                      class="pl-15"
                      v-model="customer_postcode"
                    />
                    <i class="fas fa-paw"></i>
                  </b-col>
                  <b-col cols="4" sm="2" md="2" lg="2" xl="2"></b-col>
                  <b-col cols="2" sm="1" md="1" lg="1" xl="1">
                    <i
                      class="fas fa-exclamation-circle field_error_icon pt-2"
                      v-if="!customerPostcodeIsValid"
                    ></i>
                  </b-col>
                  <b-col cols="0" sm="6" md="6" lg="6" xl="6"></b-col>
                  <b-col
                    cols="10"
                    sm="6"
                    md="6"
                    lg="6"
                    xl="6"
                    class="vue_field_error pt-2"
                  >
                    <ul>
                      <li v-if="!customerPostcodeIsValid">
                        You must enter a valid postcode to find a customer
                      </li>
                    </ul>
                  </b-col>
                </b-row>
                <template v-if="customerList">
                  <b-row class="mt-4 mb-4">
                    <b-col class="col-6 text-right pt-4">
                      <span class="font-weight-bold"
                        >Please select the customer you are submitting the claim
                        for</span
                      >
                    </b-col>
                    <b-col class="col-5 input_icon_fixed_width">
                      <select
                        v-if="customerList"
                        class="select-css"
                        v-model="selected_customer"
                      >
                        <option value="null">-- Please Select --</option>
                        <option
                          v-for="(customer, index) in customerList"
                          v-bind:value="customer.id"
                          :key="'customer_' + index"
                        >
                          {{ customer.first_name }} {{ customer.last_name }}
                        </option>
                      </select>
                      <i class="fas fa-paw"></i>
                    </b-col>
                    <b-col class="col-1 mt-2">
                      <i
                        v-if="selected_customer === 'null'"
                        class="fas fa-exclamation-circle field_error_icon"
                      ></i>
                    </b-col>
                  </b-row>
                  <b-row class="justify-content-right">
                    <b-col class="col-6"></b-col>
                    <b-col
                      v-if="selected_customer === 'null'"
                      class="col-6 vue_field_error"
                    >
                      <ul>
                        <li>
                          You must select the customer you are submitting the
                          claim for
                        </li>
                      </ul>
                    </b-col>
                  </b-row>
                  <template v-if="selectedCustomerPolicies">
                    <b-row class="mt-4 mb-4">
                      <b-col class="col-6 text-right pt-4">
                        <span class="font-weight-bold"
                          >Please select the policy reference number that the
                          customer has provided to you</span
                        >
                      </b-col>
                      <b-col class="col-5 input_icon_fixed_width">
                        <select
                          v-if="selectedCustomerPolicies"
                          class="select-css"
                          v-model="selected_policy"
                        >
                          <option value="null">-- Please Select --</option>
                          <option
                            v-for="(policy, index) in selectedCustomerPolicies"
                            v-bind:value="policy.id"
                            :key="'policy_' + index"
                          >
                            {{ policy.reference }}
                          </option>
                        </select>
                        <i class="fas fa-paw"></i>
                      </b-col>
                      <b-col class="col-1 mt-2">
                        <i
                          v-if="selected_policy === 'null'"
                          class="fas fa-exclamation-circle field_error_icon"
                        ></i>
                      </b-col>
                    </b-row>
                    <b-row class="justify-content-right">
                      <b-col class="col-6"></b-col>
                      <b-col
                        v-if="selected_policy === 'null'"
                        class="col-6 vue_field_error"
                      >
                        <ul>
                          <li>
                            You must select the policy reference number that the
                            customer has provided to you
                          </li>
                        </ul>
                      </b-col>
                    </b-row>
                    <template v-if="selectedPolicyPets">
                      <b-row class="mt-4 mb-4">
                        <b-col class="col-6 text-right pt-4">
                          <span class="font-weight-bold"
                            >Please select the pet you are submitting the claim
                            for</span
                          >
                        </b-col>
                        <b-col class="col-5 input_icon_fixed_width">
                          <select
                            v-if="selectedPolicyPets"
                            class="select-css"
                            v-model="selected_pet"
                          >
                            <option value="null">-- Please Select --</option>
                            <option
                              v-for="(pet, index) in selectedPolicyPets"
                              v-bind:value="pet.id"
                              :key="'pet_' + index"
                            >
                              {{ pet.name }}
                            </option>
                          </select>
                          <i class="fas fa-paw"></i>
                        </b-col>
                        <b-col class="col-1 mt-2">
                          <i
                            v-if="selected_pet === 'null'"
                            class="fas fa-exclamation-circle field_error_icon"
                          ></i>
                        </b-col>
                      </b-row>
                      <b-row class="justify-content-right">
                        <b-col class="col-6"></b-col>
                        <b-col
                          v-if="selected_pet === 'null'"
                          class="col-6 vue_field_error"
                        >
                          <ul>
                            <li>
                              You must select the the pet you are submitting the
                              claim for
                            </li>
                          </ul>
                        </b-col>
                      </b-row>
                      <b-row v-if="selected_pet !== 'null'">
                        <b-col class="col-6"></b-col>
                        <b-col class="col-6">
                          <b-button
                            v-if="hasDataToGenerateForm"
                            size="md"
                            class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
                            @click="
                              startClaim = false;
                              showClaimForm();
                            "
                            >Start Claim</b-button
                          >
                        </b-col>
                      </b-row>
                    </template>
                  </template>
                </template>
              </template>
            </b-card>
          </slide-up-down>
          <spark-magic
            v-if="show_claim_form"
            :create_claim="createClaimProps"
          ></spark-magic>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  CLAIM_VET_DISCLAIMER,
  CLIENT_REMOTE_RESOURCES,
  ACCOUNT_DETAIL,
  PAGE_NAME_PORTAL_CLAIM_VET_CREATE,
  POSTCODE_REGEX,
  LOOKUP_CUSTOMER
} from "@/core/services/vars.service";

import SparkSlideUpDown from "@/view/spark_components/portal/claim/form/Slider";
import SparkDynamicFormGenerator from "@/view/spark_components/portal/claim/form/DynamicFormGenerator";

export default {
  name: PAGE_NAME_PORTAL_CLAIM_VET_CREATE,
  short_name: "New Claim",
  path: "create",
  sub_menu: true,
  data() {
    return {
      show_claim_form: false,
      subscription: null,
      disclaimerShow: true,
      startClaim: false,
      selected_practice: "null",
      customer_postcode: null,
      selected_customer: "null",
      selected_policy: "null",
      selected_pet: "null"
    };
  },
  components: {
    "slide-up-down": SparkSlideUpDown,
    "spark-magic": SparkDynamicFormGenerator
  },
  computed: {
    customerList() {
      return this.$store.getters[LOOKUP_CUSTOMER];
    },
    practicesList() {
      return this.$store.getters[ACCOUNT_DETAIL];
    },
    pageContent() {
      let new_content = this.$store.getters[CLAIM_VET_DISCLAIMER];
      if (/\{\{RESOURCE->\$[0-9]+\}\}/.test(new_content)) {
        this.$store.getters[CLIENT_REMOTE_RESOURCES].forEach(res => {
          new_content = new_content.replace(
            "{{RESOURCE->$" + res.id + "}}",
            res.filedata
          );
        });
      }
      return new_content;
    },
    createClaimProps() {
      return {
        policy: this.selected_policy,
        pet: this.selected_pet,
        practice: this.selected_practice
      };
    },
    customerPostcodeIsValid() {
      if (!this.customer_postcode) return false;
      return POSTCODE_REGEX.test(this.customer_postcode);
    },
    selectedCustomerPolicies() {
      if (
        !this.customerList ||
        !this.selected_customer ||
        this.selected_customer === "null"
      )
        return false;
      return this.customerList.filter(customer => {
        return customer.id === this.selected_customer;
      })[0].policies;
    },
    selectedPolicyPets() {
      if (
        !this.selectedCustomerPolicies ||
        !this.selected_policy ||
        this.selected_policy === "null"
      )
        return false;
      return this.selectedCustomerPolicies.filter(policy => {
        return policy.id === this.selected_policy;
      })[0].pets;
    },
    hasDataToGenerateForm() {
      if (
        !this.selected_policy ||
        this.selected_policy === "null" ||
        !this.selected_pet ||
        this.selected_pet === "null"
      )
        return false;
      if (!this.selected_practice || this.selected_practice === "null")
        return false;
      return true;
    }
  },
  methods: {
    showClaimForm() {
      this.show_claim_form = true;
    },
    resetCustomer() {
      this.customer_postcode = null;
    },
    resetSelectedCustomer() {
      this.selected_customer = "null";
    },
    resetPolicy() {
      this.selected_policy = "null";
    },
    resetPet() {
      this.selected_pet = "null";
    }
  },
  watch: {
    practicesList: function() {
      this.resetCustomer();
      this.resetSelectedCustomer();
      this.resetPolicy();
      this.resetPet();
      if (this.practicesList && this.practicesList.length === 1) {
        this.selected_practice = this.practicesList[0].id;
      }
    },
    customerPostcodeIsValid: function(new_val) {
      if (new_val === true) {
        let data = {
          value: this.customer_postcode
        };
        this.$spark.$api(LOOKUP_CUSTOMER, data).catch(() => {});
      }
    },
    customerList: function() {
      this.resetSelectedCustomer();
      this.resetPolicy();
      this.resetPet();
      if (this.customerList && this.customerList.length === 1) {
        this.selected_customer = this.customerList[0].id;
      }
    },
    selected_customer: function(new_val) {
      this.resetPolicy();
      this.resetPet();
      if (new_val !== "null") {
        if (
          this.selected_customer &&
          this.selectedCustomerPolicies.length === 1
        ) {
          this.selected_policy = this.selectedCustomerPolicies[0].id;
        }
      }
    },
    selected_policy: function(new_val) {
      this.resetPet();
      if (new_val !== "null") {
        if (this.selected_policy && this.selectedPolicyPets.length === 1) {
          this.selected_pet = this.selectedPolicyPets[0].id;
        }
      }
    }
  },
  mounted() {
    this.$spark.$breadcrumbs(this.$options);
  }
};
</script>

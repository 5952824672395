<template>
  <div class="mb-10">
    <b-card
      class="mb-3 rounded-card spark-variant-primary-bg-light spark-variant-primary-border spark-variant-text-dark spark-block-content"
      header-class="card-header rounded-card-header fw-400 py-4 px-4 green-bottom-border spark-variant-primary-bg"
    >
      <template #header>
        <b-row>
          <b-col>
            <div class="spark-block-title">
              <i
                class="far fa-file-alt icon-extra-large spark-variant-secondary-text mr-4"
              ></i>
              {{ $options.short_name }}: {{ item.claim_number }}
            </div>
          </b-col>
        </b-row>
      </template>

      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">Action required</b-col>
        <b-col class="col-6">
          <i class="fas fa-paw"></i>
          <i
            v-if="item.action_required !== false"
            class="fas fa-check-circle red icon-large"
          ></i>
          <i v-else class="fas fa-times-circle green icon-large"></i>
          {{ item.action_required !== false ? "Yes" : "No" }}
          <div>
            <b-button
              v-if="item.action_required !== false"
              size="md"
              class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
              @click="$spark.$fix('claim', item.id)"
              >Fix Problems</b-button
            >
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right"
          >Claim reference number</b-col
        >
        <b-col class="col-6"
          ><i class="fas fa-paw"></i>{{ item.claim_number }}</b-col
        >
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right"
          >Policy reference number</b-col
        >
        <b-col class="col-6"
          ><i class="fas fa-paw"></i>{{ item.policy_reference }}</b-col
        >
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">Pet name</b-col>
        <b-col class="col-6"
          ><i class="fas fa-paw"></i>{{ item.pet.name }}</b-col
        >
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">Claim date</b-col>
        <b-col class="col-6"
          ><i class="fas fa-paw"></i
          >{{ moment(item.claim_date).format("Do MMM YYYY") }}</b-col
        >
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right"
          >Claim created date</b-col
        >
        <b-col class="col-6"
          ><i class="fas fa-paw"></i
          >{{ moment(item.claim_created).format("Do MMM YYYY") }}</b-col
        >
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">Claim type</b-col>
        <b-col class="col-6"
          ><i class="fas fa-paw"></i>{{ item.claim_type }}</b-col
        >
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">Claim section</b-col>
        <b-col class="col-6"
          ><i class="fas fa-paw"></i>{{ item.claim_sub_type }}</b-col
        >
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">Claim status</b-col>
        <b-col class="col-6"><i class="fas fa-paw"></i>{{ item.status }}</b-col>
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">Created by</b-col>
        <b-col class="col-6"
          ><i class="fas fa-paw"></i>{{ item.created_by }}</b-col
        >
      </b-row>
      <b-row class="mb-5" v-if="item.vet_name && item.vet_name.length > 0">
        <b-col class="col-6 font-weight-bold text-right"
          >Veterinary surgery name</b-col
        >
        <b-col class="col-6"
          ><i class="fas fa-paw"></i>{{ item.vet_name }}</b-col
        >
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">Description</b-col>
        <b-col class="col-6"
          ><i class="fas fa-paw"></i>{{ item.claim_description }}</b-col
        >
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right"
          >Claim information provided</b-col
        >
        <b-col class="col-6">
          <i class="fas fa-paw"></i>
          <i
            v-if="item.claim_data_complete === true"
            class="fas fa-check-circle green icon-large"
          ></i>
          <i v-else class="fas fa-times-circle red icon-large"></i>
          {{ item.claim_data_complete === true ? "Yes" : "No" }}
          <div>
            <b-button
              v-if="item.claim_data_complete === false"
              size="md"
              class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
              @click="$spark.$fix('claim', item.id, awaitingInfo)"
              >Add Claim Information</b-button
            >
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-5" v-if="item.claim_data_complete === true">
        <b-col class="col-6 font-weight-bold text-right"
          >Required claim documents provided</b-col
        >
        <b-col class="col-6">
          <i class="fas fa-paw"></i>
          <i
            v-if="item.claim_docs_complete === true"
            class="fas fa-check-circle green icon-large"
          ></i>
          <i v-else class="fas fa-times-circle red icon-large"></i>
          {{ item.claim_docs_complete === true ? "Yes" : "No" }}
          <div>
            <b-button
              v-if="item.claim_docs_complete === false"
              size="md"
              class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
              @click="$spark.$fix('claim', item.id, awaitingDocs)"
              >Upload Claim Documents</b-button
            >
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-5" v-if="linkParamsClaimCustomerApprove">
        <b-col class="col-6 font-weight-bold text-right"
          >Claim awaiting customer approval</b-col
        >
        <b-col class="col-6">
          <i class="fas fa-paw"></i>
          <i class="fas fa-check-circle red icon-large"></i>Yes
          <div>
            <b-button
              v-if="this.$store.getters[CURRENT_USER].$customer"
              size="md"
              class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
              @click="openVetClaimData"
              >Review / Approve Claim</b-button
            >
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-5" v-if="linkParamsClaimAddFurtherInfo">
        <b-col class="col-6 font-weight-bold text-right"
          >Claim awaiting futher customer information</b-col
        >
        <b-col class="col-6">
          <i class="fas fa-paw"></i>
          <i class="fas fa-check-circle red icon-large"></i>Yes
          <div>
            <b-button
              size="md"
              class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
              @click="$spark.$fix('claim', item.id, awaitingFurtherInfo)"
              >Provide Further Information</b-button
            >
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  PAGE_NAME_PORTAL_DOCUMENT_CLAIM_UPLOAD,
  PAGE_NAME_PORTAL_CLAIM_CUSTOMER_MANAGE,
  PAGE_NAME_PORTAL_CLAIM_VET_MANAGE,
  PAGE_NAME_PORTAL_CLAIM_CUSTOMER_UPDATE,
  PAGE_NAME_PORTAL_CLAIM_VET_UPDATE,
  PAGE_NAME_PORTAL_CLAIM_CUSTOMER_APPROVE,
  PAGE_NAME_PORTAL_CLAIM_CUSTOMER_FURTHER_INFO,
  PROBLEM_AWAITING_CUSTOMER_INFO,
  PROBLEM_AWAITING_CUSTOMER_DOCS,
  PROBLEM_AWAITING_VET_INFO,
  PROBLEM_AWAITING_VET_DOCS,
  PROBLEM_AWAITING_CUSTOMER_APPROVAL,
  PROBLEM_AWAITING_CUSTOMER_FURTHER_INFO,
  SET_CLAIM_DETAIL_PK,
  CLAIM_INFORMATION_COMPONENT,
  CURRENT_USER
} from "@/core/services/vars.service";

export default {
  name: "Portal-Claim-General-Details",
  short_name: "General Details",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  created() {
    this.CURRENT_USER = CURRENT_USER;
  },
  methods: {
    openVetClaimData() {
      this.$store.getters[CLAIM_INFORMATION_COMPONENT].showClaimData("vet", true);
    }
  },
  computed: {
    awaitingInfo() {
      if (
        this.$store.getters[CURRENT_USER].$customer &&
        this.item.action_required.hasOwnProperty(PROBLEM_AWAITING_CUSTOMER_INFO)
      )
        return PROBLEM_AWAITING_CUSTOMER_INFO;
      if (
        this.$store.getters[CURRENT_USER].$vet &&
        this.item.action_required.hasOwnProperty(PROBLEM_AWAITING_VET_INFO)
      )
        return PROBLEM_AWAITING_VET_INFO;
      return false;
    },
    awaitingDocs() {
      if (
        this.$store.getters[CURRENT_USER].$customer &&
        this.item.action_required.hasOwnProperty(PROBLEM_AWAITING_CUSTOMER_DOCS)
      )
        return PROBLEM_AWAITING_CUSTOMER_DOCS;
      if (
        this.$store.getters[CURRENT_USER].$vet &&
        this.item.action_required.hasOwnProperty(PROBLEM_AWAITING_VET_DOCS)
      )
        return PROBLEM_AWAITING_VET_DOCS;
      return false;
    },
    awaitingCustomerApproval() {
      if (
        this.item.action_required.hasOwnProperty(
          PROBLEM_AWAITING_CUSTOMER_APPROVAL
        )
      )
        return PROBLEM_AWAITING_CUSTOMER_APPROVAL;
      return false;
    },
    awaitingFurtherInfo() {
      if (
        this.item.action_required.hasOwnProperty(
          PROBLEM_AWAITING_CUSTOMER_FURTHER_INFO
        )
      )
        return PROBLEM_AWAITING_CUSTOMER_FURTHER_INFO;
      return false;
    },
    manageName() {
      if (this.$store.getters[CURRENT_USER].$customer) {
        return PAGE_NAME_PORTAL_CLAIM_CUSTOMER_MANAGE;
      }
      if (this.$store.getters[CURRENT_USER].$vet) {
        return PAGE_NAME_PORTAL_CLAIM_VET_MANAGE;
      }
      return null;
    },
    updateName() {
      if (this.$store.getters[CURRENT_USER].$customer) {
        return PAGE_NAME_PORTAL_CLAIM_CUSTOMER_UPDATE;
      }
      if (this.$store.getters[CURRENT_USER].$vet) {
        return PAGE_NAME_PORTAL_CLAIM_VET_UPDATE;
      }
      return null;
    },
    linkParamsClaimAddInformation() {
      if (!this.item.action_required) return null;
      if (this.item.action_required.hasOwnProperty(this.awaitingInfo)) {
        return {
          title: "Add Claim Information",
          manage_name: this.updateName,
          manage_params: {
            pk_name: SET_CLAIM_DETAIL_PK,
            pk: this.item.id
          }
        };
      }
      return null;
    },
    linkParamsClaimUploadDocs() {
      if (!this.item.action_required) return null;
      if (this.item.action_required.hasOwnProperty(this.awaitingDocs)) {
        return {
          title: "Upload Claim Documents",
          manage_name: PAGE_NAME_PORTAL_DOCUMENT_CLAIM_UPLOAD,
          manage_params: {
            pk_name: SET_CLAIM_DETAIL_PK,
            pk: this.item.id
          }
        };
      }
      return null;
    },
    linkParamsClaimCustomerApprove() {
      if (!this.item.action_required) return null;
      if (
        this.item.action_required.hasOwnProperty(this.awaitingCustomerApproval)
      ) {
        return {
          title: "Review &amp; Approve Claim",
          manage_name: PAGE_NAME_PORTAL_CLAIM_CUSTOMER_APPROVE,
          manage_params: {
            pk_name: SET_CLAIM_DETAIL_PK,
            pk: this.item.id
          }
        };
      }
      return null;
    },
    linkParamsClaimAddFurtherInfo() {
      if (!this.item.action_required) return null;
      if (this.item.action_required.hasOwnProperty(this.awaitingFurtherInfo)) {
        return {
          title: "Provide Further Information",
          manage_name: PAGE_NAME_PORTAL_CLAIM_CUSTOMER_FURTHER_INFO,
          manage_params: {
            pk_name: SET_CLAIM_DETAIL_PK,
            pk: this.item.id
          }
        };
      }
      return null;
    }
  }
};
</script>

import $spark from "@/core/services/spark.service";
import {
  PORTAL_DASHBOARD_STATUS,
  SET_NAV_BLOCKED,
  SET_NAV_BLOCKED_FN,
  NAV_BLOCKED,
  NAV_BLOCKED_LOGOUT_DATA,
  SET_NAV_BLOCKED_LOGOUT_DATA
} from "@/core/services/vars.service";

const state = {
  nav_blocked: false,
  nav_blocked_fn: {
    stay: null,
    away: null
  },
  nav_blocked_logout_data: null
};

const getters = {
  [NAV_BLOCKED]: state => {
    return state.nav_blocked;
  },
  [NAV_BLOCKED_LOGOUT_DATA]: state => {
    return state.nav_blocked_logout_data;
  }
};

const actions = {
  [SET_NAV_BLOCKED](context, payload) {
    if (payload.status === true) {
      window.addEventListener("beforeunload", payload.fn);
    }
    if (payload.status === false) {
      window.removeEventListener("beforeunload", payload.fn);
    }
    context.commit(SET_NAV_BLOCKED, payload.status);
  },
  [SET_NAV_BLOCKED_FN](context, payload) {
    context.commit(SET_NAV_BLOCKED_FN, payload);
  },
  [SET_NAV_BLOCKED_LOGOUT_DATA](context, payload) {
    context.commit(SET_NAV_BLOCKED_LOGOUT_DATA, payload);
  }
};

const mutations = {
  [SET_NAV_BLOCKED](state, payload) {
    state.nav_blocked = payload;
    if (payload === false) {
      state.nav_blocked_logout_data = null;
    }
  },
  [SET_NAV_BLOCKED_FN](state, payload) {
    state.nav_blocked_fn.stay = payload.stay;
    state.nav_blocked_fn.away = payload.away;
  },
  [SET_NAV_BLOCKED_LOGOUT_DATA](state, payload) {
    state.nav_blocked_logout_data = payload;
  }
};


const dynamic = [
  {
    name: PORTAL_DASHBOARD_STATUS,
    url: {
      path: $spark.$urls.$portal.spark.dashboard
    }
  }
];

const _store = $spark.$inject(dynamic, {
  state,
  actions,
  mutations,
  getters
});
export default _store;

<template>
  <div class="spark-page">
    <div class="spark-page-title">{{ $options.short_name }}</div>
    <div v-if="$store.getters[CURRENT_USER].$customer" class="spark-page-content column-fluid row">
      <div class="col col-sm-12 col-md-6 col-lg-6">
        <spark-active-policies
          v-if="getActivePolicies"
          :item="getActivePolicies"
        ></spark-active-policies>
        <spark-active-claims
          v-if="getActiveClaims"
          :item="getActiveClaims"
        ></spark-active-claims>
        <spark-account-contact-details
          v-if="getAccountDetail"
          :item="getAccountDetail.data"
        ></spark-account-contact-details>
      </div>
      <div class="col col-sm-12 col-md-6 col-lg-6">
        <spark-policy-summary
          v-if="recentPolicy"
          :item="recentPolicy"
          :recent="true"
        ></spark-policy-summary>
        <spark-claim-summary
          v-if="recentClaim"
          :item="recentClaim"
          :recent="true"
        ></spark-claim-summary>
      </div>
    </div>
    <div v-if="$store.getters[CURRENT_USER].$vet" class="spark-page-content column-fluid row">
      <div class="col col-sm-12 col-md-6 col-lg-6">
        <spark-active-claims
          v-if="getActiveClaims"
          :item="getActiveClaims"
        ></spark-active-claims>
      </div>
      <div class="col col-sm-12 col-md-6 col-lg-6">
        <spark-claim-summary
          v-if="recentClaim"
          :item="recentClaim"
          :recent="true"
        ></spark-claim-summary>
      </div>
    </div>
  </div>
</template>

<script>
import {
  CURRENT_USER,
  POLICY_LIST,
  PORTAL_DASHBOARD_STATUS,
  ACCOUNT_DETAIL,
  CLAIM_LIST
} from "@/core/services/vars.service";

import SparkPolicySummary from "@/view/spark_components/portal/policy/PolicySummary";
import SparkActivePolicies from "@/view/spark_components/portal/policy/ActivePolicies";
import SparkClaimSummary from "@/view/spark_components/portal/claim/ClaimSummary";
import SparkActiveClaims from "@/view/spark_components/portal/claim/ActiveClaims";
import SparkAccountContactDetails from "@/view/spark_components/portal/account/ContactDetails";

export default {
  name: "Portal-Dashboard",
  short_name: "Dashboard",
  path: "dashboard",
  components: {
    "spark-active-policies": SparkActivePolicies,
    "spark-active-claims": SparkActiveClaims,
    "spark-policy-summary": SparkPolicySummary,
    "spark-claim-summary": SparkClaimSummary,
    "spark-account-contact-details": SparkAccountContactDetails
  },
  created() {
    this.CURRENT_USER = CURRENT_USER;
  },
  computed: {
    status() {
      return this.$store.getters[PORTAL_DASHBOARD_STATUS];
    },
    getActivePolicies() {
      if (this.$store.getters[CURRENT_USER].$customer) {
        if (!this.status) return null;
        return this.status.active_policies;
      }
      return null;
    },
    getActiveClaims() {
      if (!this.status) return null;
      return this.status.active_claims;
    },
    getClaimList() {
      return this.$store.getters[CLAIM_LIST];
    },
    recentClaim() {
      if (!this.getClaimList) return null;
      if (this.getClaimList.length === 0) return null;
      return this.getClaimList[0];
    },
    getPolicyList() {
      return this.$store.getters[POLICY_LIST];
    },
    recentPolicy() {
      if (!this.getPolicyList) return null;
      return this.getPolicyList[0];
    },
    getAccountDetail() {
      return this.$store.getters[ACCOUNT_DETAIL];
    }
  },
  mounted() {
    this.$spark.$breadcrumbs(this.$options);
  }
};
</script>

<template>
  <div>
    <div class="row justify-content-md-center">
      <b-modal
        ref="spark-password-modal"
        centered
        hide-footer
        title="Change Your Password"
      >
        <div class="d-block text-left">
          <b-row class="mt-4 mb-4">
            <b-col cols="12">
              <label>Enter your current password</label>
            </b-col>
            <b-col cols="10" class="input_icon_fixed_width">
              <input type="password" v-model="current" class="pl-15" />
              <i class="fas fa-paw"></i>
              <div class="vue_field_error pt-2" v-if="!lengthMatched.current">
                <ul>
                  <li v-if="!lengthMatched.current">
                    Must be at least 8 characters long
                  </li>
                </ul>
              </div>
            </b-col>
            <b-col cols="2">
              <i
                class="fas fa-exclamation-circle field_error_icon pt-1"
                v-if="!lengthMatched.current"
              ></i>
            </b-col>
          </b-row>
          <b-row class="mt-4 mb-4">
            <b-col cols="12">
              <label>Enter your new password</label>
            </b-col>
            <b-col cols="10" class="input_icon_fixed_width">
              <input type="password" v-model="new_pass" class="pl-15" />
              <i class="fas fa-paw"></i>
              <div
                class="vue_field_error pt-2"
                v-if="!lengthMatched.new_pass || newMatchesOld === true"
              >
                <ul>
                  <li v-if="!lengthMatched.new_pass">
                    Must be at least 8 characters long
                  </li>
                  <li v-if="newMatchesOld === true">
                    Must not match your current password
                  </li>
                </ul>
              </div>
            </b-col>
            <b-col cols="2">
              <i
                class="fas fa-exclamation-circle field_error_icon pt-1"
                v-if="!lengthMatched.new_pass || newMatchesOld === true"
              ></i>
            </b-col>
          </b-row>
          <b-row class="mt-4 mb-4">
            <b-col cols="12">
              <label>Confirm your new password</label>
            </b-col>
            <b-col cols="10" class="input_icon_fixed_width">
              <input type="password" v-model="confirm" class="pl-15" />
              <i class="fas fa-paw"></i>
              <div
                class="vue_field_error pt-2"
                v-if="!lengthMatched.confirm || newMatchesConfirm === false"
              >
                <ul>
                  <li v-if="!lengthMatched.confirm">
                    Must be at least 8 characters long
                  </li>
                  <li v-if="newMatchesConfirm === false">
                    Must match your new password
                  </li>
                </ul>
              </div>
            </b-col>
            <b-col cols="2">
              <i
                class="fas fa-exclamation-circle field_error_icon pt-1"
                v-if="!lengthMatched.confirm || newMatchesConfirm === false"
              ></i>
            </b-col>
          </b-row>
        </div>
        <b-row>
          <b-col>
            <b-button
              size="md"
              v-html="'Cancel'"
              class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
              @click="closeCancel"
            ></b-button>
          </b-col>
          <b-col>
            <b-button
              v-if="canSubmit"
              size="md"
              v-html="'Submit'"
              class="mt-5 rounded green-bg green-border spark-variant-text-light font-weight-bold float-right"
              @click="closeOK"
            ></b-button>
          </b-col>
        </b-row>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  SET_ACCOUNT_PASSWORD_CHANGE_COMPONENT,
  SUBMIT_PASSWORD_CHANGE_FORM
} from "@/core/services/vars.service";

export default {
  name: "SparkChangePassword",
  data() {
    return {
      current: "",
      new_pass: "",
      confirm: ""
    };
  },
  computed: {
    lengthMatched() {
      return {
        current: this.current.length >= 8,
        new_pass: this.new_pass.length >= 8,
        confirm: this.confirm.length >= 8
      };
    },
    newMatchesConfirm() {
      if (this.new_pass.length === 0 || this.confirm.lenth === 0) return null;
      return this.new_pass === this.confirm;
    },
    newMatchesOld() {
      if (this.new_pass.length === 0 || this.current.lenth === 0) return null;
      return this.new_pass === this.current;
    },
    canSubmit() {
      if (
        this.lengthMatched.current === true &&
        this.lengthMatched.new_pass === true &&
        this.lengthMatched.confirm === true
      ) {
        if (this.newMatchesConfirm === true && this.newMatchesOld === false) {
          return true;
        }
      }
      return false;
    }
  },
  methods: {
    closeCancel() {
      this.closeModal();
    },
    closeOK() {
      if (this.canSubmit) {
        let payload = {
          current_password: this.current,
          new_password: this.new_pass,
          new_password_confirm: this.confirm
        };
        this.$spark.$api(SUBMIT_PASSWORD_CHANGE_FORM, payload).then(() => {
          this.current = "";
          this.new_pass = "";
          this.confirm = "";
          this.closeModal();
          this.$spark.$success("Password changed");
        }).catch(() => {});
      }
    },
    openModal() {
      this.$refs["spark-password-modal"].show();
    },
    closeModal() {
      this.$refs["spark-password-modal"].hide();
    }
  },

  mounted() {
    this.$spark.$dispatch(SET_ACCOUNT_PASSWORD_CHANGE_COMPONENT, this);
  }
};
</script>

<template>
  <div>
    <h4 v-html="item.label" v-if="root"></h4>
    <div v-for="(obj, index) in itemData" :key="index" class="ml-5 mt-5 mb-15">
      <template v-if="obj.hasOwnProperty('field_type')">
        <spark-claim-data-item :type="type" :obj="obj"></spark-claim-data-item>
      </template>
      <template v-else-if="Array.isArray(obj.data)">
        <div v-for="(inner, inner_key) in obj.data" :key="'1_' + inner_key">
          <div
            v-for="(inner_deep, inner_deep_key) in inner"
            :key="'3_' + inner_deep_key"
          >
            <template v-if="inner_deep.hasOwnProperty('field_type')">
              <spark-claim-data-item
                :type="type"
                :obj="inner_deep"
              ></spark-claim-data-item>
            </template>
            <template v-else>
              <spark-claim-data
                :type="type"
                :item="inner_deep"
              ></spark-claim-data>
            </template>
          </div>
          <!--<spark-claim-data v-for="(inner_deep, inner_deep_key) in inner" :key="'3_'+inner_deep_key" :type="type" :item="inner_deep"></spark-claim-data>-->
        </div>
      </template>
      <template v-else>
        <spark-claim-data :type="type" :item="obj"></spark-claim-data>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "Portal-Claim-Information-Data",
  props: {
    type: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    root: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    itemData() {
      if (!this.item.data) return null;
      if (Array.isArray(this.item.data)) {
        return this.item.data;
      }
      if (typeof this.item.data === "string") {
        return this.item.data;
      }
      let values = Object.values(this.item.data);
      return values.sort((a, b) => a.order - b.order);
    }
  }
};
</script>

<template>
  <div class="spark-page">
    <div class="spark-page-title">{{ $options.short_name }}</div>
    <div class="spark-page-content column-fluid row justify-content-center">
      <div class="col col-sm-12 col-md-12 col-lg-10" v-if="references">
        <spark-correspondence-contact-us
          :references="references"
        ></spark-correspondence-contact-us>
      </div>
    </div>
  </div>
</template>

<script>
import {
  PAGE_NAME_PORTAL_CORRESPONDENCE_CONTACT_US,
  CORRESPONDENCE_CONTACT_REFERENCES
} from "@/core/services/vars.service";

import SparkCorrespondenceContactUs from "@/view/spark_components/portal/correspondence/ContactUs";

export default {
  name: PAGE_NAME_PORTAL_CORRESPONDENCE_CONTACT_US,
  short_name: "Contact Us",
  path: "contact-us",
  components: {
    "spark-correspondence-contact-us": SparkCorrespondenceContactUs
  },
  computed: {
    references() {
      return this.$store.getters[CORRESPONDENCE_CONTACT_REFERENCES];
    }
  },
  mounted() {
   this.$spark.$breadcrumbs(this.$options);
  }
};
</script>

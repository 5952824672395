import $spark from "@/core/services/spark.service";
import { LOOKUP_BANK_DETAILS } from "@/core/services/vars.service";

const state = {};
const getters = {};
const actions = {};
const mutations = {};

const dynamic = [
  {
    name: LOOKUP_BANK_DETAILS,
    method: "post",
    url: {
      path: $spark.$urls.$portal.bank_details
    }
  }
];

const _store = $spark.$inject(dynamic, {
  state,
  actions,
  mutations,
  getters
});
export default _store;

<template>
  <div class="spark-page" v-if="list && parentDetail">
    <div class="spark-page-title">{{ $options.short_name }} [Ref: {{ parentDetail.claim_number }}]</div>
    <div class="spark-page-content column-fluid row justify-content-center">
      <div class="col col-sm-12 col-md-12 col-lg-10">
        <spark-document
          v-for="(doc, index) in mandatory"
          :key="index"
          :item="doc"
          prefix="Claim"
          :upload="true"
          :parent="parentDetail.id"
        ></spark-document>
      </div>
    </div>
    <template v-if="optional && optional.length > 0">
      <div class="spark-page-title">(If you have copies)</div>
      <div class="spark-page-content column-fluid row justify-content-center">
        <div class="col col-sm-12 col-md-12 col-lg-10">
          <spark-document
            v-for="(doc, index) in optional"
            :key="index"
            :item="doc"
            prefix="Claim"
            :upload="true"
            :parent="parentDetail.id"
          ></spark-document>
        </div>
      </div>
    </template>
    <div class="spark-page-title">Extra Documents</div>
      <div class="spark-page-content column-fluid row justify-content-center">
        <div class="col col-sm-12 col-md-12 col-lg-10">
          <spark-extra-document
            :parent="parentDetail.id"
            :list="parentDetail.extra_documents"
            :upload="true"
          ></spark-extra-document>
        </div>
      </div>
  </div>
</template>

<script>
import {
  PAGE_NAME_PORTAL_DOCUMENT_CLAIM_UPLOAD,
  CLAIM_DOCUMENT_LIST,
  CLAIM_DETAIL
} from "@/core/services/vars.service";
import SparkDocument from "@/view/spark_components/portal/document/Document";
import SparkExtraDocument from "@/view/spark_components/portal/document/ExtraDocument";

export default {
  name: PAGE_NAME_PORTAL_DOCUMENT_CLAIM_UPLOAD,
  short_name: "Upload Claim Documents",
  path: "upload",
  components: {
    "spark-document": SparkDocument,
    "spark-extra-document": SparkExtraDocument
  },
  computed: {
    mandatory() {
      if (!this.list) return null;
      return this.list.data.filter(item => {
        return item.required;
      });
    },
    optional() {
      if (!this.list) return null;
      return this.list.data.filter(item => {
        return !item.required;
      });
    },
    parentDetail() {
      return this.$store.getters[CLAIM_DETAIL];
    },
    list() {
      return this.$store.getters[CLAIM_DOCUMENT_LIST];
    }
  },
  beforeMount() {
   /*((mutation, state) => {
      if (mutation.type === SET_CLAIM_DOCUMENT_LIST) {
        this.list = state.claim_document.list;
      }
      if (mutation.type === SET_CLAIM_DETAIL) {
        this.parentDetail = state.claim.detail[state.claim.detail_pk];
      }
    });*/
  },
  mounted() {
    this.$spark.$breadcrumbs(this.$options);
  }
};
</script>

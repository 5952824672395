<template>
  <div class="spark-page">
    <div class="spark-page-title">{{ $options.short_name }}</div>
    <spark-contact-us-public></spark-contact-us-public>
  </div>
</template>

<script>

import {
  IS_AUTHENTICATED,
  PAGE_NAME_PORTAL_CORRESPONDENCE_CONTACT_US
} from "@/core/services/vars.service";

import SparkContactUsPublic from "@/view/spark_components/portal/ContactUsPublic";

export default {
  name: "Portal-About-ContactUs",
  short_name: "Contact Us",
  path: "contact-us",
  components: {
    "spark-contact-us-public": SparkContactUsPublic
  },
  beforeMount() {
    if (this.$store.getters[IS_AUTHENTICATED]) {
      this.$spark.$nav({name: PAGE_NAME_PORTAL_CORRESPONDENCE_CONTACT_US});
    }
  },
  mounted() {
    this.$spark.$breadcrumbs(this.$options);
  }
};
</script>

<template>
  <div>
    <router-view />
  </div>
</template>

<script>

export default {
  name: "Layout-Portal-ClaimDocument",
  short_name: "Claim Documents",
  path: "document",
  methods: {
    loadData() {

    }
  },
  watch: {
    $route: function() {
      this.loadData();
    }
  },
  mounted() {
    this.loadData();
  }
};
</script>

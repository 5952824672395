<template>
  <div class="spark-page" v-if="detail">
    <div class="spark-page-title">{{ $options.short_name }}</div>
    <div class="spark-page-content column-fluid row justify-content-center">
      <div class="col col-sm-12 col-md-12 col-lg-10">
        <spark-correspondence-email
          v-if="detail.contact_type === 2"
          :item="detail"
          :content="detail.metadata.content"
        ></spark-correspondence-email>
        <spark-correspondence-display-contact-us
          v-if="detail.contact_type === 1"
          :item="detail"
          :full="true"
        ></spark-correspondence-display-contact-us>
      </div>
    </div>
  </div>
</template>

<script>
import {
  PAGE_NAME_PORTAL_CORRESPONDENCE_MANAGE,
  CORRESPONDENCE_DETAIL
} from "@/core/services/vars.service";

import SparkCorrespondenceEmail from "@/view/spark_components/portal/correspondence/Email";
import SparkCorrespondenceDisplayContactUs from "@/view/spark_components/portal/correspondence/DisplayContactUs";

export default {
  name: PAGE_NAME_PORTAL_CORRESPONDENCE_MANAGE,
  short_name: "Manage Correspondence",
  path: "manage",
  components: {
    "spark-correspondence-email": SparkCorrespondenceEmail,
    "spark-correspondence-display-contact-us": SparkCorrespondenceDisplayContactUs
  },
  computed: {
    detail() {
      return this.$store.getters[CORRESPONDENCE_DETAIL];
    },
  },
  mounted() {
    this.$spark.$breadcrumbs(this.$options);
  }
};
</script>

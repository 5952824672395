var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.template,{tag:"component",staticClass:"spark-portal-menu"},[_c('div',{staticClass:"row justify-content-center spark-portal-menu py-2"},[_vm._l((_vm.$store.getters[_vm.MENU_ITEMS]),function(menu,index){return [('children' in menu)?[_c('div',{key:'menu_parent_link_' + index,staticClass:"btn-group m-2"},[_c('router-link',{key:'menu_link_' + index,attrs:{"to":menu.path || '#empty',"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
return [_c('b-dropdown',{class:{
                'menu-item-open': _vm.hasActiveChildren(menu.path)
              },attrs:{"split-class":"rounded-left font-weight-bold spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light","toggle-class":"rounded-right font-weight-bold spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light","right":"","split":"","size":"lg","text":menu.label},on:{"click":navigate}},[_vm._l((menu.children),function(sub,index1){return [(!sub.seperator)?_c('router-link',{key:'menu_sub_' + index1,attrs:{"to":sub.path,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('b-dropdown-item',{staticClass:"bg-light",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"},on:{"click":navigate}},[_c('span',{domProps:{"innerHTML":_vm._s(sub.label)}})])]}}],null,true)}):_c('b-dropdown-divider',{key:'menu_sub_' + index1})]})],2)]}}],null,true)})],1)]:[_c('div',{key:'menu_item_' + index,staticClass:"btn-group m-2"},[_c('router-link',{attrs:{"to":menu.path,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('button',{staticClass:"menu-item rounded px-4 font-weight-bold spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"type":"button","size":"lg","aria-haspopup":"true","aria-expanded":"false"},on:{"click":navigate}},[_c('span',{domProps:{"innerHTML":_vm._s(menu.label)}})])]}}],null,true)})],1)]]}),[(_vm.$store.getters[_vm.IS_AUTHENTICATED])?_c('div',{staticClass:"btn-group m-2"},[_c('button',{staticClass:"menu-item rounded px-4 font-weight-bold spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light",attrs:{"type":"button","size":"lg","aria-haspopup":"true","aria-expanded":"false"},on:{"click":function($event){return _vm.logout()}}},[_vm._v(" Logout ")])]):(_vm.$route.name !== _vm.$spark.$config.views.portal.login.name)?_c('div',{staticClass:"btn-group m-2"},[_c('button',{staticClass:"menu-item rounded px-4 font-weight-bold spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light",attrs:{"type":"button","size":"lg","aria-haspopup":"true","aria-expanded":"false"},on:{"click":function($event){return _vm.$spark.$portalLogin()}}},[_vm._v(" Login ")])]):_vm._e()]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div>{{ $options.name }}</div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "Layout-Admin-Search",
  path: "search"
};
</script>

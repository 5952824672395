<template>
  <div class="mb-10">
    <b-card
      class="mb-3 rounded-card spark-variant-primary-bg-light spark-variant-primary-border spark-variant-text-dark spark-block-content"
      header-class="card-header rounded-card-header fw-400 py-4 px-4 green-bottom-border spark-variant-primary-bg"
    >
      <template #header>
        <b-row>
          <b-col>
            <div class="spark-block-title">
              <i
                class="fas fa-cloud-upload-alt icon-extra-large spark-variant-secondary-text mr-4"
              ></i>
              {{ $options.short_name }} (Optional)
            </div>
          </b-col>
        </b-row>
      </template>

      <template v-if="upload" class="mb-20">

        <div class="text-center mb-10">
          <p>If you have extra documents you think may assist your claim, please provide them here</p>
        </div>

        <div v-if="upload_show">
          <div class="text-center mb-10">
            <b-button
              size="md"
              class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
              @click="hideExtraForm"
              >Cancel</b-button
            >
          </div>

          <b-row class="mb-10">
            <b-col class="col-4 font-weight-bold text-right pt-4">Document description</b-col>
            <b-col class="col-6 input_icon_fixed_width">
              <input
                type="text"
                v-model="description"
                class="pl-15"
              />
              <i class="fas fa-paw"></i>
              <div
                class="vue_field_error pt-2"
                v-if="description.length < 5"
              >
                <ul>
                  <li>You must enter the file description<br/>Must be at least 5 characters long</li>
                </ul>
              </div>
            </b-col>
            <b-col class="col-2">
              <i
                class="fas fa-exclamation-circle field_error_icon pt-2"
                v-if="description.length < 5"
              ></i>
            </b-col>
          </b-row>
          <b-row class="mb-5 justify-content-center">
            <b-col class="col-8">
              <b-form-file
                ref="spark-file"
                class="spark-file-multiple"
                v-model="file"
                :state="Boolean(file)"
                placeholder="Choose file or drop..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-center mb-10">
              <b-button
                v-if="showButton"
                size="md"
                class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
                @click="uploadDocument"
                >Upload Document</b-button
              >
            </b-col>
          </b-row>

        </div>
        <div class="text-center mb-10" v-else>
          <b-button
            size="md"
            class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
            @click="showExtraForm"
            >Add File(s)</b-button
          >
        </div>


      </template>

      <div class="spark-page-title mb-10">Uploaded files</div>

      <div class="font-weight-bold h6 mb-10 text-center">No files</div>

      <div v-for="(item, index) in list" :key="index">

        <b-row class="mb-5">
          <b-col class="col-4 font-weight-bold text-right"
            >Description</b-col
          >
          <b-col class="col-8">
            <i class="fas fa-paw"></i>
            <span>{{ item.name }}</span>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col class="col-4 font-weight-bold text-right"
            >Uploaded by</b-col
          >
          <b-col class="col-8">
            <i class="fas fa-paw"></i>
            <span>{{ item.user_name }} ({{ item.user_type.replace('vet', 'Vet').replace('policy_holder', 'Policy Holder') }})</span>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col class="col-4"></b-col>
          <b-col class="col-8">
            <b-button
              size="md"
              class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
              @click="openInNewTab(item.link)"
              >View File</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { UPLOAD_CLAIM_EXTRA_DOCUMENT } from "@/core/services/vars.service";

export default {
  name: "Portal-Extra-Document",
  short_name: "Claim Extra Documents",
  props: {
    parent: {
      type: Number,
      required: true
    },
    list: {
      type: Array,
      required: true
    },
    upload: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      file: null,
      input: null,
      description: "",
      upload_show: false
    };
  },
  methods: {
    postData() {
      const form_data = new FormData();
      //formData.append("file", file);
      if (!this.file || this.description.length < 5) return null;
      form_data.append("description", this.description);
      form_data.append("filedata", this.file);
      return form_data;
    },
    uploadDocument() {
      this.$spark.$api(UPLOAD_CLAIM_EXTRA_DOCUMENT, {
        pk: this.parent,
        filedata: this.postData()
      }).then(() => {
        this.$parent.$mount();
        this.file = null;
        this.description = "";
        this.hideExtraForm();
      });
    },
    showExtraForm()  {
      this.upload_show = true;
    },
    hideExtraForm() {
      this.upload_show = false;
    }
  },
  computed: {
    showButton() {
      if (this.file) {
        return true;
      }
      return false;
    }
  }
};
</script>

<style>
.spark-file-multiple,
.spark-file-multiple label,
.spark-file-multiple label::after,
.spark-file-multiple input {
  height: 100px;
}
</style>

<template>
  <div class="mb-10">
    <b-card
      class="mb-3 rounded-card spark-variant-primary-bg-light spark-variant-primary-border spark-variant-text-dark spark-block-content"
      header-class="card-header rounded-card-header fw-400 py-4 px-4 green-bottom-border spark-variant-primary-bg"
    >
      <template #header>
        <b-row>
          <b-col class="col-8">
            <div class="spark-block-title">
              <i
                class="fas fa-at icon-extra-large spark-variant-secondary-text mr-2"
              ></i>
              {{ $options.short_name }}
              <span v-if="item.direction === 1">(Sent to us)</span>
              <span v-if="item.direction === 2">(Sent to you)</span>
            </div>
          </b-col>
          <b-col class="col-4 justify-content-right">
            <b-button
              v-if="content"
              size="md"
              class="rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold float-right"
              >Re-Send Email</b-button
            >
          </b-col>
        </b-row>
      </template>

      <b-row class="mb-5">
        <b-col class="col-4 font-weight-bold text-right">Date / time</b-col>
        <b-col class="col-8"
          ><i class="fas fa-paw"></i
          >{{ moment(item.created).format("Do MMM YYYY, h:mm:ss A") }}</b-col
        >
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-4 font-weight-bold text-right">From</b-col>
        <b-col class="col-8"
          ><i class="fas fa-paw"></i>{{ item.metadata.from }}</b-col
        >
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-4 font-weight-bold text-right">To</b-col>
        <b-col class="col-8"
          ><i class="fas fa-paw"></i>{{ item.metadata.to }}</b-col
        >
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-4 font-weight-bold text-right">Subject</b-col>
        <b-col class="col-8"
          ><i class="fas fa-paw"></i>{{ item.metadata.subject }}</b-col
        >
      </b-row>
      <b-row class="mb-5" v-if="content">
        <b-col class="col-12 p-0" v-html="content"></b-col>
      </b-row>
      <b-button
        v-else
        size="md"
        class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
        @click="$spark.$nav(manage_name, manage_params)"
        >View Email</b-button
      >
    </b-card>
  </div>
</template>

<script>
import {
  PAGE_NAME_PORTAL_CORRESPONDENCE_MANAGE,
  SET_CORRESPONDENCE_DETAIL_PK
} from "@/core/services/vars.service";

export default {
  name: "Portal-Correspondence-Email",
  short_name: "Email",
  props: {
    item: {
      type: Object,
      required: true
    },
    content: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      manage_name: PAGE_NAME_PORTAL_CORRESPONDENCE_MANAGE,
      manage_params: {
        pk_name: SET_CORRESPONDENCE_DETAIL_PK,
        pk: this.item.id
      }
    };
  }
};
</script>

<template>
  <div class="mb-10">
    <b-card
      class="mb-3 rounded-card spark-variant-primary-bg-light spark-variant-primary-border spark-variant-text-dark spark-block-content"
      header-class="card-header rounded-card-header fw-400 py-4 px-4 green-bottom-border spark-variant-primary-bg"
    >
      <template #header>
        <b-row>
          <b-col>
            <div class="spark-block-title">
              <i
                class="fas fa-hourglass-start icon-extra-large spark-variant-secondary-text mr-2"
              ></i>
              {{ $options.short_name }}
            </div>
          </b-col>
        </b-row>
      </template>

      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right">Action required</b-col>
        <b-col class="col-6">
          <i class="fas fa-paw"></i>
          <i
            v-if="item.action_required !== false"
            class="fas fa-check-circle red icon-large"
          ></i>
          <i v-else class="fas fa-times-circle green icon-large"></i>
          {{ item.action_required !== false ? "Yes" : "No" }}
          <div>
            <b-button
              v-if="item.action_required === true"
              size="md"
              class="mt-5 rounded spark-variant-secondary-bg spark-variant-secondary-border spark-variant-text-light font-weight-bold"
              @click="$spark.$fix('policy')"
              >Fix Problems</b-button
            >
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-5">
        <b-col class="col-6 font-weight-bold text-right"
          >Number of active policies</b-col
        >
        <b-col class="col-6">
          <i class="fas fa-paw"></i>
          <span class="fa-stack">
            <i
              class="fas fa-circle fa-stack-1x icon-large spark-variant-primary-text"
            ></i>
            <i
              class="fas fa-hashtag fa-stack-1x ml-3 spark-variant-text-light"
              style="font-size: 0.8em;"
            ></i>
          </span>
          <span class="ml-13">{{ item.total }}</span>
        </b-col>
      </b-row>
      <b-row class="mb-5" v-if="paymentIssue.length > 0">
        <b-col class="col-6 font-weight-bold text-right"
          >Number of policies with payments outstanding</b-col
        >
        <b-col class="col-6">
          <i class="fas fa-paw"></i>
          <span class="fa-stack">
            <i
              class="fas fa-circle fa-stack-1x icon-large spark-variant-primary-text"
            ></i>
            <i
              class="fas fa-hashtag fa-stack-1x ml-3 spark-variant-text-light"
              style="font-size: 0.8em;"
            ></i>
          </span>
          <span class="ml-13">{{ paymentIssue.length }}</span>
        </b-col>
      </b-row>
      <b-row class="mb-5" v-if="renewalDue.length > 0">
        <b-col class="col-6 font-weight-bold text-right"
          >Number of policies due for renewal soon</b-col
        >
        <b-col class="col-6">
          <i class="fas fa-paw"></i>
          <span class="fa-stack">
            <i
              class="fas fa-circle fa-stack-1x icon-large spark-variant-primary-text"
            ></i>
            <i
              class="fas fa-hashtag fa-stack-1x ml-3 spark-variant-text-light"
              style="font-size: 0.8em;"
            ></i>
          </span>
          <span class="ml-13">{{ renewalDue.length }}</span>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  PROBLEM_PAYMENT_ISSUE,
  PROBLEM_RENEWAL_DUE
} from "@/core/services/vars.service";

export default {
  name: "Portal-Policy-Summary",
  short_name: "Active Policies",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    paymentIssue() {
      if (!this.item) return null;
      return this.item.actions.filter(a => {
        if (a.action.hasOwnProperty(PROBLEM_PAYMENT_ISSUE)) return true;
        return false;
      });
    },
    renewalDue() {
      if (!this.item) return null;
      return this.item.actions.filter(a => {
        if (a.action.hasOwnProperty(PROBLEM_RENEWAL_DUE)) return true;
        return false;
      });
    }
  }
};
</script>

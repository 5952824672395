import $spark from "@/core/services/spark.service";
import {
  COMPLAINT_DETAIL_PK,
  COMPLAINT_DOCUMENT_LIST,
  COMPLAINT_DOCUMENT_DETAIL,
  COMPLAINT_DOCUMENT_DETAIL_PK,
  COMPLAINT_DETAIL,
  SET_COMPLAINT_DOCUMENT_DETAIL_PK,
  UPLOAD_COMPLAINT_DOCUMENT,
} from "@/core/services/vars.service";

const state = {
  detail_pk: null
};

const getters = {
  [COMPLAINT_DOCUMENT_DETAIL_PK]: state => {
    return state.detail_pk;
  }
};

const actions = {
  [SET_COMPLAINT_DOCUMENT_DETAIL_PK](context, payload) {
    $spark.$storage.set(SET_COMPLAINT_DOCUMENT_DETAIL_PK, payload);
    context.commit(SET_COMPLAINT_DOCUMENT_DETAIL_PK, payload);
    //return $spark.$api(COMPLAINT_DOCUMENT_DETAIL);
  }
};

const mutations = {
  [SET_COMPLAINT_DOCUMENT_DETAIL_PK](state, data) {
    state.detail_pk = data;
  }
};

const dynamic = [
  {
    name: COMPLAINT_DOCUMENT_LIST,
    url: {
      path: $spark.$urls.$portal.document.complaint.list,
      replace: {
        "<COMPLAINT_PK>": COMPLAINT_DETAIL_PK
      }
    }
  },
  {
    name: COMPLAINT_DOCUMENT_DETAIL,
    url: {
      path: $spark.$urls.$portal.document.complaint.detail,
      pk: true,
      replace: {
        "<COMPLAINT_PK>": COMPLAINT_DETAIL_PK,
        "<DOCUMENT_PK>": COMPLAINT_DOCUMENT_DETAIL_PK
      }
    }
  },
  {
    name: UPLOAD_COMPLAINT_DOCUMENT,
    method: "post",
    file: true,
    url: {
      path: $spark.$urls.$portal.document.complaint.detail,
      replace: {
        "<COMPLAINT_PK>": COMPLAINT_DETAIL_PK,
        "<DOCUMENT_PK>": COMPLAINT_DOCUMENT_DETAIL_PK
      }
    },
    dispatch_on_success: [COMPLAINT_DOCUMENT_LIST, COMPLAINT_DETAIL]
  }
];

const _store = $spark.$inject(dynamic, {
  state,
  actions,
  mutations,
  getters
});
export default _store;

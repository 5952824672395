<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { PROBLEMS } from "@/core/services/vars.service";

export default {
  name: "Layout-Portal-Fix-Problems",
  path: "problems",
  methods: {
    loadData() {
      this.$spark.$api(PROBLEMS).catch(() => {});
    }
  },
  watch: {
    $route: function() {
      this.loadData();
    }
  },
  mounted() {
    this.$nextTick(() => { 
      setTimeout(() => {
        this.loadData();
      },300);
    });
  }
};
</script>

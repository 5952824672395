<template>
  <div>
    <div class="row justify-content-md-center mt-10">
      <b-alert
        :show="alert.dismissCountDown"
        dismissible
        :variant="alert.variant"
        @dismissed="alert.dismissCountDown = 0"
        @dismiss-count-down="countDownChanged"
        class="spark-alert"
      >
        <p v-html="alert.message" class="h4 mt-2 ml-2 mb-5"></p>

        <b-progress
          variant="success"
          :max="alert.dismissSecs"
          :value="alert.dismissCountDown"
          height="4px"
        ></b-progress>
        <template v-slot:dismiss>
          <div class="alert-close">
            <span aria-hidden="true"><i class="la la-close"></i></span>
          </div>
        </template>
      </b-alert>
    </div>
  </div>
</template>

<script>
import { ALERT_DATA, RESET_ALERT } from "@/core/services/vars.service";

export default {
  data() {
    return {
      alert: {
        showDismissibleAlert: false,
        dismissSecs: 10,
        dismissCountDown: 0,
        message: "",
        variant: ""
      }
    };
  },
  computed: {
    alertData() {
      return this.$store.getters[ALERT_DATA];
    }
  },
  watch: {
    alertData: {
      handler: function(al) {
        this.alert.message = al.message;
        this.alert.dismissSecs = al.timer;
        this.alert.variant = al.variant;
        if (al.message) {
          this.showAlert();
        } else {
          this.hideAlert();
        }
      },
      deep: true
    }
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.alert.dismissCountDown = dismissCountDown;
      if (dismissCountDown === 0) {
        this.$spark.$dispatch(RESET_ALERT);
      }
    },
    showAlert() {
      this.alert.dismissCountDown = this.alert.dismissSecs;
    },
    hideAlert() {
      this.alert.dismissCountDown = 0;
    }
  }
};
</script>

<style>
.spark-alert {
  width: calc((100% / 12) * 5);
}
@media (max-width: 991.98px) {
  .spark-alert {
    width: calc(90%);
    margin-left: calc(5%);
    margin-right: calc(5%);
  }
}
</style>
